import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
// import './index.css';
import App from './App';

import { BrowserRouter as Router } from 'react-router-dom'

Sentry.init({ dsn: "https://a7c690259ba941f9bc019322e3d636a6@sentry.io/4509785" });

ReactDOM.render(<Router><App /></Router >, document.getElementById('root'));

