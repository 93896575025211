import { Select as AntSelect, Button, Card, Icon, Input, Modal, Popconfirm } from 'antd';
import React from 'react';
import Normalizer from '../../components/Normalizer';
import SchemaModal from '../../components/SchemaModal';
import { encapsulate } from '@simplifier/normalization';
import { deleteStep, updateStep } from '../../utils/workflows.js';

export default class WebhookStep extends React.Component {
	constructor(props) {
		super(props)

		this.state = { outputMappingModal: false }
	}

	getSelectedEvent(id, availableEvents) {
		let match = availableEvents.filter(ev => ev._id === id)

		if (!match.length) return null

		return match[0]
	}

	render() {
		const { step, localWorkflow, availableVariables, workflow, availableMethods, products, updateWorkflow, updateLocalWorkflow, updateAvailableMethods, workflowVariables, availableEvents } = this.props
		const { outputMappingModal } = this.state

		const selectedEvent = this.getSelectedEvent(step.event, availableEvents)

		return <React.Fragment>
			<td valign="top" style={{ position: 'relative' }}>
				<Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-25px', top: '150px' }} />
				<Card
					className='workflowCard'
					title="Emit event"
					extra={<Popconfirm
						title="Do you really want to delete this step?"
						onConfirm={() => {
							let newWorkflow = deleteStep(workflow, step)
							updateWorkflow(newWorkflow)
						}}
						okText="Yes"
						cancelText="No"
					>
						<Button size="small" shape="circle" icon="close" />
					</Popconfirm>}
				>

					{/* {step.uniqueId} */}


					<AntSelect
						showSearch
						optionFilterProp="children"
						style={{ width: '100%', height: 50 }}
						size="large"
						placeholder={"Click to select an event"}
						value={step.event}
						onChange={async (eventId) => {
							let newStep = { ...step }
							newStep.event = eventId
							newStep.properties = this.getSelectedEvent(eventId, availableEvents).input
							const newWorkflow = updateStep(localWorkflow, newStep)
							updateLocalWorkflow(newWorkflow)
						}}
					>
						{availableEvents.map(event => <AntSelect.Option key={event._id} value={event._id}>{event.name}</AntSelect.Option>)}
					</AntSelect>


					{
						selectedEvent && <>

							<Button block size="large" type="default" onClick={() => this.setState({ outputMappingModal: true })} style={{ marginBottom: 15 }}>Edit values</Button>
							<Modal
								title="Update data mapping"
								visible={outputMappingModal}
								onOk={() => { updateWorkflow(localWorkflow); this.setState({ outputMappingModal: false }) }}
								onCancel={() => this.setState({ outputMappingModal: false })}
								width={800}
								style={{ maxWidth: "95%" }}
							>
								<Normalizer
									input={step.properties}
									availableVariables={[...availableVariables, ...encapsulate(workflowVariables, "storage")]}
									updateStepInput={input => {

										console.log(input)

										let newStep = { ...step }
										newStep.properties = input
										const newWorkflow = updateStep(localWorkflow, newStep)
										updateLocalWorkflow(newWorkflow)
									}}
								/>
							</Modal>
						</>
					}
					{/* 
					<SchemaModal
						input={step.properties}
						style={{ marginBottom: 10 }}
						onUpdate={inputSchema => {
							// console.log({ workflow, inputSchema })
							// let newWorkflow = workflow
							// newWorkflow.inputSchema = inputSchema
							// this.updateWorkflow(newWorkflow)

							let newStep = { ...step }
							newStep.properties = inputSchema
							const newWorkflow = updateStep(localWorkflow, newStep)
							updateLocalWorkflow(newWorkflow)
						}}
					/>

					<h4>With the following values...</h4>


					<Button block size="large" type="default" onClick={() => this.setState({ outputMappingModal: true })} style={{ marginBottom: 15 }}>Edit values</Button>
					<Modal
						title="Update data mapping"
						visible={outputMappingModal}
						onOk={() => { updateWorkflow(localWorkflow); this.setState({ outputMappingModal: false }) }}
						onCancel={() => this.setState({ outputMappingModal: false })}
						width={800}
						style={{maxWidth: "95%"}}
					>
						<Normalizer
							input={step.properties}
							availableVariables={[...availableVariables, ...encapsulate(workflowVariables, "storage")]}
							updateStepInput={input => {
								// const newWorkflow = updateStepInput(localWorkflow, step.stepId, input)
								// updateLocalWorkflow(newWorkflow)

								console.log(input)

								let newStep = { ...step }
								newStep.properties = input
								const newWorkflow = updateStep(localWorkflow, newStep)
								updateLocalWorkflow(newWorkflow)
							}}
						/>
					</Modal>

					<h4>To the following URL...</h4>

					<Input
						placeholder="https://www.myurl.com/hook"
						style={{ marginBottom: 5 }}
						size="large"
						value={step.url || ""}

						onChange={e => {
							let newStep = { ...step }
							newStep.url = e.target.value
							const newWorkflow = updateStep(localWorkflow, newStep)
							updateLocalWorkflow(newWorkflow)
						}} /> */}

				</Card>
			</td>
		</React.Fragment>
	}
}