import objectPath from 'object-path'

function buildEmptySchema(example, models) {
    let scaffold = { _models: [models] }
    
    function buildScaffoldForObject(obj, models) {
        let emptyScaffold = {}
        for (let model of models) {

            // console.log(obj)

            if (obj && typeof obj === "object" && Object.keys(obj).length === 0) continue;

            emptyScaffold['_' + model] = "#"
            emptyScaffold['_example'] = obj
            emptyScaffold['_type'] = typeof obj
        }
        
        if(obj === null) return emptyScaffold
        if(typeof obj === 'string') return emptyScaffold

        if(typeof obj === 'string') return emptyScaffold
        if(typeof obj === 'number') return emptyScaffold
        if(typeof obj === 'integer') return emptyScaffold
        if(typeof obj === 'boolean') return emptyScaffold
        if(typeof obj === 'object' && Array.isArray(obj)) {
            if(obj.length === 0) return emptyScaffold
            return obj.map(item => {
                return {...buildScaffoldForObject(item, models), _base: emptyScaffold }
            }).slice(0, 1)
        }
        if(typeof obj === 'object') {
            let scaff = {}
            for(let key in obj) {
                scaff[key] = buildScaffoldForObject(obj[key], models)
            }
            if(Object.keys(obj).length === 0) return emptyScaffold
            return scaff
        }           
        return emptyScaffold
    }
    return { ...buildScaffoldForObject(example, models), _models: models }
}

function setProperty(schema, property, valuePath, model) {

    console.log('Setting value ' + valuePath + ' to property ' + property + '._' + model)

    if (valuePath) {
        objectPath.set(schema, property + '._' + model, (typeof valuePath === "string" ? valuePath.trim() : valuePath))
    } else {
        objectPath.set(schema, property + '._' + model, '#')
    }

    return schema
}

function setArrayProperty(schema, base, property, valuePath, model) {
    let part = objectPath.get(schema, base)[0]
    objectPath.set(part, property + '._' + model, valuePath);
    
    objectPath.set(schema, base + '.0', part)
    
    return schema
} 

function getPaths(schema, base) {
    const allPaths = []

    // console.log({gettingPathFromSchema: schema})

    function isAnObjectWithChildren(key, value) {

        // try {
        //     let v = typeof value === "object"
        //         && key[0] !== '_'
        //         && !value['_' + schema._models[0]]
        // // } catch (e) {
        // //     console.log(key)
        // //     console.log(value)
        // //     console.log(typeof value)
        // //     console.log(key[0])
        // //     console.log({schema})
        // //     console.log(value['_' + schema._models[0]])
        // }
    
        
        return value
            && typeof value === "object" 
            && !Array.isArray(value)
            && key[0] !== '_' 
            && !value['_' + schema._models[0]]
    }

    function isAnArrayWithChildren(key, value) {

        // try {
        //     let v = typeof value === "object"
        //         && key[0] !== '_'
        //         && !value['_' + schema._models[0]]
        // // } catch (e) {
        // //     console.log(key)
        // //     console.log(value)
        // //     console.log(typeof value)
        // //     console.log(key[0])
        // //     console.log({schema})
        // //     console.log(value['_' + schema._models[0]])
        // }
    
        
        return value
            && typeof value === "object" 
            && Array.isArray(value)
            && key[0] !== '_' 
            && !value['_' + schema._models[0]]
    }

    function isAnEmptyObject(key, value) {
        return (value && typeof value == "object")
            // && Object.keys(value).length === 0
    }

    function isAnArray(key, value) {
        return typeof value == "object" && Array.isArray(value) && key !== '_models'
    }

    function appendOriginPath(originPath, key) {
        return originPath + (originPath ? '.' : '') + key
    }

    const getPath = function(object, originPath) {
        for(let key in object) {
            if(object.hasOwnProperty(key)) {

                let value = object[key]

                if (isAnObjectWithChildren(key, value)) {
                    
                    // console.log('Is an object with children', key, value)

                    getPath(value, appendOriginPath(originPath, key))

                } else if (isAnArray(key, value)) {
                    
                    // console.log('Is an array', key, value)

                    const children = { ...value[0], _models: schema._models }

                    // console.log({ children })

                    // console.log({push: {
                    //     _base: appendOriginPath(originPath, key),
                    //     paths: getPaths(children),
                    //     type: "array"
                    // }})

                    allPaths.push({
                        _base: appendOriginPath(originPath, key),
                        paths: getPaths(children),
                        type: "array"
                    })
                    
                // } else if (isAnEmptyObject(key, value)) {



                } else if (key[0] !== '_') {
                    
                    // console.log('Is neither', key, value)

                    const child = value['_' + schema._models[0]]

                    if (typeof child === "object") continue;

                    allPaths.push({ 
                        path: appendOriginPath(originPath, key),
                        label: appendOriginPath(originPath, key),
                        type: value._type,
                        example: value._example,
                        _x: value._x !== "#" ? value._x : ""
                    })
                }
            }
        }
        return object
    }
    getPath(schema, base || '')
    return allPaths
}






export { buildEmptySchema, setProperty, setArrayProperty, getPaths }