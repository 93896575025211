import { Spinner } from "@blueprintjs/core";
import { buildTree } from '@simplifier/normalization';
import { Badge, Button, Card, Descriptions, message, PageHeader, Select, Tabs, Typography } from 'antd';
import React from 'react';
import Pretty from 'react-json-prettify';
import { atomOneLight } from 'react-json-prettify/dist/themes';
import api from '../api';
import SchemaModal from '../components/SchemaModal';

const RunKit = require('react-runkit')

const { Paragraph } = Typography;

const { TabPane } = Tabs;

const outputCodes = [
    { value: 200, label: "Success" },
    { value: 201, label: "Resource created" },
    { value: 404, label: "Not found" },
    { value: 500, label: "Something wrong happened" },
]

export default class Event extends React.Component {
    constructor(props) {
        super(props)

        console.log("Event page")

        this.state = {
            eventStructure: {},
            saveLoading: false
        }
    }

    async componentDidMount() {
        const { eventId } = this.props.match.params
        const { data: { eventStructure } } = await api(`events/${eventId}`)

        this.setState({ eventStructure: eventStructure })
    }

    async regenerateSecret() {
        const project = this.state.eventStructure.project


        const { data: { secret } } = await api('projects/' + project._id + '/regenerate_secret')

        let eventStructure = this.state.eventStructure
        eventStructure.project.secret = secret

        this.setState({ eventStructure })
    }

    addOutput() {
        let { eventStructure } = this.state

        let name = "Success", i = 0
        let isNameUsed = (outputs, name) => outputs.filter(o => o.name === name).length
        while (isNameUsed(eventStructure.outputs, name)) {
            i++
            name = `Success (${i})`
        }

        eventStructure.outputs.push({
            name,
            code: 200,
            properties: []
        })

        this.setState({ eventStructure })
    }

    updateOutputName(outputName, newName) {
        let { eventStructure } = this.state
        let outputs = eventStructure.outputs.filter(o => o.name === outputName)

        // Check if exists

        if (eventStructure.outputs.filter(o => o.name === newName).length) {
            alert('Name already taken!')
        } else {

            if (outputs[0]) {
                outputs[0].name = newName
            }

            this.setState({ eventStructure })
        }
    }

    updateInputProperties(properties) {
        let { eventStructure } = this.state
        eventStructure.input = properties

        this.setState({ eventStructure })
    }


    updateOutputProperties(outputName, properties) {
        let { eventStructure } = this.state
        let outputs = eventStructure.outputs.filter(o => o.name === outputName)

        if (outputs[0]) {
            outputs[0].properties = properties
        }

        this.setState({ eventStructure })
    }

    updateOutputCode(outputName, code) {
        let { eventStructure } = this.state
        let outputs = eventStructure.outputs.filter(o => o.name === outputName)

        if (outputs[0]) {
            outputs[0].code = code
        }

        this.setState({ eventStructure })
    }

    async saveEvent() {
        this.setState({ saveLoading: true })
        const { eventId } = this.props.match.params
        await api.post(`events/${eventId}`, this.state.eventStructure)

        this.setState({ saveLoading: false })
    }

    async deleteEvent() {
        const { eventStructure } = this.state

        if(window.confirm("Are you sure you want to delete this event?")) {
            const { eventId } = this.props.match.params
            const { data } = await api(`events/${eventId}/delete`, 'post')
            message.success(<span>Event has been deleted</span>);
            this.props.history.push(`/${eventStructure.project._id}/scenarios`) 
        }
    }

    render() {
        const { eventStructure } = this.state

        console.log({ eventStructure })

        if (!eventStructure._id) {
            return <React.Fragment>
                <PageHeader
                    title={"Event"}
                    style={{ backgroundColor: 'white' }}
                >
                    <br /><br /><h3 className="text-center"><Spinner /></h3><br /><br />
                </PageHeader>
            </React.Fragment>
        }


        return <React.Fragment>

            <PageHeader
                title={eventStructure.name}
                onBack={() => { if (eventStructure.project) { this.props.history.push(`/${eventStructure.project._id}/scenarios`) } }}
                // subTitle={block.description}
                // onBack={() => this.props.history.push(`/products/${block.product.id}`)}
                style={{ backgroundColor: 'white' }}
                extra={[
                    <Button type="danger" size="large" icon="delete" onClick={this.deleteEvent.bind(this)}>Delete event</Button>,
                    eventStructure.project && eventStructure.project._id === "28216bkx64mszu" ? <Button key={2} type="primary" size="large" icon="save" onClick={() => window.open('https://ytest.gitbook.io/libeo/introduction/events')}>Documentation</Button> : <></>,
                    <Button key={1} type="primary" size="large" icon="save" onClick={this.saveEvent.bind(this)} loading={this.state.saveLoading}>Save</Button>
                ]}
            >

                {/* <SchemaBuilder inputSchema={eventStructure.input || []} onUpdate={console.log} /> */}



            </PageHeader>

            <br />

            <Card style={{ margin: 15 }} title="Input" extra={<SchemaModal
                input={eventStructure.input || []}
                onUpdate={this.updateInputProperties.bind(this)}
                noMargin
            />}>
                <Pretty theme={atomOneLight} json={buildTree(eventStructure.input || [])} padding={5} />
            </Card>

            <Card style={{ margin: 15 }} title="Output(s)" extra={<Button block size="large" type="default" onClick={this.addOutput.bind(this)}>
                Add output
            </Button>}>
                <Tabs type="card">
                    {eventStructure.outputs.map((output, i) => <TabPane tab={<><Badge status={output.code < 300 ? "success" : "error"} /> {output.name}</>} key={i}>

                        <Descriptions bordered layout="vertical" size="small">
                            <Descriptions.Item label="Name"><Paragraph className="ant-typography-upd" editable={{ onChange: newName => this.updateOutputName(output.name, newName) }}>{output.name}</Paragraph></Descriptions.Item>
                            <Descriptions.Item label="Type">

                                <Select
                                    showSearch
                                    placeholder="Select a person"
                                    optionFilterProp="children"
                                    value={output.code}
                                    size="large"
                                    style={{ width: 200 }}
                                    onChange={code => this.updateOutputCode(output.name, code)}
                                >
                                    {outputCodes.map(code => <Select.Option value={code.value}>{code.label}</Select.Option>)}
                                </Select>


                            </Descriptions.Item>
                            <Descriptions.Item label="Actions"><SchemaModal
                                input={output.properties || []}
                                onOk={properties => this.updateOutputProperties(output.name, properties)}
                                noMargin
                            /></Descriptions.Item>
                        </Descriptions>

                        <br />

                        <Pretty theme={atomOneLight} json={buildTree(output.properties || [])} padding={5} />
                    </TabPane>)}
                </Tabs>
            </Card>

            <Card style={{ margin: 15 }} title="Trigger code" extra={eventStructure.project.secret ? <>
                <Button size="large" type="default" style={{ display: 'inline-block' }} onClick={this.regenerateSecret.bind(this)}>
                    Generate new secret key
                </Button>
            </> : <>
                <Button size="large" type="primary" style={{ display: 'inline-block' }} onClick={this.regenerateSecret.bind(this)}>
                    Generate secret key
                </Button>
            </>}>

                <RunKit source={`const Simplifier = require('@simplifier/node-client').default

const simplifier = new Simplifier({
    appId: "${eventStructure.project._id}",
    privateKey: "${eventStructure.project.secret || "GENERATE_A_SECRET_KEY_FIRST"}"
})
            
simplifier.emit("${eventStructure.name}", ".*", ${JSON.stringify(buildTree(eventStructure.input || []), null, 2)}).then(console.log)`} />
            </Card>
        </React.Fragment>
    }
}
