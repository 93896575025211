import { Button, Card, Table, Typography } from 'antd';
import 'brace/mode/json';
import 'brace/theme/solarized_dark';
import React, { useState } from 'react';
import TimeAgo from 'react-timeago';
import api from '../api';
import SchemaBuilder from '../components/SchemaBuilder';

const { Title, Paragraph } = Typography;

function DeleteButton(props) {
    const { authId, removeUserFromUI } = props
    const [loading, setLoading] = useState(false)

    return <Button
        type="danger"
        icon="delete"
        loading={loading}
        onClick={async () => {
            await setLoading(true)
            await api.delete(`users/auth/${authId}`)
            console.log("deleted")
            removeUserFromUI()
            await setLoading(false)
        }}
    >
        Delete
    </Button>
}

export default class WorkflowUsers extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            workflowId: props.workflowId,
            auths: []
        }
    }

    async componentDidMount() {
        const { data: { auths } } = await api(`workflow/${this.state.workflowId}/users`)

        console.log({
            auths
        })

        this.setState({ auths })
    }


    render() {
        const { auths, workflowId } = this.state
        const { setWorkflowVariables, workflowVariables } = this.props

        return <Card>
            <div style={{ float: 'right' }}>
                <Button size="large" icon="plus" onClick={async () => { const { data: { auths } } = await api(`workflow/${this.state.workflowId}/users`); this.setState({ auths }) }} icon="reload" /> &nbsp; &nbsp;
                <Button size="large" icon="plus" type="primary" onClick={() => this.props.history.push("/workflow/auth/" + workflowId)}>New user</Button>
            </div>


            <Title>Authenticated users</Title>
            {/* <Divider /> */}

            <Table
                bordered
                columns={[
                    {
                        title: "User token", dataIndex: "user", key: "user", render: user => {
                            return <Paragraph copyable={{ text: user.token }}><a key={0} href={`/users/${user._id}`} target="_blank">{user.token}</a></Paragraph>
                        }
                    },
                    { title: "User creation", dataIndex: "user", key: "userCreatedAt", render: user => <span title={user.createdAt}><TimeAgo date={user.createdAt} /></span> },
                    { title: "Connection to workflow", dataIndex: "createdAt", key: "createdAt", render: createdAt => <span title={createdAt}><TimeAgo date={createdAt} /></span>, defaultSortOrder: 'ascend', sorter: (a, b) => (new Date(a.createdAt)).getTime() - (new Date(b.createdAt)).getTime() },
                    { title: "Last invoke", dataIndex: "lastCheck", key: "lastCheck", render: lastCheck => lastCheck ? <span title={lastCheck}><TimeAgo date={lastCheck} /></span> : <span>—</span>, defaultSortOrder: 'ascend', sorter: (a, b) => (new Date(a.lastCheck)).getTime() - (new Date(b.lastCheck)).getTime() },
                    {
                        title: "Delete", dataIndex: "_id", key: "_id", render: _id => <DeleteButton
                            authId={_id}
                            removeUserFromUI={() => {
                                this.setState({ auths: auths.filter(auth => auth._id !== _id) })
                            }}
                        />
                    }
                ]}
                dataSource={auths}
            />

            <br />

            <Title>User variables</Title>

            <SchemaBuilder structureOnly inputSchema={workflowVariables || []} onUpdate={inputSchema => {
                console.log("Updating workflow variables", inputSchema)
                setWorkflowVariables(inputSchema)
            }} />

        </Card>
    }
}