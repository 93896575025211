import { Button, Card, Input, Steps, Table, Typography } from 'antd';
import React from 'react';
import api from '../api';
import ReactJson from 'react-json-view'
import ReactMarkdown from 'react-markdown'

const { Step } = Steps;
const { Title, Paragraph, Text } = Typography;

export default class Log extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            productId: null,
            product: null,
            authMode: null,
            developerData: {},
            authorizationLink: null,
            scope: ""
        }
    }

    async componentDidMount() {
        const { productId } = this.props.match.params
        const { data: { product, authMode } } = await api.get(`authentication/test/${productId}/init`)

        console.log({ productId, product, authMode })

        this.setState({ productId, product, authMode })
    }

    render() {
        const {
            productId,
            product,
            authMode,
            authorizationLink,
            scope
        } = this.state

        if (!product) {
            return <p>Loading...</p>
        }

        return <>
            <div style={{ padding: 24, minHeight: 360 }} >
                <Card>
                    <Title>Authentication test</Title>

                    <ReactJson src={product} />

                    {product.auth.developerDashboard && <><a
                        target="_blank"
                        href={product.auth.developerDashboard}
                    >
                        Developer dashboard
                    </a><br /><br /></>}

                    <ReactMarkdown source={product.auth.authenticationHelp} />

                    <p>Use the following redirect_uri : {api.endpoint + "test/callback"}</p>

                    <Table bordered pagination={false} dataSource={[
                        ...authMode.developerDataSchema.map(param => {
                            return {
                                key: param.path,
                                parameter: param.label,
                                value: { id: param.path, label: param.label }
                            }

                        })]} columns={[
                            { title: "Parameter", dataIndex: "parameter", key: "parameter" },
                            {
                                title: 'Value', dataIndex: 'value', key: 'value',
                                render: ({ id, label }) => <Input
                                    key={id}
                                    onChange={(e) => {
                                        let change = this.state.developerData
                                        change[id] = e.target.value
                                        this.setState(change)
                                    }}
                                />
                            }
                        ]}
                    />

                    <br />

                    <Input
                        onChange={(e) => {
                            this.setState({ scope: e.target.value })
                        }}
                        value={scope}
                        placeholder="Required scope"
                    />

                    <br />
                    <br />

                    <Button size="large" type="primary" onClick={async () => {
                        const result = await api.post(`authentication/test/${productId}/create-auth-link`, {
                            developerData: this.state.developerData,
                            scopes: scope
                        })

                        this.setState({
                            authorizationLink: result.data.authorizationLink
                        })
                    }}>Get authorization link</Button>

                    <br />

                    {
                        authorizationLink && <a
                            href={authorizationLink}
                            target="_blank"
                        >{authorizationLink}</a>
                    }

                </Card>
            </div>
        </>
    }
}
