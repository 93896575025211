import React from 'react'
import Form from 'react-bootstrap/Form'

import { Button, Form as AntForm, Input, Switch as AntSwitch, Icon, Select } from 'antd'

import { InputGroup, FormGroup, Switch } from '@blueprintjs/core'

export default class DynForm extends React.Component {
    constructor(props) {
        super(props)

        // console.log(this.props.promptVariables)

        let s = {}

        props.promptVariables.map(prompt => {
            let securitySchema = props.securitySchema[prompt]
            // console.log(prompt, securitySchema.type)
            // if(securitySchema.type === "string") s[prompt] = ""
            if(securitySchema.type === "boolean") s[prompt] = false
        })

        this.state = s
    }

    submitForm() {

        let config = this.state
        const payload = { config }

        console.log(config)

        return this.props.callback(payload)
    }

    render() {
        const { props: { promptVariables, securitySchema: variablesSecuritySchema, buttonText, alwaysShowButton } } = this

        const that = this
        return <Form onSubmit={ this.submitForm.bind(this) }>
            { promptVariables.map(prompt => {
                let securitySchema = variablesSecuritySchema[prompt]
                // console.log(variablesSecuritySchema, prompt, securitySchema)
                const isRequired = securitySchema.flags && securitySchema.flags.presence && securitySchema.flags.presence === "required"

                if(securitySchema.type === "string" && !securitySchema.valids) {

                        return <div><AntForm.Item 
                            key={prompt}
                            label={securitySchema.label || prompt}
                            helperText={securitySchema.description || ''}
                            required={isRequired}
                        >
                            <Input 
                                id={'variable.' + prompt} 
                                size="large" 
                                required 
                                placeholder={securitySchema.examples ? securitySchema.examples[0].value : ''} 
                                onChange={e => {
                                    let s = {}
                                    s[prompt] = e.target.value
                                    that.setState(s)
                                }}
                                value={that.state[prompt]}
                            />
                        </AntForm.Item></div>
                } 

                else if(securitySchema.type === "string" && securitySchema.valids) {
                    return <div><Form.Group key={prompt}>
                        <Form.Label>{prompt}{isRequired ? " (required)" : ""}</Form.Label>
                        <Form.Control id={'config.' + prompt} required={isRequired} as="select">
                            { securitySchema.valids.map(opt => <option value={opt}>{ opt }</option>) }
                        </Form.Control>
                    </Form.Group><br /></div>
                }

                if(securitySchema.type === "array") {

                    return <div><AntForm.Item 
                    key={prompt}
                    label={(securitySchema.label || prompt) + "— Separated by commas"}
                    helperText={securitySchema.description || ''}
                    required={isRequired}
                >
                    <Select 
                        size="large" 
                        mode="tags" 
                        style={{ width: '100%' }} 
                        required 
                        placeholder={securitySchema.examples ? securitySchema.examples[0].value : ''} 
                        onChange={value => {
                            let s = {}
                            s[prompt] = value
                            that.setState(s)
                        }}
                        open={false}
                        value={that.state[prompt]}
                        tokenSeparators={[',']}
                    ></Select>
                </AntForm.Item></div>


                    


                    return <div><FormGroup
                    key={prompt} 
                    label={securitySchema.label || prompt}
                    labelFor={'variable.' + prompt}
                    labelInfo={isRequired ? "(required)" : ""}
                    helperText={securitySchema.description || ''}
                >
                    <InputGroup id={'variable.' + prompt} large required placeholder={securitySchema.examples ? securitySchema.examples[0].value : ''} />
                </FormGroup><br /></div>
            } 

                else if(securitySchema.type === "boolean") {


                    return <AntForm.Item 
                        key={prompt}
                        label={securitySchema.label || prompt}
                        helperText={securitySchema.description || ''}
                        required={isRequired}
                    >
                        <AntSwitch
                            checkedChildren={<Icon type="check" />}
                            unCheckedChildren={<Icon type="close" />}
                            id={'variable.' + prompt}
                            size="large"
                            value={that.state[prompt]}
                            onChange={(c, e) => {
                                let s = {}
                                s[prompt] = c
                                that.setState(s)
                            }}
                        />
                    </AntForm.Item>


                    return <div><Form.Group 
                        key={prompt}
                        label={securitySchema.label || prompt}
                        labelFor={'variable.' + prompt}
                        labelInfo={isRequired ? "(required)" : ""}
                        helperText={securitySchema.description || ''}
                    >
                            <Switch 
                            // checked={this.state.isPublic} 
                            id={'variable.' + prompt}
                            label={securitySchema.label || prompt}
                            // onChange={this.handlePublicChange} 
                        />
                    </Form.Group><br /></div>



                    // return <Form.Group key={prompt}>
                    //     <Form.Label>{prompt}{isRequired ? " (required)" : ""}</Form.Label>
                    //     <Form.Control id={'config.' + prompt} required={isRequired} as="select">
                    //         <option value={true}>Yes</option>
                    //         <option value={false}>No</option>
                    //     </Form.Control>
                    // </Form.Group>
                }

                return <p>{ JSON.stringify(securitySchema) }</p>
            }) }

            {/* {JSON.stringify(this.state)} */}

            { promptVariables.length || alwaysShowButton ? <Button type="primary" block size="large" onClick={e => this.submitForm()}>
                { buttonText || "Create" }
            </Button> : <React.Fragment></React.Fragment> }
        </Form>
    }
}