// import Card from 'react-bootstrap/Card'
import { Breadcrumbs, Callout, Card, Code, Divider, H2, H3, InputGroup, Spinner } from "@blueprintjs/core";
import { Alert as AntAlert, Button as AntButton, Card as AntCard, Col, Descriptions, Input, PageHeader, Row, Steps, Typography } from 'antd';
import React from 'react';
import ReactJson from 'react-json-view';
import { Link } from 'react-router-dom';
import api from '../api';
import Trigger from './Trigger';






const { Paragraph } = Typography
const { Step } = Steps

export default class extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            setup: {},
        }
    }

    async componentDidMount() {
        const { setupId } = this.props.match.params

        


        const { data: setup } = await api('setups/' + setupId)
        const { data: { variableParameters, parametersSecuritySchema, way, expectedAnswer } } = await api(`action/${setupId}/variableParameters`)
        this.setState({ setup, setupId: setup.id, variableParameters, parametersSecuritySchema, way, expectedAnswer })

        console.log(expectedAnswer)
    }

    Config(props) {
        const { setup } = props
        return <Card>
            <H2>Integration configuration</H2>
            <Callout title="Redirect URL" icon="link">
                Set the redirect URL of your app to the following : <Code>{api.endpoint + "cb/" + setup.id}</Code>
            </Callout>

            <p>Edit JSON parameters to change your integration config.</p>
            <ReactJson src={setup.config} theme="solarized" style={{padding: 10, borderRadius: 3}} onEdit={ async (edit) => {
                await api(`setups/${setup.id}/updateConfig`, 'post', {
                    action: setup.action,
                    config: edit.updated_src
                })} } 
            />

            <br />

            {setup.isOAuth2 ? 
                <div>

                    <br /><Divider /><br />

                    <H2>Write, upload and run your custom integration code</H2>

                        <p style={{fontSize: 16}}>Open your terminel and run the following commands.</p>

                        <Code style={{display: 'block', whiteSpace: 'pre-wrap', fontSize: 16}}>
                            <span style={{userSelect: 'none'}}>$ </span>git clone https://github.com/ahirel/knowball-serverless<br />
                            <span style={{userSelect: 'none'}}>$ </span>cd knowball-serverless
                            <span style={{userSelect: 'none'}}>$ </span>npm init<br />
                            <span style={{userSelect: 'none'}}>$ </span>npm i --save axios<br />

                        </Code>

                        <br />
                        <br />

                        <p style={{fontSize: 16}}>Replace the content of the <code>serverless.yml</code> file by the following content.</p>

                        <Code style={{display: 'block', whiteSpace: 'pre-wrap', fontSize: 16, lineHeight: 1.5}}>
                        {"service: user-functions"} <br /><br />

                        {"provider:"}<br />
                        {"  name: aws"} <br />
                        {"  runtime: nodejs10.x"} <br /><br />

                        {"custom:"} <br />
                        {"  setupId: " + props.setupId} <br />
                        {"  baseUrl: " + props.setup.config.baseUrl} <br /><br />

                        {"functions:"} <br />
                        {"  " + props.setupId + ":"} <br />
                        {"    handler: handler.${self:custom.setupId}"} <br />
                        {"    environment:"} <br />
                        {"      SETUP_ID: ${self:custom.setupId}"} <br />
                        {"      BASE_URL: ${self:custom.baseUrl}"} <br />

                        </Code>


                        <br /><br />


                        <H2>Write the integration code</H2><br />

                        <p style={{fontSize: 16}}>Write your function code into the <code>index.js</code> file.</p>
                        <p style={{fontSize: 16}}>Return the value you want the function to respond.</p>
                        <p style={{fontSize: 16}}>Return the value you want the function to respond.</p>

                        <br /><H2>Upload and run the code</H2><br />


                        <br /><H2>Write, upload and run your custom integration code</H2><br />

                        <p style={{fontSize: 16}}>If serverless is not installed on your computer.</p>

                        <Code style={{display: 'block', whiteSpace: 'pre-wrap', fontSize: 16, lineHeight: 1.5}}><span style={{userSelect: 'none'}}>$ </span>npm install serverless -g</Code>

                        <br /><p style={{fontSize: 16}}>Run the following code in your terminal. Those keys are dedicated to you and let you upload Integration Functions and update them. Do not share them with anyone or publically publish them.</p>

                        <Code style={{display: 'block', whiteSpace: 'no-wrap', overflowX: 'scroll', fontSize: 16, lineHeight: 1.5}}><span style={{userSelect: 'none'}}>$ </span>AWS_ACCESS_KEY_ID=AKIAXT3JU7RIOUQH42U3 AWS_SECRET_ACCESS_KEY=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 serverless deploy</Code>



                </div>
            : <span></span>}
            
            </Card>
    }



    Auth(props) {
        const { setup } = props

        const deleteAuth = async function(authId) {
            await api('auths/' + authId + '/delete')
    
            const { setupId } = props.match.params
            const { data: setup } = await api('setups/' + setupId)
            props.updateState({ setup })
        }


        return <Card>
            <H2>Users (<Link to={"/authUser/" + setup.id}>Add a test user</Link>)</H2>

            <H3>Let users connect to the integration</H3>

            <p>Link to the following address from your site : <InputGroup large value={api.baseURL + 'authUser/' + setup.id + '?redirect_uri=YOUR_REDIRECT_URI'} /></p>

            <br /><p>If you already have an <Code>userToken</Code> from your user, pass it as a query parameter to the link and the integration will be linked to their existing user profile (Otherwise, a new profile will be created) : <InputGroup large value={api.baseURL + 'authUser/' + setup.id + '?redirect_uri=YOUR_REDIRECT_URI&userToken=1234567890'} /></p>

            <br /><p>After the authentication process, your user will be redirected to your <Code>redirect_uri</Code> with a query param <Code>userToken</Code> corresponding to that user.</p>

            <br /><Callout large intent='warning'>Do not forget to save the <Code>userToken</Code> as it is the only way to trigger the integration connection of that user.</Callout>
            
            <br /><br />
            { setup.auths && setup.auths.map(auth => {
                return <Callout style={{marginBottom: 10}} key={auth.id}>
                    <strong><Link to={"/auth/" + auth.id}>{auth.user.token}</Link> <a onClick={e => deleteAuth(auth.id)}>🗑</a> </strong><br />
                    {/* <Pre>{JSON.stringify(auth.parameters)}</Pre> */}
                    <ReactJson theme="solarized" style={{padding: 10, borderRadius: 3}} src={auth.parameters} />
                </Callout>
            }) }</Card>
    }


    render() {
        const { state: { setup }, props } = this

        const { Auth, Config } = this

        if(!setup.id) {
            return <span><br /><br /><h3 className="text-center"><Spinner /></h3></span>
        }

        const BREADCRUMBS = [
            { href: "/scenarios", icon: "folder-close", text: "Workflows" },
            { href: "/scenarios/" + setup.scenario.id, icon: "folder-close", text: setup.scenario.name },
            { icon: "document", text: setup.name },
        ];



        return <div style={{backgroundColor: 'white', padding: 24}}>


            <PageHeader title={<span><Breadcrumbs items={BREADCRUMBS} /><h1>{setup.name}</h1></span>}>
                
                <div className="wrap">
                    <div className="content">

                        { this.state.way && <Paragraph>

                            {
                                (this.state.way === "insideOut" && !setup.auths.length) ? 

                                <Steps current={2}>
                                    <Step title="Create workflow" />
                                    <Step title="Set up the config" />
                                    <Step title="Add a test user" description="Click the + in the users section" />
                                    <Step title="Done"  />
                                </Steps>

                                : <React.Fragment></React.Fragment>
                            }

                        {/* // SI insideOut
                            // Créer user test */}

                            {
                                (this.state.way === "outsideIn" && !setup.auths.length && (!setup.delay || !setup.webhook)) ? 

                                <Steps current={1}>
                                    <Step title="Create workflow" />
                                    <Step title="Set up the config" description="Enter a webhook URL and a delay" />
                                    <Step title="Add a test user" description="Click the + in the users section" />
                                    <Step title="Done"  />
                                </Steps>

                                : <React.Fragment></React.Fragment>
                            }

                            {
                                (this.state.way === "outsideIn" && !setup.auths.length && (setup.delay || setup.webhook)) ? 

                                <Steps current={2}>
                                    <Step title="Create workflow" />
                                    <Step title="Set up the config" description="Enter a webhook URL and a delay" />
                                    <Step title="Add a test user" description="Click the + in the users section" />
                                    <Step title="Done"  />
                                </Steps>

                                : <React.Fragment></React.Fragment>
                            }


                        {/*// SI outsideIn
                            // entrer webhook +delay
                            // créer user test


                        // Si webhook
                            // entrer webhook
                            // créer user test */}

                    
                            {
                                (this.state.way === "webhook" && !setup.auths.length && !setup.webhook) ? 

                                <Steps current={1}>
                                    <Step title="Create workflow" />
                                    <Step title="Set up the config" description="Enter a webhook URL" />
                                    <Step title="Add a test user" description="Click the + in the users section" />
                                    <Step title="Done"  />
                                </Steps>

                                : <React.Fragment></React.Fragment>
                            }

                            {
                                (this.state.way === "webhook" && !setup.auths.length && setup.webhook) ? 

                                <Steps current={2}>
                                    <Step title="Create workflow" />
                                    <Step title="Set up the config" description="Enter a webhook URL" />
                                    <Step title="Add a test user" description="Click the + in the users section" />
                                    <Step title="Done"  />
                                </Steps>

                                : <React.Fragment></React.Fragment>
                            }



                        </Paragraph>  }

                        <Paragraph>
                            <Descriptions title="Configuration" bordered>
                                {
                                    Object.keys(setup.config).map(key => {
                                        return <Descriptions.Item label={key}>
                                            {
                                                (typeof setup.config[key] === 'string') ? 

                                                    <span><Paragraph editable={{ onChange: async str => {
                                                        let newConfig = setup.config
                                                        newConfig[key] = str
                                                        
                                                        const newSetup = setup
                                                        newSetup.config = newConfig
                                                        this.setState({ setup: newSetup })

                                                        const { data } = await api(`setups/${setup.id}/updateConfig`, 'post', {
                                                            action: setup.action,
                                                            config: newConfig
                                                        })

                                                    }}}>{setup.config[key]}</Paragraph></span> : 

                                                    <span>
                                                        { ['boolean', 'number', 'integer'].includes(typeof setup.config[key]) ? <React.Fragment>{setup.config[key].toString()}</React.Fragment> : <React.Fragment></React.Fragment>}
                                                        &nbsp;(Non editable)
                                                    </span>

                                            }
                                        </Descriptions.Item>
                                    })
                                }
                            </Descriptions>
                        </Paragraph>

                        <Paragraph>
                            <AntAlert
                                message="Redirect URL"
                                description={<span>Please add the following link as a redirect URL : <Paragraph code copyable>{api.endpoint + "cb/" + setup.id}</Paragraph></span>}
                                type="info"
                                showIcon
                            />
                        </Paragraph>
                    </div>
                </div>
            </PageHeader>

            <div style={{ padding: 24, minHeight: 360 }}>

                <Row gutter={16}>
                    <Col span={12}>
                        <PageHeader title="Trigger and webhooks">
                            <div className="wrap">
                                <div className="content">
                                    <Trigger    
                                        setup={this.state.setup} 
                                        variableParameters={this.state.variableParameters} 
                                        parametersSecuritySchema={this.state.parametersSecuritySchema} 
                                        way={this.state.way} 
                                        expectedAnswer={this.state.expectedAnswer} 
                                    />  
                                </div>
                            </div>
                        </PageHeader>
                    </Col>
                    <Col span={12}>
                        <PageHeader title={"Users (" + setup.auths.length + ')'} extra={<AntButton shape="circle" icon="plus" onClick={() => this.props.history.push("/authUser/" + setup.id)} />}>
                            <div className="wrap">
                                <div className="content">
                                {/* <H2>Users (<Link to={"/authUser/" + setup.id}>Add a test user</Link>)</H2> */}

                                <h4>Let users connect to the integration</h4>

                                { setup.auths && setup.auths.map(auth => {


                                    return <React.Fragment>
                                        <AntCard title={<Paragraph copyable>{auth.user.token}</Paragraph>} extra={<Link to={"/auth/" + auth.id}>Open</Link>}>
                                            {/* <Link to={"/auth/" + auth.id}>{auth.user.token}</Link> */}

                                            <p><i>Last check : {auth.lastCheck}</i></p>

                                            { Object.keys(auth.parameters).length > 0 && <Descriptions title="Parameters" bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                                                { Object.keys(auth.parameters).map(key => {

                                                    return <Descriptions.Item label={key}>
                                                        {
                                                            (typeof auth.parameters[key] === 'string') ? 

                                                                <span><Paragraph>{auth.parameters[key]}</Paragraph></span> : 

                                                                <span>
                                                                    { ['boolean', 'number', 'integer'].includes(typeof setup.config[key]) ? <React.Fragment>{setup.config[key].toString()}</React.Fragment> : <React.Fragment></React.Fragment>}
                                                                </span>


                                                        }
                                                    </Descriptions.Item>
                                                })}
                                            </Descriptions> }

                                            <p><a onClick={async e => {
                                                await api('auths/' + auth.id + '/delete')
                                        
                                                const { setupId } = this.props.match.params
                                                const { data: setup } = await api('setups/' + setupId)
                                                this.setState({ setup })
                                            }}>🗑 Delete user</a></p>

                                            {/* { Object.keys(auth.parameters).length === 0 && <p>No parameter filled. <a onClick={async e => {
                                                await api('auths/' + auth.id + '/delete')
                                        
                                                const { setupId } = this.props.match.params
                                                const { data: setup } = await api('setups/' + setupId)
                                                this.setState({ setup })
                                            }}>🗑 Delete user</a></p> } */}
                                                                                
                                            {/* <ReactJson theme="solarized" style={{padding: 10, borderRadius: 3}} src={auth.parameters} /> */}

                                        </AntCard><br />
                                    </React.Fragment>


                                    // return <Callout style={{marginBottom: 10}} key={auth.id}>
                                    //     <strong><Link to={"/auth/" + auth.id}>{auth.user.token}</Link> <a onClick={e => deleteAuth(auth.id)}>🗑</a></strong><br />
                                    //     {/* <Pre>{JSON.stringify(auth.parameters)}</Pre> */}
                                    //     <ReactJson theme="solarized" style={{padding: 10, borderRadius: 3}} src={auth.parameters} />
                                    // </Callout>
                                }) }

                                <p>Add a link to the following address on your site and replace <code>YOUR_REDIRECT_URL</code> by the URL to which your users should be redirected when they are authenticated : <Input size="large" value={api.baseURL + 'authUser/' + setup.id + '?redirect_uri=YOUR_REDIRECT_URL'} /></p>

                                <p>If you already have an <code>userToken</code> from your user, pass it as a query parameter to the link and the integration will be linked to their existing user profile (Otherwise, a new profile will be created) : <Input size="large" value={api.baseURL + 'authUser/' + setup.id + '?redirect_uri=YOUR_REDIRECT_URL&userToken=1234567890'} /></p>

                                <p>After the authentication process, your user will be redirected to your <code>redirect_url</code> with a query param <Code>userToken</Code> corresponding to that user.</p>

                                <br /><Callout large intent='warning'>Do not forget to save the <code>userToken</code> as it is the only way to trigger the integration connection of that user.</Callout>


                                </div>
                            </div>
                        </PageHeader>
                    </Col>
                </Row>

            </div>

            



            {/* <Breadcrumbs items={BREADCRUMBS} />
            <br /> */}

            {/* <H1>{setup.name}</H1>

            <Tabs id="TabsExample" onChange={(newTabId) => this.setState({selectedTab: newTabId})} selectedTabId={this.state.selectedTab || 'home'}>
                <Tab id="home" title="Config" panel={<Config {...this.props} {...this.state} />} />
                <Tab id="triggers" title="Trigger" panel={<Trigger setup={this.state.setup} variableParameters={this.state.variableParameters} parametersSecuritySchema={this.state.parametersSecuritySchema} way={this.state.way} expectedAnswer={this.state.expectedAnswer} /> } />
                <Tab id="auths" title="Users" panel={<Auth {...this.props} {...this.state} updateState={s => this.setState(s)} />} />

                <Tabs.Expander />
            </Tabs> */}
            
        </div>
    }
}
