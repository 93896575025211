import { Button, Card, Icon, Input, Modal, Popconfirm } from 'antd';
import React from 'react';
import Normalizer from '../../components/Normalizer';
import SchemaModal from '../../components/SchemaModal';
import { encapsulate } from '@simplifier/normalization';
import { deleteStep, updateStep } from '../../utils/workflows.js';

export default class WebhookStep extends React.Component {
	constructor(props) {
		super(props)

		this.state = { outputMappingModal: false }
	}

	render() {
		const { step, localWorkflow, availableVariables, workflow, availableMethods, products, updateWorkflow, updateLocalWorkflow, updateAvailableMethods, workflowVariables } = this.props
		const { outputMappingModal } = this.state

		return <React.Fragment>
			<td valign="top" style={{ position: 'relative' }}>
				<Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-25px', top: '150px' }} />
				<Card
					className='workflowCard'
					title="Webhook"
					extra={<Popconfirm
						title="Do you really want to delete this step?"
						onConfirm={() => {
							let newWorkflow = deleteStep(workflow, step)
							updateWorkflow(newWorkflow)
						}}
						okText="Yes"
						cancelText="No"
					>
						<Button size="small" shape="circle" icon="close" />
					</Popconfirm>}
				>

					{/* {step.uniqueId} */}


					<h4>Post the following data structure...</h4>


					<SchemaModal
						input={step.properties}
						style={{ marginBottom: 10 }}
						onUpdate={inputSchema => {
							// console.log({ workflow, inputSchema })
							// let newWorkflow = workflow
							// newWorkflow.inputSchema = inputSchema
							// this.updateWorkflow(newWorkflow)

							let newStep = { ...step }
							newStep.properties = inputSchema
							const newWorkflow = updateStep(localWorkflow, newStep)
							updateLocalWorkflow(newWorkflow)
						}}
					/>

					<h4>With the following values...</h4>


					<Button block size="large" type="default" onClick={() => this.setState({ outputMappingModal: true })} style={{ marginBottom: 15 }}>Edit values</Button>
					<Modal
						title="Update data mapping"
						visible={outputMappingModal}
						onOk={() => { updateWorkflow(localWorkflow); this.setState({ outputMappingModal: false }) }}
						onCancel={() => this.setState({ outputMappingModal: false })}
						width={800}
						style={{maxWidth: "95%"}}
					>
						<Normalizer
							input={step.properties}
							availableVariables={[...availableVariables, ...encapsulate(workflowVariables, "storage")]}
							updateStepInput={input => {
								// const newWorkflow = updateStepInput(localWorkflow, step.stepId, input)
								// updateLocalWorkflow(newWorkflow)

								console.log(input)

								let newStep = { ...step }
								newStep.properties = input
								const newWorkflow = updateStep(localWorkflow, newStep)
								updateLocalWorkflow(newWorkflow)
							}}
						/>
					</Modal>

					<h4>To the following URL...</h4>

					<Input
						placeholder="https://www.myurl.com/hook"
						style={{ marginBottom: 5 }}
						size="large"
						value={step.url || ""}

						onChange={e => {
							let newStep = { ...step }
							newStep.url = e.target.value
							const newWorkflow = updateStep(localWorkflow, newStep)
							updateLocalWorkflow(newWorkflow)
						}} />

				</Card>
			</td>
		</React.Fragment>
	}
}