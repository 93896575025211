import React from 'react';
import api from '../api'

// import { Link, Redirect } from 'react-router-dom'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import { H1, H2, H3, Button as BPButton, InputGroup, Breadcrumbs, Callout, Card, Code, Divider, Icon, Slider } from "@blueprintjs/core"
import { Selec } from "@blueprintjs/select"

import DynForm from '../components/DynForm'


export default class extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            way: "",
            setupId: "",
            scenarioId: "",
            actionsAvailable: [],
            configSecuritySchema: {},
            promptConfig: [],
            selectedTemplate: ''
        }
    }

    async componentDidMount() {
        console.log(this.props.match.params.projectId)
        const { data: { actionsAvailable } } = await api('actionsAvailable')
        const { data: { id: setupId, scenario: { id: scenarioId } } } = await api('setups/create/empty/in/' + this.props.match.params.projectId, 'post')

        

        // const [setupId, scenarioId] = ["cjwbvpqecnj860b05d17njqc2", "cjwbvpqefnj890b05qxbmr9fm"]

        this.setState({ actionsAvailable, setupId, scenarioId })
    }

    async changeAction(value) {

        console.log(value)

        if(!value) return this.setState({
            selectedTemplate: '',
            way: '',
            promptConfig: [],
            configSecuritySchema: {}
        })


        const { data } = await api('configSecuritySchema/' + value)
        const { way, promptConfig, configSecuritySchema } = data
        
        console.log(data)

        this.setState({
            selectedTemplate: value,
            way,
            promptConfig,
            configSecuritySchema
        })
    }

    async submitForm({config}) {
        // e.preventDefault();
        // e.stopPropagation();


        const { selectedTemplate: actionName } = this.state
        const { match } = this.props
        const { projectId } = match


        const payload = { 
            name: actionName.split('-')[0], 
            actionName, 
            config,
            scenarioName: actionName.split('-')[1],
            projectId: projectId,
            scenarioId: this.state.scenarioId,
            setupId: this.state.setupId
        }

        
        console.log(config)
        
        console.log(this.state)
        // return true

        // const payload = {
        //     scenario: match.params.scenarioId,
        //     name: elements.name.value,
        //     action: elements.action.value,
        //     webhook: elements.webhook.value,
        //     config: config
        // }

        const { data: { id } } = await api('setups/update', 'post', payload)
        this.props.history.push('/setups/' + id)

        return true




    //     const { props: { match, history } } = this
        
    //     let { elements } = e.target

    //     let config = {}

    //     for(let entry in elements) {

    //         if(elements[entry].id && elements[entry].id.split('.') && elements[entry].id.split('.')[0] === "config") {
    //             let value = elements[entry].value
                
    //             if(elements[entry].value === "true") value = true
    //             if(elements[entry].value === "false") value = false
                
    //             config[elements[entry].id.split('.')[1]] = value
    //         }
    //     }
        
    //     const payload = {
    //         scenario: match.params.scenarioId,
    //         name: elements.name.value,
    //         action: elements.action.value,
    //         webhook: elements.webhook.value,
    //         config: config
    //     }

    //     const { data: { id } } = await api('setups/create', 'post', payload)
    //     history.push('/setups/' + id)
    }

    toggleOverlay() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {

        const { 
            state: 
                { actionsAvailable, way, promptConfig, configSecuritySchema, selectedTemplate, setupId, scenarioId }, 
            props: 
                { match } 
        } = this

        return <div style={{backgroundColor: 'white', padding: 24}}>
                <Breadcrumbs items={[
                { href: "/scenarios", icon: "folder-close", text: "My workflows" },
                { icon: "document", text: "New workflow" },
            ]} />
            <br />
                    <H1>Create a new workflow</H1>

                    <Divider /><br />

                    { !selectedTemplate && <InputGroup large required placeholder={"Search among " + actionsAvailable.filter(action => !!action.meta.logo).length + " templates"} onChange={e => this.setState({ search: e.target.value })} /> } <br />

                    <div style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-around', alignItems: 'flex-start'}} className="appList">
                        { actionsAvailable.filter(action => (action.name + action.meta.description)
                                .indexOf(this.state.search || '') > -1)
                                .filter(action => !!action.meta.logo).map(action => 
                            <Card interactive style={{padding: 0, marginBottom: 20, textAlign: 'center', margin: '10px 5px', display: (!selectedTemplate || selectedTemplate === action.name) ? 'block' : 'none', flexGrow: selectedTemplate === action.name ? 2 : 0 }} onClick={e => this.changeAction(action.name)}>
                                <Callout interactive={true} style={{ width: selectedTemplate === action.name ? '' : 180, height: 250 }}>
                                    {
                                        selectedTemplate === action.name ? <div style={{textAlign: 'left', padding: 15}}>
                                            <img src={action.meta.logo} style={{height: 180, marginTop: 'auto', marginBottom: 'auto', marginRight: 20, float: 'left'}} />
                                            <H1>{action.meta.app || action.name}</H1><br />
                                            <H3>{action.meta.description}</H3><br />
                                            <p>La description</p>
                                        </div>

                                        :

                                        <div>
                                            <img src={action.meta.logo} style={{width: 50, marginBottom: 10, marginTop: 5}} />
                                            <H3>{action.meta.app || action.name}</H3>
                                            <p>{action.meta.description}</p>
                                            <BPButton fill 
                                                style={{position: 'absolute', bottom: 15, width: 155}} 
                                                onClick={e => this.changeAction(action.name)}
                                            >Use</BPButton>
                                        </div>
                                    }

                                </Callout>
                            </Card>
                        )}

                        {/* <Card interactive style={{padding: 0, marginBottom: 20, textAlign: 'center', margin: '10px 5px', display: (!selectedTemplate || selectedTemplate === 'oauth2') ? 'block' : 'none'}} onClick={e => this.props.history.push('/setups/new/' + this.state.scenarioId + '/oauth2/' + this.state.setupId)}><Callout interactive={true} elevation={2} style={{width: 180, height: 250 }}>
                            <Icon icon="plus" iconSize={50} style={{marginBottom: 10, marginTop: 5}} />
                            <H3>OAuth</H3>
                            <p>Create from scratch from an OAuth app</p>
                            <BPButton fill style={{position: 'absolute', bottom: 15, width: 155}}>Use</BPButton>
                        </Callout></Card> */}

                        <Card interactive style={{padding: 0, marginBottom: 20, textAlign: 'center', margin: '10px 5px', display: selectedTemplate ? 'block' : 'none'}} onClick={e => this.changeAction(null)}><Callout interactive={true} elevation={2} style={{width: 180, height: 250 }}>
                            <Icon icon="exchange" iconSize={50} style={{marginBottom: 10, marginTop: 5}} />
                            <H3>Use another template</H3>
                            <BPButton fill style={{position: 'absolute', bottom: 15, width: 155}} onClick={e => this.changeAction(null)}>Change</BPButton>
                        </Callout></Card>
                    </div><br /><br />

                    { selectedTemplate && <Card>
                        <H2>Template configuration</H2><Divider /><br />

                        <DynForm 
                            promptVariables={promptConfig}
                            securitySchema={configSecuritySchema}
                            callback={this.submitForm.bind(this)}
                            alwaysShowButton={true}
                        />

                        {/* { way === "insideOut" ? <div>insideOut</div> : <div>outsideIn</div> } */}

                    </Card> }

                    {/* <Card>
                        <H2>How should the connection be triggered?</H2>


                    </Card>

                    <br />

                    <Card style={{padding: 50}}>

                    <Callout title="Redirect URL" icon="link">
                        Set the redirect URL of your app to the following : <Code>{api.endpoint + "cb/" + setupId}</Code>
                    </Callout>

                        <H2>Trigger frequence</H2>

                        <Slider 
                            min={0}
                            max={6}
                            labelStepSize={1}
                            showTrackFill={false}
                            value={this.state.sliderStep || 0}
                            onChange={value => this.setState({sliderStep: value})}
                            labelRenderer={(value) => {
                                switch (value) {
                                    case 0: return "Never"; break;
                                    case 1: return "2min"; break;
                                    case 2: return "10min"; break;
                                    case 3: return "30min"; break;
                                    case 4: return "1h"; break;
                                    case 5: return "6h"; break;
                                    case 6: return "1d"; break;
                                
                                    default: return "1d"; break;
                                }
                            }}
                        />

                        <br />

                        { this.state.sliderStep === 0 && <p>Never triggered from clock.</p> }
                        { this.state.sliderStep === 1 && <p>Approximatively <strong>21,600</strong> calls per user and per month</p> }
                        { this.state.sliderStep === 2 && <p>Approximatively <strong>4,320</strong> calls per user and per month</p> }
                        { this.state.sliderStep === 3 && <p>Approximatively <strong>1,440</strong> calls per user and per month</p> }
                        { this.state.sliderStep === 4 && <p>Approximatively <strong>720</strong> calls per user and per month</p> }
                        { this.state.sliderStep === 5 && <p>Approximatively <strong>120</strong> calls per user and per month</p> }
                        { this.state.sliderStep === 6 && <p>Approximatively <strong>30</strong> calls per user and per month</p> }
                    
                    </Card> */}

                {/* </Card> */}

                {/* <h3>Create from an existing framework</h3> */}




            
                
                

            



{/* 
                { Object.keys(actionsAvailable)
                    .map(action => {
                        return <p key={action} >{ action } ({actionsAvailable[action].type})</p>
                    }) } */}

                {/* <Form.Group controlId="action">
                    <Form.Label>Action</Form.Label>
                    <Form.Control as="select" onChange={ this.changeAction.bind(this) }>
                        <option hidden disabled selected value>Select an action</option>
                        { Object.keys(actionsAvailable).map(action => <option key={action} value={action}>{ action } ({actionsAvailable[action].type})</option>) }
                    </Form.Control>
                </Form.Group> */}

                {/* <Form.Group controlId="webhook">
                    <Form.Label>Webhook</Form.Label>
                    <Form.Control type="text" />
                </Form.Group> */}


            {/* <p>Gérer si pas template</p>

            <p>Créer scénario, récupérer Id</p>

            <p>Si template</p>

                <p>&nbsp;&nbsp;&nbsp;&nbsp;Demander paramètres config (DynForm)</p>

                <p>&nbsp;&nbsp;&nbsp;&nbsp;Si insideOut</p> 

                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Donner url de connexion</p>

                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Doc : gestion des users</p>

                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Donner code de trigger</p>

                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Suggérer config test + trigger de test depuis le client</p>

                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lien doc générée auto</p>

                <p>&nbsp;&nbsp;&nbsp;&nbsp;Si outsideIn</p>

                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Choisir trigger : webhook et/ou clock</p>

                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Documentation gestion des utilisateurs</p>

                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Démo du payload + envoi</p>

                <p>&nbsp;&nbsp;&nbsp;&nbsp;Suggérer d'ajouter intégration similaire</p>

            <p>Si pas template, gérer paramètres</p> */}






            {/* <Form onSubmit={ this.submitForm.bind(this) }>
                <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" />
                </Form.Group>

                <Form.Group controlId="action">
                    <Form.Label>Action</Form.Label>
                    <Form.Control as="select" onChange={ this.changeAction.bind(this) }>
                        <option hidden disabled selected value>Select an action</option>
                        { Object.keys(actionsAvailable).map(action => <option key={action} value={action}>{ action } ({actionsAvailable[action].type})</option>) }
                    </Form.Control>
                </Form.Group>

            
            </Form>    


 */}


                





        </div>
    }
}
