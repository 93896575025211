import { Button, Card, Icon, Mentions, Popconfirm, Select as AntSelect } from 'antd';
import React from 'react';
import { encapsulate, flatten, getChildProperties, getParentProperties, getProperties } from '@simplifier/normalization';
import { deleteStep, display, updateStep, singularize } from '../../utils/workflows.js';
import Steps from '../Steps';

const { Option } = Mentions

export default class ForStep extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const {
			step,
			localWorkflow,
			availableVariables,
			databases,
			workflow,
			availableMethods,
			products,
			updateWorkflow,
			updateLocalWorkflow,
			updateAvailableMethods,
			workflowVariables
		} = this.props

		const entityName = step.forEachPath ?
			step.forEachPath.split(".")[step.forEachPath.split(".").length - 1] :
			"items"

		const [entityLabel, entityPath] = singularize(entityName)

		console.log({singularize: singularize(entityName)})

		let variablesToInherit = getParentProperties(availableVariables, step.forEach || "")
		// ...flatten({ parent: getParentProperties(availableVariables, step.forEach || ""), }),

		let arr = {}
		arr[entityPath] = getChildProperties(availableVariables, step.forEach || "")
		variablesToInherit = [...flatten(arr), ...variablesToInherit]

		// console.log({ availableVariables })

		// console.log(getProperties([...availableVariables, ...encapsulate(workflowVariables, "storage")]))

		// console.log({ variablesToInherit })

		return <React.Fragment>
			<td><table className='workflowTable' style={{ height: '100%' }}><tbody>
				<tr style={{ height: '100%' }}>

					<td rowSpan={2} valign="top" style={{ height: '100%', position: 'relative' }}>
						<Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-25px', top: '150px' }} />
						<Card
							className='workflowCard'
							style={{ scrollSnapAlign: "start", height: 'calc(100% - 20px)' }}
							title="For each element of..."
							extra={<Popconfirm
								title="Do you really want to delete this step?"
								onConfirm={() => {
									let newWorkflow = deleteStep(workflow, step)
									updateWorkflow(newWorkflow)
								}}
								okText="Yes"
								cancelText="No"
							>
								<Button size="small" shape="circle" icon="close" />
							</Popconfirm>}
						>
							<AntSelect
								showSearch
								optionFilterProp="children"
								filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								style={{ width: '100%', height: 50, marginBottom: 15 }}
								size="large"
								placeholder={"Click to select a second term variable"}
								value={step.forEach ? JSON.stringify({ id: step.forEach || null, path: step.forEachPath || null }) : undefined}
								onChange={(value) => {
									const { id, path } = JSON.parse(value)
									const newWorkflow = updateStep(localWorkflow, {
										...step,
										forEach: id,
										forEachPath: path
									})

									console.log({ newWorkflow })
									updateLocalWorkflow(newWorkflow)
								}}
							>
								{getProperties([...availableVariables, ...encapsulate(workflowVariables, "storage")]).filter(property => property.type === "array").map(model => <AntSelect.Option key={model.path} value={JSON.stringify({ id: model.id, path: model.path })}>{display(model.path)}</AntSelect.Option>)}
							</AntSelect>

							{/* {JSON.stringify([...availableVariables, ...encapsulate(workflowVariables, "storage")])} */}
						</Card>
					</td>

					<td style={{ height: '100%', position: 'relative' }}>
						<Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-24px', top: '100px' }} />
						<Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-24px', top: '126px' }} />
						<table className='workflowTable'><tbody><tr>

							<Steps
								steps={step.do}
								sequenceId={step.sequenceId}
								workflow={workflow}
								availableMethods={availableMethods}
								products={products}
								updateWorkflow={updateWorkflow.bind(this)}
								updateAvailableMethods={updateAvailableMethods}
								inheritedVariables={variablesToInherit}
								workflowVariables={workflowVariables}
								databases={databases}
								availableEvents={this.props.availableEvents}
								inputSequenceRank={this.props.inputSequenceRank}
							/>
						</tr></tbody></table>
					</td>
				</tr></tbody>
			</table><br /></td>

		</React.Fragment >
	}
}