import { EuiBasicTable, EuiButton, EuiCard, EuiFlexGrid, EuiFlexItem, EuiPage, EuiPageBody, EuiPageContentHeader, EuiPageHeaderSection, EuiPanel, EuiSpacer, EuiTitle } from '@elastic/eui';
import { Button, PageHeader, Typography } from 'antd';
import React from 'react';
import { Link } from "react-router-dom";
import api from '../api';

const { Paragraph } = Typography

export default class Scenarios extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            scenarios: [],
            workflows: [],
            createWorkflowLoader: false,
            availableMethods: [],
            eventStructures: []
        }
    }

    async componentDidMount() {
        const { projectId } = this.props.match.params
        // const { data: { blocks: availableMethods } } = await api('blocks')
        let availableMethods = []
        // const { data: { scenarios } } = await api('projects/' +  projectId + '/scenarios')
        const { data: { workflows } } = await api(`projects/${projectId}/workflows`)
        const { data: { eventStructures } } = await api(`events/project/${projectId}`)
        // const { data: { userAliases } } = await api(`users/aliases/${projectId}`)

        // console.log({userAliases})

        this.setState({ workflows, availableMethods, eventStructures })
    }

    count(scenario) {
        return scenario.setups.reduce((acc, curr) => acc + curr.auths.length, 0)
    }

    render() {

        const { createWorkflowLoader, eventStructures, workflows } = this.state

        const { projectId } = this.props.match.params

        const columns = [
            {
                field: 'name', name: 'Name', render: (name, w) => {
                    return <Link to={'../workflows/' + w._id}>{name}</Link>
                }
            },
            {
                field: 'products', name: 'Product(s)', render: (products) => {
        
                    console.log({ products })
                    return products.map(product => <span>
                        <img
                            style={{ height: 21, borderRadius: 5, marginRight: 5 }}
                            src={product.logo}
                            title={product.name}
                        />
                    </span>)
                }
            },
            { field: 'oneDay', name: '24h executions', sortable: true },
            { field: 'sevenDays', name: '7d executions', sortable: true },
            { field: 'users', name: 'Users', sortable: true },
            // { field: 'events', name: 'Events', render: (evts, w) => {
            //     const workflowEvents = eventStructures.filter(event => event.workflows.includes(w._id))
            //     return workflowEvents.map(e => e.name).join(',')
            // } },
        ]

        return <React.Fragment>

            <PageHeader
                title="Project"
                onBack={() => this.props.history.push(`/`)}
                style={{ backgroundColor: 'white' }}
                extra={[
                    this.props.match.params && this.props.match.params.projectId === "28216bkx64mszu" ? <Button key={2} type="primary" size="large" icon="save" onClick={() => window.open('https://ytest.gitbook.io/libeo/introduction/general-concepts')}>Documentation</Button> : <></>,
                ]}
            >
                <div className="wrap">
                    <div className="content">
                        <Paragraph>
                            A project contains events and integrations. Events can be triggered from your code or internally by Simplifier. They can trigger integrations or other events.
                        </Paragraph>
                    </div>
                </div>
            </PageHeader>

            <EuiPage>





                <EuiSpacer size="l" />

                <EuiPageBody component="div">

                    <EuiPage>
                        <EuiPageBody component="div">

                            <EuiPageContentHeader>
                                <EuiPageHeaderSection>
                                    <EuiTitle size="l">
                                        <h1>Events</h1>
                                    </EuiTitle>
                                </EuiPageHeaderSection>
                                <EuiPageHeaderSection>
                                    <EuiButton isLoading={this.state.createEventLoader} onClick={async () => {
                                        this.setState({ createEventLoader: true })

                                        let name = window.prompt("Event name?", "newEvent"), i = 0
                                        let isNameUsed = (events, name) => events.filter(e => e.name === name).length
                                       
                                        while (isNameUsed(this.state.eventStructures, name)) {
                                            i++
                                            name = window.prompt("Event name already talen, choose another one", "newEvent")
                                        }

                                        const { projectId } = this.props.match.params
                                        const defaultEvent = {
                                            "name": name,
                                            "input": [],
                                            "workflows": [],
                                            "project": projectId,
                                            "outputs": [{
                                                "name": "default", "code": 200, "properties": [{
                                                    "id": "a2c537b0-b7ca-4988-8704-dd7eb6302c92",
                                                    "label": "ok",
                                                    "path": "ok",
                                                    "type": "string",
                                                    "example": "done",
                                                    "base": null
                                                }]
                                            }]
                                        }

                                        const { data: createdEventStructure } = await api('events', 'post', defaultEvent)

                                        console.log(createdEventStructure)

                                        const { data: { eventStructures } } = await api(`events/project/${projectId}`)

                                        this.setState({ createEventLoader: false, eventStructures })

                                        this.props.history.push(`/events/${createdEventStructure._id}`)
                                    }}>Create Event</EuiButton>
                                </EuiPageHeaderSection>
                            </EuiPageContentHeader>

                            <EuiFlexGrid gutterSize="l" columns={4}>
                                {eventStructures.map((event, index) => <EuiFlexItem style={{ }} key={index}>
                                    <Link to={'../events/' + event._id}><EuiCard
                                        layout="horizontal"
                                        title={event.name}
                                        // description={`Can trigger ${event.workflows.length} integration${event.workflows.length > 1 ? "s" : ""}`}
                                        onClick={() => { }}
                                        titleSize="xs"
                                    /></Link>
                                </EuiFlexItem>)}

                            </EuiFlexGrid>

                            <EuiSpacer size="xl" />

                            <EuiPageContentHeader>
                                <EuiPageHeaderSection>
                                    <EuiTitle size="l">
                                        <h1>Integrations</h1>
                                    </EuiTitle>
                                </EuiPageHeaderSection>
                                <EuiPageHeaderSection>
                                    <EuiButton isLoading={this.state.createWorkflowLoader} onClick={async () => {
                                        this.setState({ createWorkflowLoader: true })

                                        const { projectId } = this.props.match.params
                                        const defaultWorkflow = {
                                            "version": 2,
                                            "auth": {},
                                            "name": "New integration",
                                            "sequences": []
                                        }

                                        const { data: { _id } } = await api('workflows', 'post', { projectId, workflow: defaultWorkflow })

                                        this.setState({ createWorkflowLoader: false })

                                        this.props.history.push(`/workflows/${_id}`)
                                    }}>Create integration</EuiButton>
                                </EuiPageHeaderSection>
                            </EuiPageContentHeader>
                            <EuiPanel paddingSize="m">

                                <EuiBasicTable
                                    items={this.state.workflows}
                                    rowHeader="name"
                                    columns={columns}
                                // sorting={{
                                //     enableAllColumns: true
                                // }}
                                // onChange={console.log}
                                />

                            </EuiPanel>
                            {/* <div style={{ padding: 24, minHeight: 360, borderRadius: 4 }}>
                                <List
                                    grid={{ gutter: 16, xs: 2, sm: 2, md: 2, lg: 3, xl: 3, xxl: 3 }}
                                    dataSource={this.state.workflows}
                                    renderItem={workflow => (
                                        <List.Item
                                        >

                                            <Card hoverable title={workflow.name} hoverable onClick={() => this.props.history.push("/workflows/" + workflow._id)} actions={[<span>Open</span>]}>
                                            </Card>
                                        </List.Item>
                                    )}
                                />

                            </div> */}

                        </EuiPageBody>
                    </EuiPage>
                </EuiPageBody>
            </EuiPage>



            {/* <div style={{ padding: 24, minHeight: 360, display: this.state.scenarios.length ? "block" : "none" }}>
                <List
                    grid={{ gutter: 16, xs: 2, sm: 2, md: 2, lg: 3, xl: 3, xxl: 3 }}
                    dataSource={
                        this.state.scenarios
                            .filter(scenario => scenario.name !== "Untitled")
                            .sort((a, b) => this.count(b) - this.count(a))}
                    renderItem={scenario => (
                        <List.Item>
                            <Card hoverable title={scenario.name} hoverable onClick={() => this.props.history.push("/scenarios/" + scenario.id)} actions={[<span>Open</span>]}>
                                <Statistic title="Active Users" value={this.count(scenario)} />
                            </Card>
                        </List.Item>
                    )}
                />

            </div> */}
        </React.Fragment>
    }
}
