import { Spinner } from '@blueprintjs/core';
import { Icon, Layout } from 'antd';
import React from 'react';
import api from '../api';
import append from "url-parameter-append"

export default class extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			setupId: "",
			authUrl: "",
			postConsentPromptParameters: [],
			parametersSecuritySchema: {},
			authId: localStorage.getItem('authId'),
			loading: true,
			done: false
		}
	}

	async componentDidMount() {
		const query = this.props.location.search

		console.log(this.props.location.search)

		const queryParameters = this.props.location.search ? JSON.parse('{"' + decodeURI(this.props.location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/==/g, '£££££').replace(/=/g, '":"').replace(/£££££/g, '==') + '"}') : {}

		const { done } = queryParameters

		if(done) {
			console.log('Auth is done')

			this.setState({
				loading: false,
				done: true
			})

			return;
		}

		console.log(query)

		const { workflowId } = this.props.match.params

		let previousAuthId = localStorage.getItem('authId')
		let previousAccessId = localStorage.getItem('accessId')

		const { data } = await api('workflow/auth/next/' + previousAuthId + "/" + previousAccessId + query)

		const {
			authDone, url, redirect_uri, authId, accessId, userToken, authValid
		} = data

		console.log("post consent redirection")
		console.log(JSON.stringify({data}))

		// alert('post consent')

		if (authDone) {
			const redirect_uri = localStorage.getItem('redirect_uri')
			if (redirect_uri) {
				let redirectTo = decodeURIComponent(redirect_uri)
				localStorage.removeItem('redirect_uri')

				console.log("redirecting")


				console.log(redirect_uri, 'xxxx', redirectTo)
				// window.location = redirectTo + "?userToken=" + userToken + "&authValid=" + authValid

				// alert(append(redirectTo, { userToken, authValid }))
				console.log('9')
				window.location = append(redirectTo, { userToken, authValid })
			} else {
				this.setState({ done: true, loading: false })

				console.log('11')
				window.history.replaceState({}, "Done!", window.location.href.split('?')[0] + "?userToken=" + userToken + "&authValid=" + authValid + "&done=true");
				// window.location = 
			}
		}

		localStorage.setItem('authId', authId)
		localStorage.setItem('accessId', accessId)

		if (data.url) {
			console.log('12')
			window.location = data.url
		}
	}

	async submitForm({ config: parameters }) {

	}

	render() {
		const { state: { postConsentPromptParameters, parametersSecuritySchema, authUrl, setupId }, props } = this


		return <React.Fragment><div style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0, zIndex: 100000, backgroundColor: "white" }}>

            <Layout style={{ position: "relative", borderRadius: 5 }}><Layout.Content style={{ maxWidth: 800, backgroundColor: 'white', padding: 30, marginTop: 100, position: "absolute", left: "50%", textAlign: 'center', transform: "translate(-50%, 0)", borderRadius: 10 }}>

                {this.state.done ? <React.Fragment>

                    <Icon type="check-circle" style={{ margin: 5, zoom: 7 }} theme="twoTone" twoToneColor="#52c41a" />

                    <br />

                    <h1 style={{fontSize: 24}}>Authentication done</h1>

                </React.Fragment> :

                    <React.Fragment>{this.state.loading ? <Spinner /> : <React.Fragment />}

                        {authUrl && <p style={{ textAlign: "center" }}><a href={authUrl}><span><br /><br /><h3 className="text-center"><Spinner /></h3></span><br />Click here if you are not redirected</a></p>}
                    </React.Fragment>
                }
            </Layout.Content></Layout>
        </div></React.Fragment>







		return <div style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0, zIndex: 100000 }}><React.Fragment>

			<Layout style={{ height: "100vh", position: "relative", borderRadius: 5 }}><Layout.Content style={{ maxWidth: 800, backgroundColor: 'white', padding: 30, marginTop: 100, position: "absolute", left: "50%", textAlign: 'center', transform: "translate(-50%, 0)", borderRadius: 10, boxShadow: "0px 10px 20px 0px rgba(50, 50, 50, 0.22)" }}>

				{this.state.done ? <React.Fragment>

					<Icon type="check-circle" style={{ margin: 5, zoom: 7 }} theme="twoTone" twoToneColor="#52c41a" />

					<br />

					<h1 style={{fontSize: 24}}>Authentication done</h1>

				</React.Fragment> :

					<React.Fragment>{this.state.loading ? <Spinner /> : <React.Fragment />}


					</React.Fragment>
				}
			</Layout.Content></Layout>
		</React.Fragment></div>
	}
}
