import { Breadcrumbs, H2, H3 } from "@blueprintjs/core";
import { Card, Col, List, PageHeader, Row, Statistic, Typography } from 'antd';
import React from 'react';
import AceEditor from 'react-ace';
import ReactJson from 'react-json-view';
import 'brace/mode/json';
import 'brace/theme/solarized_dark';
import Select, { components } from 'react-select';
import api from '../api';
import { buildEmptySchema, getPaths, setArrayProperty, setProperty } from '../utils/normalization';





const { Paragraph } = Typography;

const cap = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export default class Scenario extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            scenario: {},
            scaffold: "{}",
            schema: {},
            scaffoldProperties: []
        }

    }

    async componentDidMount() {
        const scenarioId = this.props.match.params.scenarioId
        const { data: scenario } = await api('scenarios/' + scenarioId)
        

        scenario.setups.map(async setup => {
            const { data: { expectedAnswer } } = await api('action/' + setup.action + '/expectedAnswer')

            let st = {}
            // console.log(expectedAnswer)
            const schema = buildEmptySchema(expectedAnswer, ['x'])
            st[setup.name + '-model'] = getPaths(schema)


            this.setState(st)
        })

        this.setState({ scenario })
    }
    
    async updateScaffold(string) {
        this.setState({scaffold: string})

        try {
            const json = JSON.parse(string)
            
            const models = this.state.scenario.setups.map(setup => setup.action).map(action => action.split('-')[0])

            let schema = buildEmptySchema(json, models)

            console.log({scaffoldProperties: getPaths(schema)})

            this.setState({ scaffoldProperties: getPaths(schema), schema })

            console.log(json)
        } catch (error) {
            return;
        }
    }

    render() {
        const { scenario } = this.state

        if(!scenario.id) { return <p>Loading...</p> }

        console.log(this.state)

        const BREADCRUMBS = [
            { href: "/scenarios", icon: "folder-close", text: "Workflows" },
            { icon: "document", text: scenario.name },
        ]

        return <React.Fragment>

            {/* <Breadcrumbs items={[
                { href: "/scenarios", icon: "folder-close", text: "My workflows" },
                { icon: "document", text: scenario.name },
            ]} /><br /> */}

            <PageHeader title={<span><Breadcrumbs items={BREADCRUMBS} /><br /><br /><Paragraph editable={{onChange: async name => {
                let newScenario = scenario
                newScenario.name = name
                this.setState({ scenario: newScenario })
                await api('scenario/' + scenario.id + '/updateName', 'post', { name })
            }}}>{scenario.name}</Paragraph></span>}>
                <div className="wrap">
                    <div className="content">
                    </div>
                </div>
            </PageHeader>

            <div style={{ padding: 24, minHeight: 360 }}>

                <List
                    grid={{ gutter: 16, xs: 1, }}
                    dataSource={scenario.setups}
                    renderItem={setup => (
                    <List.Item>
                        <Card hoverable title={cap(scenario.name) + " in " + cap(setup.name)} onClick={() => this.props.history.push("/setups/" + setup.id)} actions={[<span>Open</span>]}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Statistic title="Active Users" value={setup.auths.length} />
                            </Col>
                            <Col span={12}>
                                {/* <Statistic title="Account Balance (CNY)" value={112893} precision={2} /> */}
        
                            </Col>
                        </Row>

                        </Card>
                    </List.Item>
                    )}
                />

            </div>

                {/* { scenario.setups && scenario.setups.map(setup => {
                    return <div onClick={() => this.props.history.push("/setups/" + setup.id)}><BPCard interactive={true} elevation={1} style={{marginBottom: 10}}>
                        <H3>{setup.name}</H3>
                    </BPCard></div>
                }) } */}


                <H2>Normalization</H2>

                <table>
                    <tr>
                        <td><H3>Property</H3></td>
                        { scenario.setups.map(setup => {
                            return <td><H3>{setup.name}</H3></td>
                        })}
                    </tr>

                    {this.state.scaffoldProperties.filter(property => property.type !== 'array').map(property => 
                        <tr>
                            <td><strong>{property.path}</strong><br /><i>{property.type}</i><br /></td>
                            { scenario.setups.map(setup => {
                                return <td style={{minWidth: 300}}>
                                    <Select
										className="basic-single"
										classNamePrefix="select"
										style={{minWidth: 300, color: "black"}}
                                        defaultValue={{ label: "❌", value: "leaveempty"}}
                                        onChange={(v, e) => {
                                            console.log(v)
                                            if(v.path === "leaveempty") return this.setState({schema: setProperty(this.state.schema, property.path, property.type.split(' : ')[1], setup.action.split('-')[0])})
                                            this.setState({schema: setProperty(this.state.schema, property.path, v.value, setup.action.split('-')[0])
                                        })}}
										isClearable={false}
                                        isSearchable={true}
                                        components={{ Option: props => {
                                            return <components.Option {...props}>
                                                <strong style={{color: "#293742"}}>{props.data.value}</strong><br />
                                                <i style={{color: "#495762"}}>{props.data.type}</i>
                                            </components.Option> } 
                                        }}
										name={ property.path }
										options={[{ value: "❌", label: "❌", path: "leaveempty", type: "Leave empty"}, ...(this.state[setup.name + '-model'] || []).filter(model => model.type !== 'array').map(model => {
                                            console.log(model)
											return {
                                                path: model.path,
                                                type: model.type,
												value: model.path,
												label: model.path,
											}
										})]}
									/><br />
                                </td>
                            })}<br /><br />
                        </tr>
                    )}



                    
                    {this.state.scaffoldProperties.filter(property => property.type === 'array').map(property => 
                        <tr>
                            <td><strong>{property._base}</strong><br /><i>{property.type}</i><br />
                                {property.paths.map(path => <p>{JSON.stringify(path)}</p>)}
                                
                            </td>
                            { scenario.setups.map(setup => {

                                const isBase = this.state.schema[property._base][0]._base['_' + setup.action.split('-')[0]]
                                const isBaseFilled = isBase[0] !== '#'

                                return <td style={{minWidth: 300}}>
                                    
                                    <Select
										className="basic-single"
										classNamePrefix="select"
										style={{minWidth: 300, color: "black"}}
										defaultValue={{ label: "❌", value: "leaveempty"}}
                                        isClearable={false}
                                        onChange={(v, e) => {
                                            const { path } = v
                                            
                                            console.log(setup.action)
                                            this.setState({schema: setArrayProperty(this.state.schema, property._base, '_base', path, setup.action.split('-')[0])})
                                        }}
                                        isSearchable={true}
                                        components={{ Option: props => {
                                            return <components.Option {...props}>
                                                <strong style={{color: "#293742"}}>{props.data.value}</strong><br />
                                                <i style={{color: "#495762"}}>aa{props.data.type}</i>
                                            </components.Option> } 
                                        }}
										name={ property._base }
										options={[{ value: "❌", label: "❌", path: "leaveempty", type: "Leave empty"}, ...(this.state[setup.name + '-model'] || []).filter(model => model.type === 'array').map(model => {
                                            console.log(model)
											return {
                                                path: model._base,
                                                type: model.type,
												value: model._base,
												label: model._base,
											}
										})]}
									/><br />
                                    {isBaseFilled ? JSON.stringify(isBase) : ''}
                                </td>
                            })}<br /><br />
                        </tr>
                    )}
                    

                </table>

                <AceEditor
                    mode="json"
                    theme="solarized_dark"
                    onChange={this.updateScaffold.bind(this)}
                    value={this.state.scaffold}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{$blockScrolling: true}}
                    style={{borderRadius: 3, padding: 10}}
                /> 

                <br />

                <h2>schema</h2>

                <ReactJson
                    src={this.state.schema}
                    style={{borderRadius: 3, padding: 10}}
                /> 

            
        </React.Fragment>
    }
}
