import React from 'react';
import api from '../api'

import { Link } from 'react-router-dom'

// import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import { H2, Breadcrumbs, Card, Callout, Code, IBreadcrumbProps, Icon, Spinner } from "@blueprintjs/core";

import ReactJson from 'react-json-view'

import DynForm from '../components/DynForm'

export default class extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            authId: '',
            auth: {},
            unFilledParameters: [],
            parametersSecuritySchema: {},
            isLoading: false
        }
    }

    async componentDidMount() {
        const { authId } = this.props.match.params
        const { data: auth } = await api('auths/' + authId)
        const { data: { parametersSecuritySchema } } = await api('connectAction/getPrompts/' + auth.setup.id)
        const unFilledParameters = Object.keys(parametersSecuritySchema).filter(param => !auth.parameters[param])
        this.setState({ auth, unFilledParameters, parametersSecuritySchema })
        
    }

    async submitForm({ config: prompts }) {
        this.setState({ isLoading: true })
        const { props: { match, history }, state: { setupId } } = this

        let parameters = {...this.state.auth.parameters, ...prompts}

        const resp = await api('action/' + this.state.auth.id + '/execute', 'post', { parameters })

        this.setState({isLoading: false, err: resp.data.err, result: resp.data.result})
    }

    render() {

        const { state: { authId, auth, unFilledParameters, parametersSecuritySchema, isLoading }, props } = this
        
        if(!auth.setup) {
            return <span><br /><br /><h3 className="text-center"><Spinner /></h3></span>
        }

        const BREADCRUMBS = [
            { href: "/scenarios", icon: "folder-close", text: "Index" },
            { href: "/scenarios/" + auth.setup.scenario.id, icon: "folder-close", text: auth.setup.scenario.name },
            { href: "/setups/" + auth.setup.id, icon: "folder-close", text: auth.setup.name },
            { icon: "document", text: "Auth" },
        ];

        return <React.Fragment>

                <Breadcrumbs items={BREADCRUMBS} />

                <Card>

               <h1>Auth</h1>

               <h3>User token: <Code>{auth.user.token}</Code></h3>

               { auth.parameters && <ReactJson theme="solarized" style={{padding: 10, borderRadius: 3}} src={auth.parameters} /> }

               <br /><br />
                <H2>Trigger manually</H2>

               <Form onSubmit={ this.submitForm.bind(this) }>

                    <DynForm 
                        promptVariables={unFilledParameters}
                        securitySchema={parametersSecuritySchema}
                        callback={this.submitForm.bind(this)}
                        buttonText="Trigger"
                        alwaysShowButton
                    />

                </Form>  

                <br />


                {
                    this.state.err && <div>
                        <h3>Error</h3> 
                        <ReactJson theme="solarized" style={{padding: 10, borderRadius: 3}} src={this.state.err} />
                    </div>
                }

                {
                    this.state.result && <div>
                        <h3>Result</h3> 
                        <ReactJson theme="solarized" style={{padding: 10, borderRadius: 3}} src={this.state.result} />
                    </div>
                }

                

            </Card>
        </React.Fragment>
    }
}
