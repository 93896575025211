import { Spinner } from '@blueprintjs/core';
import { Icon, Layout } from 'antd';
import React from 'react';
import api from '../api';
import append from "url-parameter-append"

export default class extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			setupId: "",
			authUrl: "",
			done: false,
			promptParameters: [],
			parametersSecuritySchema: {},
			error: ""
		}
	}

	async componentDidMount() {

		const { workflowId } = this.props.match.params

		console.log(3, this.props.location.search)


		const queryParameters = this.props.location.search ? JSON.parse('{"' + decodeURI(this.props.location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : {}

		console.log({ queryParameters, loc: this.props.location.search })

		if (queryParameters.redirect_uri) {
			localStorage.setItem('redirect_uri', queryParameters.redirect_uri)
		} else {
			localStorage.removeItem('redirect_uri')
		}

		const { data } = await api('workflow/startAuth/' + workflowId, 'post', { query: queryParameters })

		const {
			authDone, url, redirect_uri, authId, accessId, userToken, authValid, error
		} = data

		console.log({ d: data })

		this.setState({ url, redirect_uri, error })

		if (authDone) {
			const redirect_uri = localStorage.getItem('redirect_uri')
			if (redirect_uri) {
				let redirectTo = decodeURIComponent(redirect_uri)
				localStorage.removeItem('redirect_uri')
				console.log(redirect_uri, redirectTo)

				console.log({ data })

				console.log("REDIRECT", append(redirectTo, { userToken, authValid }))
				console.log('6')
				window.location = append(redirectTo, { userToken, authValid })

				return;

				// window.location = redirectTo + "?userToken=" + userToken
			} else {
				this.setState({ done: true, loading: false })
			}
		}


		localStorage.setItem('authId', authId)
		localStorage.setItem('accessId', accessId)

		if (url) {
			console.log('7')
			window.location = url
			this.setState({ loading: false })
		}
	}

	render() {

		if (this.state.error && this.state.error === "unicity_key_already_used") {
			return <React.Fragment><div style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0, zIndex: 100000, backgroundColor: "white" }}>

				<Layout style={{ position: "relative", borderRadius: 5 }}><Layout.Content style={{ maxWidth: 800, backgroundColor: 'white', padding: 30, marginTop: 100, position: "absolute", left: "50%", textAlign: 'center', transform: "translate(-50%, 0)", borderRadius: 10 }}>
					<React.Fragment>
						<h2>Error : You already connected that integration.</h2>
					</React.Fragment>
				</Layout.Content></Layout>
			</div></React.Fragment>
		}

		const { state: { authUrl }, props } = this

		return <React.Fragment><div style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0, zIndex: 100000, backgroundColor: "white" }}>

			<Layout style={{ position: "relative", borderRadius: 5 }}><Layout.Content style={{ maxWidth: 800, backgroundColor: 'white', padding: 30, marginTop: 100, position: "absolute", left: "50%", textAlign: 'center', transform: "translate(-50%, 0)", borderRadius: 10 }}>

				{this.state.done ? <React.Fragment>

					<Icon type="check-circle" style={{ margin: 5, zoom: 7 }} theme="twoTone" twoToneColor="#52c41a" />

					<br />

					<h1 style={{ fontSize: 24 }}>Authentication done</h1>

				</React.Fragment> :

					<React.Fragment>
						{this.state.loading ? <p style={{ textAlign: "center" }}><span><br /><br /><h3 className="text-center"><Spinner /></h3></span><br /></p> : <React.Fragment />}
					</React.Fragment>
				}



			</Layout.Content></Layout>
		</div></React.Fragment>

		return <div style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0, zIndex: 10000000 }}><React.Fragment>

			<Layout style={{ height: "100vh", position: "relative", borderRadius: 5 }}><Layout.Content style={{ maxWidth: 800, backgroundColor: 'white', padding: 30, marginTop: 100, position: "absolute", left: "50%", textAlign: 'center', transform: "translate(-50%, 0)", borderRadius: 10, boxShadow: "0px 10px 20px 0px rgba(50, 50, 50, 0.22)" }}>


				{this.state.done ? <React.Fragment>

					<Icon type="check-circle" style={{ margin: 5, zoom: 7 }} theme="twoTone" twoToneColor="#52c41a" />

					<br />

					<h1 style={{ fontSize: 24 }}>Authentication done</h1>

				</React.Fragment> :

					<React.Fragment>{this.state.loading ? <Spinner /> : <React.Fragment />}</React.Fragment>
				}

			</Layout.Content></Layout>
		</React.Fragment></div>
	}
}
