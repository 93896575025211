import { Button, Card, Checkbox, Icon, Mentions, Popconfirm, Select as AntSelect } from 'antd';
import React from 'react';
import { encapsulate, flatten, getChildProperties, getParentProperties, getProperties } from '@simplifier/normalization';
import { deleteStep, display, updateStep, singularize } from '../../utils/workflows.js';
import Steps from '../Steps';

const { Option } = Mentions

export default class FilterStep extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			base: null,
			availableVariables: props.availableVariables
		}
	}

	render() {
		const {
			step,
			localWorkflow,
			workflow,
			availableMethods,
			availableVariables,
			products,
			updateWorkflow,
			updateLocalWorkflow,
			updateAvailableMethods,
			databases,
			workflowVariables
		} = this.props



		let itemProperties = [
			...flatten({ parent: getParentProperties(availableVariables, step.forEach || ""), }),
			...flatten({ current: getChildProperties(availableVariables, step.forEach || "") })
        ]

        
        const entityName = step.forEachPath ? 
			step.forEachPath.split(".")[step.forEachPath.split(".").length - 1] : 
			"items"

        const [entityLabel, entityPath] = singularize(entityName)
        
        let variablesToInherit = getParentProperties(availableVariables, step.forEach || "")
			// ...flatten({ parent: getParentProperties(availableVariables, step.forEach || ""), }),

		let arr = {}
		arr[entityPath] = getChildProperties(availableVariables, step.forEach || "")
        variablesToInherit = [...flatten(arr), ...variablesToInherit]
        
        itemProperties = variablesToInherit

		return <React.Fragment>

			<table className='workflowTable' style={{ height: '100%' }}><tbody>
				<tr style={{ height: '100%' }}>

					<td rowSpan="2" valign="top" style={{ height: '100%', position: 'relative' }}>
						<Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-25px', top: '150px' }} />
						<Card
							className='workflowCard'
							style={{ scrollSnapAlign: "start", height: 'calc(100% - 20px)' }}
							title="Filter..."
							extra={<Popconfirm
								title="Do you really want to delete this step?"
								onConfirm={() => {
									let newWorkflow = deleteStep(workflow, step)
									updateWorkflow(newWorkflow)
								}}
								okText="Yes"
								cancelText="No"
							>
								<Button size="small" shape="circle" icon="close" />
							</Popconfirm>}
						>

							{/* {step.uniqueId} */}

							<h4>For each element of...</h4>

							<AntSelect
								showSearch
								optionFilterProp="children"
								filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								style={{ width: '100%', height: 50, marginBottom: 15 }}
								size="large"
								placeholder={"Click to select a second term variable"}
								value={JSON.stringify({ id: step.forEach || null, path: step.forEachPath || null })}
								onChange={value => {
									const { id, path } = JSON.parse(value)
									const newWorkflow = updateStep(localWorkflow, { ...step, forEach: id, forEachPath: path })
									updateLocalWorkflow(newWorkflow)
								}}
							>
								{getProperties([...availableVariables, ...encapsulate(workflowVariables, "storage")]).filter(property => property.type === "array").map(model => <AntSelect.Option key={model.path} value={JSON.stringify({ id: model.id, path: model.path })}>{display(model.path)}</AntSelect.Option>)}
							</AntSelect>

							<br /><br />{/* 
								Condition 
									1
									op
									2
							*/}

							<h4>Check if...</h4>

							<Mentions
								style={{ width: '100%', marginBottom: 15 }}
								placeholder={"{ to select a first term variable"}
								size="large"
								prefix={['{']}
								onChange={value => {
									let newStep = { ...step }
									newStep.if["1"] = value
									const newWorkflow = updateStep(localWorkflow, newStep)
									updateLocalWorkflow(newWorkflow)
								}}
								value={step.if["1"] || ""}
							>
								{[...getProperties(itemProperties)].filter(property => property.type !== 'object' && property.type !== "array").map(model => <Option key={model.path} value={model.path + "}"}>
									<strong style={{ color: "#293742" }}>{display(model.path)}</strong><br />
									<i style={{ color: "#495762" }}>{display(model.type)}{model.example ? <span>, Ex.: {model.example}</span> : <span></span>}</i><br />
								</Option>)}
							</Mentions>

							<AntSelect
								placeholder="Condition type"
								style={{ width: '100%', marginBottom: 15 }}
								size="large"
								onChange={value => {
									let newStep = { ...step }
									newStep.if["type"] = value
									const newWorkflow = updateStep(localWorkflow, newStep)
									updateLocalWorkflow(newWorkflow)
								}}
								value={step.if.type || "eq"}
							>
								<AntSelect.Option value="eq">Is equal to</AntSelect.Option>
								<AntSelect.Option value="notempty">Is not empty</AntSelect.Option>
								<AntSelect.Option value="empty">Is empty</AntSelect.Option>
								<AntSelect.Option value="contains">Contains</AntSelect.Option>
								<AntSelect.Option value="containsns">Contains (not case sensitive)</AntSelect.Option>
								<AntSelect.Option value="gt">Is greater than</AntSelect.Option>
								<AntSelect.Option value="st">Is smaller than</AntSelect.Option>
								<AntSelect.Option value="get">Is greater or equal to</AntSelect.Option>
								<AntSelect.Option value="set">Is smaller or equal to</AntSelect.Option>
								<AntSelect.Option value="diff">Is different from</AntSelect.Option>
								<AntSelect.Option value="defined">Is properly defined</AntSelect.Option>

							</AntSelect>

							{
								!["notempty"].includes(step.if.type) && <Mentions
									style={{ width: '100%', marginBottom: 15 }} size="large" prefix={['{']}
									placeholder={"{ to select a second term variable"}
									value={step.if["2"] || ""}
									onChange={value => {
										let newStep = { ...step }
										newStep.if["2"] = value
										const newWorkflow = updateStep(localWorkflow, newStep)
										updateLocalWorkflow(newWorkflow)
									}}
								>
									{[...getProperties(itemProperties)].filter(property => property.type !== 'object' && property.type !== "array").map(model => <Option key={model.path} value={model.path + "}"}>
										<strong style={{ color: "#293742" }}>{display(model.path)}</strong><br />
										<i style={{ color: "#495762" }}>{model.type} ({model.example})</i>
									</Option>)}
								</Mentions>
							}

							<br /><br />

							<Checkbox checked={step.firstChildOnly} onChange={e => {
								let newStep = { ...step }
								newStep.firstChildOnly = e.target.checked
								const newWorkflow = updateStep(localWorkflow, newStep)
								updateLocalWorkflow(newWorkflow)
							}}>Execute for the first child item only</Checkbox>


						</Card>
					</td>

					<td style={{ height: '100%', position: 'relative' }}>
						<Icon type="check" style={{ fontSize: '16px', width: 50, position: 'absolute', left: '-25px', top: '70px', color: 'green' }} />
						<Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-24px', top: '100px' }} />
						<Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-24px', top: '126px' }} />						<table className='workflowTable'><tr>
							<Steps
								steps={step.each.do}
								sequenceId={step.each.sequenceId}
								workflow={workflow}
								availableMethods={availableMethods}
								products={products}
								updateWorkflow={updateWorkflow.bind(this)}
								updateAvailableMethods={updateAvailableMethods}
								inheritedVariables={variablesToInherit}
								workflowVariables={workflowVariables}
								databases={databases}
								availableEvents={this.props.availableEvents}
								inputSequenceRank={this.props.inputSequenceRank}
							/>
						</tr></table>
					</td>
				</tr>

				<tr>
					<td style={{ height: '100%', position: 'relative' }}>
						<Icon type="close" style={{ fontSize: '16px', width: 50, position: 'absolute', left: '-25px', top: '130px', color: 'red' }} />
						<table className='workflowTable'><tbody><tr>
							<Steps
								steps={step.empty.do}
								sequenceId={step.empty.sequenceId}
								workflow={workflow}
								availableMethods={availableMethods}
								products={products}
								updateWorkflow={updateWorkflow.bind(this)}
								updateAvailableMethods={updateAvailableMethods}
								inheritedVariables={availableVariables}
								workflowVariables={workflowVariables}
								databases={databases}
								availableEvents={this.props.availableEvents}
								inputSequenceRank={this.props.inputSequenceRank}
							/>
						</tr></tbody></table>
					</td>
				</tr>

			</tbody></table>


			<br /></React.Fragment>
	}
}