import { Button, Card, Icon, Mentions, Popconfirm, Select as AntSelect } from 'antd';
import React from 'react';
import { encapsulate, getProperties } from '@simplifier/normalization';
import { deleteStep, display, updateStep } from '../../utils/workflows.js';

const { Option } = Mentions

export default class StorageStep extends React.Component {
	constructor(props) {
		super(props)

		this.state = { outputMappingModal: false }
	}

	render() {
		const { step, localWorkflow, availableVariables, workflow, availableMethods, products, updateWorkflow, updateLocalWorkflow, updateAvailableMethods, workflowVariables } = this.props
		const { outputMappingModal } = this.state

		return <React.Fragment>
			<td valign="top" style={{ position: 'relative' }}>
				<Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-25px', top: '150px' }} />
				<Card
					className='workflowCard'
					title="Store data"
					extra={<Popconfirm
						title="Do you really want to delete this step?"
						onConfirm={() => {
							let newWorkflow = deleteStep(workflow, step)
							updateWorkflow(newWorkflow)
						}}
						okText="Yes"
						cancelText="No"
					>
						<Button size="small" shape="circle" icon="close" />
					</Popconfirm>}
				>

					<h4>Set variable...</h4>


					<AntSelect
						showSearch
						optionFilterProp="children"
						filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						style={{ width: '100%', height: 50, marginBottom: 15 }}
						size="large"
						placeholder={"Click to select a variable to set / update"}
						value={JSON.stringify({ id: step.key || null, path: step.keyPath || null })}
						onChange={(value) => {
							const { id, path } = JSON.parse(value)
							const newWorkflow = updateStep(localWorkflow, {
								...step,
								key: id,
								keyPath: path
							})

							console.log({ newWorkflow })
							updateLocalWorkflow(newWorkflow)
						}}
					>
						{getProperties(workflowVariables).map(model => <AntSelect.Option key={model.path} value={JSON.stringify({ id: model.id, path: model.path })}>{display(model.path)}</AntSelect.Option>)}
					</AntSelect>

					<h4>To value...</h4>

					<Mentions
						style={{ width: '100%', marginBottom: 15 }}
						placeholder={"Value"}
						size="large"
						prefix={['{']}
						onChange={value => {
							let newStep = { ...step }
							newStep.value = value
							const newWorkflow = updateStep(localWorkflow, newStep)
							updateLocalWorkflow(newWorkflow)
						}}
						value={step.value || ""}
					>
						{getProperties([...availableVariables, ...encapsulate(workflowVariables, "storage")])
							// .filter(property => property.type !== 'object' && property.type !== "array")
							.map(model => <Option key={model.path} value={model.path + "}"}>
								<strong style={{ color: "#293742" }}>{display(model.path)}</strong><br />
								<i style={{ color: "#495762" }}>{display(model.type)}{model.example ? <span>, Ex.: {model.example}</span> : <span></span>}</i><br />
							</Option>)}
					</Mentions>

				</Card>
			</td>
		</React.Fragment>
	}
}