import { Breadcrumbs, Button as BPButton, Callout, Card, Code, Divider, FormGroup, H1, H2, InputGroup, MenuItem, Slider, TagInput, Tooltip } from "@blueprintjs/core";
import axios from 'axios';
import React from 'react';
import api from '../api';





export default class extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            way: "",
            setupId: "",
            scenarioId: "",
            actionsAvailable: [],
            configSecuritySchema: {},
            promptConfig: [],
            selectedTemplate: '',


            presets: [],
            showPassword: false
        }
    }

    async componentDidMount() {

        const { data } = await axios.get('https://api.apis.guru/v2/list.json')

        const presets = Object.keys(data).map(apiName => {
            const version = Object.keys(data[apiName].versions)[0]
            const { swaggerUrl } = data[apiName].versions[version]

            return { apiName, swaggerUrl }
        })

        const [scenarioId, setupId] = [this.props.match.params.scenarioId, this.props.match.params.setupId]

        this.setState({presets, setupId, scenarioId})

    }


    itemRenderer(item, { handleClick, modifiers, query }) {

        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${item.apiName}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                key={item.apiName}
                onClick={handleClick}
                text={this.highlightText(text, query)}
            />
        );
    }

    itemPredicate(query, item, _index, exactMatch) {
        const normalizedTitle = item.apiName.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if(normalizedQuery.length < 3) return false
    
        if (exactMatch) {
            return normalizedTitle === normalizedQuery;
        } else {
            return `${normalizedTitle} ${item.swaggerUrl}`.indexOf(normalizedQuery) >= 0;
        }
    };

    highlightText(text, query) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(text => text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1"));
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    selectPreset(e) {
        console.log(e)
    }

    handleScopesChange(selectedScopes) { this.setState({scopes: selectedScopes}) }

    async updateConfig() {
        const payload = {
            action: 'oauth2-action', 
            name: this.state.apiName,
            scenarioName: this.state.actionName,
            scenarioId: this.state.scenarioId,
            isOAuth2: true,
            config: {
                clientId: this.state.clientId,
                baseUrl: this.state.baseUrl,
                clientSecret: this.state.clientSecret,            
                authorizationUrl: this.state.authorizationUrl,            
                accessTokenUrl: this.state.accessTokenUrl,            
                scopes: this.state.scopes
            }
        }

        const setup = await api('setups/' + this.state.setupId + '/updateConfig', 'post', payload)
        
        console.log(setup)

        this.props.history.push('/setups/' + this.state.setupId)

    }

    render() {

        const { 
            state: 
                { actionsAvailable, way, promptConfig, configSecuritySchema, selectedTemplate, setupId, scenarioId }, 
            props: 
                { match } 
        } = this

        // console.log(promptConfig, configSecuritySchema)

        return <React.Fragment>
                <Breadcrumbs items={[
                { href: "/scenarios", icon: "folder-close", text: "My workflows" },
                { icon: "document", text: "New OAuth2 workflow" },
            ]} />
            <br />

                    <H1>Create a new OAuth2 workflow</H1>

                    <Divider /><br />

                    <Card>

                        <FormGroup
                            label="Name of the product"
                            labelFor="text-input-apiNale"
                        >
                            <InputGroup id="text-input-apiName" placeholder="Github" large onChange={e => this.setState({apiName: e.target.value})}/>
                        </FormGroup>

                        <FormGroup
                            label="Name of the integration"
                            labelFor="text-input-actionName"
                        >
                            <InputGroup id="text-input-actionName" placeholder="Get user starred repos" large onChange={e => this.setState({actionName: e.target.value})}/>
                        </FormGroup>

                        <FormGroup
                            label="Auth URL"
                            labelFor="text-input-authURL"
                        >
                            <InputGroup id="text-input-authURL" placeholder="http://example.api/auth" large onChange={e => this.setState({authorizationUrl: e.target.value})}/>
                        </FormGroup>

                        <FormGroup
                            label="Token URL"
                            labelFor="text-input-tokenURL"
                        >
                            <InputGroup id="text-input-tokenURL" placeholder="http://example.api/token" large onChange={e => this.setState({accessTokenUrl: e.target.value})} />
                        </FormGroup>

                        <FormGroup
                            label="API Base URL"
                            labelFor="text-input-baseURL"
                        >
                            <InputGroup id="text-input-baseURL" placeholder="http://example.api" large onChange={e => this.setState({baseUrl: e.target.value})} />
                        </FormGroup>

                        <FormGroup
                            label="Scopes"
                            labelFor="scopes-input"
                        >
                            <TagInput
                                id="scopes-input"
                                onChange={this.handleScopesChange.bind(this)}
                                placeholder="Separate values with commas..."
                                values={this.state.scopes || []}
                                large
                            />
                        </FormGroup>

                        <Divider /><br />

                        <FormGroup
                            label="Client ID"
                            labelFor="clientId-input"
                        >
                            <InputGroup id="clientId-input" placeholder="0123456789" large onChange={e => this.setState({clientId: e.target.value})} />
                        </FormGroup>

                        <FormGroup
                            label="Client Secret"
                            labelFor="clientSecret-input"
                        >
                            <InputGroup
                                large
                                placeholder="09876543211234567890"
                                rightElement={<Tooltip content={`${this.state.showPassword ? "Hide" : "Show"} Client Secret`} >
                                    <BPButton
                                        icon={this.state.showPassword ? "unlock" : "lock"}
                                        minimal={true}
                                        onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                                    />
                                </Tooltip>}
                                type={this.state.showPassword ? "text" : "password"}
                                onChange={e => this.setState({clientSecret: e.target.value})}
                            />
                        </FormGroup>

                        <Callout title="Redirect URL" icon="link">
                            Set the redirect URL of your app to the following : <Code>{api.endpoint + "cb/" + setupId}</Code>
                        </Callout><br />

                        <BPButton text="Create workflow" large fill intent="primary" onClick={e => this.updateConfig()} />

                        
                    {/* <Suggest
                        inputValueRenderer={item => item.apiName}
                        itemsEqual={(item1, item2) => item1.apiName.toLowerCase() === item2.apiName.toLowerCase()}
                        items={this.state.presets}
                        noResults={<MenuItem disabled={true} text="No results." />}
                        itemRenderer={this.itemRenderer.bind(this)}
                        itemPredicate={this.itemPredicate.bind(this)}
                        large
                        onItemSelect={this.selectPreset}
                    /> (1440 APIs available. Enter at least 3 characters.) */}

                    </Card>

                    {/* <br />

                    <Card>
                        <H2>Write, upload and run your custom integration code</H2>

                        <p style={{fontSize: 16}}>Open your terminel and run the following commands.</p>

                        <Code style={{display: 'block', whiteSpace: 'pre-wrap', fontSize: 16}}>
                            $ git clone https://github.com/ahirel/knowball-serverless<br />
                            $ npm install

                        </Code>

                        <br />
                        <br />

                        <p style={{fontSize: 16}}>Replace the content of the <code>serverless.yml</code> file by the following content.</p>

                        <Code style={{display: 'block', whiteSpace: 'pre-wrap', fontSize: 16, lineHeight: 1.5}}>
                        {"service: user-functions"} <br /><br />

                        {"provider:"}
                        {"  name: aws"} <br />
                        {"  runtime: nodejs10.x"} <br />

                        {"custom:"} <br />
                        {"  setupId: " + this.state.setupId} <br />
                        {"  baseUrl: http://api.ex.com"} <br />

                        {"functions:"} <br />
                        {"  " + this.state.setupId + ":"} <br />
                        {"    handler: handler.${self:custom.setupId}"} <br />
                        {"    environment:"} <br />
                        {"    SETUP_ID: ${self:custom.setupId}"} <br />
                        {"    BASE_URL: ${self:custom.baseUrl}"} <br />

                        </Code>


                        <br /><br />


                        <H2>Write the integration code</H2><br />

                        <p style={{fontSize: 16}}>Write your function code into the <code>index.js</code> file.</p>
                        <p style={{fontSize: 16}}>Return the value you want the function to respond.</p>
                        <p style={{fontSize: 16}}>Return the value you want the function to respond.</p>

                        <br /><H2>Upload and run the code</H2><br />


                        <br /><H2>Write, upload and run your custom integration code</H2><br />

                        <p style={{fontSize: 16}}>If serverless is not installed on your computer.</p>

                        <Code style={{display: 'block', whiteSpace: 'pre-wrap', fontSize: 16, lineHeight: 1.5}}>npm install serverless -g</Code>

                        <br /><p style={{fontSize: 16}}>Run the following code in your terminal. Those keys are dedicated to you and let you upload Integration Functions and update them. Do not share them with anyone or publically publish them.</p>

                        <Code style={{display: 'block', whiteSpace: 'pre-wrap', fontSize: 16, lineHeight: 1.5}}>$ AWS_ACCESS_KEY_ID=AKIAXT3JU7RIOUQH42U3 AWS_SECRET_ACCESS_KEY=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 serverless deploy</Code>

                    </Card>


                    <br /><br /> */}

                    {/* <BPButton large text={this.getDescription(this.state.selectedTemplate) || "Select a template"} rightIcon="double-caret-vertical" onClick={this.toggleOverlay.bind(this)} />
                    <Overlay isOpen={this.state.isOpen} onClose={this.toggleOverlay.bind(this)} className="bp3-over-scroll-container">
                        <div className="bp3-card bp3-elevation-4 docs-overlay-example-transition bp3-dark bp3-overlay-content" style={{width: '60%', marginLeft: 'auto', marginRight: 'auto'}}>
                            <H3>I'm an Overlay!</H3>
                            <p>
                                This is a simple container with some inline styles to position it on the screen. Its CSS
                                transitions are customized for this example only to demonstrate how easily custom
                                transitions can be implemented.
                            </p>
                            <p>
                                Click the "Focus button" below to transfer focus to the "Show overlay" trigger button
                                outside of this overlay. If persistent focus is enabled, focus will be constrained to the
                                overlay. Use the  key to move to the next focusable element to illustrate
                                this effect.
                            </p>
                            <p>
                                Click the "Make me scroll" button below to make this overlay's content really tall, which
                                will make the overlay's container (but not the page) scrollable
                            </p>
                            <br />
                            <div className="bp3-dialog-footer-actions">
                                <BPButton intent="danger" onClick={this.toggleOverlay.bind(this)} style={{ margin: "" }}>
                                    Close
                                </BPButton>
                            </div>
                        </div>
                    </Overlay> */}

                    
                    
                    {/* <React.Fragment>
                        &nbsp;&nbsp;&nbsp;or&nbsp;&nbsp;&nbsp;
                        <BPButton large text="create a workflow from scratch" />
                    </React.Fragment> */}


                    {/* <br /><br />

                    {selectedTemplate && <BPButton text="Create workflow" large fill intent="primary" onClick={e => this.changeAction(selectedTemplate)} />} */}

                    {/* <br /><Divider /><br /> */}

                   

                    <Card>
                        <H2>How should the connection be triggered?</H2>



                    </Card>

                    <br />

                    <Card style={{padding: 50}}>

                    

                        <H2>Trigger frequence</H2>

                        <Slider 
                            min={0}
                            max={5}
                            labelStepSize={1}
                            showTrackFill={false}
                            value={this.state.sliderStep || 0}
                            onChange={value => this.setState({sliderStep: value})}
                            labelRenderer={(value) => {
                                switch (value) {
                                    case 0: return "2min"; break;
                                    case 1: return "10min"; break;
                                    case 2: return "30min"; break;
                                    case 3: return "1h"; break;
                                    case 4: return "6h"; break;
                                    case 5: return "1d"; break;
                                
                                    default: return "1d"; break;
                                }
                            }}
                        />

                        <br />

                        { this.state.sliderStep === 0 && <p>Approximatively <strong>21,600</strong> calls per user and per month</p> }
                        { this.state.sliderStep === 1 && <p>Approximatively <strong>4,320</strong> calls per user and per month</p> }
                        { this.state.sliderStep === 2 && <p>Approximatively <strong>1,440</strong> calls per user and per month</p> }
                        { this.state.sliderStep === 3 && <p>Approximatively <strong>720</strong> calls per user and per month</p> }
                        { this.state.sliderStep === 4 && <p>Approximatively <strong>120</strong> calls per user and per month</p> }
                        { this.state.sliderStep === 5 && <p>Approximatively <strong>30</strong> calls per user and per month</p> }
                    
                    </Card>

                {/* </Card> */}

                <h3>Create from an existing framework</h3>




            
                
                

            



{/* 
                { Object.keys(actionsAvailable)
                    .map(action => {
                        return <p key={action} >{ action } ({actionsAvailable[action].type})</p>
                    }) } */}

                {/* <Form.Group controlId="action">
                    <Form.Label>Action</Form.Label>
                    <Form.Control as="select" onChange={ this.changeAction.bind(this) }>
                        <option hidden disabled selected value>Select an action</option>
                        { Object.keys(actionsAvailable).map(action => <option key={action} value={action}>{ action } ({actionsAvailable[action].type})</option>) }
                    </Form.Control>
                </Form.Group> */}

                {/* <Form.Group controlId="webhook">
                    <Form.Label>Webhook</Form.Label>
                    <Form.Control type="text" />
                </Form.Group> */}


        





            {/* <Form onSubmit={ this.submitForm.bind(this) }>
                <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" />
                </Form.Group>

                <Form.Group controlId="action">
                    <Form.Label>Action</Form.Label>
                    <Form.Control as="select" onChange={ this.changeAction.bind(this) }>
                        <option hidden disabled selected value>Select an action</option>
                        { Object.keys(actionsAvailable).map(action => <option key={action} value={action}>{ action } ({actionsAvailable[action].type})</option>) }
                    </Form.Control>
                </Form.Group>

            
            </Form>    


 */}


                





        </React.Fragment>
    }
}
