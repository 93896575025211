import React from 'react';
import {Route} from 'react-router-dom';
import auth0Client from './Auth';

import { Spinner } from '@blueprintjs/core'

function SecuredRoute(props) {
    const {component: Component, exact, path, checkingSession} = props;
    // console.log(props)
    
    return (
      <Route path={path} exact={exact} render={(p) => {
            if (checkingSession) return <span><br /><br /><h3 className="text-center">Loading</h3></span>;
            if (!auth0Client.isAuthenticated()) {

                // console.log("Props before auth", props.location)

                localStorage.setItem('redirect_to_page', props.location.pathname)

                auth0Client.signIn();
                return <div></div>;
            }
            return <Component {...p} />
        }} 
        onChange={console.log}
        
        />
    );
}

export default SecuredRoute;