import React from 'react';
import { Spinner } from "@blueprintjs/core";
import { Button, Divider, Form, Input, PageHeader, Popconfirm, Radio, Row, Select, Tabs, Typography } from 'antd';
import camelCase from 'camelcase';
import api from '../api';
import SchemaBuilder from '../components/SchemaBuilder';

import MarkdownIt from 'markdown-it'
import MdEditor from 'react-markdown-editor-lite'
import 'react-markdown-editor-lite/lib/index.css';

const { Paragraph } = Typography
const { TabPane } = Tabs

const mdParser = new MarkdownIt(/* Markdown-it options */);




export default class Scenarios extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			method: null,
			input: {}
		}
	}

	async componentDidMount() {
		console.log("componentDidMount")
		const { blockId } = this.props.match.params
		const { data: { method } } = await api(`blocks/${blockId}`)

		this.setState({
			method,

			input: method.input,
			output: method.output,
		})
	}

	updateValue(value, field, subfield = null) {
		let newBlock = this.state.method
		let newValue = value

		console.log(newValue)

		if (subfield) {
			newBlock[field][subfield] = newValue
		} else {
			newBlock[field] = newValue
		}

		this.setState({ method: newBlock })
	}

	render() {
		const { method, search, input, output } = this.state
		console.log('render')

		if (!method) {
			return <React.Fragment>
				<PageHeader
					title={"Product"}
					style={{ backgroundColor: 'white' }}
				>
					<br /><br /><h3 className="text-center"><Spinner /></h3><br /><br />
				</PageHeader>
			</React.Fragment>
		}

		const formItemLayout = { labelCol: { span: 3 }, wrapperCol: { span: 14 }, }

		return <React.Fragment>

			<PageHeader
				title={method.name || "Untitled block"}
				subTitle={method.description}
				onBack={() => this.props.history.push(`/products/${method.product._id}`)}
				style={{ backgroundColor: 'white' }}
				extra={[
					<Select key="1" loading={this.state.loadingUpdateStatus} size="large" defaultValue={this.state.method.state || "3"} style={{ width: 220 }} onChange={async v => {
						this.setState({ loadingUpdateStatus: true })
						await api(`blocks/${method._id}/state`, 'post', { state: v })
						this.setState({ loadingUpdateStatus: false })
					}}>
						<Select.Option value="1">Tested</Select.Option>
						<Select.Option value="2">Valid</Select.Option>
						<Select.Option value="3">Needs check</Select.Option>
						<Select.Option value="4">Broken</Select.Option>
					</Select>,


					<Popconfirm key="3" title="Are you sure ?" onConfirm={async () => {
						this.setState({ deletingBlock: true })
						await api(`blocks/${method._id}/delete`, 'post')
						this.props.history.push(`/products/${method.product._id}`)
						this.setState({ deletingBlock: false })
					}} okText="Yes" cancelText="No"><Button size="large" type="danger" icon="delete" loading={this.state.deletingBlock}>Delete</Button></Popconfirm>,
					<Button key="2" size="large" type="primary" icon="save" loading={this.state.updateingBlock} onClick={async () => {
						this.setState({ updateingBlock: true })
						await api(`blocks/${method._id}/update`, 'post', method)
						this.setState({ updateingBlock: false })
					}}>Save</Button>
				]}
			>
				<Tabs defaultActiveKey="1">
					<TabPane tab="General information" key="1">
						<Form layout="horizontal">
							<Form.Item label="Method" {...formItemLayout}>
								<Radio.Group defaultValue="horizontal" size="large" onChange={(e) => this.updateValue(e.target.value, 'method')} value={method.method}>
									<Radio.Button value="GET">GET</Radio.Button>
									<Radio.Button value="POST">POST</Radio.Button>
									<Radio.Button value="PUT">PUT</Radio.Button>
									<Radio.Button value="PATCH">PATCH</Radio.Button>
									<Radio.Button value="DELETE">DELETE</Radio.Button>
								</Radio.Group>
							</Form.Item>
							<Form.Item label="Name" {...formItemLayout}><Input placeholder="Create a lead" size="large" onChange={(e) => this.updateValue(e.target.value, 'name')} value={method.name} /></Form.Item>
							<Form.Item label="Description" {...formItemLayout}><Input placeholder="Creates a lead" size="large" onChange={(e) => this.updateValue(e.target.value, 'description')} value={method.description} /></Form.Item>
							<Form.Item label="Action" {...formItemLayout}><Input placeholder="createLead" size="large" addonBefore={camelCase(method.product.name) + "-"} onChange={(e) => this.updateValue(e.target.value, 'action')} value={method.action.replace(camelCase(method.product.name) + "-", '')} /></Form.Item>
							<Form.Item label="Request path" {...formItemLayout}><Input placeholder="/leads" size="large" onChange={(e) => this.updateValue(e.target.value, 'requestPath')} value={method.requestPath} /></Form.Item>

							<Form.Item label="Method API doc page" {...formItemLayout}><Input placeholder="https://api.slack.com/auth" size="large" onChange={(e) => this.updateValue(e.target.value, 'apiDocumentation')} value={method.apiDocumentation} /></Form.Item>


							<Form.Item label="Scopes" {...formItemLayout}>
								<Select
									size="large"
									mode="tags"
									style={{ width: '100%' }}
									placeholder="contacts"
									value={method.scopes.split(', ').length > 0 && method.scopes.split(', ')[0] ? method.scopes.split(', ') : []}
									onChange={(value) => this.updateValue(value.join(', '), 'scopes')}
								></Select>
							</Form.Item>
						</Form>
					</TabPane>
					<TabPane tab="Helper" key="2">

						{/* <h1>Help</h1> */}

						<MdEditor
							value={method.help}
							style={{ height: "300px" }}
							renderHTML={(text) => mdParser.render(text)}
							onChange={v => this.updateValue(v.text, 'help')}
						/>
					</TabPane>

					<TabPane tab="Input" key="3">

						<h2>Body</h2>
						<SchemaBuilder inputSchema={this.state.input.body} onUpdate={value => this.updateValue(value, 'input', 'body')} />

						<br /><h2>Headers</h2>
						<SchemaBuilder inputSchema={this.state.input.headers} onUpdate={value => this.updateValue(value, 'input', 'headers')} />

						<br /><h2>Path</h2>
						<SchemaBuilder inputSchema={this.state.input.path} onUpdate={value => this.updateValue(value, 'input', 'path')} />

						<br /><h2>Query</h2>
						<SchemaBuilder inputSchema={this.state.input.query} onUpdate={value => this.updateValue(value, 'input', 'query')} />

					</TabPane>

					<TabPane tab="Output" key="4">
						<h2>Output</h2>
						<SchemaBuilder inputSchema={this.state.output.body} onUpdate={value => this.updateValue(value, 'output', 'body')} />
					</TabPane>
				</Tabs>

			</PageHeader>

		</React.Fragment>
	}
}
