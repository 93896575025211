import { Button, Card, Col, Icon, Mentions, Modal, Popconfirm, Row, Select as AntSelect } from 'antd';
import React from 'react';
import api from '../../api';
import Normalizer from '../../components/Normalizer';
import { encapsulate, flatten, getProperties } from '@simplifier/normalization';
import { deleteStep, display, updateStep } from '../../utils/workflows.js';
const { Option } = Mentions

export default class ConnectorStep extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            empty: this.props.empty,
            inputMappingModal: false,
            outputVariablesModal: false,
            relaodingStep: false
        }
    }

    render() {
        const {
            step,
            block,
            databases,
            localWorkflow,
            availableVariables,
            workflow,
            availableMethods,
            products,
            updateWorkflow,
            updateLocalWorkflow,
            updateAvailableMethods,
            workflowVariables
        } = this.props

        const { empty, inputMappingModal, parametersMapping } = this.state

        const outputParameters = block ? flatten(block.output)
            .filter(item => !item.base)
            .filter(item => item.path) : []

        const componentActions = {
            objectId: ["get", "update", "delete"],
            listFilter: ["list"],
            parameters: ["insert", "update", "loginByEmail", "deleteByUid"]
        }

        const database = databases.filter(db => db._id === step.databaseId).length ? databases.filter(db => db._id === step.databaseId)[0] : null

        return <td valign="top" style={{ position: 'relative' }}>

            <Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-25px', top: '150px' }} />
            <Card
                style={{ scrollSnapAlign: "start" }}
                className='workflowCard'
                loading={this.state.relaodingStep}
                title="Database"
                extra={<>
                    <Button size="small" shape="circle" icon="reload" style={{ marginRight: 5 }} onClick={async e => {
                        let newStep = { ...step }

                        this.setState({ relaodingStep: true })

                        let currentParameters = step.parameters
                        const { data: database } = await api(`databases/${step.databaseId}/${step.tableName}`)

                        let newParameters = database.tables[0].fields

                        for (let parameter of currentParameters) {
                            if (parameter.value) {
                                console.log('found', parameter)
                                let property = newParameters.filter(param => param.id === parameter.id)[0]

                                if (property) {
                                    property.value = parameter.value
                                    console.log(newParameters.filter(param => param.id === parameter.id)[0])
                                }


                            }
                        }

                        newStep.parameters = newParameters.filter(prop => !prop.delete)

                        const newWorkflow = updateStep(localWorkflow, newStep)
                        updateLocalWorkflow(newWorkflow)

                        this.setState({ relaodingStep: false })

                    }} />

                    <Popconfirm
                        title="Do you really want to delete this step?"
                        onConfirm={() => {
                            let newWorkflow = deleteStep(workflow, step)
                            updateWorkflow(newWorkflow)
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button size="small" shape="circle" icon="close" />
                    </Popconfirm>
                </>}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <AntSelect
                            placeholder="Database"
                            style={{ width: '100%', marginBottom: 15 }}
                            size="large"
                            onChange={value => {
                                let newStep = { ...step }
                                newStep.databaseId = value
                                const newWorkflow = updateStep(localWorkflow, newStep)
                                updateLocalWorkflow(newWorkflow)
                            }}
                            value={step.databaseId || undefined}
                        >
                            {databases.map(database => <AntSelect.Option value={database._id}>{database.name}</AntSelect.Option>)}

                        </AntSelect>
                    </Col>
                    <Col span={12}>
                        <AntSelect
                            placeholder="Table"
                            style={{ width: '100%', marginBottom: 15 }}
                            size="large"
                            disabled={!step.databaseId}
                            onChange={value => {
                                let newStep = { ...step }
                                newStep.tableName = value

                                newStep.parameters = databases
                                    .filter(db => db._id === step.databaseId)[0]
                                    .tables.filter(table => table.name === value)[0]
                                    .fields

                                const newWorkflow = updateStep(localWorkflow, newStep)
                                updateLocalWorkflow(newWorkflow)
                            }}
                            value={step.tableName || undefined}
                        >
                            {
                                database &&
                                database.tables
                                    .map(table => <AntSelect.Option value={table.name}>{table.name}</AntSelect.Option>)

                            }

                        </AntSelect>
                    </Col>
                </Row>


                {/* {step.action} */}


                <AntSelect
                    placeholder="Action"
                    style={{ width: '100%', marginBottom: 15 }}
                    size="large"
                    disabled={!step.databaseId}
                    onChange={value => {
                        let newStep = { ...step }
                        newStep.action = value
                        const newWorkflow = updateStep(localWorkflow, newStep)
                        updateWorkflow(newWorkflow)
                    }}
                    value={step.action || undefined}
                >
                    {database && Object.keys(database.actions).map(action => {
                        return <AntSelect.Option value={action}>{database.actions[action]}</AntSelect.Option>
                    })}

                </AntSelect>

                {
                    componentActions.objectId.includes(step.action) && <>
                        <Mentions
                            style={{ width: '100%', marginBottom: 15 }}
                            placeholder={"Object ID"}
                            size="large"
                            prefix={['{']}
                            onChange={value => {
                                let newStep = { ...step }
                                newStep.objectId = value
                                const newWorkflow = updateStep(localWorkflow, newStep)
                                updateLocalWorkflow(newWorkflow)
                            }}
                            value={step.objectId || ""}
                        >
                            {[...getProperties(availableVariables)].filter(property => property.type !== 'object' && property.type !== "array").map(model => <Option key={model.path} value={model.path + "}"}>
                                <strong style={{ color: "#293742" }}>{display(model.path)}</strong><br />
                                <i style={{ color: "#495762" }}>{display(model.type)}{model.example ? <span>, Ex.: {model.example}</span> : <span></span>}</i><br />
                            </Option>)}
                        </Mentions>
                    </>
                }

                {
                    componentActions.listFilter.includes(step.action) && <>
                        <Mentions
                            style={{ width: '100%', marginBottom: 15 }}
                            placeholder={"Filter"}
                            size="large"
                            prefix={['{']}
                            onChange={value => {
                                let newStep = { ...step }
                                newStep.listFilter = value
                                const newWorkflow = updateStep(localWorkflow, newStep)
                                updateLocalWorkflow(newWorkflow)
                            }}
                            value={step.listFilter || ""}
                        >
                            {[...getProperties(availableVariables)].filter(property => property.type !== 'object' && property.type !== "array").map(model => <Option key={model.path} value={model.path + "}"}>
                                <strong style={{ color: "#293742" }}>{display(model.path)}</strong><br />
                                <i style={{ color: "#495762" }}>{display(model.type)}{model.example ? <span>, Ex.: {model.example}</span> : <span></span>}</i><br />
                            </Option>)}
                        </Mentions>
                    </>
                }

                {
                    componentActions.parameters.includes(step.action) && <>
                        <Button block size="large" type="default" onClick={() => this.setState({ parametersMapping: true })} style={{ marginBottom: 15 }}>Edit values</Button>
                        <Modal
                            title="Parameters"
                            visible={parametersMapping}
                            onOk={() => { updateWorkflow(localWorkflow); this.setState({ parametersMapping: false }) }}
                            onCancel={() => this.setState({ parametersMapping: false })}
                            width={800}
                            style={{maxWidth: "95%"}}
                        >
                            <Normalizer
                                input={step.parameters}
                                availableVariables={[...availableVariables, ...encapsulate(workflowVariables, "storage")]}
                                updateStepInput={input => {
                                    // const newWorkflow = updateStepInput(localWorkflow, step.stepId, input)
                                    // updateLocalWorkflow(newWorkflow)

                                    console.log(input)

                                    let newStep = { ...step }
                                    newStep.parameters = input
                                    const newWorkflow = updateStep(localWorkflow, newStep)
                                    updateLocalWorkflow(newWorkflow)
                                }}
                            />
                        </Modal>
                    </>
                }



            </Card></td >
    }
}