import { Button, Card, Dropdown, Menu, Icon, List, PageHeader, Typography } from 'antd';
import React from 'react';
import api from '../api';

const { Paragraph } = Typography

export default class Databases extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            databases: [],
            availableDatabases: [],
            newDatabaseLoader: false
        }
    }

    async componentDidMount() {
        const { data: databases } = await api('databases')
        const { data: availableDatabases } = await api('databases/available')
        this.setState({ databases, availableDatabases })
        console.log(availableDatabases)
    }

    count(scenario) {
        return scenario.setups.reduce((acc, curr) => acc + curr.auths.length, 0)
    }

    render() {

        const { databases, availableDatabases } = this.state

        const menu = <Menu onClick={async (e) => {
            this.setState({ newDatabaseLoader: true })
            const { data } = await api('databases', 'post', { type: e.key })
            this.setState({ newDatabaseLoader: false })
            this.props.history.push(`/databases/${data.id}`)
        }}>
            {
                availableDatabases.map(db => <Menu.Item key={db.type} {...db}>
                    {/* <Icon type="user" /> */}
                    {db.type}
                </Menu.Item>)
            }
        </Menu >

        return <React.Fragment>

            <PageHeader
                title="My databases"
                style={{ backgroundColor: 'white' }}
            >
                <div className="wrap">
                    <div className="content">
                        <Paragraph>
                            Connect your own product's databases in order to perform basic actions in them (CRUD) directly from the workflows you create.
                        </Paragraph>
                        {/* <Button className="contentLink" type="primary" size="large" icon="plus" onClick={async () => {
                            this.setState({ createWorkflowLoader: true })

                            const { projectId } = this.props.match.params
                            const defaultWorkflow = {
                                "version": 2,
                                "auth": {},
                                "name": "Workflow",
                                "sequences": []
                            }

                            const { data: { createWorkflow: { id } } } = await api('workflows', 'post', { projectId, workflow: defaultWorkflow })

                            this.setState({ createWorkflowLoader: false })

                            this.props.history.push(`/workflows/${id}`)
                            return true
                        }
                        }>
                            Connect a database
                        </Button> */}

                        <Dropdown overlay={menu}>
                            <Button loading={this.state.newDatabaseLoader} className="contentLink" type="primary" size="large" icon="plus">
                                Connect a database <Icon type="down" />
                            </Button>
                        </Dropdown>
                    </div>
                </div>
            </PageHeader>

            <div style={{ padding: 24, minHeight: 360, borderRadius: 4 }}>
                <List
                    grid={{ gutter: 16, xs: 2, sm: 2, md: 2, lg: 3, xl: 3, xxl: 3 }}
                    dataSource={databases}
                    // itemLayout="horizontal"
                    renderItem={database => (
                        <List.Item>
                            <Card title={database.name}
                                style={{ borderRadius: 5 }}
                                extra={<Button shape="circle" icon="setting" onClick={() => this.props.history.push("/databases/" + database._id)} />}
                            >
                                {database.tables.map(table => <Card.Grid style={{ width: '33.333333%', textAlign: 'center', cursor: "pointer" }} onClick={() => this.props.history.push("/databases/" + database._id + "/" + table.name)}>{table.name}</Card.Grid>)}

                                <Card.Grid
                                    style={{ width: '33.333333%', textAlign: 'center', cursor: "pointer" }}
                                    onClick={async () => {
                                        const { data } = await api('databases/' + database._id, 'post')
                                        this.props.history.push(`/databases/${database._id}/NewTable`)
                                    }}
                                >
                                    <Icon type="plus" /> New table
                                </Card.Grid>

                                {/* <ReactJson src={database} /> */}
                            </Card>
                        </List.Item>
                    )}
                />

            </div>
        </React.Fragment>
    }
}
