import React, { useState } from 'react';

import {
    EuiButton,
    EuiCode,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiPanel,
    EuiSpacer,
    EuiTitle,
    EuiOverlayMask,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiText,
    EuiModalFooter,
    EuiFormControlLayout,
    EuiButtonEmpty,
    EuiFlexGroup,
    EuiFlexItem,
    EuiCheckbox,
    EuiIcon,
    EuiFieldText,
    EuiPopover,
    EuiButtonIcon
} from '@elastic/eui';


function cap(string) { return string.charAt(0).toUpperCase() + string.slice(1); }


export default function DataStructureFlyout({
    text,
    input,
    onUpdate
}) {
    const [flyout, toggleFlyout] = useState(false);

    const closeFlyout = () => toggleFlyout(false)


    return <>
        <EuiButton onClick={() => toggleFlyout(true)}>
            {text}
        </EuiButton>

        {flyout && <EuiFlyout
            ownFocus
            onClose={() => toggleFlyout(false)}
            size="l"
            aria-labelledby="flyoutLargeTitle">
            <EuiFlyoutHeader hasBorder>
                <EuiTitle size="m">
                    <h2 id="flyoutLargeTitle">A large flyout</h2>
                </EuiTitle>
            </EuiFlyoutHeader>
            <EuiFlyoutBody>
                {input.map((property, i) => <div key={i}>
                    <ParameterInput property={property} />
                </div>)}

            </EuiFlyoutBody>
        </EuiFlyout>}
    </>
}

const ParameterInput = function ({ property }) {
    const [required, setRequired] = useState(false)
    const [def, setDef] = useState("")
    const [example, setExample] = useState("")

    return <>
        <EuiFlexGroup gutterSize="s">
            <EuiFlexItem grow={false}>

                <EuiFormControlLayout
                    // isLoading
                    // clear={{ onClick: () => { } }}
                    prepend={
                        <EuiButtonEmpty size="xs" iconType="arrowDown" iconSide="right" style={{ minWidth: 100 }}>
                            {cap(property.type)}
                        </EuiButtonEmpty>
                    }
                    append={
                        <EuiButtonEmpty size="xs" onClick={() => setRequired(!required)} style={{ color: required ? "" : "#BBB" }}>
                            <EuiIcon type="asterisk" color={required ? "accent" : ""} size="m" style={{ marginTop: -4 }} />
                        </EuiButtonEmpty>
                    }
                >
                    <input
                        type="text"
                        className="euiFieldText euiFieldText--inGroup"
                        aria-label="Use aria labels when no actual label is in use"
                        placeholder="Label"
                    />
                </EuiFormControlLayout>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
                <EuiButtonEmpty size="xs" onClick={() => setExample(!example)} style={{
                    color: example ? "" : "#BBB",
                    backgroundColor: "#fbfcfd",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "0% 100%",
                    boxShadow: "0 0, inset 0 0 0 1px rgba(19, 34, 149, 0.1)",
                    transition: "box-shadow 150ms ease-in, background-image 150ms ease-in, background-size 150ms ease-in, background-color 150ms ease-in",
                    display: "flex",
                    alignItems: "stretch",
                    height: 40, width: 34
                }}>
                    <EuiIcon type="quote" color={example ? "primary" : ""} size="m" style={{ marginTop: "8px", marginBottom: "8px" }} />
                </EuiButtonEmpty>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
                {example && <EuiFieldText
                    placeholder="Example value"
                    prepend={
                        <EuiButtonEmpty size="xs" onClick={() => setExample(!example)} style={{ color: example ? "" : "#BBB" }}>
                            <EuiIcon type="fold" color={example ? "primary" : ""} size="m" style={{ marginTop: -4 }} />
                        </EuiButtonEmpty>
                    }
                    style={{ width: example ? 200 : 80 }}
                    autoFocus
                />}

                {!example && <EuiButtonEmpty size="xs" onClick={() => setExample(!example)} style={{
                    color: example ? "" : "#BBB",
                    backgroundColor: "#fbfcfd",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "0% 100%",
                    boxShadow: "0 0, inset 0 0 0 1px rgba(19, 34, 149, 0.1)",
                    transition: "box-shadow 150ms ease-in, background-image 150ms ease-in, background-size 150ms ease-in, background-color 150ms ease-in",
                    display: "flex",
                    alignItems: "stretch",
                    height: 40, width: 34
                }}>
                    <EuiIcon type="fold" color={example ? "primary" : ""} size="m" style={{ marginTop: "8px", marginBottom: "8px" }} />
                </EuiButtonEmpty>}

            </EuiFlexItem>


            <EuiFlexItem grow={false}>
                {def && <EuiFieldText
                    placeholder="Default value"
                    prepend={
                        <EuiButtonEmpty size="xs" onClick={() => setDef(!def)} style={{ color: def ? "" : "#BBB" }}>
                            <EuiIcon type="magnet" color={def ? "primary" : ""} size="m" style={{ marginTop: -4 }} />
                        </EuiButtonEmpty>
                    }
                    style={{ width: def ? 200 : 80 }}
                />}

                {!def && <EuiButtonEmpty size="xs" onClick={() => setDef(!def)} style={{
                    color: def ? "" : "#BBB",
                    backgroundColor: "#fbfcfd",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "0% 100%",
                    boxShadow: "0 0, inset 0 0 0 1px rgba(19, 34, 149, 0.1)",
                    transition: "box-shadow 150ms ease-in, background-image 150ms ease-in, background-size 150ms ease-in, background-color 150ms ease-in",
                    display: "flex",
                    alignItems: "stretch",
                    height: 40, width: 34
                }}>
                    <EuiIcon type="magnet" color={def ? "primary" : ""} size="m" style={{ marginTop: "8px", marginBottom: "8px" }} />
                </EuiButtonEmpty>}

            </EuiFlexItem>
        </EuiFlexGroup>

        {/* </EuiPanel> */}
        <EuiSpacer size="l" />
    </>
}