import { Callout, FormGroup, Slider, Spinner } from '@blueprintjs/core'
import { Button as AntButton, Input } from 'antd'
import md5 from 'md5'
import React from 'react'
import ReactJson from 'react-json-view'
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
// import dark from 'react-syntax-highlighter/dist/esm/styles/prism/vs-dark'
import api from '../api'





export default class extends React.Component {
    constructor(props) { 
        super(props) 
        this.state = {
            setup: props.setup,
            sliderStep: this.getSliderStep(props.setup.delay),
            webhook: props.setup.webhook,
            loading: false
        }
    }

    componentDidMount() {
        // console.log(this.props)
    }

    getSliderValue(sliderStep) {
        if(sliderStep === 0) return null
        if(sliderStep === 1) return "2min"
        if(sliderStep === 2) return "10min"
        if(sliderStep === 3) return "30min"
        if(sliderStep === 4) return "1hour"
        if(sliderStep === 5) return "6hours"
        if(sliderStep === 6) return "1day"
        return null
    }

    getSliderStep(delay) {
        if(delay === null) return 0
        if(delay === "2min") return 1
        if(delay === "10min") return 2
        if(delay === "30min") return 3
        if(delay === "1hour") return 4
        if(delay === "6hours") return 5
        if(delay === "1day") return 6
        return 0
    }

    async updateConfig(e) {
        e.preventDefault()

        const payload = {
            webhook : this.state.webhook,
            delay: this.getSliderValue(this.state.sliderStep)
        }

        this.setState({ loading: true })

        const config = await api('setups/' + this.state.setup.id + '/update', 'post', payload)
        
        this.setState({ loading: false })
    }

    displayType(type) {
        if(type === "string") return `"string"`
        if(type === "array") return `["array"]`
        else return `"${type}"`
    }

    render() {

        const { setup, sliderStep, loading } = this.state
        const { props } = this

        if(this.state.loading) return <span><br /><br /><h3 className="text-center"><Spinner /></h3></span>

        // console.log(setup)

        console.log(md5(md5("cjxdu4hwo3z0n0b68ze4zhvyt" + "knowball")))

        return <React.Fragment>
            {/* <H2>Triggers and webhooks</H2> */}

            <br />
            
            {this.props.way === 'insideOut' && 
            
            <React.Fragment>
                <h4>Trigger from an API call</h4>

                {/* <SyntaxHighlighter language='javascript' style={dark}>{
                `const axios = require('axios')
class Simplifier { constructor(key) { this.key = key } async invoke(s,u,p){return await axios({method:'post',url:'${api.endpoint}hook/'+s+'/'+u,data:{parameters:p},headers:{'Authorization':'Bearer '+this.key}})}}

const simplifier = new Simplifier("${md5(md5(setup.scenario.project.id + "knowball"))}")
const userId = "REPLACE_WITH_USER_ID"
const result = await simplifier.invoke("${setup.id}", userId, {${this.props.variableParameters.map(parameter => "\n    " + parameter + ': ' + this.displayType(this.props.parametersSecuritySchema[parameter].type))}\n})`}</SyntaxHighlighter> */}

            </React.Fragment> }

            {['outsideIn'].includes(this.props.way) && 

            <React.Fragment>

                <h4>Trigger periodically</h4>

                {/* <Card style={{padding: 20}}> */}
                    <Slider 
                        min={0}
                        max={6}
                        labelStepSize={1}
                        showTrackFill={false}
                        value={this.state.sliderStep}
                        onChange={value => this.setState({sliderStep: value})}
                        style={{width: '80%'}}
                        labelRenderer={(value) => {
                            switch (value) {
                                case 0: return "Never"; break;
                                case 1: return "2min"; break;
                                case 2: return "10min"; break;
                                case 3: return "30min"; break;
                                case 4: return "1h"; break;
                                case 5: return "6h"; break;
                                case 6: return "1d"; break;
                                        
                                default: return "1d"; break;
                            }
                        }}
                    />
                
                    <br />

                    <Callout intent="primary">
                        { (!sliderStep || sliderStep === 0) && <React.Fragment>Never triggered from clock.</React.Fragment> }
                        { sliderStep === 1 && <React.Fragment>Approximatively <strong>21,600</strong> calls per user and per month</React.Fragment> }
                        { sliderStep === 2 && <React.Fragment>Approximatively <strong>4,320</strong> calls per user and per month</React.Fragment> }
                        { sliderStep === 3 && <React.Fragment>Approximatively <strong>1,440</strong> calls per user and per month</React.Fragment> }
                        { sliderStep === 4 && <React.Fragment>Approximatively <strong>720</strong> calls per user and per month</React.Fragment> }
                        { sliderStep === 5 && <React.Fragment>Approximatively <strong>120</strong> calls per user and per month</React.Fragment> }
                        { sliderStep === 6 && <React.Fragment>Approximatively <strong>30</strong> calls per user and per month</React.Fragment> }
                    </Callout>
                
                {/* </Card> */}

            </React.Fragment> }

            {['outsideIn', 'webhook'].includes(this.props.way) && 

            <React.Fragment>

                <h4>Webhook to call after execution</h4>

                <p>A post request will be made to the following webhook, containing the value returned by the integration function.</p>

                <FormGroup
                    label="Webhook URL"
                    labelFor="after-webhook-input"
                >
                    <Input id="after-webhook-input" size="large" placeholder="http://yoursite.com/hook" onChange={e => this.setState({webhook: e.target.value})} value={this.state.webhook || ''} />
                </FormGroup>

                <br />

                <AntButton fill large type="primary" style={{right: '0px'}} onClick={this.updateConfig.bind(this)}>Save</AntButton>
                
                <br />

                <h4>Example payload</h4>

                <ReactJson theme="solarized" style={{padding: 10, borderRadius: 3}} src={{error: null, userToken: "XXXXXXXX", result: this.props.expectedAnswer}} />

            </React.Fragment> }

        </React.Fragment>
    }
}