import { Button, Card, Icon, Input, Modal, Popconfirm, Select as AntSelect } from 'antd';
import React from 'react';
import Normalizer from '../../components/Normalizer';
import SchemaModal from '../../components/SchemaModal';
import { encapsulate } from '@simplifier/normalization';
import { deleteStep, updateStep } from '../../utils/workflows.js';

import Steps from '../Steps'

export default class WebhookStep extends React.Component {
    constructor(props) {
        super(props)

        this.state = { outputMappingModal: false }
    }

    render() {

        const { step, databases, localWorkflow, availableVariables, workflow, availableMethods, products, updateWorkflow, updateLocalWorkflow, updateAvailableMethods, workflowVariables } = this.props
        const { outputMappingModal } = this.state

        return <React.Fragment>

            <table className='workflowTable' style={{ height: '100%' }}><tbody>
                <tr style={{ height: '100%' }}>

                    <td rowspan="2" valign="top" style={{ height: '100%', position: 'relative' }}>
                        <Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-25px', top: '150px' }} />
                        <Card
                            className='workflowCard'
                            title="Run another sequence"
                            extra={<>

                                <Button size="small" shape="circle" icon="reload" style={{ marginRight: 5 }} onClick={async e => {
                                    let newStep = { ...step }

                                    this.setState({ reloadingStep: true })

                                    const newInput = workflow.sequences.filter(s => s.apiPath === step.sequencePath)[0].inputSchema.map(p => ({ ...p, value: null }))

                                    // console.log({ newInput })

                                    for (let property of step.properties) {
                                        if (property.value) {
                                            let new_property = newInput.filter(param => param.id === property.id)[0]
                                            console.log('found', property.value, new_property.value)

                                            if (new_property) {

                                                new_property.value = property.value
                                            }
                                        }
                                    }

                                    newStep.properties = newInput

                                    // console.log({ newStep })

                                    const newWorkflow = updateStep(localWorkflow, newStep)
                                    updateLocalWorkflow(newWorkflow)

                                    this.setState({ reloadingStep: false })

                                }} />


                                {step.uniqueId}



                                <Popconfirm
                                    title="Do you really want to delete this step?"
                                    onConfirm={() => {
                                        let newWorkflow = deleteStep(workflow, step)
                                        updateWorkflow(newWorkflow)
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button size="small" shape="circle" icon="close" />
                                </Popconfirm></>}
                        >

                            {/* {step.uniqueId} */}


                            <h4>Run sequence...</h4>

                            <AntSelect
                                showSearch
                                optionFilterProp="children"
                                style={{ width: '100%', height: 50, marginBottom: 15 }}
                                size="large"
                                placeholder={"Click to select a sequence to run"}
                                value={step.sequencePath || undefined}
                                onChange={value => {
                                    const newWorkflow = updateStep(localWorkflow, {
                                        ...step,
                                        sequencePath: value,
                                        properties: workflow.sequences.filter(s => s.apiPath === value)[0].inputSchema.map(p => { p.value = ""; return p })
                                    })
                                    updateLocalWorkflow(newWorkflow)
                                }}
                            >
                                {
                                    localWorkflow.sequences
                                        .filter(s => s.type === "api" && s.apiPath)
                                        .map(sequence => <AntSelect.Option key={sequence.apiPath} value={sequence.apiPath}>/{sequence.apiPath}</AntSelect.Option>)
                                }
                            </AntSelect>

                            <Button block size="large" type="default" onClick={() => this.setState({ outputMappingModal: true })} style={{ marginBottom: 15 }}>Edit values</Button>
                            <Modal
                                title="Update data mapping"
                                visible={outputMappingModal}
                                onOk={() => { updateWorkflow(localWorkflow); this.setState({ outputMappingModal: false }) }}
                                onCancel={() => this.setState({ outputMappingModal: false })}
                                width={800}
                                style={{ maxWidth: "95%" }}
                            >
                                <Normalizer
                                    input={step.properties}
                                    availableVariables={[...availableVariables, ...encapsulate(workflowVariables, "storage")]}
                                    updateStepInput={input => {
                                        // const newWorkflow = updateStepInput(localWorkflow, step.stepId, input)
                                        // updateLocalWorkflow(newWorkflow)

                                        console.log(input)

                                        let newStep = { ...step }
                                        newStep.properties = input
                                        const newWorkflow = updateStep(localWorkflow, newStep)
                                        updateLocalWorkflow(newWorkflow)
                                    }}
                                />
                            </Modal>
                        </Card>
                    </td>

                    <td style={{ height: '100%', position: 'relative' }}>
                        <Icon type="check" style={{ fontSize: '16px', width: 50, position: 'absolute', left: '-25px', top: '130px', color: 'green' }} />
                        <table className='workflowTable'><tr>

                            <Steps
                                steps={step.then.do}
                                sequenceId={step.then.sequenceId}
                                workflow={workflow}
                                availableMethods={availableMethods}
                                products={products}
                                updateWorkflow={updateWorkflow.bind(this)}
                                updateAvailableMethods={updateAvailableMethods}
                                inheritedVariables={availableVariables}
                                workflowVariables={workflowVariables}
                                databases={databases}
                                availableEvents={this.props.availableEvents}
                                inputSequenceRank={this.props.inputSequenceRank}
                            />
                        </tr></table>
                    </td>
                </tr>

                <tr>

                </tr>

            </tbody></table>


            <br /></React.Fragment>
    }

    renderXX() {
        const { step, localWorkflow, availableVariables, workflow, availableMethods, products, updateWorkflow, updateLocalWorkflow, updateAvailableMethods, workflowVariables } = this.props
        const { outputMappingModal } = this.state

        return <React.Fragment>
            <td valign="top" style={{ position: 'relative' }}>
                <Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-25px', top: '150px' }} />
                <Card
                    className='workflowCard'
                    title="Run another sequence"
                    extra={<Popconfirm
                        title="Do you really want to delete this step?"
                        onConfirm={() => {
                            let newWorkflow = deleteStep(workflow, step)
                            updateWorkflow(newWorkflow)
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button size="small" shape="circle" icon="close" />
                    </Popconfirm>}
                >

                    {/* {step.uniqueId} */}


                    <h4>Run sequence...</h4>

                    <AntSelect
                        showSearch
                        optionFilterProp="children"
                        style={{ width: '100%', height: 50, marginBottom: 15 }}
                        size="large"
                        placeholder={"Click to select a sequence to run"}
                        value={step.sequencePath || undefined}
                        onChange={value => {
                            const newWorkflow = updateStep(localWorkflow, {
                                ...step,
                                sequencePath: value,
                                properties: workflow.sequences.filter(s => s.apiPath === value)[0].inputSchema
                            })
                            updateLocalWorkflow(newWorkflow)
                        }}
                    >
                        {
                            localWorkflow.sequences
                                .filter(s => s.type === "api" && s.apiPath)
                                .map(sequence => <AntSelect.Option key={sequence.apiPath} value={sequence.apiPath}>/{sequence.apiPath}</AntSelect.Option>)
                        }
                    </AntSelect>

                    {step.sequencePath}


                    <Button block size="large" type="default" onClick={() => this.setState({ outputMappingModal: true })} style={{ marginBottom: 15 }}>Edit values</Button>
                    <Modal
                        title="Update data mapping"
                        visible={outputMappingModal}
                        onOk={() => { updateWorkflow(localWorkflow); this.setState({ outputMappingModal: false }) }}
                        onCancel={() => this.setState({ outputMappingModal: false })}
                        width={800}
                        style={{ maxWidth: "95%" }}
                    >
                        <Normalizer
                            input={step.properties}
                            availableVariables={[...availableVariables, ...encapsulate(workflowVariables, "storage")]}
                            updateStepInput={input => {
                                // const newWorkflow = updateStepInput(localWorkflow, step.stepId, input)
                                // updateLocalWorkflow(newWorkflow)

                                console.log(input)

                                let newStep = { ...step }
                                newStep.properties = input
                                const newWorkflow = updateStep(localWorkflow, newStep)
                                updateLocalWorkflow(newWorkflow)
                            }}
                        />
                    </Modal>


                    {/* <SchemaModal
						input={step.properties}
						style={{ marginBottom: 10 }}
						onUpdate={inputSchema => {
							// console.log({ workflow, inputSchema })
							// let newWorkflow = workflow
							// newWorkflow.inputSchema = inputSchema
							// this.updateWorkflow(newWorkflow)

							let newStep = { ...step }
							newStep.properties = inputSchema
							const newWorkflow = updateStep(localWorkflow, newStep)
							updateLocalWorkflow(newWorkflow)
						}}
					/>

					<h4>With the following values...</h4>


					<Button block size="large" type="default" onClick={() => this.setState({ outputMappingModal: true })} style={{ marginBottom: 15 }}>Edit values</Button>
					<Modal
						title="Update data mapping"
						visible={outputMappingModal}
						onOk={() => { updateWorkflow(localWorkflow); this.setState({ outputMappingModal: false }) }}
						onCancel={() => this.setState({ outputMappingModal: false })}
						width={800}
						style={{maxWidth: "95%"}}
					>
						<Normalizer
							input={step.properties}
							availableVariables={[...availableVariables, ...encapsulate(workflowVariables, "storage")]}
							updateStepInput={input => {
								// const newWorkflow = updateStepInput(localWorkflow, step.stepId, input)
								// updateLocalWorkflow(newWorkflow)

								console.log(input)

								let newStep = { ...step }
								newStep.properties = input
								const newWorkflow = updateStep(localWorkflow, newStep)
								updateLocalWorkflow(newWorkflow)
							}}
						/>
					</Modal>

					<h4>To the following URL...</h4>

					<Input
						placeholder="https://www.myurl.com/hook"
						style={{ marginBottom: 5 }}
						size="large"
						value={step.url || ""}

						onChange={e => {
							let newStep = { ...step }
							newStep.url = e.target.value
							const newWorkflow = updateStep(localWorkflow, newStep)
							updateLocalWorkflow(newWorkflow)
						}} /> */}

                </Card>
            </td>
        </React.Fragment>
    }
}