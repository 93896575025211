import { Spinner } from '@blueprintjs/core';
import { Anchor, Avatar, Card, Input, Layout, List, PageHeader, Select, Tag, Typography } from 'antd';
import React from 'react';
import api from '../api';

const { Title, Paragraph, Text } = Typography

const { Link } = Anchor

const { Header, Footer, Sider, Content } = Layout

const cap = (s) => {
	if (typeof s !== 'string') return ''
	return s.charAt(0).toUpperCase() + s.slice(1)
}


export default class Scenarios extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			products: [],
			search: "",
			loading: true,
			category: null
		}
	}

	async componentDidMount() {
		const { workflowId } = this.props.match.params

		const { data: { products } } = await api('products')


		this.setState({
			products,
			loading: false
		})
	}

	render() {
		const { category, loading, products, search } = this.state

		if (loading) {
			return <div style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0, zIndex: 100000 }}>

				<Layout style={{ minHeight: "100vh" }}>

					<Content style={{ padding: '100px', paddingTop: 50 }}>
						<div style={{ background: '#fff', padding: 24, minHeight: 280, borderRadius: 5 }}>


							<PageHeader
								title="Simplifier"
								subTitle="Available products"
								ghost={false}
								style={{ padding: 0 }}
							/>

							<br />

							<Input
								size="large"
								value={search}
								autoFocus required
								placeholder={"Search among " + products.length + " blocks"}
								onChange={e => this.setState({ search: e.target.value })}
							/> <br /> <br />

							<h3 className="text-center"><Spinner /></h3><br /><br />


						</div>
					</Content>
				</Layout>
			</div>
		}


		return <div style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0, zIndex: 100000 }}>

			<Layout style={{ minHeight: "100vh" }}>

				<Content style={{ padding: '100px', paddingTop: 50 }}>
					<div style={{ background: '#fff', padding: 24, minHeight: 280, borderRadius: 5 }}>

						<PageHeader
							title="Simplifier"
							subTitle="Available products"
							ghost={false}
							style={{ padding: 0 }}
						/>

						<br />

						<Input.Group compact>
							<Input
								size="large"
								value={search}
								autoFocus required
								placeholder={"Search among " + products.length + " blocks"}
								onChange={e => this.setState({ search: e.target.value })}
								style={{ width: "80%" }}
							/>

							<Select showSearch defaultValue="All categories" size="large" style={{ width: "20%" }} onChange={value => {
								if (value === "All categories") { this.setState({ category: null }) }
								else this.setState({ category: value })
							}}>

								<Select.Option value="All categories">All categories</Select.Option>

								{[...new Set(products.map(product => product.category))].map(option => {
									return <Select.Option value={option}>{cap(option)}</Select.Option>
								})}
							</Select>
						</Input.Group>

						<br /> <br />

						{products.length && <List
							grid={{ gutter: 16, xs: 4, }}
							pagination={{ pageSize: 36 }}
							dataSource={products.filter(product => product.name.toLowerCase().indexOf(search ? search.toLowerCase() : '') > -1).filter(product => { if (!category) { return true } else { return product.category === category } })}
							renderItem={product => (
								<List.Item>
									<Card
										style={{ textAlign: "center" }}
										cover={
											product.logo ?
												<Avatar shape="square" size={100} style={{ marginLeft: "auto", marginRight: "auto", marginTop: 30 }} src={product.logo} /> :
												<Avatar shape="square" size={100} style={{ marginLeft: "auto", marginRight: "auto", marginTop: 30 }}>{product.name}</Avatar>
										}
									>
										<Card.Meta title={product.name} description={product.blocks.length + " actions"} />
									</Card>
									<Tag style={{ position: "absolute", top: "7px", left: "15px" }}>{cap(product.category)}</Tag>
								</List.Item>
							)}
						/>}


					</div>
				</Content>
			</Layout>
		</div>
	}
}
