import React from 'react';
import ConnectorStep from './steps/Connector';
import DatabaseStep from './steps/Database';
import ErrorStep from './steps/Error';
import FilterStep from './steps/Filter';
import ForStep from './steps/For';
import For2Step from './steps/For2';
import IfStep from './steps/If';
import ResponseStep from './steps/Response';
import StorageStep from './steps/Storage';
import WebhookStep from './steps/Webhook';
import DictionaryStep from './steps/Dictionary';

export default class Step extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			step: props.step,
			workflow: props.workflow,
			empty: true,
			localWorkflow: props.workflow
		}
	}

	render() {
		const { step, localWorkflow } = this.state
		const { block, availableVariables, products, availableMethods, workflow, updateWorkflow, updateAvailableMethods, workflowVariables, databases } = this.props

		const blockProps = {
			step,
			block,
			localWorkflow,
			availableVariables,
			databases,
			workflow,
			availableMethods,
			products,
			updateWorkflow: updateWorkflow.bind(this),
			updateLocalWorkflow: newWorkflow => this.setState({ localWorkflow: newWorkflow, empty: false }),
			updateAvailableMethods,
			workflowVariables
		}

		if (step.ifelse) return <IfStep {...blockProps} />
		if (step.for) return <ForStep {...blockProps} />
		if (step.for2) return <For2Step {...blockProps} />
		if (step.filter) return <FilterStep {...blockProps} />
		if (step.error) return <ErrorStep {...blockProps} />
		if (step.return) return <ResponseStep {...blockProps} />
		if (step.storage) return <StorageStep {...blockProps} />
		if (step.webhook) return <WebhookStep {...blockProps} />
		if (step.database) return <DatabaseStep {...blockProps} />
		if (step.dictionary) return <DictionaryStep {...blockProps} />
		return <ConnectorStep {...blockProps} />
	}
}