import { EuiErrorBoundary } from '@elastic/eui';
import { Spinner } from "@blueprintjs/core";
import { Alert, Anchor, Badge, Button, Input, Layout, Menu, Modal, Table, Typography } from 'antd';
import { format } from 'json-string-formatter';
import React from 'react';
import Highlight from 'react-highlight';
import api from '../api';
import { buildTree } from '@simplifier/normalization';
import { representPropertiesRecursively } from '../utils/workflows';

const { Title, Paragraph, Text } = Typography

const { Link } = Anchor

const { Header, Footer, Sider, Content } = Layout

const capitalize = (s) => {
	if (typeof s !== 'string') return ''
	return s.charAt(0).toUpperCase() + s.slice(1)
}

function getReturnSteps(steps) {
	let returnSteps = []

	function rec(st) {
		for (let step of st) {

			if (step.filter || step.filter2) {
				rec(step.each.do)
				rec(step.empty.do)
			}

			if (step.for || step.for2) {
				console.log({ step })
				rec(step.do)
			}

			if (step.ifelse || step.ifelse2) {
				rec(step.then.do)
				rec(step.else.do)
			}

			if (step.return) {

				// console.log(returnSteps.map(s => JSON.stringify(buildTree(s.properties))))
				// console.log(JSON.stringify(buildTree(step.properties)))

				if (!returnSteps.map(s => JSON.stringify(buildTree(s.properties))).includes(JSON.stringify(buildTree(step.properties)))) {
					returnSteps.push(step)
				}
			}
		}
	}

	rec(steps)
	return returnSteps
}


export default class Scenarios extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			availableMethods: [],
			scaffold: {},
			id: "",
			workflow: null,
		}
	}

	async componentDidMount() {
		const { workflowId } = this.props.match.params
		const { data } = await api(`workflows/${workflowId}`)
		const workflow = data

		console.log(workflow)

		this.setState({
			workflow: workflow,
			projectId: workflow.project._id,
			id: workflow._id,
			inputPaths: [],
			inputTree: {}
		})
	}

	render() {
		const { workflow, id, inputPaths, inputTree } = this.state

		const columns = [
			{
				title: "Field name", width: 120, dataIndex: "prop", key: "path", render: record => {
					return <>
						<strong>{record.required && <Badge status="error" />}{record.label}</strong>
						<p><i>({record.path})</i></p>
					</>
				}
			},
			{
				title: "Type", width: 120, dataIndex: "prop", key: "type", render: record => {
					return <>
						{record.type}
					</>
				}
			},
			{
				title: "Description", width: 150, dataIndex: "prop", key: "example", render: record => {
					return <>

						<p>
							{record.description}<br />

							{record.example && <>
								<i>Example: </i> {record.example}<br />
							</>}

							{record.default && <>
								<i>Default: </i> {record.default}<br />
							</>}
						</p>
					</>
				}
			}
		]

		if (!workflow) return <div style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0, zIndex: 100000 }}>
			<Layout style={{ height: "100vh" }}>
				<Sider theme="light" width={250} style={{ background: "rgb(255, 255, 255)", padding: "0px", boxShadow: "rgba(0, 21, 41, 0.2) 0px 1px 4px", zIndex: 100000 }}>
					<Anchor targetOffset={100} onClick={(e, link) => { e.preventDefault() }} style={{ width: "90%", height: "100%" }}>
						<Title level={2} style={{ margin: 16 }}>Simplifier</Title>
					</Anchor>
				</Sider>
				<Content style={{ height: "100%" }}>
					<Layout style={{ backgroundColor: "white", height: "100%" }}><Content style={{ padding: 50 }}><Typography><br /><br /><Spinner /></Typography></Content><Sider theme="dark" width={400} style={{ padding: 24, paddingTop: 130 }}>&nbsp;</Sider></Layout>
					<Layout style={{ height: "100%" }}><Content>&nbsp;</Content><Sider theme="dark" width={400}>&nbsp;</Sider></Layout>
				</Content>
			</Layout>
		</div>

		workflow.sequences.map(sequence => console.log(JSON.stringify(representPropertiesRecursively(sequence.inputSchema))))


		return <div style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0, zIndex: 100000 }}>

			<Layout>

				<Sider theme="light" width={250} style={{ background: "rgb(255, 255, 255)", padding: "0px", boxShadow: "rgba(0, 21, 41, 0.2) 0px 1px 4px", zIndex: 100000 }}>

					<Anchor targetOffset={100} onClick={(e, link) => { e.preventDefault() }} style={{ width: "90%", height: "100%" }}>
						<Title level={2} style={{ margin: 16 }}>Simplifier</Title>
						<Link href="#intro" title="Introduction" />
						<Link href="#authentication" title="Authentication">
							<Link href="#newuser" title="New user" />
							<Link href="#existinguser" title="Existing user" />
						</Link>

						{workflow.sequences.filter(sequence => ["api"].includes(sequence.type)).map(sequence => <Link href={"#path-" + (sequence.apiPath || "emptypath")} title={capitalize(sequence.apiPath || "Root path")}>
							<Link href={"#description-" + (sequence.apiPath || "emptypath")} title="Description" />
							<Link href={"#trigger-" + (sequence.apiPath || "emptypath")} title="Trigger" />
							<Link href={"#errors-" + (sequence.apiPath || "emptypath")} title="Errors" />
						</Link>)}

						{workflow.sequences.filter(sequence => ["clock"].includes(sequence.type)).length && <>
							<Link href={"#path-clocks"} title={"Clock" + ((workflow.sequences.filter(sequence => ["clock"].includes(sequence.type)).length > 1) ? "s" : "")}>
								<Link href={"#description-clocks"} title="Description" />
								{/* <Link href={"#trigger-clocks"} title="Trigger" /> */}
							</Link>
						</>}
					</Anchor>
				</Sider>

				<Content style={{ height: "100%" }}>
					{/* Intro */}
					<Layout style={{ backgroundColor: "white" }}>
						<Content style={{ padding: 50, height: "100%", paddingTop: 16 }}>

							<Typography>
								<Title id="intro">Introduction — {workflow.name}</Title>
								<Paragraph>Simplifier let's you create simple no-code third party integrations that are natively embedable into your product. This documentation explains how to execute a specific Simplifier integration.</Paragraph>
								<Paragraph>This API provides an easy way to integrate this integration with any external system. The API closely follows REST semantics, uses JSON to encode objects, and relies on standard HTTP codes to signal operation outcomes.</Paragraph>
								<Paragraph>The API documentation below is specifically generated for your integration. We recommend that you use the graphical Simplifier interface to edit the steps, data structures and data mappings. Once edited, the result will be displayed in the following documentation.</Paragraph>
								<Paragraph>The ID of this integration is {id}.</Paragraph>

								{/* <Divider /> */}

							</Typography>

						</Content>

						<Sider theme="dark" width={400} style={{ padding: 24, paddingTop: 50 }}>

							<Menu theme="dark" mode="horizontal" defaultSelectedKeys="curl" style={{ marginTop: -30, marginBottom: 30 }}>
								<Menu.Item key="curl" style={{ borderRadius: 50 }}>CURL</Menu.Item>

								{/* <Menu.Item key="js" style={{ borderRadius: 50 }}>
									Javascript
								</Menu.Item> */}
							</Menu>

							<br />
						</Sider>
					</Layout>




					{/* Auth */}
					<Layout style={{ backgroundColor: "white" }}>
						<Content style={{ padding: 50 }}>

							<Typography>
								<Title id="authentication">Authentication</Title>
								<Title level={2} id="newuser">New users</Title>
								<Paragraph>Add a link to the following address on your site and replace <code>YOUR_REDIRECT_URL</code> by the URL to which your users should be redirected when they are authenticated.</Paragraph>
								<Paragraph><Input size="large" value={api.baseURL + "workflow/auth/" + id + '?redirect_uri=YOUR_REDIRECT_URL'} /></Paragraph>

								<Paragraph>The new user will then be redirected to the redirect URI with an <code>userToken</code> as query parameter.</Paragraph>

								<Alert message="User token" description={<span>Do not forget to save the <code>userToken</code> as it is the only way to trigger the integration connection of that user.</span>} type="info" showIcon />

								<Title level={2} id="existinguser">Existing users</Title>
								<Paragraph>If you already have an <code>userToken</code> from your user, pass it as a query parameter to the link and the integration will be linked to their existing user profile (Otherwise, a new profile will be created).</Paragraph>

								<Paragraph><Input size="large" value={api.baseURL + "workflow/auth/" + id + '?redirect_uri=YOUR_REDIRECT_URL&userToken=1234567890'} /></Paragraph>

								<Paragraph></Paragraph>
							</Typography>

						</Content>
						<Sider theme="dark" width={400} style={{ padding: 24, paddingTop: 50 }}></Sider>
					</Layout>

					{/* Trigger */}
					{workflow.sequences.filter(sequence => sequence.type === "api").map(sequence => <>
						<Layout style={{ backgroundColor: "white" }}>
							<Content style={{ padding: 50 }}>
								<Typography>
									<Title id={"path-" + (sequence.apiPath || "emptypath")} >{capitalize((sequence.apiPath || "Root path"))}</Title>

									<Title id={"description-" + (sequence.apiPath || "emptypath")} level={2}>Description</Title>

									<Paragraph>When executed, the workflow will follow the following steps.</Paragraph>

									<SequenceSteps steps={sequence.steps} /><br />

									<Title level={2} id={"trigger-" + (sequence.apiPath || "emptypath")}>Trigger</Title>

									<Paragraph>
										This flow of your integration is trigerred by sending a POST request to the following URL : <br /><br />
										<Input size="large" value={`https://beta.getsimplifier.com/integration/${id}/{userToken}/${sequence.apiPath}`} /><br /><br />
										The parameter {"{userToken}"} lets you identify one of your users on the side of Simplifier. To get userTokens, please look at the Authentication section.
									</Paragraph>
								</Typography>
							</Content>

							<Sider theme="dark" width={400}>&nbsp;</Sider>

						</Layout>

						<Layout style={{ backgroundColor: "white" }}>
							<Content style={{ padding: 50 }}>
								<Typography>
									<Title level={3}>Input parameters</Title>
									<Paragraph>The input parameters should be provided as an application/json formatted body.</Paragraph><br />

									<Table
										bordered pagination={false}
										size="middle"
										dataSource={representPropertiesRecursively(sequence.inputSchema)}
										columns={columns}
									/><br />


									<Paragraph></Paragraph>

									<Title level={3}>Possible outputs</Title>

									{getReturnSteps(sequence.steps).length ?
										getReturnSteps(sequence.steps).map(step => {
											return <><Table
												bordered pagination={false}
												size="middle"
												dataSource={representPropertiesRecursively(step.properties)}
												columns={columns}
											/><br /></>
										}) : <><i>No return has been set up</i></>}

									<Title level={2} id={"errors-" + (sequence.apiPath || "emptypath")}>Errors</Title>

									<Paragraph>There are three ways in which a workflow trigger can fail.</Paragraph>

									<Paragraph>
										<ul>
											<li><strong>Trigerred error : </strong> You can choose to trigger an error in specific conditions from the workflow editor. For instance, if an email address specified in input does not match a certain predefined regex.</li><br />
											<li><strong>API errors : </strong> When something goes wrong with an third party API call made by Simplifier.</li><br />
											<li><strong>HTTP errors : </strong> When one of the parameters (workflowId, userToken, path) is incorrect, our service is down, or something went wrong on our side.</li>
										</ul>
									</Paragraph>

									<Paragraph>

										<br />
										<Table
											bordered pagination={false}
											size="middle"
											dataSource={[
												{ code: "200", message: "OK - Everything worked as expected." },
												{ code: "201", message: "Created - Resource has been successfully created" },
												{ code: "202", message: "Accepted - Request has been accepted, but requires asynchronous work to be fully performed" },
												{ code: "400", message: "Bad Request - Request failed due to a combination of errors" },
												{ code: "401", message: "Unauthorized - No valid API credentials provided." },
												{ code: "402", message: "Payment Required - Your request was in livemode while your account is not activated for live requests yet." },
												{ code: "403", message: "Forbidden - You are not authorized to access the requested item." },
												{ code: "404", message: "Not Found - The requested item doesn't exist." },
												{ code: "406", message: "Unacceptable - Your request format was not supported. Use JSON." },
												{ code: "409", message: "Conflict - The data you submitted is in conflict with existing data." },
												{ code: "422", message: "Unprocessable Entity - Often missing a required parameter." },
												{ code: "429", message: "Too Many Requests - You sent us too many API requests in a row. Please retry later." },
												{ code: "500", message: "Server errors - Something went wrong with an API call." },
												{ code: "Custom code", message: "Workflow error — Purposefully trigerred error." },

											]}
											columns={[
												{ title: "Status code", width: 80, dataIndex: "code", key: "code" },
												{ title: "Error", width: 250, dataIndex: "message", key: "message" },
											]}
										/><br />


									</Paragraph>
								</Typography>

							</Content>
							<Sider theme="dark" width={400} style={{ padding: 24, paddingTop: 50 }}>

								<Highlight language="curl" style={{ width: "95%" }}>{`# Definition\nPOST https://beta.getsimplifier.com/integration/${id}/{userToken}/${sequence.apiPath}\n\n\n# Example input\n${sequence.inputSchema ? format(JSON.stringify(buildTree(sequence.inputSchema))) : "{}"}`.replace(/	/g, '  ')}</Highlight>

								{getReturnSteps(sequence.steps).length ?
									getReturnSteps(sequence.steps).map(step => {
										return <><Highlight language="curl" style={{ width: "95%" }}>
											{`# Example output\n${step.properties ? format(JSON.stringify(buildTree(step.properties))) : "{}"}`.replace(/	/g, '  ')}
										</Highlight><br /></>
									}) : <></>}
							</Sider>
						</Layout></>)
					}



					{workflow.sequences.filter(sequence => sequence.type === "clock").length ? <>
						<Layout style={{ backgroundColor: "white" }}>
							<Content style={{ padding: 50 }}>
								<Typography>
									<Title id={"path-clocks"} >Clocks</Title>

									<Title id={"description-clocks"} level={2}>Description</Title>

									<Paragraph>The following sequences will be automatically executed at a recurrent delay:</Paragraph>

									{workflow.sequences.filter(sequence => sequence.type === "clock").map(sequence => <>
										<Title id={"description-clocks"} level={2}>Every {workflow.delay}</Title>

										<Paragraph>The workflow will follow the following steps.</Paragraph>

										<SequenceSteps steps={sequence.steps} /><br />
									</>)}


								</Typography>
							</Content>

							<Sider theme="dark" width={400}>&nbsp;</Sider>

						</Layout>

					</> : <></>}

					<Layout style={{ height: "100%" }}><Content>&nbsp;</Content><Sider theme="dark" width={400}>&nbsp;</Sider></Layout>
				</Content>

			</Layout>
		</div>
	}
}

class SequenceSteps extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			showModal: false,
			modalProperties: []
		}
	}

	render() {

		const { steps } = this.props

		const columns = [
			{
				title: "Field name", width: 120, dataIndex: "prop", key: "path", render: record => {
					return <>
						<strong>{record.required && <Badge status="error" />}{record.label}</strong>
						<p><i>({record.path})</i></p>
					</>
				}
			},
			{
				title: "Type", width: 120, dataIndex: "prop", key: "type", render: record => {
					return <>
						{record.type}
					</>
				}
			},
			{
				title: "Description", width: 150, dataIndex: "prop", key: "example", render: record => {
					return <>

						<p>
							{record.description}<br />

							{record.example && <>
								<i>Example: </i> {record.example}<br />
							</>}

							{record.default && <>
								<i>Default: </i> {record.default}<br />
							</>}
						</p>
					</>
				}
			}
		]

		if (!steps.length) return <ul><li><i>Do nothing</i></li></ul>

		return <ol>

			{steps.map(step => {

				if (step.id) { return <li>{step.name}</li> }

				if (step.filter || step.filter2) {
					return <>
						<li>For element matching a condition <SequenceSteps steps={step.each.do} /></li>
						<li>If no element matches the condition <SequenceSteps steps={step.empty.do} /></li>
					</>
				}

				if (step.for || step.for2) { return <> <li>For all elements <SequenceSteps steps={step.do} /></li> </> }

				if (step.ifelse || step.ifelse2) {
					return <>
						<li>If condition is fulfilled <SequenceSteps steps={step.then.do} /></li>
						<li>Else <SequenceSteps steps={step.else.do} /></li>
					</>
				}
				if (step.return) {
					return <> <li>Return the <a href="#" onClick={e => {
						e.preventDefault()
						this.setState({
							showModal: true,
							modalProperties: step.properties
						})
					}}>following content</a></li> </>
				}
				if (step.storage) { return <> <li>Assign a value to the user variable {step.keyPath}</li> </> }
				if (step.database) { return <> <li>Access a database</li> </> }
				if (step.webhook) {
					return <>
						<li>Send a webhook to <u>{step.url}</u> with the <a href="#" onClick={e => {
							e.preventDefault()
							this.setState({
								showModal: true,
								modalProperties: step.properties
							})
						}}>following content</a></li>
					</>
				}
				return <li><i>Do nothing</i></li>
			})}

			{/* <Button type="primary" onClick={this.showModal}>Open Modal</Button> */}
			<Modal
				title="Schema"
				visible={this.state.showModal}
				onOk={() => { this.setState({ showModal: false, modalProperties: [] }) }}
				onCancel={() => { this.setState({ showModal: false, modalProperties: [] }) }}
				style={{ width: 800 }}
				style={{ maxWidth: "95%" }}
			>
				{/* <Sider theme="dark" width={400} style={{ padding: 24, paddingTop: 50 }}> */}
				<div style={{ backgroundColor: "#010F1B", borderRadius: 5 }}>
					<Highlight language="curl" style={{ width: "100%", backgroundColor: "#010F1B" }}>
						{`${this.state.modalProperties ? format(JSON.stringify(buildTree(this.state.modalProperties))) : "{}"}`.replace(/	/g, '  ')}
					</Highlight>
				</div>

				<br />

				<Table
					bordered pagination={false}
					size="middle"
					dataSource={representPropertiesRecursively(this.state.modalProperties)}
					columns={columns}
				/>
				{/* </Sider> */}
			</Modal>
		</ol>
	}
}