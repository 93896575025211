import { Spinner } from '@blueprintjs/core'
import { Avatar, Button, Card, Col, Icon, Input, List, Modal, PageHeader, Row, Tag } from 'antd'
import React from 'react'
import uuidv4 from 'uuid/v4'
import api from '../api'
import Step from '../components2/Step'
import defaultSteps from '../utils/defaultSteps'
import { encapsulate, flatten } from '@simplifier/normalization'
import { getBlockById, pushToSequence, updateStepInput } from '../utils/workflows.js'
import TextTruncate from 'react-text-truncate'

import ErrorBoundary from './utils/ErrorBoundary'

import Delay from "react-delay"

import {
    EuiCard,
    EuiButton,
    EuiIcon,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButtonIcon,
    EuiEmptyPrompt,
    EuiFlyout,
    EuiFlyoutHeader,
    EuiFlyoutBody,
    EuiText,
    EuiTitle,
    EuiFlexGrid,
    EuiHorizontalRule,
    EuiFieldText,
    EuiFieldSearch,
    EuiBadge,
    EuiAvatar,
    EuiLoadingContent,
    EuiPagination,
    EuiSpacer,
} from '@elastic/eui'

import inflect from 'i'
const infl = inflect()

const getMethodColor = function (method) {
    if (method === "GET") return "primary"
    else if (method === "POST") return "secondary"
    else if (method === "PATCH") return "warning"
    else if (method === "PUT") return "warning"
    else if (method === "DELETE") return "danger"

    else return "default"
}

const getResource = function (path) {
    return path.replace(/\.[A-z0-9]+/gi, '/')
        .replace(/\/{[A-z0-9]+}/gi, '')
        .split('/')
        .filter(x => x)
}

class SelectorModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showModal: false,
            search: "",
            isProductSelected: false,
            isLoading: true,
            products: [],
            blocks: []
        }
    }

    async showModal() {
        this.setState({ showModal: true })
        if (this.state.products.length) {
            this.setState({ isLoading: false })
        } else {
            const { data: { products } } = await api('products')
            this.setState({ products, isLoading: false })
        }
    }

    async openProduct(product) {
        this.setState({
            isProductSelected: product.id,
            isLoading: true,
            search: "",
            selectedProduct: product
        })

        const { data: { product: { blocks } } } = await api(`products/${product.id}`)

        console.log(blocks)


        this.setState({ blocks, isLoading: false })
    }

    async insertBlock(block) {
        let newStep = {
            "uniqueId": uuidv4(),
            "stepId": uuidv4(),
            "id": block.id,
            "name": block.name,
            "action": block.action,
            "input": flatten(block.input)
        }

        let newWorkflow = pushToSequence(this.props.workflow, this.props.sequenceId, newStep)

        // console.log({ b: block })

        const av = await this.props.updateAvailableMethods(block)

        // console.log({ av })

        this.props.updateWorkflow(newWorkflow)
        this.props.onDone()

        this.setState({
            showModal: false,
            search: "",
            isProductSelected: false,
            isLoading: true,
            blocks: []
        })
    }

    display(string) {
        if (!string) return "Empty"
        return infl.titleize(string)
        return string.charAt(0).toUpperCase() + string.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1").substring(1)
    }

    render() {
        const { showModal, search, isProductSelected, isLoading, products, blocks, selectedProduct } = this.state

        return <div>
            <Button block size="large" style={{ marginBottom: 10 }} onClick={this.showModal.bind(this)}>API Connector</Button>

            <Modal
                title="API Connectors"
                visible={showModal || false}
                width={1000}
                style={{ maxWidth: "95%" }}
                onOk={() => this.setState({ showModal: false, search: "" })}
                onCancel={() => this.setState({ showModal: false, search: "" })}
            >

                {
                    isLoading && <div>
                        {selectedProduct && <PageHeader
                            title={selectedProduct.name}
                            ghost={false}
                            style={{ padding: 0 }}
                        />}
                        <br /><br /><h3 className="text-center"><Spinner /></h3><br /><br />
                    </div>
                }


                {
                    !isLoading && isProductSelected && <div>
                        <PageHeader
                            title={selectedProduct.name}
                            ghost={false}
                            style={{ padding: 0 }}
                            onBack={() => this.setState({
                                search: "",
                                isProductSelected: false,
                                blocks: []
                            })}
                        /><br />
                        <Input
                            size="large"
                            value={search}
                            autoFocus required
                            placeholder={"Search among " + blocks.length + " blocks"}
                            onChange={e => this.setState({ search: e.target.value })}
                        /> <br /> <br />

                        {
                            blocks.length && [...new Set(blocks.filter(block => block.name.toLowerCase().indexOf(search ? search.toLowerCase() : '') > -1).map(block => block.resource))].map(resource => {
                                return <div key={resource}>
                                    <h2>{this.display(resource)}</h2>
                                    <List
                                        // grid={{ gutter: 16, xs: 1, }}
                                        itemLayout="horizontal"
                                        dataSource={blocks
                                            .filter(block => block.resource === resource)
                                            .filter(block => block.name.toLowerCase().indexOf(search ? search.toLowerCase() : '') > -1)}
                                        renderItem={block => {
                                            return <List.Item style={{ padding: 10 }}>

                                                <Card
                                                    hoverable
                                                    style={{ textAlign: "center" }}
                                                    display="plain"
                                                    onClick={console.log}
                                                >
                                                    <Card.Meta title={block.name} description={<TextTruncate
                                                        line={2}
                                                        element="span"
                                                        text={block.description}
                                                    />} />
                                                </Card>


                                                {/* <List.Item.Meta
                                                    title={block.name}
                                                    description={block.description}
                                                />
                                                <div>
                                                    <Button type="primary" onClick={() => this.insertBlock(block)}>Insert</Button>
                                                </div> */}

                                            </List.Item>
                                        }}
                                    />
                                    <br />
                                </div>
                            })
                        }

                    </div>
                }



                {
                    !isLoading && !isProductSelected && <div>
                        <Input
                            size="large"
                            value={search}
                            autoFocus required
                            placeholder={"Search among " + products.length + " products"}
                            onChange={e => this.setState({ search: e.target.value })}
                        /> <br /> <br />

                        {products.length && <List
                            grid={{ gutter: 16, xs: 4, }}
                            pagination={{ pageSize: 20 }}
                            dataSource={products
                                .filter(product => product.blocks.length)
                                .filter(product => product.name)
                                .filter(product => product.name.toLowerCase().indexOf(search ? search.toLowerCase() : '') > -1)
                                .sort((a, b) => a.owner - b.owner)
                            }
                            renderItem={product => (
                                <List.Item>
                                    <Card
                                        hoverable
                                        style={{ textAlign: "center" }}
                                        onClick={async () => await this.openProduct(product)}
                                        cover={
                                            product.logo ?
                                                <Avatar shape="square" size={100} style={{ marginLeft: "auto", marginRight: "auto", marginTop: 10 }} src={product.logo} /> :
                                                <Avatar shape="square" size={100} style={{ marginLeft: "auto", marginRight: "auto", marginTop: 10 }}>{product.name}</Avatar>
                                        }
                                    >
                                        <Card.Meta title={product.name} description={product.blocks.length + " actions"} />

                                        {product.owner === "public" ? <Tag color="#2db7f5" style={{ marginTop: 10 }}>Public</Tag> : <Tag color="#87d068" style={{ marginTop: 5 }}>Private</Tag>}
                                    </Card>
                                </List.Item>
                            )}
                        />}
                    </div>
                }
            </Modal>
        </div>
    }
}

export default class Steps extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showNewBlockCard: false,
            search: "",
            page: "main",
            products: [],
            loadingProducts: false,
            loadingMethods: false,
            activePage: 0,
            blocks: []
        }
    }

    updateStepInput(stepId, input) {
        const newWorkflow = updateStepInput(this.props.workflow, stepId, input)
        this.props.updateWorkflow(newWorkflow)
    }

    getCurrentSequenceVariablesAvailable(currentStep) {
        let variables = []
        let i = 0
        let steps = this.props.steps

        for (let step of steps) {
            // console.log(step.stepId, currentStep.id)
            if (step.stepId && step.stepId === currentStep.stepId) return variables

            else if (step.uniqueId === currentStep.uniqueId) return variables

            else if (step.dictionary) {
                variables = [...variables, {
                    "id": uuidv4(),
                    "label": "Key",
                    "path": "dictionary.key",
                    "type": "string",
                    "base": null
                }, {
                    "id": uuidv4(),
                    "label": "Value",
                    "path": "dictionary.value",
                    "type": "string",
                    "base": null
                }]
            }

            else if (step.database) {
                // console.log('HAS A DB', step)
                if (step.action === "get") {
                    variables = [...variables, ...encapsulate(step.parameters, step.tableName + ".get")]
                }
                if (step.action === "list") {
                    let wrapperId = step.wrapperId
                    variables = [...variables, {
                        "id": uuidv4(),
                        "label": "listLength",
                        "path": step.tableName + ".listLength",
                        "type": "number",
                        "base": null
                    }, ...[{
                        "id": wrapperId,
                        "label": "list",
                        "path": step.tableName + ".list",
                        "type": "array",
                        "base": null
                    }, ...step.parameters.map(step => {
                        let newStep = { ...step }
                        if (!newStep.base) { newStep.base = wrapperId }
                        return newStep
                    })]]
                }
                if (step.action === "insert") {
                    let paramId = step.wrapperId
                    variables = [...variables, ...[{
                        "id": paramId,
                        "label": "ID",
                        "path": step.tableName + ".inserted.id",
                        "type": "string",
                        "base": null
                    }]]
                }
                if (step.action === "update") {
                    let paramId = step.wrapperId
                    variables = [...variables, ...[{
                        "id": paramId,
                        "label": "ID",
                        "path": step.tableName + ".updated.id",
                        "type": "string",
                        "base": null
                    }]]
                }
                if (step.action === "delete") {
                    let paramId = step.wrapperId
                    variables = [...variables, ...[{
                        "id": paramId,
                        "label": "ID",
                        "path": step.tableName + ".deleted.id",
                        "type": "string",
                        "base": null
                    }]]
                }
                if (step.action === "loginByEmail") {
                    let paramId = step.wrapperId
                    variables = [...variables, ...[{
                        "id": paramId,
                        "label": "Email",
                        "path": step.tableName + ".signedIn.email",
                        "type": "string",
                        "base": null
                    }]]
                }
                if (step.action === "deleteByUid") {
                    let paramId = step.wrapperId
                    variables = [...variables, ...[{
                        "id": paramId,
                        "label": "UID",
                        "path": step.tableName + ".deleted.uid",
                        "type": "string",
                        "base": null
                    }]]
                }
            }

            else if (!step.stepId) continue;
            else {
                let block = getBlockById(step.id, this.props.availableMethods) || { output: {} }
                variables = [...variables, ...encapsulate(flatten(block.output), step.action)]
            }
        }

        return variables
    }

    addConnectorBlock() {
        this.setState({ blocksListModal: true })
    }

    addBlock(type) {
        let newStep = defaultSteps.get(type)
        let { workflow, sequenceId, updateWorkflow } = this.props

        let newWorkflow = pushToSequence(workflow, sequenceId, newStep)

        this.setState({ showNewBlockCard: false })
        updateWorkflow(newWorkflow)
    }

    async openProduct(product) {
        this.setState({
            page: "method",
            productSelected: product.id,
            productName: product.name,
            search: "",
            selectedProduct: product,
            loadingMethods: true
        })

        const { data: { product: { blocks } } } = await api(`products/${product.id}`)

        // console.log(blocks)


        this.setState({ blocks, isLoading: false, loadingMethods: false })
    }

    async insertBlock(block) {
        let newStep = {
            "uniqueId": uuidv4(),
            "stepId": uuidv4(),
            "id": block.id,
            "name": block.name,
            "action": block.action,
            "input": flatten(block.input)
        }

        let newWorkflow = pushToSequence(this.props.workflow, this.props.sequenceId, newStep)

        // console.log({ b: block })

        const av = await this.props.updateAvailableMethods(block)

        // console.log({ av })

        this.props.updateWorkflow(newWorkflow)
        // this.props.onDone()

        this.setState({
            flyout: false, products: [], page: "main"
        })
    }

    display(string) {
        if (!string) return "Empty"

        return infl.titleize(string)


        return infl.titleize(string.charAt(0).toUpperCase() + string.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1").substring(1))
    }

    render() {

        const { steps, workflow, availableMethods, inputScaffold, sequenceId, inheritedVariables, products, workflowVariables, databases } = this.props
        const {
            showNewBlockCard,
            search,
            loadedProducts,
            productSearch,
            activePage,
            blocks
        } = this.state
        // console.log(steps)

        const ITEMS_PER_PAGE = 21

        const PAGE_COUNT = parseInt(products.length / ITEMS_PER_PAGE) + 1

        const goToPage = pageNumber => {
            this.setState({ activePage: pageNumber })
        };

        if (this.state.flyout) {
            console.log('Render flyout')
        }

        return <React.Fragment>

            {
                steps.map(step => {
                    // console.log(step.id, availableMethods, getBlockById(step.id, availableMethods))
                    return <ErrorBoundary><Step
                        step={step}
                        sequenceId={sequenceId}
                        key={uuidv4()}
                        block={getBlockById(step.id, availableMethods)}
                        products={products}
                        workflow={workflow}
                        updateStepInput={this.updateStepInput.bind(this)}
                        updateWorkflow={this.props.updateWorkflow.bind(this)}
                        availableMethods={availableMethods}
                        updateAvailableMethods={this.props.updateAvailableMethods}
                        availableVariables={[
                            ...inheritedVariables,
                            ...this.getCurrentSequenceVariablesAvailable(step)
                        ]}
                        workflowVariables={workflowVariables}
                        databases={databases}
                    /></ErrorBoundary>
                })
            }

            <td rowspan="1" valign="top" style={{ display: !showNewBlockCard && steps.filter(step => step.id || step.webhook || step.storage || step.database || step.dictionary).length === steps.length ? 'table-cell' : 'none', position: "relative", height: 300, verticalAlign: "top" }}>

                <div style={{ position: 'absolute', left: 8, top: 130 }}>
                    <EuiIcon type="arrowRight" />
                </div>

                <EuiCard
                    textAlign="center"
                    onClick={() => this.setState({ flyout: true })}
                    className="add-step"
                    style={{
                        scrollSnapAlign: "start",
                        position: "relative",
                        width: 325,
                        height: 325,
                        marginLeft: 32, paddingTop: 32,
                        overflow: "hidden",
                        backgroundColor: "#fafbfd",
                    }}
                >
                    <div style={{
                        backgroundColor: "#fafbfd",
                        position: "absolute",
                        top: 0, left: 0, right: 0,
                        padding: 6,
                        paddingLeft: 16,
                        height: 36,
                    }}>

                        <EuiFlexGroup justifyContent="spaceBetween">
                            <EuiFlexItem grow={false}>
                                <EuiFlexGroup gutterSize="s">
                                    &nbsp;
                                </EuiFlexGroup>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiFlexGroup gutterSize="s">
                                    &nbsp;
                                </EuiFlexGroup>
                            </EuiFlexItem>
                        </EuiFlexGroup>

                    </div>

                    <EuiEmptyPrompt
                        iconType="plusInCircle"
                        iconColor={null}
                        title={<h3>Add step</h3>}
                        titleSize="s"
                        style={{ marginTop: 25 }}
                    />

                </EuiCard>
            </td>



            <td valign="top" style={{ display: showNewBlockCard ? 'block' : 'none' }}>
                <EuiCard className='workflowCard' style={{ backgroundColor: 'transparent' }}>

                    <SelectorModal
                        updateAvailableMethods={this.props.updateAvailableMethods}
                        workflow={workflow}
                        sequenceId={sequenceId}
                        updateWorkflow={this.props.updateWorkflow}
                        onDone={() => this.setState({ showNewBlockCard: false })}
                    />

                    <Button block size="large" style={{ marginBottom: 10 }} onClick={() => this.addBlock("ifelse")}>Condition</Button>

                    <Row gutter={10}>
                        <Col span={12}><Button block size="large" style={{ marginBottom: 10 }} onClick={() => this.addBlock("for")}>For each</Button></Col>
                        <Col span={12}><Button block size="large" style={{ marginBottom: 10 }} onClick={() => this.addBlock("filter")}>Filter</Button></Col>
                    </Row>

                    <Row gutter={10}>
                        <Col span={12}><Button block size="large" style={{ marginBottom: 10 }} onClick={() => this.addBlock("storage")}>Store user data</Button></Col>
                        <Col span={12}><Button block size="large" style={{ marginBottom: 10 }} onClick={() => this.addBlock("database")}>Database</Button></Col>
                    </Row>

                    <Button block size="large" style={{ marginBottom: 10 }} onClick={() => this.addBlock("dictionary")}>Dictionary</Button>

                    <Row gutter={10}>
                        <Col span={12}><Button block size="large" style={{ marginBottom: 10 }} type="primary" onClick={() => this.addBlock("webhook")}>Webhook</Button></Col>
                        <Col span={12}><Button block size="large" style={{ marginBottom: 10 }} type="primary" onClick={() => this.addBlock("response")}>Response</Button></Col>
                    </Row>

                </EuiCard>

            </td>

            {this.state.flyout && <EuiFlyout
                ownFocus
                onClose={() => this.setState({ flyout: false, products: [], page: "main" })}
                size="m"
            >
                <EuiFlyoutHeader hasBorder>

                    {this.state.page === "product" && <EuiButtonIcon
                        iconType="arrowLeft"
                        style={{ float: "left", marginTop: 5, marginRight: 5 }}
                        onClick={() => this.setState({ products: [], page: "main" })}
                    />
                    }

                    {this.state.page === "method" && <EuiButtonIcon
                        iconType="arrowLeft"
                        style={{ float: "left", marginTop: 5, marginRight: 5 }}
                        onClick={async () => {
                            this.setState({
                                loadingProducts: true,
                                page: "product"
                            })

                            const { data: { products } } = await api('products')

                            this.setState({
                                loadedProducts: products,
                                loadingProducts: false
                            })
                        }}
                    />
                    }







                    <EuiTitle size="m">
                        <h2>
                            {this.state.page === "main" && "Add a step"}
                            {this.state.page === "product" && "Select product"}
                            {this.state.page === "method" && this.state.productName}
                        </h2>
                    </EuiTitle>
                </EuiFlyoutHeader>
                {this.state.page === "main" && <EuiFlyoutBody>



                    <EuiFieldSearch
                        placeholder="Search..."
                        value={productSearch}
                        onChange={e => this.setState({ productSearch: e.target.value })}
                        isClearable fullWidth
                    /> <br /><br />

                    <EuiFlexGrid columns={2}>
                        {[
                            {
                                title: "API Connector", f: async () => {
                                    this.setState({
                                        loadingProducts: true,
                                        page: "product"
                                    })

                                    const { data: { products } } = await api('products')

                                    this.setState({
                                        loadedProducts: products,
                                        loadingProducts: false
                                    })
                                },
                                description: "Connect to a 3rd party product"
                            },
                            {
                                title: "Condition", f: async () => {
                                    this.addBlock("ifelse")
                                    this.setState({ flyout: false })
                                },
                                description: "If A, do B, else do C"
                            },
                            {
                                title: "Loop", f: async () => {
                                    this.addBlock("for")
                                    this.setState({ flyout: false })
                                },
                                description: "Execute for each element of a list"
                            },
                            {
                                title: "Filter", f: async () => {
                                    this.addBlock("filter")
                                    this.setState({ flyout: false })
                                },
                                description: "Filter among a list"
                            },
                            {
                                title: "Response", f: async () => {
                                    this.addBlock("response")
                                    this.setState({ flyout: false })
                                },
                                description: "Return an HTTP response"
                            },
                            {
                                title: "Webhook", f: async () => {
                                    this.addBlock("webhook")
                                    this.setState({ flyout: false })
                                },
                                description: "Send data to an URL"
                            },
                            {
                                title: "User variables", f: async () => {
                                    this.addBlock("storage")
                                    this.setState({ flyout: false })
                                },
                                description: "Store user-related variables"
                            },
                            {
                                title: "Dictionary", f: async () => {
                                    this.addBlock("dictionary")
                                    this.setState({ flyout: false })
                                },
                                description: "Dynamic key-value store"
                            },
                            {
                                title: "Database", f: async () => {
                                    this.addBlock("database")
                                    this.setState({ flyout: false })
                                },
                                description: "Connect to an external database"
                            },
                            {
                                title: "Run sequence", f: async () => {
                                    this.addBlock("encapsulation")
                                    this.setState({ flyout: false })
                                },
                                description: "Run another sequence of this integration"
                            },
                        ]
                            .filter(block => (block.title || "").toLowerCase().indexOf((productSearch || "").toLowerCase()) > -1)
                            .map((s, i) => <Delay
                                wait={i * 60}
                            ><EuiFlexItem>
                                    <EuiCard
                                        layout="horizontal"
                                        title={s.title}
                                        description={s.description || "Add step"}
                                        onClick={s.f}
                                        className="fade-in-entry"
                                    />
                                </EuiFlexItem></Delay>)}

                    </EuiFlexGrid>





                </EuiFlyoutBody>}

                {this.state.page === "product" &&
                    <EuiFlyoutBody>
                        {this.state.loadingProducts && <>
                            <EuiFieldSearch placeholder="Search..." isClearable fullWidth disabled />
                            <br /><br />

                            <EuiFlexGrid columns={3} gutterSize="s">
                                {[1, 2, 3, 4, 5, 6, 7]
                                    .map((product, i) => <EuiFlexItem key={i}>
                                        <EuiCard title={<EuiLoadingContent lines={1} />} description={<EuiLoadingContent lines={3} />} />
                                    </EuiFlexItem>)
                                }
                            </EuiFlexGrid></>
                        }

                        {!this.state.loadingProducts && <ProductsList
                            products={loadedProducts}
                            search={search}
                            openProduct={this.openProduct.bind(this)}
                            workflow={workflow}
                        />}
                    </EuiFlyoutBody>
                }

                {this.state.page === "method" &&

                    <EuiFlyoutBody>

                        <span id="top" style={{ marginTop: 0 }}></span>

                        {
                            this.state.loadingMethods && <EuiFlexGrid columns={1} gutterSize="s">
                                {[1, 2, 3, 4, 5, 6, 7]
                                    .map((product, i) => <EuiFlexItem key={i}>
                                        <EuiCard title={<EuiLoadingContent lines={1} />} description={<EuiLoadingContent lines={3} />} />
                                    </EuiFlexItem>)
                                }
                            </EuiFlexGrid>
                        }

                        {!this.state.loadingMethods && <>




                            {
                                [...new Set(blocks.map(block => getResource(block.requestPath)[0]))].map(resource => {

                                    return <EuiButton size="s" href={"#" + infl.tableize(this.display(resource))} style={{ marginRight: 5, marginBottom: 5 }}>
                                        {this.display(resource)}
                                    </EuiButton>
                                })
                            }

                            <br />


                            {
                                [...new Set(blocks.map(block => getResource(block.requestPath)[0]))].map(resource => {
                                    return <>

                                        <br />

                                        <EuiButtonIcon
                                            href="#top"
                                            iconType="arrowUp"
                                            style={{ float: "right" }}
                                        />

                                        <EuiTitle size="m"><h2 id={infl.tableize(this.display(resource))}>
                                            {this.display(resource)}
                                        </h2></EuiTitle>

                                        <br />

                                        <EuiFlexGrid columns={1} gutterSize="s">

                                            {
                                                blocks.filter(block => getResource(block.requestPath)[0] === resource)
                                                    .map(block => {
                                                        return <EuiFlexItem><EuiCard
                                                            layout="horizontal"
                                                            titleSize="s"
                                                            title={<span style={{ textAlign: "left" }}><TextTruncate line={2} element="h4" text={block.name} /></span>}
                                                            onClick={() => {
                                                                this.insertBlock(block);
                                                            }}
                                                            // className="fade-in-entry"
                                                            style={{ marginBottom: 10 }}
                                                        >
                                                            <TextTruncate line={2} element="span" text={block.description} />
                                                        </EuiCard>
                                                        </EuiFlexItem>
                                                    })
                                            }

                                        </EuiFlexGrid>
                                    </>
                                })
                            }


                        </>
                        }


                    </EuiFlyoutBody>
                }
            </EuiFlyout>}

        </React.Fragment>
    }
}

class ProductsList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            activePage: 0,
            search: ''
        }
    }

    render() {

        const { products, workflow } = this.props

        const { activePage, search } = this.state

        const ITEMS_PER_PAGE = 21


        const goToPage = pageNumber => {
            this.setState({ activePage: pageNumber })
        };

        const productsToDisplay = products
            .filter(product => product.blocks.length)
            .filter(product => product.name)
            .sort((a, b) => a.owner - b.owner)
            .filter(product => product.name.toLowerCase().indexOf(search ? search.toLowerCase() : '') > -1)


        const suggestedProducts = products
            .filter(product => product.blocks.length)
            .filter(product => Object.values(workflow.auth).map(p => p.product.id).includes(product.id))

        const PAGE_COUNT = parseInt(productsToDisplay.length / ITEMS_PER_PAGE) + 1

        return <>

            <EuiFieldSearch
                placeholder="Search..."
                // value={search}
                onChange={e => this.setState({ search: e.target.value, activePage: 0 })}
                isClearable fullWidth
            /> <br /><br />

            {!!suggestedProducts.length && !search && <>

                <EuiTitle>
                    <h3>Suggested products</h3>
                </EuiTitle><br />

                <EuiFlexGrid columns={3} gutterSize="s">

                    {suggestedProducts
                        .map((product, i) => <EuiFlexItem key={i}>
                            <EuiCard
                                // layout="horizontal"
                                title={product.name}
                                titleSize="xs"
                                onClick={async () => {
                                    await this.props.openProduct(product)

                                }}
                                description={<><EuiBadge color="hollow">{product.category}</EuiBadge></>}
                                icon={product.logo ? <img src={product.logo} style={{ maxWidth: 70, marginBottom: -5 }} /> : <EuiAvatar size="xl" type="space" name="Not provided" initials="?" color="#D3DAE6" />}
                                betaBadgeLabel={product.owner === "private" ? <span>private</span> : null}
                            // betaBadgeTooltipContent={product.product.owner}
                            // onClick={() => this.props.history.push(`/products/${product.id}`)}
                            />
                        </EuiFlexItem>)
                    }

                </EuiFlexGrid>

                <EuiSpacer size="l" />

                <br />

                <EuiTitle>
                    <h3>Available products</h3>
                </EuiTitle><br />

            </>}


            <EuiFlexGrid columns={3} gutterSize="s">

                {productsToDisplay
                    .filter((item, i) => activePage * ITEMS_PER_PAGE <= i && (1 + activePage) * ITEMS_PER_PAGE > i)
                    .map((product, i) => <EuiFlexItem key={i}>
                        <EuiCard
                            // layout="horizontal"
                            title={product.name}
                            titleSize="xs"
                            onClick={async () => {
                                await this.props.openProduct(product)

                            }}
                            description={<><EuiBadge color="hollow">{product.category}</EuiBadge></>}
                            icon={product.logo ? <img src={product.logo} style={{ maxWidth: 70, marginBottom: -5 }} /> : <EuiAvatar size="xl" type="space" name="Not provided" initials="?" color="#D3DAE6" />}
                            betaBadgeLabel={product.owner === "private" ? <span>private</span> : null}
                        // betaBadgeTooltipContent={product.product.owner}
                        // onClick={() => this.props.history.push(`/products/${product.id}`)}
                        />
                    </EuiFlexItem>)
                }

            </EuiFlexGrid>

            <EuiSpacer size="l" />

            {!!productsToDisplay.length && <EuiFlexGroup justifyContent="spaceAround">
                <EuiFlexItem grow={false}>
                    <EuiPagination
                        pageCount={PAGE_COUNT}
                        activePage={activePage}
                        onPageClick={activePage => goToPage(activePage)}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>}
        </>
    }
}