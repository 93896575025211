import { Button, Col, Descriptions, Form, Icon, Input, PageHeader, Row, Steps, Typography } from 'antd';
import React from 'react';
import TimeAgo from 'react-timeago';
import api from '../api';
import ReactJson from 'react-json-view';
import Log from '../components/Log'

const { Paragraph } = Typography
const { Step } = Steps;

export default class RequestLog extends React.Component {
    constructor(props) {
        super(props)

        const defaultLog = (new URLSearchParams(window.location.search)).get('requestId') || ""

        this.state = {
            log: null,
            searchValue: defaultLog,
            selectedStep: 0
        }
    }

    async componentDidMount() {
        if (this.state.searchValue) {
            const { data: log } = await api('logs/request/' + this.state.searchValue)
            console.log(log)
            this.setState({ log })
        }
    }


    async updateSearchField(e) {
        const params = new URLSearchParams(window.location.search);

        console.log(params)
        params.set('requestId', e.target.value);
        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);


        this.setState({ searchValue: e.target.value })
    }

    async search(e) {
        e.preventDefault()
        const { data: log } = await api('logs/request/' + this.state.searchValue)
        console.log(log)
        
        this.setState({ log })
    }

    render() {

        const { log, selectedStep } = this.state

        return <React.Fragment>

            <PageHeader
                title="Logs"
                style={{ backgroundColor: 'white' }}
            >
                <div className="wrap">
                    <div className="content">
                        <Paragraph>
                            <Form layout="inline" onSubmit={this.search.bind(this)}>
                                <Form.Item style={{ width: 400 }}>
                                    <Input
                                        prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        placeholder="Request ID"
                                        value={this.state.searchValue}
                                        onChange={this.updateSearchField.bind(this)}
                                        style={{ width: 400 }}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Paragraph>
                        <Paragraph>
                            Logs are kept for an amout of time depending on your pricing plan.
                        </Paragraph>

                    </div>

                    {log && <Log log={log} />}
                </div>
            </PageHeader>


        </React.Fragment >
    }
}
