import { Button as AntButton, Card, List, PageHeader, Statistic, Typography } from 'antd';
import React from 'react';
import uuidv4 from 'uuid/v4';
import api from '../api';







const { Paragraph } = Typography;


export default class Scenarios extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            scenarios: [],
            workflows: [],
            createWorkflowLoader: false
        }
    }

    async componentDidMount() {
        // console.log(this.props.match.params.projectId)
        // const { data: { scenarios } } = await api('projects/' + this.props.match.params.projectId + '/scenarios')
        // const { data: { workflows } } = await api('projects/' + this.props.match.params.projectId + '/workflows')
        
        // console.log(scenarios)
        // this.setState({ scenarios, workflows })

    }

    count(scenario) {
        return scenario.setups.reduce((acc, curr) => acc + curr.auths.length, 0)
    }

    render() {

        const { createWorkflowLoader } = this.state
        
        const { projectId } = this.props.match.params

        return <React.Fragment>

            <PageHeader
                title="Workflows"
                style={{ backgroundColor: 'white' }}
                extra={[
                    <AntButton key="3" size="large" type="primary" icon="solution" onClick={() => this.props.history.push(`/project-logs/${projectId}`)}>Logs</AntButton>
                ]}
            >
                <div className="wrap">
                    <div className="content">
                        <Paragraph>
                            A workflow is an integration scenario you can add to your app. It can be "Creating a lead", "Getting data from a spreadsheet", ...
                        </Paragraph>
                        <Paragraph>
                            A single workflow can be linked to several third party products. For instance, "Creating a lead" can be connected at the same time to Salesforce, Hubspot and other CRMs.
                        </Paragraph>
                        <AntButton className="contentLink" type="primary" size="large" icon="plus" onClick={() => this.props.history.push("/" + this.props.match.params.projectId + "/setups/new")}>
                            Create a new scenario
                        </AntButton>&nbsp;
                        <AntButton className="contentLink" type="primary" size="large" icon="plus" loading={createWorkflowLoader} onClick={async () => {
                            this.setState({ createWorkflowLoader: true })
                            
                            const { projectId } = this.props.match.params
                            const defaultWorkflow = {
                                "version": 1,
                                "trigger": "api",
                                "name": "Workflow",
                                "sequenceId": uuidv4(),
                                "inputSchema": {},
                                "inputExample": {},
                                "steps": [],
                                "outputSchema": {},
                                "outputExample": {}
                            }
                            
                            const { data: { createWorkflow: { id } } } = await api('workflows', 'post', { projectId, workflow: defaultWorkflow })

                            this.setState({ createWorkflowLoader: false })

                            this.props.history.push(`/workflows/${id}`)
                            return true
                        }}>
                            Create a new workflow
                        </AntButton>
                    </div>
                </div>
            </PageHeader>

            <div style={{ padding: 24, minHeight: 360, borderRadius: 4 }}>
                <List
                    grid={{ gutter: 16, xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}
                    dataSource={
                        this.state.workflows
                            // .filter(scenario => scenario.name !== "Untitled")
                        // .sort((a, b) => this.count(b) - this.count(a))
                    }
                    renderItem={workflow => (
                        <List.Item>
                            <Card hoverable title={workflow.workflow.name} hoverable onClick={() => this.props.history.push("/workflows/" + workflow.id)} actions={[<span>Open</span>]}>
                                {/* <ReactJson src={workflow.workflow} /> */}
                            </Card>
                        </List.Item>
                    )}
                />
            
            </div>
            
            <div style={{ padding: 24, minHeight: 360 }}>
                <List
                    grid={{ gutter: 16, xs: 2, sm: 2, md: 2, lg: 3, xl: 3, xxl: 3 }}
                    dataSource={
                        this.state.scenarios
                            .filter(scenario => scenario.name !== "Untitled")
                            .sort((a, b) => this.count(b) - this.count(a))}
                    renderItem={scenario => (
                        <List.Item>
                            <Card hoverable title={scenario.name} hoverable onClick={() => this.props.history.push("/scenarios/" + scenario.id)} actions={[<span>Open</span>]}>
                                <Statistic title="Active Users" value={this.count(scenario)} />
                            </Card>
                        </List.Item>
                    )}
                />
            
            </div>
        </React.Fragment>
    }
}
