import { EuiHeader, EuiSelect, EuiHeaderSectionItemButton, EuiIcon, EuiAvatar, EuiHeaderLink, EuiBadge, EuiSpacer, EuiHeaderLinks, EuiHeaderLogo, EuiHeaderSectionItem, EuiPage, EuiPageBody, EuiPageContent, EuiPageContentBody, EuiPageContentHeader, EuiPageContentHeaderSection, EuiTitle } from '@elastic/eui';
import * as e from "@elastic/eui"
import { Icon, Layout, Menu } from 'antd';
import React from 'react';
import { Redirect, Route, Switch, withRouter, BrowserRouter as Router, Link } from "react-router-dom";
import auth0Client from './Auth';
import Callback from './Callback';
import AllProducts from './pages/AllProducts';
// import Apps from './pages/Apps';
import Auth from './pages/Auth';
import AuthPostConsent from './pages/AuthPostConsent';
import AuthUser from './pages/AuthUser';
import AuthWorkflow from './pages/AuthWorkflow.jsx';
import AuthWorkflowPostConsent from './pages/AuthWorkflowPostConsent.jsx';
import Block from './pages/Block';
import Blocks from './pages/Blocks';
import ComplexWorkflow from './pages/ComplexWorkflow.jsx';
import ComplexWorkflowBeta from './pages/ComplexWorkflowsBeta.jsx';
import CreateOAuth2Setup from './pages/CreateOAuth2Setup';
import CreateSetup from './pages/CreateSetup';
import Database from './pages/Database';
import Databases from './pages/Databases';
import TestAuth from './pages/TestAuth';
import ProjectLogs from './pages/Logs.jsx';
import NavBar from './pages/NavBar';
import Product from './pages/Product';
import Products from './pages/Products';
import Projects from './pages/Projects';
import RequestLogs from './pages/RequestLogs';
import Scenario from './pages/Scenario';
import Scenarios from './pages/Scenarios';
import Setup from './pages/Setup';
import Stats from './pages/Stats';
import Event from './pages/Event';
import Table from './pages/Table';
import User from './pages/User';
import WorkflowDocumentation from './pages/WorkflowDocumentation';
import SecuredRoute from './SecuredRoute';

import api from './api'


import Workflows2 from './pages2/Workflows'
import Products2 from './pages2/Products';
import Product2 from './pages2/Product';
import Projects2 from './pages2/Projects';
import ComplexWorkflow2 from './pages2/ComplexWorkflow';

import { createBrowserHistory } from "history";



import './App.css';
// import '@elastic/eui/dist/eui_theme_amsterdam_light.css';
// import theme from '@elastic/eui/dist/eui_theme_amsterdam_light.json';
import './eui.css'
const newUI = false

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

console.log(e)

const history = createBrowserHistory();
class App extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			checkingSession: true,
			collapsed: false,
			key: localStorage.getItem('tab') || "1"
		}
	}

	async componentDidMount() {

		// console.log('componentDidMount')

		if (this.props.location.pathname === '/callback') {
			this.setState({ checkingSession: false });
			return;
		}

		// console.log('Not callack')

		try {
			await auth0Client.silentAuth();
			console.log("Silent auth")
			this.forceUpdate();
		} catch (err) {
			if (err.error !== 'login_required') console.log(err.error);
		}

		// window.Intercom("boot", {
		// 	app_id: "kjcxf6s7",
		// 	// name: "<%= current_user.name %>", // Full name
		// 	// email: "<%= current_user.email %>", // Email address
		// 	// created_at: "<%= current_user.created_at.to_i %>" // Signup date as a Unix timestamp
		//   });

		// console.log('loading projects')

		// this.setState({ loadingProjects: true })
		// const { data: projects } = await api('projects')
		// this.setState({ projects, loadingProjects: false })

		// console.log({ projects })

		this.setState({ checkingSession: false });
	}

	onCollapse = collapsed => {
		console.log(collapsed);
		this.setState({ collapsed });
	};

	pathContains = list => {
		if (!this.props.location.pathname) return false
		for (let name of list) {
			if (this.props.location.pathname.indexOf('/' + name) === 0) return true
		}
		return false
	}

	render() {


		console.log(this.props)







		return (
			<Layout style={{ minHeight: '100vh', position: 'sticky' }}>
				{false && auth0Client.isAuthenticated() && <Sider theme="light" collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse} trigger={null} style={{ position: 'sticky' }}>
					{/* { auth0Client.isAuthenticated() && <NavBar /> } */}
					{
						this.state.collapsed ?
							<div className="logo" style={{ textAlign: 'center' }} onClick={() => this.props.history.push('/')}>
								<h1 style={{ color: '#333', cursor: 'pointer' }}>S</h1>
							</div>
							:

							<div className="logo" onClick={() => this.props.history.push('/')}>
								<h1 style={{ color: '#333', cursor: 'pointer' }}>Simplifier</h1>
							</div>
					}
				</Sider>}
				<Layout>
					{auth0Client.isAuthenticated() && <NavBar>

						<Menu theme="light" defaultSelectedKeys={[this.state.key]} mode="horizontal" >
							<Menu.Item key="1" onClick={() => { this.setState({ key: "1" }); localStorage.setItem('tab', "1"); this.props.history.push('/') }}>
								<Icon type="home" />
								<span>Workflows</span>
							</Menu.Item>

							{
								(auth0Client.profile) ?
									<Menu.Item key="2" onClick={() => { this.setState({ key: "2" }); localStorage.getItem('tab', "2"); this.props.history.push('/products') }}>
										<Icon type="appstore" />
										<span>Products</span>
									</Menu.Item>
									: <></>
							}



							<Menu.Item key="3" onClick={() => { this.setState({ key: "3" }); localStorage.getItem('tab', "3"); this.props.history.push('/databases') }}>
								<Icon type="database" />
								<span>My databases</span>
							</Menu.Item>
						</Menu>
					</NavBar>}
					<Content style={{ margin: '0' }}>
						{/* <Breadcrumb style={{ margin: '16px 0' }}>
						<Breadcrumb.Item>User</Breadcrumb.Item>
						<Breadcrumb.Item>Bill</Breadcrumb.Item>
					</Breadcrumb> */}
						<div style={{ minHeight: 360 }}>
							{/* <div style={{ padding: 24, background: '#fff', minHeight: 360 }}> */}
							<Switch>

								<Route exact path='/callback' component={Callback} />

								<SecuredRoute path="/" exact component={Projects} />




								<SecuredRoute path="/scenarios/:scenarioId" exact component={Scenario} checkingSession={this.state.checkingSession} />
								<SecuredRoute path="/:projectId/scenarios" exact component={Scenarios} checkingSession={this.state.checkingSession} />

								<SecuredRoute path="/:projectId/setups/new" exact component={CreateSetup} checkingSession={this.state.checkingSession} />
								<SecuredRoute path="/:projectId/setups/new/:scenarioId/oauth2/:setupId" exact component={CreateOAuth2Setup} checkingSession={this.state.checkingSession} />
								<SecuredRoute path="/setups/:setupId" exact component={Setup} checkingSession={this.state.checkingSession} />

								<Route path="/auth/postConsent/:setupId" exact component={AuthPostConsent} />
								<Route path="/authUser/:setupId" exact component={AuthUser} />


								<Route path="/workflow/auth/:workflowId" exact component={AuthWorkflow} />
								<Route path="/workflow/auth/next/:workflowId" exact component={AuthWorkflowPostConsent} />

								<SecuredRoute path="/auth/:authId" exact component={Auth} checkingSession={this.state.checkingSession} />

								<SecuredRoute path="/workflows/:workflowId" exact component={ComplexWorkflow} checkingSession={this.state.checkingSession} />
								<SecuredRoute path="/workflows-beta/:workflowId" exact component={ComplexWorkflowBeta} checkingSession={this.state.checkingSession} />

								<Route path="/logs" exact component={RequestLogs} />
								<Route path="/pulsar-stats" exact component={Stats} />

								<Route path="/project-logs/:projectId" exact component={ProjectLogs} />

								<SecuredRoute path="/products" exact component={Products} checkingSession={this.state.checkingSession} />
								<SecuredRoute path="/products/:productId" exact component={Product} checkingSession={this.state.checkingSession} />
								<SecuredRoute path="/products/:productId/test-authentication" exact component={TestAuth} checkingSession={this.state.checkingSession} />
								<SecuredRoute path="/products/test-authentication/callback" exact component={TestAuth} checkingSession={this.state.checkingSession} />
								<SecuredRoute path="/blocks" exact component={Blocks} checkingSession={this.state.checkingSession} />
								<SecuredRoute path="/blocks/:blockId" exact component={Block} checkingSession={this.state.checkingSession} />

								<SecuredRoute path="/users/:userToken" exact component={User} checkingSession={this.state.checkingSession} />


								<SecuredRoute path="/events/:eventId" exact component={Event} checkingSession={this.state.checkingSession} />



								<SecuredRoute path="/databases" exact component={Databases} checkingSession={this.state.checkingSession} />
								<SecuredRoute path="/databases/:databaseId" exact component={Database} checkingSession={this.state.checkingSession} />
								<SecuredRoute path="/databases/:databaseId/:table" exact component={Table} checkingSession={this.state.checkingSession} />

								<Route path="/workflows/:workflowId/doc" exact component={WorkflowDocumentation} />
								<Route path="/productsList" exact component={AllProducts} />



								<SecuredRoute component={() => <Redirect to="/" />} checkingSession={this.state.checkingSession} />
							</Switch>
						</div>
					</Content>
				</Layout>
			</Layout>
		);
	}
}

export default withRouter(App)