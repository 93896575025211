import { EuiButtonIcon, EuiSpacer, EuiButton, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiLink, EuiSuperSelect, EuiToolTip } from '@elastic/eui';
import React from 'react';

export default function (props) {
    const { product, updateProduct, complete, advancement } = props

    return <>
        <EuiFlexGroup>
            <EuiFlexItem>
                <EuiFormRow label="Content Type">
                    <EuiSuperSelect
                        options={[
                            "application/json",
                            "x-www-form-urlencoded"
                        ].map(t => ({ value: t, inputDisplay: <>{t}</>, }))}
                        valueOfSelected={product.auth.contentType || "application/json"}
                        onChange={v => updateProduct(v, 'auth', 'contentType')}
                        append={[<EuiToolTip position="right" className="pink" content="What is the format of the data Simplifier should send to the API? Don't worry, we will do the conversion for you.">
                            <EuiLink href="#"><EuiButtonIcon iconType="questionInCircle" aria-label="Gear this" /></EuiLink>
                        </EuiToolTip>]}
                    />
                </EuiFormRow>
            </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size="l" />

        <EuiFlexGroup>
            <EuiFlexItem>
                <EuiFormRow label="API rate limiting">
                    <EuiFieldText
                        name="first"
                        placeholder={"100"}
                        onChange={e => updateProduct(e.target.value, 'auth', 'delay')}
                        value={product.auth.delay}
                        append={["ms", <EuiToolTip position="right" className="pink" content="What is the format of the data Simplifier should send to the API? Don't worry, we will do the conversion for you.">
                            <EuiLink href="#"><EuiButtonIcon iconType="questionInCircle" aria-label="Gear this" /></EuiLink>
                        </EuiToolTip>]}
                    />
                </EuiFormRow>
            </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size="l" />

        {
            advancement === "incomplete" && <EuiButton iconType="arrowRight" fill color="primary" onClick={() => complete()}>Next</EuiButton>
        }
    </>
}