import { Badge, Button, Card, Icon, Mentions, Popconfirm, Select as AntSelect } from 'antd';
import React from 'react';
import { encapsulate, getProperties } from '@simplifier/normalization';
import { deleteStep, display, updateStep } from '../../utils/workflows.js';

const { Option } = Mentions

export default class ErrorStep extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const {
			step,
			localWorkflow,
			availableVariables,
			workflow,
			availableMethods,
			products,
			updateWorkflow,
			updateLocalWorkflow,
			updateAvailableMethods,
			workflowVariables
		} = this.props

		return <React.Fragment>
			<td valign="top" style={{ position: 'relative' }}>
				<Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-25px', top: '150px' }} />
				<Card
					style={{ scrollSnapAlign: "start" }}
					className='workflowCard'
					title={<Badge count={"Error"} />}
					extra={<Popconfirm
						title="Do you really want to delete this step?"
						onConfirm={() => {
							let newWorkflow = deleteStep(workflow, step)
							updateWorkflow(newWorkflow)
						}}
						okText="Yes"
						cancelText="No"
					>
						<Button size="small" shape="circle" icon="close" />
					</Popconfirm>}
				>
					<AntSelect
						placeholder="Error code"
						style={{ width: '100%', marginBottom: 15 }}
						size="large"
						onChange={value => {
							const newWorkflow = updateStep(localWorkflow, { ...step, errorCode: value })
							updateLocalWorkflow(newWorkflow)
						}}
						value={step.errorCode || "404"}
					>
						<AntSelect.Option value="404">404 — Not found</AntSelect.Option>
						<AntSelect.Option value="403">403 — Forbidden</AntSelect.Option>
						<AntSelect.Option value="422">422 — Missing parameter</AntSelect.Option>
						<AntSelect.Option value="500">500 — Internal server error</AntSelect.Option>

					</AntSelect>

					<br />
					<Mentions
						style={{ width: '100%', marginBottom: 15 }}
						placeholder={"Error message"}
						size="large"
						rows={4}
						prefix={['{']}
						value={step.errorMessage || ""}
						onChange={value => {
							const newWorkflow = updateStep(localWorkflow, { ...step, errorMessage: value })
							updateLocalWorkflow(newWorkflow)
						}}
					>
						{getProperties([...availableVariables, ...encapsulate(workflowVariables, "storage")]).filter(property => property.type !== 'object' && property.type !== "array").map(model => <Option key={model.path} value={model.path + "}"}>
							<strong style={{ color: "#293742" }}>{display(model.path)}</strong><br />
							<i style={{ color: "#495762" }}>{model.type} ({model.example})</i>
						</Option>)}
					</Mentions>
				</Card>
			</td>
		</React.Fragment>
	}
}