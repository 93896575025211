import { Spinner } from "@blueprintjs/core";
import { Button, Card, Checkbox, Divider, Form, Icon, Input, List, PageHeader, Radio, Select, Switch, Tag, Tabs, Typography } from 'antd';
import React from 'react';
import api from '../api';
import ReactJson from 'react-json-view'

import MarkdownIt from 'markdown-it'
import MdEditor from 'react-markdown-editor-lite'
import 'react-markdown-editor-lite/lib/index.css';

const { Paragraph } = Typography;
const { TabPane } = Tabs;

const mdParser = new MarkdownIt(/* Markdown-it options */);

class State extends React.Component {
	render() {
		if (this.props.state === "4") return <Tag color="volcano">Broken</Tag>
		if (this.props.state === "3") return <Tag color="gold">Needs check</Tag>
		if (this.props.state === "2") return <Tag color="green">Valid</Tag>
		if (this.props.state === "1") return <Tag color="blue">Tested</Tag>
		return <span></span>
	}
}

export default class Scenarios extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			connector: null,
			search: "",
			authModes: []
		}
	}

	async componentDidMount() {
		const { productId } = this.props.match.params
		const { data: { connector } } = await api(`products/${productId}`)
		const { data: authModes } = await api('workflow/authModes')

		console.log(connector)

		this.setState({ connector, authModes })
	}

	updateValue(value, field, subfield = null) {
		let newProduct = this.state.connector
		let newValue = value

		// console.log(newValue)

		if (subfield) {
			newProduct[field][subfield] = newValue
		} else {
			newProduct[field] = newValue
		}

		this.setState({ connector: newProduct })
	}

	async updatePublicStatus(value) {
		this.setState({ loadingUpdatePublic: true })
		await api.patch(`products/${this.state.connector._id}/set-public`, { value })
		this.setState({ loadingUpdatePublic: false })
	}

	render() {
		const { connector, search, authModes } = this.state

		// console.log(this.props)

		if (!connector) {
			return <React.Fragment>
				<PageHeader
					title={"Product"}
					style={{ backgroundColor: 'white' }}
				>
					<br /><br /><h3 className="text-center"><Spinner /></h3><br /><br />
				</PageHeader>
			</React.Fragment>
		}

		const formItemLayout = { labelCol: { span: 5 }, wrapperCol: { span: 14 }, }

		let selectedAuth = authModes.filter(auth => auth.slug === connector.auth.type).length ?
			authModes.filter(auth => auth.slug === connector.auth.type)[0]
			: null


		return <React.Fragment>

			<PageHeader
				title={connector.name || "Product"}
				onBack={() => this.props.history.push(`/products`)}
				style={{ backgroundColor: 'white' }}
				extra={[

					<>{connector.isAdmin ? <Select size="large" loading={this.state.loadingUpdatePublic} defaultValue={connector.owner} style={{ width: 190 }} onChange={this.updatePublicStatus.bind(this)}>
						<Select.Option value="private">Private connector</Select.Option>
						<Select.Option value="public">Public connector</Select.Option>
					</Select> : <Button size="large" disabled>
							{connector.owner === "private" ? "Private connector" : "Public connector"}
						</Button>}</>,

					<Select loading={this.state.loadingUpdateStatus} key="1" size="large" defaultValue={connector.state} style={{ width: 190 }} onChange={async v => {
						this.setState({ loadingUpdateStatus: true })
						let productToSend = { ...connector }
						delete productToSend.blocks
						await api(`products/${connector._id}/state`, 'post', { state: v })
						this.setState({ loadingUpdateStatus: false })
					}}>
						<Select.Option value="1">Tested</Select.Option>
						<Select.Option value="2">Valid</Select.Option>
						<Select.Option value="3">Needs check</Select.Option>
						<Select.Option value="4">Broken</Select.Option>
					</Select>,



					<Button key="3" size="large" type="primary" icon="save" loading={this.state.updatingProduct} onClick={async () => {
						this.setState({ updatingProduct: true })
						let productToSend = { ...connector }
						delete productToSend.blocks
						await api(`products/${connector._id}/update`, 'post', productToSend)
						this.setState({ updatingProduct: false })
					}}>Save product</Button>,

					<Button key="2" size="large" type="primary" icon="plus" loading={this.state.createBlockLoader} onClick={async () => {
						this.setState({ createBlockLoader: true })

						const { productId } = this.props.match.params
						const defaultBlock = {
							productId,
							name: "",
							description: "",
							method: "GET",
							contentType: "application/json",
							action: "",
							requestPath: "",
							scopes: "",
							input: { body: [], headers: [], path: [], query: [] },
							output: { body: [], headers: [] }
						}

						const { data: { createBlock: { _id } } } = await api('blocks', 'post', defaultBlock)

						this.setState({ createBlockLoader: false })

						this.props.history.push(`/blocks/${_id}`)
						return true
					}}>Add block</Button>,
				]}
			>


				<Tabs defaultActiveKey="1">
					<TabPane tab="General information" key="1">
						<Form layout="horizontal">
							<Form.Item label="Name" {...formItemLayout}><Input placeholder="Slack" size="large" onChange={(e) => this.updateValue(e.target.value, 'name')} value={connector.name} /></Form.Item>
							<Form.Item label="Base URL" {...formItemLayout}><Input placeholder="https://api.slack.com" size="large" onChange={(e) => this.updateValue(e.target.value, 'baseURL')} value={connector.baseURL} /></Form.Item>
							<Form.Item label="Logo URL" {...formItemLayout}><Input placeholder="https://api.slack.com/logo.png" size="large" onChange={(e) => this.updateValue(e.target.value, 'logo')} value={connector.logo} /></Form.Item>

							<Form.Item label="API doc" {...formItemLayout}><Input placeholder="https://api.slack.com" size="large" onChange={(e) => this.updateValue(e.target.value, 'auth', 'developerInstructions')} value={connector.auth.developerInstructions} /></Form.Item>
							<Form.Item label="API doc auth page" {...formItemLayout}><Input placeholder="https://api.slack.com/auth" size="large" onChange={(e) => this.updateValue(e.target.value, 'auth', 'developerAuthInstructions')} value={connector.auth.developerAuthInstructions} /></Form.Item>
							<Form.Item label="Developer dashboard URL" {...formItemLayout}><Input placeholder="https://api.slack.com/dashboard" size="large" onChange={(e) => this.updateValue(e.target.value, 'auth', 'developerDashboard')} value={connector.auth.developerDashboard} /></Form.Item>


							<Form.Item label="Request delay (rate limiting)" {...formItemLayout}><Input placeholder="50" size="large" onChange={(e) => { console.log(e.target.value); this.updateValue(parseInt(e.target.value), 'auth', 'delay') }} type="number" addonAfter="ms" value={connector.auth.delay} /></Form.Item>


						</Form>
					</TabPane>
					<TabPane tab="Authentication" key="2">
						<Form layout="horizontal">
							<Form.Item label="Auth mode" {...formItemLayout}>
								<Select
									showSearch
									style={{ width: "100%" }}
									size="large"
									optionFilterProp="children"
									placeholder="Select auth mode"
									onChange={value => {
										console.log(value)
										this.updateValue(value, 'auth', 'type')
									}}
									value={connector.auth.type}
									filterOption={(input, option) =>
										option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
								>

									{authModes.map(mode => <Select.Option value={mode.slug}>{mode.name}</Select.Option>)}
								</Select>
							</Form.Item>

							{
								selectedAuth && <>
									{
										selectedAuth.productDataSchema.map(param => {

											if (param.type === "boolean") {
												return <Form.Item label={param.label} {...formItemLayout}>
													<Checkbox checked={connector.auth[param.path]} onChange={(e) => this.updateValue(e.target.checked, 'auth', param.path)} ></Checkbox>
												</Form.Item>
											}

											else if (param.isEnum) {
												return <Form.Item label={param.label} {...formItemLayout}>
													<Radio.Group defaultValue="horizontal" size="large" onChange={(e) => this.updateValue(e.target.value, 'auth', param.path)} value={connector.auth[param.path]}>
														{param.enumValues.map(enumValue => <Radio.Button value={enumValue.value}>{enumValue.label}</Radio.Button>)}
													</Radio.Group>
												</Form.Item>
											}

											else {
												return <Form.Item label={param.label} {...formItemLayout}>
													<Input
														placeholder={param.example}
														size="large"
														value={connector.auth[param.path]}
														onChange={(e) => this.updateValue(e.target.value, 'auth', param.path)}
													/>
												</Form.Item>
											}
										})
									}
								</>
							}
						</Form>
					</TabPane>
					<TabPane tab="Helpers" key="3">
						<h1>General product help</h1>

						<MdEditor
							value={connector.auth.productHelp}
							style={{ height: "300px" }}
							renderHTML={(text) => mdParser.render(text)}
							onChange={v => this.updateValue(v.text, 'auth', 'productHelp')}
						/>

						<br />

						<h1>Authentication help</h1>

						<MdEditor
							value={connector.auth.authenticationHelp}
							style={{ height: "300px" }}
							renderHTML={(text) => mdParser.render(text)}
							onChange={v => this.updateValue(v.text, 'auth', 'authenticationHelp')}
						/>
					</TabPane>
				</Tabs>

			</PageHeader>

			<br />

			<Card style={{ marginLeft: 24, marginRight: 24, marginTop: 24, borderRadius: 4 }}>
				<Input.Search size="large" placeholder="Search among blocks" value={search} onChange={e => this.setState({ search: e.target.value })} />
			</Card>

			<List
				grid={{ gutter: 16, xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}
				dataSource={connector.blocks.filter(block => ((block.description + block.name) || "").toUpperCase().indexOf(search.toUpperCase()) > -1)}
				style={{ padding: 24, minHeight: 360, borderRadius: 4 }}
				renderItem={block => (
					<List.Item>
						<Card hoverable onClick={() => this.props.history.push(`/blocks/${block._id}`)} title={<span> <State state={block.state} /> {block.name || "Untitled"}</span>} extra={
							block.scopes.split(', ').map(scope => <Tag>{scope}</Tag>)} style={{ borderRadius: 5 }}>
							<Card.Meta description={block.description || "No description"} />
						</Card>
					</List.Item>
				)}
			/>

		</React.Fragment>
	}
}
