import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import auth0Client from './Auth';

class Callback extends Component {
  async componentDidMount() {

    const redirectTo = localStorage.getItem('redirect_to_page') || "/"

    console.log("should redirect to ", redirectTo)
    await auth0Client.handleAuthentication();
    this.props.history.replace(redirectTo);
  }

  render() {
    return (
      <p>Loading profile...</p>
    );
  }
}

export default withRouter(Callback);