import { Spinner } from "@blueprintjs/core";
import { Button as AntButton, Card as AntCard, List, PageHeader, Typography } from 'antd';
import React from 'react';
import { Redirect } from 'react-router-dom';
import api from '../api';

const { Paragraph } = Typography

export default class Projects extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            projects: [],
            loading: false
        }
    }

    async componentDidMount() {
        this.setState({ loading: true })
        const { data: projects } = await api('projects')
        this.setState({ projects, loading: false })
    }

    async timeout() {
        setTimeout(async () => {
            this.setState({ loading: true })
            const { data: projects } = await api('projects')
            if (!projects) return this.timeout()
            this.setState({ projects, loading: false })
        }, 100);
    }

    render() {

        if (this.state.loading) return <span><br /><br /><h3 className="text-center"><Spinner /></h3></span>

        if (!this.state.projects.length) {
            this.timeout()
            return <span><br /><br /><h3 className="text-center"><Spinner /></h3></span>
        }

        return <React.Fragment>

            <PageHeader
                title="My stores"
                style={{ backgroundColor: 'white' }}

                extra={[
                    <AntButton key={1} type="primary" size="large" icon="plus" onClick={async () => {
                        const name = window.prompt("Project name", "New project")

                        const { data } = await api('projects', 'post', { name })

                        // alert(data._id)

                        this.props.history.push(data._id + "/scenarios")

                        // alert(name)
                    }}>New store</AntButton>
                ]}
            >
                <div className="wrap">
                    <div className="content">
                        {/* <Dropdown overlay={menu}>
                            <Button loading={this.state.newDatabaseLoader} className="contentLink" type="primary" size="large" icon="plus">
                                Connect a database <Icon type="down" />
                            </Button>
                        </Dropdown> */}
                    </div>
                </div>
            </PageHeader>

            <div style={{ padding: 24, minHeight: 360 }}>

                {/* {this.state.projects.length === 1 && <Redirect to={this.state.projects[0].id + "/scenarios"} />} */}

                <List
                    grid={{ gutter: 16, xs: 2, sm: 2, md: 2, lg: 3, xl: 3, xxl: 3 }}
                    dataSource={[...this.state.projects]}
                    renderItem={project => {

                        // if(project === "new") {
                        //     return <List.Item>
                        //     <Button type="dashed"  style={{ borderRadius: 5 }} onClick={() => {
                        //         const name = window.prompt("Project name", "New project")
                        //         alert(name)
                        //     }}>
                        //         <h3>+ Add project</h3>
                        //     </Button>
                        // </List.Item>
                        // }

                        return <List.Item>
                            <AntCard style={{ borderRadius: 5 }} hoverable onClick={() => this.props.history.push(project._id + "/scenarios")}>
                                <h3>{project.name}</h3>
                            </AntCard>
                        </List.Item>
                    }}
                />

            </div>
        </React.Fragment>
    }
}
