import { EuiAvatar, EuiButton, EuiButtonGroup, EuiCard, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiLoadingContent, EuiPageContent, EuiPageContentBody, EuiPageContentHeader, EuiPageHeaderSection, EuiSpacer, EuiSteps, EuiTitle } from '@elastic/eui';
import { Tabs, Tag, Typography } from 'antd';
import MarkdownIt from 'markdown-it';
import React from 'react';
import ReactJson from 'react-json-view';
import 'react-markdown-editor-lite/lib/index.css';
import api from '../api';
import Authentication from './ProductForms/Authentication';
import General from './ProductForms/General';
import Optional from './ProductForms/Optional';



const { Paragraph } = Typography;
const { TabPane } = Tabs;

const mdParser = new MarkdownIt(/* Markdown-it options */);

class State extends React.Component {
    render() {
        if (this.props.state === "4") return <Tag color="volcano">Broken</Tag>
        if (this.props.state === "3") return <Tag color="gold">Needs check</Tag>
        if (this.props.state === "2") return <Tag color="green">Valid</Tag>
        if (this.props.state === "1") return <Tag color="blue">Tested</Tag>
        return <span></span>
    }
}

export default class Scenarios extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            product: null,
            search: "",
            authModes: [],
            needsSave: false,
            updatingProduct: false,
            advancement: {
                general: "incomplete",
                optional: false,
                authentication: false,
                helpers: false
            }
        }
    }

    async componentDidMount() {
        const { productId } = this.props.match.params
        const { data: { product } } = await api(`products/${productId}`)
        const { data: authModes } = await api('workflow/authModes')

        this.setState({ product, authModes })
    }

    async saveProduct() {
        this.setState({ updatingProduct: true, needsSave: false })
        let productToSend = { ...this.state.product }
        delete productToSend.blocks
        await api(`products/${this.state.product.id}/update`, 'post', productToSend)
        this.setState({ updatingProduct: false })
    }

    updateValue(value, field, subfield = null) {
        let newProduct = this.state.product
        let newValue = value

        if (subfield) {
            newProduct[field][subfield] = newValue
        } else {
            newProduct[field] = newValue
        }

        this.setState({ product: newProduct, needsSave: true })
    }

    async updatePublicStatus(value) {
        this.setState({ loadingUpdatePublic: true })
        await api.patch(`products/${this.state.product.id}/set-public`, { value })
        this.setState({ loadingUpdatePublic: false })
    }

    render() {
        const { product, search, authModes, needsSave, advancement, updatingProduct } = this.state

        if (!product) {
            return <EuiPageContent>
                <EuiLoadingContent lines={3} />
            </EuiPageContent>
        }

        let allSteps = [
            {
                title: 'General product info',
                id: 'general',
                children: advancement => <General
                    product={product}
                    advancement={advancement}
                    updateProduct={this.updateValue.bind(this)}
                    complete={() => {
                        this.saveProduct()
                        this.setState({
                            advancement: {
                                general: "complete",
                                optional: "incomplete",
                                authentication: false,
                                helpers: false
                            }
                        })
                    }}
                />,
            }, {
                title: 'Optional API settings',
                id: 'optional',
                children: advancement => <Optional
                    product={product}
                    advancement={advancement}
                    updateProduct={this.updateValue.bind(this)}
                    complete={() => {
                        this.saveProduct()
                        this.setState({
                            advancement: {
                                general: "complete",
                                optional: "complete",
                                authentication: "incomplete",
                                helpers: false
                            }
                        })
                    }}
                />
            }, {
                title: 'Product authentication',
                id: 'authentication',
                children: advancement => <Authentication
                    product={product}
                    advancement={advancement}
                    updateProduct={this.updateValue.bind(this)}
                    complete={() => {
                        this.saveProduct()
                        this.setState({
                            advancement: {
                                general: "complete",
                                optional: "complete",
                                authentication: "complete",
                                helpers: "incomplete"
                            }
                        })
                    }}
                    authModes={authModes}
                />,
            }, {
                title: 'Documentation & developer help',
                id: 'helpers',
                children: advancement => <div></div>,
            }
        ]

        let steps = []

        for (let step of allSteps) {
            if (advancement[step.id]) {
                steps.push({
                    ...step,
                    children: step.children(advancement[step.id])
                })
            }
        }

        return <>

            <EuiPageContent>
                <EuiPageContentHeader>
                    <EuiPageHeaderSection>

                        <EuiFlexGroup>
                            <EuiFlexItem grow={false}>{product.logo ? <img src={product.logo} style={{ maxWidth: 50, marginBottom: -5, float: "left", borderRadius: 5 }} /> : <EuiAvatar size="l" type="space" name="Not provided" initials="?" color="#D3DAE6" />}</EuiFlexItem>
                            <EuiFlexItem><EuiTitle size="l"><h1>{product.name || "Untitled product"}</h1></EuiTitle></EuiFlexItem>
                        </EuiFlexGroup>



                    </EuiPageHeaderSection>
                    <EuiPageHeaderSection>
                        <EuiButtonGroup
                            legend="This is a basic group"
                            options={[
                                { id: `public`, label: 'Public connector', },
                                { id: `private`, label: 'Private connector', }
                            ]}
                            idSelected={'public'}
                            color="primary"
                            onChange={console.log}
                            buttonSize="m"
                            style={{ marginRight: 10 }}
                        />
                        <EuiButton iconType="save" fill={needsSave} isLoading={updatingProduct} color="secondary" onClick={this.saveProduct.bind(this)}>Save</EuiButton>
                    </EuiPageHeaderSection>
                </EuiPageContentHeader>
                <EuiPageContentBody>
                    <EuiSteps headingElement="h2" steps={steps} />
                </EuiPageContentBody>
            </EuiPageContent>

            <EuiSpacer size="l" />

            {product && <EuiFlexGrid columns={3}>
                {product.blocks.map(block => <EuiFlexItem>
                    <EuiCard
                        layout="horizontal"
                        display="panel"
                        title={block.name}
                        description={block.description}
                    // onClick={() => this.props.history.push("/workflows/" + workflow._id)}
                    />
                </EuiFlexItem>)}

            </EuiFlexGrid>}


        </>
    }
}
