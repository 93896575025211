import {
    EuiButton,
    EuiButtonIcon,
    EuiContextMenuPanel,
    EuiFieldText,
    EuiFormRow, EuiIcon,
    EuiPopover
} from "@elastic/eui";
import { Button, Card, Cascader, Icon, Mentions, Popconfirm, Select as AntSelect } from 'antd';
import React from 'react';
import { encapsulate, flatten, getChildProperties, getParentProperties, getProperties } from '@simplifier/normalization';
import { deleteStep, display, updateStep, buildSelectorTree } from '../../utils/workflows';
import StepCard from "../CardComponents";
import Steps from '../Steps';





const { Option } = Mentions

export default class For2Step extends React.Component {
    constructor(props) {
        super(props)

        this.state = { popoverOpen: false }
    }

    render2() {
        const {
            step,
            localWorkflow,
            availableVariables,
            databases,
            workflow,
            availableMethods,
            products,
            updateWorkflow,
            updateLocalWorkflow,
            updateAvailableMethods,
            workflowVariables
        } = this.props

        return <React.Fragment>
            <td><table className='workflowTable' style={{ height: '100%' }}><tbody>
                <tr style={{ height: '100%' }}>

                    <td rowSpan={2} valign="top" style={{ height: '100%', position: 'relative' }}>
                        <Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-25px', top: '150px' }} />
                        <Card
                            className='workflowCard'
                            style={{ scrollSnapAlign: "start", height: 'calc(100% - 20px)' }}
                            title="For each element of..."
                            extra={<Popconfirm
                                title="Do you really want to delete this step?"
                                onConfirm={() => {
                                    let newWorkflow = deleteStep(workflow, step)
                                    updateWorkflow(newWorkflow)
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button size="small" shape="circle" icon="close" />
                            </Popconfirm>}
                        >

                            {/* {step.uniqueId} */}

                            <AntSelect
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                style={{ width: '100%', height: 50, marginBottom: 15 }}
                                size="large"
                                placeholder={"Click to select a second term variable"}
                                value={step.forEach ? JSON.stringify({ id: step.forEach || null, path: step.forEachPath || null }) : undefined}
                                onChange={(value) => {
                                    const { id, path } = JSON.parse(value)
                                    const newWorkflow = updateStep(localWorkflow, {
                                        ...step,
                                        forEach: id,
                                        forEachPath: path
                                    })

                                    console.log({ newWorkflow })
                                    updateLocalWorkflow(newWorkflow)
                                }}
                            >
                                {getProperties([...availableVariables, ...encapsulate(workflowVariables, "storage")]).filter(property => property.type === "array").map(model => <AntSelect.Option key={model.path} value={JSON.stringify({ id: model.id, path: model.path })}>{display(model.path)}</AntSelect.Option>)}
                            </AntSelect>

                            {/* {JSON.stringify([...availableVariables, ...encapsulate(workflowVariables, "storage")])} */}
                        </Card>
                    </td>

                    <td style={{ height: '100%', position: 'relative' }}>
                        <Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-24px', top: '100px' }} />
                        <Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-24px', top: '126px' }} />
                        <table className='workflowTable'><tbody><tr>

                            <Steps
                                steps={step.do}
                                sequenceId={step.sequenceId}
                                workflow={workflow}
                                availableMethods={availableMethods}
                                products={products}
                                updateWorkflow={updateWorkflow.bind(this)}
                                updateAvailableMethods={updateAvailableMethods}
                                inheritedVariables={[
                                    ...flatten({ parent: getParentProperties(availableVariables, step.forEach || ""), }),
                                    ...flatten({ current: getChildProperties(availableVariables, step.forEach || "") })
                                ]}
                                workflowVariables={workflowVariables}
                                databases={databases}
                            />
                        </tr></tbody></table>
                    </td>
                </tr></tbody>
            </table><br /></td>

        </React.Fragment >
    }








    render() {

        const {
            step,
            localWorkflow,
            availableVariables,
            workflow,
            availableMethods,
            products,
            updateWorkflow,
            updateLocalWorkflow,
            updateAvailableMethods,
            databases,
            workflowVariables,
        } = this.props

        return <React.Fragment>

            <table className='workflowTable' style={{ height: '100%' }}><tbody>
                <tr style={{ height: '100%' }} className="sticky-block-content">

                    {/* <td rowspan="2" valign="top" style={{ height: '100%', position: 'relative' }}> */}
                    {/* <Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-25px', top: '150px' }} /> */}


                    <StepCard
                        arrow={<EuiIcon type="arrowRight" />}
                        title="Loop"
                        iconsLeft={[]}
                        iconsRight={[
                            <EuiPopover
                                id="contentPanel"
                                anchorPosition="rightUp"
                                button={<EuiButtonIcon size="l" iconType="trash" onClick={() => this.setState({ popoverOpen: !this.state.popoverOpen })} />}
                                isOpen={this.state.popoverOpen}
                                closePopover={() => this.setState({ popoverOpen: false })}
                                panelPaddingSize="s"
                            >
                                <EuiContextMenuPanel>
                                    <EuiFormRow label="Are you sure?">
                                        <EuiButton
                                            onClick={() => {
                                                let newWorkflow = deleteStep(workflow, step)
                                                updateWorkflow(newWorkflow)
                                            }}
                                            iconType="trash"
                                            color="danger"
                                        >
                                            Delete that step
										</EuiButton>
                                    </EuiFormRow>
                                </EuiContextMenuPanel>
                            </EuiPopover>
                        ]}>

                        {/* <AntSelect
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            style={{ width: '100%', height: 50, marginBottom: 15 }}
                            size="large"
                            placeholder={"Click to select a second term variable"}
                            value={step.forEach ? JSON.stringify({ id: step.forEach || null, path: step.forEachPath || null }) : undefined}
                            onChange={(value) => {
                                const { id, path } = JSON.parse(value)
                                const newWorkflow = updateStep(localWorkflow, {
                                    ...step,
                                    forEach: id,
                                    forEachPath: path
                                })

                                console.log({ newWorkflow })
                                updateLocalWorkflow(newWorkflow)
                            }}
                        >
                            {getProperties([...availableVariables, ...encapsulate(workflowVariables, "storage")]).filter(property => property.type === "array").map(model => <AntSelect.Option key={model.path} value={JSON.stringify({ id: model.id, path: model.path })}>{display(model.path)}</AntSelect.Option>)}
                        </AntSelect> */}

                        <Cascader
                            autoFocus
                            popupClassName="popoup"
                            expandTrigger="hover"
                            fieldNames={{ label: "label", value: "path", children: "children" }}
                            options={buildSelectorTree(
                                getProperties([...availableVariables, ...encapsulate(workflowVariables, "storage")]).filter(property => property.type === "array")
                            )}
                            // onChange={v => onChange(property, (property.value || "") + " {" + v.join(".") + "}")}
                            onChange={(selected, s) => {
                                console.log({
                                    id: s[s.length - 1].id,
                                    path: selected.join('.')
                                })

                                const newWorkflow = updateStep(localWorkflow, {
                                    ...step,
                                    forEach: s[s.length - 1].id,
                                    forEachPath: selected.join('.')
                                })

                                console.log({ newWorkflow })
                                updateLocalWorkflow(newWorkflow)
                            }}
                            placeholder="Please select"
                            value=""
                        >
                            <EuiFormRow label=" ">
                                <EuiFieldText
                                    placeholder="data.users"
                                    value={step.forEach ? step.forEachPath : undefined}
                                    append={
                                        <EuiPopover
                                            button={<EuiButtonIcon iconType="arrowDown" aria-label="Popover" />}
                                            closePopover={() => { }}
                                        />
                                    }
                                    onChange={e => { }}
                                />
                            </EuiFormRow>
                        </Cascader>



                    </StepCard>

                    <td style={{ height: '100%', position: 'relative' }}>
                        {/* <Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-24px', top: '100px' }} /> */}
                        {/* <Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-24px', top: '126px' }} /> */}
                        <table className='workflowTable'><tbody><tr>

                            <Steps
                                steps={step.do}
                                sequenceId={step.sequenceId}
                                workflow={workflow}
                                availableMethods={availableMethods}
                                products={products}
                                updateWorkflow={updateWorkflow.bind(this)}
                                updateAvailableMethods={updateAvailableMethods}
                                inheritedVariables={[
                                    ...flatten({ parent: getParentProperties(availableVariables, step.forEach || ""), }),
                                    ...flatten({ current: getChildProperties(availableVariables, step.forEach || "") })
                                ]}
                                workflowVariables={workflowVariables}
                                databases={databases}
                            />
                        </tr></tbody></table>
                    </td>
                </tr>

            </tbody></table>
        </React.Fragment>
    }
}