import React from 'react';
import {
	EuiButton,
	EuiButtonIcon,
	EuiContextMenuPanel,
	EuiFieldText,
	EuiFlexGroup,
	EuiFlexItem, EuiFormRow, EuiIcon,
	EuiPopover,
	EuiSuperSelect,
	EuiCard,
	EuiEmptyPrompt,
	EuiTitle
} from "@elastic/eui";
import { Button, Cascader, Mentions, Select as AntSelect } from 'antd';
import { encapsulate, getProperties } from '@simplifier/normalization';
import { deleteStep, display, updateStep, countNextSteps, buildSelector } from '../../utils/workflows';
import StepCard from "../CardComponents";
import Steps from '../Steps';


const { Option } = Mentions





export default class IfStep extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			open: true
		}
	}

	componentDidMount2() {

		let timer;
		function debounce(callback) {
			return function () {
				var args = arguments;
				var context = this;
				clearTimeout(timer);
				timer = setTimeout(function () {
					callback.apply(context, args);
				}, 25)
			}
		}

		const stepId = this.props.step.uniqueId

		const nav = document.getElementById(stepId);
		const rectTop = nav.getBoundingClientRect().top;

		const parentNode = document.getElementById(stepId).parentNode.parentNode

		const f = debounce(function (e) {

			console.log(e)

			/*
				Un seul listener dans complexW
				A componentDidMount : stocker rectTop, parent.clientHeight, clientHight

				On scroll, pour chaque elt du dom, modifier marginTop
			*/



			try {
				if (window.pageYOffset > rectTop) {
					nav.style.marginTop = `${Math.max(Math.min(window.pageYOffset - rectTop + 30, parentNode.clientHeight - nav.clientHeight), 0)}px`
				} else {
					nav.style.marginTop = "0px"
				}

				console.log(window.pageYOffset, rectTop, parentNode.clientHeight, nav.clientHeight)
			} catch (error) {
				console.log(error)
			}
		})


		// nav.removeEventListener('scroll', f);
		window.addEventListener('scroll', f);
	}

	render() {

		const {
			step,
			localWorkflow,
			availableVariables,
			workflow,
			availableMethods,
			products,
			updateWorkflow,
			updateLocalWorkflow,
			updateAvailableMethods,
			databases,
			workflowVariables,
		} = this.props

		return <React.Fragment>

			<table className='workflowTable' style={{ height: '100%' }}><tbody>
				<tr style={{ height: '100%' }} className="sticky-block-content">

					{/* <td rowspan="2" valign="top" style={{ height: '100%', position: 'relative' }}> */}
					{/* <Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-25px', top: '150px' }} /> */}


					<StepCard
						arrow={<EuiIcon type="arrowRight" />}
						flex={this.state.open}
						iconsLeft={[]}
						iconsRight={[
							<EuiButtonIcon size="l" iconType={this.state.open ? "minimize" : "expand"} onClick={() => this.setState({ open: !this.state.open })} />,
							<EuiPopover
								id="contentPanel"
								anchorPosition="rightUp"
								button={<EuiButtonIcon size="l" iconType="trash" onClick={() => this.setState({ popoverOpen: !this.state.popoverOpen })} />}
								isOpen={this.state.popoverOpen}
								closePopover={() => this.setState({ popoverOpen: false })}
								panelPaddingSize="s"
							>
								<EuiContextMenuPanel>
									<EuiFormRow label="Are you sure?">
										<EuiButton
											onClick={() => {
												let newWorkflow = deleteStep(workflow, step)
												updateWorkflow(newWorkflow)
											}}
											iconType="trash"
											color="danger"
										>
											Delete that step
										</EuiButton>
									</EuiFormRow>
								</EuiContextMenuPanel>
							</EuiPopover>
						]}>

						<div className="sticky-container">

							<div className="sticky-block-content" id={step.uniqueId}>

								<EuiTitle size="s"><h3>Condition</h3></EuiTitle>

								<EuiFormRow label=" ">
									<EuiFieldText
										placeholder="{input.user.name}"
										// value={t""}
										onChange={e => { }}
									/>
								</EuiFormRow>


								<EuiFormRow label=" ">


									<EuiSuperSelect
										options={[
											{ value: "eq", inputDisplay: "Is equal to" },
											{ value: "notempty", inputDisplay: "Is not empty" },
											{ value: "contains", inputDisplay: "Contains" },
											{ value: "containsns", inputDisplay: "Contains (not case sensitive)" },
											{ value: "gt", inputDisplay: "Is greater than" },
											{ value: "st", inputDisplay: "Is smaller than" },
											{ value: "get", inputDisplay: "Is greater or equal to" },
											{ value: "set", inputDisplay: "Is smaller or equal to" },
											{ value: "diff", inputDisplay: "Is different from" },
											{ value: "defined", inputDisplay: "Is properly defined" }
										]}
										onChange={value => {
											let newStep = { ...step }
											newStep.if["type"] = value
											const newWorkflow = updateStep(localWorkflow, newStep)
											updateLocalWorkflow(newWorkflow)
										}}
										valueOfSelected={step.if.type || "eq"}
									/>

								</EuiFormRow>

								<EuiFormRow label=" ">
									<EuiFieldText
										placeholder="Ada Lovelace"
										// value={t""}
										onChange={e => { }}
									/>
								</EuiFormRow>

							</div>

						</div>

						{/* 
						<br />
						<br />
						<br /> */}

						{/* <Mentions
							style={{ width: '100%', marginBottom: 15 }}
							placeholder={"{ to select a first term variable"}
							size="large"
							prefix={['{']}
							onChange={value => {
								let newStep = { ...step }
								newStep.if["1"] = value
								const newWorkflow = updateStep(localWorkflow, newStep)
								updateLocalWorkflow(newWorkflow)
							}}
							value={step.if["1"] || ""}
						>
							{getProperties([...availableVariables, ...encapsulate(workflowVariables, "storage")])
								// .filter(property => property.type !== 'object' && property.type !== "array")
								.map(model => <Option key={model.path} value={model.path + "}"}>
									<strong style={{ color: "#293742" }}>{display(model.path)}</strong><br />
									<i style={{ color: "#495762" }}>{display(model.type)}{model.example ? <span>, Ex.: {model.example}</span> : <span></span>}</i><br />
								</Option>)}
						</Mentions>

						<AntSelect
							placeholder="Condition type"
							style={{ width: '100%', marginBottom: 15 }}
							size="large"
							onChange={value => {
								let newStep = { ...step }
								newStep.if["type"] = value
								const newWorkflow = updateStep(localWorkflow, newStep)
								updateLocalWorkflow(newWorkflow)
							}}
							value={step.if.type || "eq"}
						>
							<AntSelect.Option value="eq">Is equal to</AntSelect.Option>
							<AntSelect.Option value="notempty">Is not empty</AntSelect.Option>
							<AntSelect.Option value="contains">Contains</AntSelect.Option>
							<AntSelect.Option value="containsns">Contains (not case sensitive)</AntSelect.Option>
							<AntSelect.Option value="gt">Is greater than</AntSelect.Option>
							<AntSelect.Option value="st">Is smaller than</AntSelect.Option>
							<AntSelect.Option value="get">Is greater or equal to</AntSelect.Option>
							<AntSelect.Option value="set">Is smaller or equal to</AntSelect.Option>
							<AntSelect.Option value="diff">Is different from</AntSelect.Option>
							<AntSelect.Option value="defined">Is properly defined</AntSelect.Option>

						</AntSelect> */}

						{/* {
							!["notempty"].includes(step.if.type) && <Mentions
								style={{ width: '100%', marginBottom: 15 }} size="large" prefix={['{']}
								placeholder={"{ to select a second term variable"}
								value={step.if["2"] || ""}
								onChange={value => {
									let newStep = { ...step }
									newStep.if["2"] = value
									const newWorkflow = updateStep(localWorkflow, newStep)
									updateLocalWorkflow(newWorkflow)
								}}
							>
								{getProperties([...availableVariables, ...encapsulate(workflowVariables, "storage")])
									.filter(property => property.type !== 'object' && property.type !== "array")
									.map(model => <Option key={model.path} value={model.path + "}"}>
										<strong style={{ color: "#293742" }}>{display(model.path)}</strong><br />
										<i style={{ color: "#495762" }}>{model.type} ({model.example})</i>
									</Option>)}
							</Mentions>


						} */}
					</StepCard>

					<td style={{ height: '100%', position: 'relative' }}>
						{/* <Icon type="check" style={{ fontSize: '16px', width: 50, position: 'absolute', left: '-25px', top: '130px', color: 'green' }} /> */}
						<table className='workflowTable' style={{}}><tr>
							{this.state.open && <Steps
								steps={step.then.do}
								sequenceId={step.then.sequenceId}
								workflow={workflow}
								availableMethods={availableMethods}
								products={products}
								updateWorkflow={updateWorkflow.bind(this)}
								updateAvailableMethods={updateAvailableMethods}
								inheritedVariables={encapsulate(availableVariables, "parent")}
								workflowVariables={workflowVariables}
								databases={databases}
							/>}


							{!this.state.open && <><td rowspan="1" valign="top" style={{ display: 'table-cell', position: "relative", height: 325, verticalAlign: "top" }}>

								<div style={{ position: 'absolute', left: 8, top: 65 }}><EuiIcon type="arrowRight" /></div>
								<div style={{ position: 'absolute', left: 8, top: 240 }}><EuiIcon type="arrowRight" /></div>

								<EuiCard
									textAlign="center"
									onClick={() => this.setState({ open: true })}
									className=""
									style={{ scrollSnapAlign: "start", position: "relative", width: 147, height: 147, marginLeft: 32, overflow: "hidden" }}
								>
									<h3 style={{ marginTop: -15 }}><EuiIcon type="expand" size="xl" /></h3><br />
									<i>{countNextSteps(step.then.do)} steps</i>
								</EuiCard>

								<div style={{ width: "100%", height: 32 }}>&nbsp;</div>

								<EuiCard
									textAlign="center"
									onClick={() => this.setState({ open: true })}
									className=""
									style={{ scrollSnapAlign: "start", position: "relative", width: 147, height: 147, marginLeft: 32, overflow: "hidden" }}
								>
									<h3 style={{ marginTop: -15 }}><EuiIcon type="expand" size="xl" /></h3><br />
									<i>{countNextSteps(step.else.do)} steps</i>
								</EuiCard>
							</td></>
							}


						</tr></table>
					</td>
				</tr>

				{this.state.open &&

					<>

						<tr style={{ height: 32 }}><td>&nbsp;</td></tr>

						<tr>
							<td style={{ height: '100%', position: 'relative' }}>
								{/* <Icon type="close" style={{ fontSize: '16px', width: 50, position: 'absolute', left: '-25px', top: '130px', color: 'red' }} /> */}
								<table className='workflowTable'><tbody><tr>
									{this.state.open && <Steps
										steps={step.else.do}
										sequenceId={step.else.sequenceId}
										workflow={workflow}
										availableMethods={availableMethods}
										products={products}
										updateWorkflow={updateWorkflow.bind(this)}
										updateAvailableMethods={updateAvailableMethods}
										inheritedVariables={encapsulate(availableVariables, "parent")}
										workflowVariables={workflowVariables}
										databases={databases}
									/>}

									<td rowspan="1" valign="top" style={{ display: 'table-cell', position: "relative", height: 100, verticalAlign: "top" }}>
										<div style={{ position: 'absolute', left: 8, top: 130 }}><EuiIcon type="arrowRight" /></div>
									</td>

								</tr></tbody></table>
							</td>
						</tr>

					</>}

			</tbody></table>


		</React.Fragment >
	}
}