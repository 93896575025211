import React from 'react';
import api from '../api'

import { Link } from 'react-router-dom'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import { Spinner } from '@blueprintjs/core'

import DynForm from '../components/DynForm'

import { Layout } from 'antd'

export default class extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            setupId: "",
            authUrl: "",
            promptParameters: [],
            parametersSecuritySchema: {}
        }
    }

    async componentDidMount() {
        // Loader
        // SI pas de prompt redirect

        // this.setState({loading: true})


        const { setupId } = this.props.match.params
        console.log({ setupId })
        const { data } = await api('connectAction/getPrompts/' + setupId)
        const { promptParameters: rawPromptParameters, parametersSecuritySchema } = data

        console.log({ data })

        const promptParameters = rawPromptParameters.filter(parameter =>
            !parametersSecuritySchema[parameter].description
            || parametersSecuritySchema[parameter].description !== 'afterConsent'
        )

        const queryParameters = this.props.location.search ? JSON.parse('{"' + decodeURI(this.props.location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : {}

        if (queryParameters.redirect_uri) {
            localStorage.setItem('redirect_uri', queryParameters.redirect_uri)
        } else {
            localStorage.removeItem('redirect_uri')
        }

        this.setState({ setupId, promptParameters, parametersSecuritySchema })

        console.log('Before !promptParameters.length')

        if (setupId === "ck1qjoabnory90908pnyvjsbn" && queryParameters.store) {

            const { data: { hasAuthUrl, authUrl, authId } } = await api('connectAction/setPrompts/' + setupId, 'post', { prompts: {
                storeName: queryParameters.store
            }, query: queryParameters })
            localStorage.setItem('authId', authId)
            this.setState({ authUrl })
            console.log('3')
            window.location = authUrl
            this.setState({ loading: false })


        }

        else if (!promptParameters.length) {
            const { data: { hasAuthUrl, authUrl, authId } } = await api('connectAction/setPrompts/' + setupId, 'post', { prompts: {}, query: queryParameters })
            localStorage.setItem('authId', authId)
            this.setState({ authUrl })
            console.log('4')
            window.location = authUrl
            this.setState({ loading: false })
        } else {
            this.setState({ loading: false })
        }
    }

    async submitForm({ config: parameters }) {
        const { props: { match, history }, state: { setupId } } = this

        let prompts = parameters

        const queryParameters = this.props.location.search ? JSON.parse('{"' + decodeURI(this.props.location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : {}

        const { data: { hasAuthUrl, authUrl, authId } } = await api('connectAction/setPrompts/' + setupId, 'post', { prompts: prompts, query: queryParameters })

        localStorage.setItem('authId', authId)

        // alert(authId)

        this.setState({ authUrl })
        console.log('5')
        window.location = authUrl
    }

    render() {

        const { state: { promptParameters, parametersSecuritySchema, authUrl, setupId }, props } = this
        const queryParameters = this.props.location.search ? JSON.parse('{"' + decodeURI(this.props.location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : {}


        return <React.Fragment><div style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0, zIndex: 100000, backgroundColor: "white" }}>

            <Layout style={{ position: "relative", borderRadius: 5 }}><Layout.Content style={{ maxWidth: 800, backgroundColor: 'white', padding: 30, marginTop: 100, position: "absolute", left: "50%", textAlign: 'center', transform: "translate(-50%, 0)", borderRadius: 10 }}>


                {this.state.loading ? <p style={{ textAlign: "center" }}><span><br /><br /><h3 className="text-center"><Spinner /></h3></span><br /></p> : <React.Fragment />}

                {
                    (setupId !== "ck1qjoabnory90908pnyvjsbn" || !queryParameters.store) && <DynForm
                        promptVariables={promptParameters}
                        securitySchema={parametersSecuritySchema}
                        callback={this.submitForm.bind(this)}
                        buttonText="Connect"
                    />
                }

                {authUrl && !promptParameters.length && <p style={{ textAlign: "center" }}><a href={authUrl}><span><br /><br /><h3 className="text-center"><Spinner /></h3></span><br />Click here if you are not redirected</a></p>}

            </Layout.Content></Layout>
        </div></React.Fragment>
    }
}
