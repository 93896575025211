import React from 'react'
import inflect from 'i'
// import { NounInflector } from 'natural'
// import camelCase from 'camelcase'

const infl = inflect()


function camelCase(str) {
	return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
		return index === 0 ? word.toLowerCase() : word.toUpperCase();
	}).replace(/\s+/g, '');
}

// const nounInflector = new NounInflector()


const capitalize = s => {
	if (typeof s !== "string") return "";
	return s.charAt(0).toUpperCase() + s.slice(1);
};

function buildSelectorTree(properties) {
	function cleanPath(path) {
		return path.replace(/\.\./g, ".");
	}

	function displayRec(props) {
		let origins = props.map(p => cleanPath(p.path).split(".")[0]);
		let uniqueOrigins = [...new Set(origins)];

		return uniqueOrigins.map(origin => {
			let associatedProperty = props.filter(
				p => cleanPath(p.path).split(".")[0] === origin
			).length
				? props.filter(p => cleanPath(p.path) === origin)[0]
				: {};

			const getLabelproperty = function (pr, o) {
				if (!pr) return capitalize(o);
				if (pr.example) {
					return <span title={pr.description} id={pr.id}>
						{capitalize(pr.label)}<br />
						<i style={{ fontSize: 12 }}>Ex: {pr.example}</i>
					</span>
				}
				return <span title={pr.description} id={pr.id}>{capitalize(pr.label)}</span>;
			};

			return {
				...associatedProperty,
				label: getLabelproperty(associatedProperty, origin),
				path: origin,
				children: displayRec(
					props
						.filter(p => cleanPath(p.path).indexOf(`${origin}.`) === 0)
						.map(p => {
							let newProperty = { ...p };
							newProperty.path = cleanPath(newProperty.path).replace(`${origin}.`, "");
							return { ...newProperty };
						})
				)
			};
		});
	}

	return displayRec(properties);
}

const singularize = function (word) {
	let res = ""
	if (word === infl.singularize(word)) {
		res = "Current " + word + " item"
	}
	else {
		res = "Current " + infl.singularize(word)
	}

	console.log("CamCase of", res, "is", camelCase(res))

	return [res, camelCase(res)]

}

const getBlockById = function (id, availableMethods) {


	console.log("getBlockById", id, availableMethods)


	const blocks = availableMethods.filter(block => block._id === id)
	if (!blocks.length) return null
	return blocks[0]
}

const updateStepInput = function (workflow, stepId, input) {
	// console.log({ workflow })
	let newWorkflow = workflow
	function exploreAndUpdateSteps(steps) {
		for (let step of steps) {
			if (step.stepId === stepId) {
				step.input = input
			}

			if (step.ifelse || step.ifelse2) {
				step.then.do = exploreAndUpdateSteps(step.then.do)
				step.else.do = exploreAndUpdateSteps(step.else.do)
			}

			if (step.for || step.for2) {
				step.do = exploreAndUpdateSteps(step.do)
			}

			if (step.filter || step.filter2) {
				step.each.do = exploreAndUpdateSteps(step.each.do)
				step.empty.do = exploreAndUpdateSteps(step.empty.do)
			}

			if (step.encapsulation) {
				console.log("Encapsu", step.then.sequenceId)
				step.then.do = exploreAndUpdateSteps(step.then.do)
			}
		}

		return steps
	}

	newWorkflow.sequences = newWorkflow.sequences.map(sequence => {
		sequence.steps = exploreAndUpdateSteps(sequence.steps)
		return sequence
	})

	// console.log({ newWorkflow })

	return newWorkflow
}

const updateStep = function (workflow, newStep) {
	// console.log({ workflow })
	let newWorkflow = workflow
	function exploreAndUpdateSteps(steps) {
		for (let step of steps) {
			if (step.uniqueId === newStep.uniqueId) {
				// console.log("found step to update", step.uniqueId)
				for (let key in newStep) {
					step[key] = newStep[key]
				}
			}

			if (step.ifelse || step.ifelse2) {
				step.then.do = exploreAndUpdateSteps(step.then.do)
				step.else.do = exploreAndUpdateSteps(step.else.do)
			}

			if (step.for || step.for2) {
				step.do = exploreAndUpdateSteps(step.do)
			}

			if (step.encapsulation) {
				console.log("Encapsu", step.then.sequenceId)
				step.then.do = exploreAndUpdateSteps(step.then.do)
			}

			if (step.filter || step.filter2) {
				step.each.do = exploreAndUpdateSteps(step.each.do)
				step.empty.do = exploreAndUpdateSteps(step.empty.do)
			}
		}

		return steps
	}
	newWorkflow.sequences = newWorkflow.sequences.map(sequence => {
		sequence.steps = exploreAndUpdateSteps(sequence.steps)
		return sequence
	})

	// console.log({ newWorkflow })

	return newWorkflow
}

const deleteStep = function (workflow, stepToDelete) {

	console.log('Deleting step', stepToDelete)

	let newWorkflow = workflow
	function exploreAndUpdateSteps(steps) {
		let newSteps = []

		// console.log(steps)

		for (let step of steps) {
			// console.log(step.uniqueId, stepToDelete.uniqueId)

			if (step.ifelse || step.ifelse2) {
				// console.log("Exploring the if else", step.then.di, step.else.do)
				step.then.do = exploreAndUpdateSteps(step.then.do)
				step.else.do = exploreAndUpdateSteps(step.else.do)
			}

			else if (step.for || step.for2) {
				// console.log("Exploring the for loop", step.do)
				step.do = exploreAndUpdateSteps(step.do)
			}

			else if (step.encapsulation) {
				console.log("Encapsu", step.then.sequenceId)
				// console.log("Exploring the for loop", step.do)
				step.then.do = exploreAndUpdateSteps(step.then.do)
			}

			else if (step.filter || step.filter2) {
				// console.log("Exploring the filter", step.each.do, step.empty.do)
				step.each.do = exploreAndUpdateSteps(step.each.do)
				step.empty.do = exploreAndUpdateSteps(step.empty.do)
			}

			if (step.uniqueId !== stepToDelete.uniqueId) {
				// console.log("Exploring connector")
				newSteps.push(step)
			}

			else { console.log("deleting step", step) }
		}

		return newSteps
	}
	newWorkflow.sequences = newWorkflow.sequences.map(sequence => {
		sequence.steps = exploreAndUpdateSteps(sequence.steps)
		return sequence
	})

	return newWorkflow
}

const pushToSequence = function (workflow, sequenceId, newStep, position = Infinity) {
	console.log("Pusing to sequence", newStep, sequenceId)
	let newWorkflow = workflow
	function exploreAndUpdateSteps(id, steps) {
		for (let step of steps) {

			if (step.stepId) { continue; }

			if (step.ifelse || step.ifelse2) {
				step.then.do = exploreAndUpdateSteps(step.then.sequenceId, step.then.do)
				step.else.do = exploreAndUpdateSteps(step.else.sequenceId, step.else.do)
			}

			if (step.for || step.for2) {
				step.do = exploreAndUpdateSteps(step.sequenceId, step.do)
			}

			if (step.encapsulation) {
				console.log("Encapsu push", step.then.sequenceId)
				step.then.do = exploreAndUpdateSteps(step.then.sequenceId, step.then.do)
			}

			if (step.filter || step.filter2) {
				step.each.do = exploreAndUpdateSteps(step.each.sequenceId, step.each.do)
				step.empty.do = exploreAndUpdateSteps(step.empty.sequenceId, step.empty.do)
			}
		}

		// let newSteps = (sequenceId === id) ? [...steps].push({...newStep}) : [...steps]
		// let newSteps = (sequenceId === id) ? [...steps, newStep] : [...steps]

		if (sequenceId === id) {
			let newSteps = [...steps]
			newSteps.splice((position === Infinity) ? newSteps.length : position, 0, newStep)
			return newSteps
		}

		return [...steps]
	}

	newWorkflow.sequences = newWorkflow.sequences.map(sequence => {
		sequence.steps = exploreAndUpdateSteps(sequence.sequenceId, sequence.steps)
		return sequence
	})

	// console.log({ newWorkflow })

	return newWorkflow
}

function display(string) {

	if (!string) return ""

	return string.replace('body.', '').split('.')
		.map(s => s
			.replace(/([A-Z])/g, ' $1')
			.replace(/^./, function (str) { return str.toUpperCase(); })
		)
		.join(' → ')

	return string.replace(/\./g, " → ").toLowerCase()
		.replace(/([A-Z])/g, ' $1')
		.replace(/^./, function (str) { return str.toUpperCase(); })
}

function getAuths(workflow, availableMethods) {

	console.log('Update auths (utils)', workflow, availableMethods)

	let auth = {}
	function explore(steps) {
		// console.log({ steps })
		for (let step of steps) {

			if (step.ifelse || step.ifelse2) {
				explore(step.then.do)
				explore(step.else.do)
			}

			if (step.for || step.for2) {
				explore(step.do)
			}

			if (step.encapsulation) {
				console.log("Encapsu", step.then.sequenceId)
				explore(step.then.do)
			}

			if (step.filter || step.filter2) {
				explore(step.each.do)
				explore(step.empty.do)
			}

			if (step.stepId) {
				const block = getBlockById(step.id, availableMethods)

				if (block) {
					let product = block.product
					let scopes = block.scopes ? block.scopes.split(', ') : []

					if (workflow.auth[product._id]) {

						console.log({
							...workflow.auth[product._id].data,
							step1: workflow.auth[product._id].data.step1 || false,
							step2: workflow.auth[product._id].data.step2 || false,
							step3: workflow.auth[product._id].data.step3 || false,
							// client_id: workflow.auth[product._id].data.client_id || "",
							// client_secret: workflow.auth[product._id].data.client_secret || ""
						})

						auth[product._id] = {
							scopes: [...new Set([...workflow.auth[product._id].scopes, ...scopes])],
							product: block.product,
							data: {
								...workflow.auth[product._id].data,
								step1: workflow.auth[product._id].data.step1 || false,
								step2: workflow.auth[product._id].data.step2 || false,
								step3: workflow.auth[product._id].data.step3 || false,
								// client_id: workflow.auth[product._id].data.client_id || "",
								// client_secret: workflow.auth[product._id].data.client_secret || ""
							}
						}
					} else {
						console.log('Adding auth data!')
						auth[product._id] = {
							scopes: scopes,
							product: block.product,
							data: {
								step1: false,
								step2: false,
								step3: false,
								// client_id: "",
								// client_secret: ""
							}
						}
					}
				}
			}
		}

		return steps
	}

	// console.log({ workflow })

	workflow.sequences.map(sequence => explore(sequence.steps))
	// console.log({auth})
	return auth
}

function representPropertiesRecursively(properties, currentBase = null) {
	return (properties || []).filter(prop => prop.base === currentBase).map(prop => {

		let dataSource = representPropertiesRecursively(properties, prop.id)

		if (!dataSource.length) return {
			dataIndex: prop.id,
			key: prop.id,
			...prop,
			prop,
		}

		return {
			dataIndex: prop.id,
			key: prop.id,
			...prop,
			prop,
			children: representPropertiesRecursively(properties, prop.id)
		}
	})

}

const getAvailableVariables = function (currentStep, sequenceId, workflow) {

	function getParentSequences(leafSequenceId, workflow) {
		let fullPath = []

		function exploreChildren(sequence, path) {

			let steps = sequence.steps || sequence.do

			if (sequence.sequenceId === leafSequenceId) {
				fullPath = [...path, sequence.sequenceId]

			} else {
				for (let step of steps) {
					if (step.ifelse || step.ifelse2) {
						exploreChildren(step.then, [...path, step.then.sequenceId])
						exploreChildren(step.else, [...path, step.else.sequenceId])
					}

					if (step.for || step.for2) {
						exploreChildren(step, [...path, step.sequenceId])
					}

					if (step.encapsulation) {
						console.log("Encapsu", step.then.sequenceId)
						exploreChildren(step, [...path, step.then.sequenceId])
					}

					if (step.filter || step.filter2) {
						exploreChildren(step.each, [...path, step.each.sequenceId])
						exploreChildren(step.empty, [...path, step.empty.sequenceId])
					}
				}
			}
		}

		exploreChildren(workflow, [workflow.sequenceId])

		fullPath.pop()

		return fullPath.reverse()
	}

	console.log({ parentSeq: getParentSequences(sequenceId, workflow) })

	return []
}

const countNextSteps = function (steps) {
	let count = 0

	function exploreAndCount(steps) {
		for (let step of steps) {
			// if (step.uniqueId === uniqueId) {
			// 	for (let key in newStep) {
			// 		// step[key] = newStep[key]
			// 	}
			// }

			// console.log("Step", step)

			count++

			if (step.ifelse || step.ifelse2) {
				step.then.do = exploreAndCount(step.then.do)
				step.else.do = exploreAndCount(step.else.do)
			}

			if (step.for || step.for2) {
				step.do = exploreAndCount(step.do)
			}

			if (step.encapsulation) {
				step.then.do = exploreAndCount(step.then.do)
			}

			if (step.filter || step.filter2) {
				step.each.do = exploreAndCount(step.each.do)
				step.empty.do = exploreAndCount(step.empty.do)
			}
		}

		return steps
	}

	exploreAndCount(steps)

	return count
}

export {
	getBlockById,
	updateStepInput,
	pushToSequence,
	display,
	getAuths,
	updateStep,
	deleteStep,
	representPropertiesRecursively,
	getAvailableVariables,
	countNextSteps,
	singularize,
	buildSelectorTree
}