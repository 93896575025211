import { Spinner } from '@blueprintjs/core'
import { Avatar, Button, Card, Col, Icon, Input, List, Modal, PageHeader, Row, Tag } from 'antd'
import React from 'react'
import uuidv4 from 'uuid/v4'
import api from '../api'
import Step from '../components/Step'
import defaultSteps from '../utils/defaultSteps'
import { encapsulate, flatten } from '@simplifier/normalization'
import { getBlockById, pushToSequence, updateStepInput } from '../utils/workflows.js'
class SelectorModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showModal: false,
            search: "",
            isProductSelected: false,
            isLoading: true,
            connectors: [],
            blocks: []
        }
    }

    async showModal() {
        this.setState({ showModal: true })
        if (this.state.connectors.length) {
            this.setState({ isLoading: false })
        } else {
            const { data: { connectors } } = await api('products')

            console.log(connectors)

            this.setState({ connectors, isLoading: false })
        }
    }

    async openProduct(product) {
        this.setState({
            isProductSelected: product._id,
            isLoading: true,
            search: "",
            selectedProduct: product
        })


        const { data: { connector: { blocks } } } = await api(`products/${product._id}`)

        console.log(blocks)

        // const { blocks } = prod

        this.setState({ blocks, isLoading: false })
    }

    async insertBlock(block) {
        let newStep = {
            "uniqueId": uuidv4(),
            "stepId": uuidv4(),
            "id": block._id,
            "name": block.name,
            "action": block.action,
            "input": flatten(block.input)
        }

        let newWorkflow = pushToSequence(this.props.workflow, this.props.sequenceId, newStep)

        // console.log({ b: block })

        const av = await this.props.updateAvailableMethods(block)

        // console.log({ av })

        this.props.updateWorkflow(newWorkflow)
        this.props.onDone()

        this.setState({
            showModal: false,
            search: "",
            isProductSelected: false,
            isLoading: true,
            blocks: []
        })
    }

    display(string) {
        if (!string) return "Empty"
        return string.charAt(0).toUpperCase() + string.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1").substring(1)
    }

    render() {
        const { showModal, search, isProductSelected, isLoading, connectors, blocks, selectedProduct } = this.state


        console.log({ connectors })


        return <div>
            <Button block size="large" style={{ marginBottom: 10 }} onClick={this.showModal.bind(this)}>API Connector</Button>

            <Modal
                title="API Connectors"
                visible={showModal || false}
                width={1000}
                style={{ maxWidth: "95%" }}
                onOk={() => this.setState({ showModal: false, search: "" })}
                onCancel={() => this.setState({ showModal: false, search: "" })}
            >

                {
                    isLoading && <div>
                        {selectedProduct && <PageHeader
                            title={selectedProduct.name}
                            ghost={false}
                            style={{ padding: 0 }}
                        />}
                        <br /><br /><h3 className="text-center"><Spinner /></h3><br /><br />
                    </div>
                }


                {
                    !isLoading && isProductSelected && <div>
                        <PageHeader
                            title={selectedProduct.name}
                            ghost={false}
                            style={{ padding: 0 }}
                            onBack={() => this.setState({
                                search: "",
                                isProductSelected: false,
                                blocks: []
                            })}
                        /><br />
                        <Input
                            size="large"
                            value={search}
                            autoFocus required
                            placeholder={"Search among " + blocks.length + " blocks"}
                            onChange={e => this.setState({ search: e.target.value })}
                        /> <br /> <br />

                        {
                            blocks.length && [...new Set(blocks.filter(block => block.name.toLowerCase().indexOf(search ? search.toLowerCase() : '') > -1).map(block => block.resource))].map(resource => {
                                return <div key={resource}>
                                    <h2>{this.display(resource)}</h2>
                                    <List
                                        // grid={{ gutter: 16, xs: 1, }}
                                        itemLayout="horizontal"
                                        dataSource={blocks
                                            .filter(block => block.resource === resource)
                                            .filter(block => block.name.toLowerCase().indexOf(search ? search.toLowerCase() : '') > -1)}
                                        renderItem={block => {
                                            return <List.Item style={{ padding: 10 }}>
                                                <List.Item.Meta
                                                    title={block.name}
                                                    description={block.description}
                                                />
                                                <div>
                                                    {/* <a key={0} href={`/blocks/${block.id}`} target="_blank"><Button icon="link">Block settings</Button></a>&nbsp;&nbsp;&nbsp; */}
                                                    <Button type="primary" onClick={() => this.insertBlock(block)}>Insert</Button>
                                                </div>

                                            </List.Item>
                                        }}
                                    />
                                    <br />
                                </div>
                            })
                        }

                    </div>
                }



                {
                    !isLoading && !isProductSelected && <div>
                        <Input
                            size="large"
                            value={search}
                            autoFocus required
                            placeholder={"Search among " + connectors.length + " products"}
                            onChange={e => this.setState({ search: e.target.value })}
                        /> <br /> <br />

                        {/* {console.log(connectors)} */}

                        {connectors.length && <List
                            grid={{ gutter: 16, xs: 4, }}
                            pagination={{ pageSize: 20 }}
                            dataSource={connectors
                                .filter(product => product.methods && product.methods.length)
                                .filter(product => product.name)
                                .filter(product => product.name.toLowerCase().indexOf(search ? search.toLowerCase() : '') > -1)
                                .sort((a, b) => a.owner - b.owner)
                            }
                            renderItem={product => (
                                <List.Item>
                                    <Card
                                        hoverable
                                        style={{ textAlign: "center" }}
                                        onClick={async () => await this.openProduct(product)}
                                        cover={
                                            product.logo ?
                                                <Avatar shape="square" size={100} style={{ marginLeft: "auto", marginRight: "auto", marginTop: 10 }} src={product.logo} /> :
                                                <Avatar shape="square" size={100} style={{ marginLeft: "auto", marginRight: "auto", marginTop: 10 }}>{product.name}</Avatar>
                                        }
                                    >
                                        <Card.Meta title={product.name} description={product.methods.length + " actions"} />

                                        {product.owner === "public" ? <Tag color="#2db7f5" style={{ marginTop: 10 }}>Public</Tag> : <Tag color="#87d068" style={{ marginTop: 5 }}>Private</Tag>}
                                    </Card>
                                </List.Item>
                            )}
                        />}
                    </div>
                }
            </Modal>
        </div>
    }
}

export default class Steps extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showNewBlockCard: false,
            search: "",
        }
    }

    updateStepInput(stepId, input) {
        const newWorkflow = updateStepInput(this.props.workflow, stepId, input)
        this.props.updateWorkflow(newWorkflow)
    }

    getCurrentSequenceVariablesAvailable(currentStep) {

        // console.log(currentStep)


        let variables = []
        let i = 0
        let steps = this.props.steps

        for (let step of steps) {
            // console.log(step.stepId, currentStep.id)
            if (step.stepId && step.stepId === currentStep.stepId) return variables

            else if (step.uniqueId === currentStep.uniqueId) return variables

            else if (step.dictionary) {
                variables = [...variables, {
                    "id": uuidv4(),
                    "label": "Key",
                    "path": "dictionary.key",
                    "type": "string",
                    "base": null
                }, {
                    "id": uuidv4(),
                    "label": "Value",
                    "path": "dictionary.value",
                    "type": "string",
                    "base": null
                }]
            }

            else if (step.database) {
                // console.log('HAS A DB', step)
                if (step.action === "get") {
                    variables = [...variables, ...encapsulate(step.parameters, step.tableName + ".get")]
                }
                if (step.action === "list") {
                    let wrapperId = step.wrapperId
                    variables = [...variables, {
                        "id": uuidv4(),
                        "label": "listLength",
                        "path": step.tableName + ".listLength",
                        "type": "number",
                        "base": null
                    }, ...[{
                        "id": wrapperId,
                        "label": "list",
                        "path": step.tableName + ".list",
                        "type": "array",
                        "base": null
                    }, ...step.parameters.map(step => {
                        let newStep = { ...step }
                        if (!newStep.base) { newStep.base = wrapperId }
                        return newStep
                    })]]
                }
                if (step.action === "insert") {
                    let paramId = step.wrapperId
                    variables = [...variables, ...[{
                        "id": paramId,
                        "label": "ID",
                        "path": step.tableName + ".inserted.id",
                        "type": "string",
                        "base": null
                    }]]
                }
                if (step.action === "update") {
                    let paramId = step.wrapperId
                    variables = [...variables, ...[{
                        "id": paramId,
                        "label": "ID",
                        "path": step.tableName + ".updated.id",
                        "type": "string",
                        "base": null
                    }]]
                }
                if (step.action === "delete") {
                    let paramId = step.wrapperId
                    variables = [...variables, ...[{
                        "id": paramId,
                        "label": "ID",
                        "path": step.tableName + ".deleted.id",
                        "type": "string",
                        "base": null
                    }]]
                }
                if (step.action === "loginByEmail") {
                    let paramId = step.wrapperId
                    variables = [...variables, ...[{
                        "id": paramId,
                        "label": "Email",
                        "path": step.tableName + ".signedIn.email",
                        "type": "string",
                        "base": null
                    }]]
                }
                if (step.action === "deleteByUid") {
                    let paramId = step.wrapperId
                    variables = [...variables, ...[{
                        "id": paramId,
                        "label": "UID",
                        "path": step.tableName + ".deleted.uid",
                        "type": "string",
                        "base": null
                    }]]
                }
            }

            else if (!step.stepId) continue;


            else {
                // console.log({step: step, avBloks: this.props.availableMethods})
                let block = getBlockById(step.id, this.props.availableMethods) || { output: {} }
                variables = [...variables, ...encapsulate(flatten(block.output), step.action)]
            }

            // else variables[step.action] = getBlockById(step.id, this.props.availableMethods).output
        }

        return variables
    }

    addConnectorBlock() {
        this.setState({ blocksListModal: true })
    }



    addBlock(type) {
        let newStep = defaultSteps.get(type)
        let { workflow, sequenceId, updateWorkflow } = this.props

        let newWorkflow = pushToSequence(workflow, sequenceId, newStep)

        this.setState({ showNewBlockCard: false })
        updateWorkflow(newWorkflow)
    }

    render() {

        const { steps, workflow, availableMethods, inputScaffold, sequenceId, inheritedVariables, products, workflowVariables, databases } = this.props
        const { showNewBlockCard, search } = this.state
        // console.log(steps)

        return <React.Fragment>

            {
                steps.map(step => {
                    // console.log(step.id, availableMethods, getBlockById(step.id, availableMethods))
                    return <Step
                        step={step}
                        sequenceId={sequenceId}
                        key={uuidv4()}
                        block={getBlockById(step.id, availableMethods)}
                        products={products}
                        workflow={workflow}
                        updateStepInput={this.updateStepInput.bind(this)}
                        updateWorkflow={this.props.updateWorkflow.bind(this)}
                        availableMethods={availableMethods}
                        updateAvailableMethods={this.props.updateAvailableMethods}
                        availableVariables={[
                            ...inheritedVariables,
                            ...this.getCurrentSequenceVariablesAvailable(step)
                        ]}
                        workflowVariables={workflowVariables}
                        databases={databases}
                        availableEvents={this.props.availableEvents}
                        inputSequenceRank={this.props.inputSequenceRank}
                    />
                })
            }

            <td valign="top" style={{ position: 'relative', display: steps.filter(step => step.id || step.webhook || step.storage || step.database || step.dictionary || step.emit).length === steps.length ? 'block' : 'none' }}>


                <Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-25px', top: '150px' }} />

                <Button type="dashed" className='workflowCard' block style={{ display: !showNewBlockCard ? 'block' : 'none', backgroundColor: 'transparent' }} onClick={() => this.setState({ showNewBlockCard: true })}>
                    <Icon type="file-add" style={{ fontSize: 24 }} />
                    <h2>Add a step</h2>
                </Button>
            </td>

            <td valign="top"><Card className='workflowCard' style={{ backgroundColor: 'transparent', display: showNewBlockCard ? 'block' : 'none' }}>

                {/* {
                    JSON.stringify(workflow.sequences)
                } */}

                <SelectorModal
                    updateAvailableMethods={this.props.updateAvailableMethods}
                    workflow={workflow}
                    sequenceId={sequenceId}
                    updateWorkflow={this.props.updateWorkflow}
                    onDone={() => this.setState({ showNewBlockCard: false })}
                />

                <Row gutter={10}>
                    <Col span={12}><Button block size="large" style={{ marginBottom: 10 }} onClick={() => this.addBlock("ifelse")}>Condition</Button></Col>
                    <Col span={12}><Button block size="large" style={{ marginBottom: 10 }} onClick={() => this.addBlock("emit")}>Emit event</Button></Col>
                </Row>

                <Row gutter={10}>
                    <Col span={12}><Button block size="large" style={{ marginBottom: 10 }} onClick={() => this.addBlock("for")}>For each</Button></Col>
                    <Col span={12}><Button block size="large" style={{ marginBottom: 10 }} onClick={() => this.addBlock("filter")}>Filter</Button></Col>
                </Row>

                <Row gutter={10}>
                    <Col span={12}><Button block size="large" style={{ marginBottom: 10 }} onClick={() => this.addBlock("storage")}>Store user data</Button></Col>
                    <Col span={12}><Button block size="large" style={{ marginBottom: 10 }} onClick={() => this.addBlock("database")}>Database</Button></Col>
                </Row>

                <Row gutter={10}>
                    <Col span={12}><Button block size="large" style={{ marginBottom: 10 }} onClick={() => this.addBlock("dictionary")}>Dictionary</Button></Col>
                    <Col span={12}><Button block size="large" style={{ marginBottom: 10 }} onClick={() => this.addBlock("encapsulation")}>Run sequence</Button></Col>
                </Row>





                <Row gutter={10}>
                    <Col span={12}><Button block size="large" style={{ marginBottom: 10 }} type="primary" onClick={() => this.addBlock("webhook")}>Webhook</Button></Col>
                    <Col span={12}><Button block size="large" style={{ marginBottom: 10 }} type="primary" onClick={() => this.addBlock("response")}>Response</Button></Col>
                </Row>

                {/* <Button block size="large" style={{ marginBottom: 10 }} type="primary" onClick={this.addResponseBlock.bind(this)}>Send response</Button> */}
                {/* <Button block size="large" type="danger" onClick={() => this.addBlock("error")}>Error</Button> */}


            </Card>

            </td>

        </React.Fragment>
    }
}