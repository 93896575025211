import { EuiButton, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiLink, EuiSpacer, EuiText } from '@elastic/eui';
import React from 'react';

export default function (props) {
    const { product, updateProduct, complete, advancement } = props

    return <>
        <EuiFlexGroup>
            <EuiFlexItem>
                <EuiFormRow label="Product name" labelAppend={<EuiText size="xs">Required</EuiText>}>
                    <EuiFieldText fullWidth name="first" value={product.name} onChange={(e) => updateProduct(e.target.value, 'name')} />
                </EuiFormRow>

                <EuiSpacer size="l" />

                <EuiFormRow label="API Base URL" labelAppend={<EuiText size="xs">Required</EuiText>}>
                    <EuiFieldText fullWidth name="first" onChange={(e) => updateProduct(e.target.value, 'baseURL')} value={product.baseURL} />
                </EuiFormRow>

                <EuiSpacer size="l" />

                <EuiFormRow label="Logo URL" labelAppend={product.baseURL ? <EuiText size="xs"><EuiLink href="#" onClick={() => updateProduct("https://logo.clearbit.com/" + product.baseURL, 'logo')}>Get from Clearbit Logo API</EuiLink></EuiText> : undefined}>
                    <EuiFieldText fullWidth name="first" onChange={(e) => updateProduct(e.target.value, 'logo')} value={product.logo} />
                </EuiFormRow>

                <EuiSpacer size="l" />

                <EuiFormRow label="API documentation main page">
                    <EuiFieldText fullWidth name="first" placeholder="https://api.slack.com" onChange={(e) => updateProduct(e.target.value, 'auth', 'developerInstructions')} value={product.auth.developerInstructions} />
                </EuiFormRow>

            </EuiFlexItem>

        </EuiFlexGroup>

        <EuiSpacer size="l" />

        {
            advancement === "incomplete" && <EuiButton iconType="arrowRight" fill color="primary" onClick={() => complete()}>Next</EuiButton>
        }
    </>
}