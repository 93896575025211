import React from "react";
import { EuiCard, EuiFlexGroup, EuiFlexItem, EuiText, EuiTitle } from '@elastic/eui';

export default class StepCard extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {

    }

    render() {
        const { props } = this
        const { data } = this.props


        return <td rowspan={props.flex ? "3" : "1"} style={{ position: 'relative', verticalAlign: "top" }}>

            {props.arrow && <div style={{ position: 'absolute', left: 8, top: 150 }}>
                {props.arrow}
            </div>}

            <EuiCard
                textAlign="left"
                style={{
                    scrollSnapAlign: "start",
                    position: "relative",
                    width: 325,
                    height: props.flex ? "calc(100%)" : 325,
                    marginLeft: 32, paddingTop: 32,
                    overflow: "hidden",
                    // marginTop: 5, 
                    ...props.style
                }}
            footer={props.footer}
            >
                <div style={{
                    backgroundColor: "#fafbfd",
                    position: "absolute",
                    top: 0, left: 0, right: 0,
                    padding: 6,
                    paddingLeft: 16,
                    borderBottom: "1px solid #F3F4F6",
                    height: 36
                }}>

                    <EuiFlexGroup justifyContent="spaceBetween">
                        <EuiFlexItem grow={false}>
                            <EuiFlexGroup gutterSize="s">
                                {props.iconsLeft ? props.iconsLeft.map(icon => <EuiFlexItem>{icon}</EuiFlexItem>) : <>&nbsp;</>}
                            </EuiFlexGroup>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiFlexGroup gutterSize="s">
                                {props.iconsRight ? props.iconsRight.map(icon => <EuiFlexItem>{icon}</EuiFlexItem>) : <>&nbsp;</>}
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    </EuiFlexGroup>

                </div>

                {props.title && <EuiTitle size="s" style={{
                    // position: "absolute",
                    // top: 44
                }}><h3>{props.title}</h3></EuiTitle> }
                {props.description && <EuiText grow={false}><p>{props.description}</p></EuiText>}

                {/* {props.footer} */}
                {props.children}

            </EuiCard>
        </td>
    }
}