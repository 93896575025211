import { EuiButton, EuiFlexItem } from '@elastic/eui'
import { Badge, Modal } from 'antd'
import React from 'react'
import SchemaBuilder from './SchemaBuilder'

export default class SchemaBuilderModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            input: this.props.input || [],
            onUpdate: this.props.onUpdate || console.log
        }
    }

    render() {
        return <div>
            {/* <Button block size="large" type="default" style={{ marginBottom: 15 }} onClick={() => this.setState({ showModal: true })}>
                {this.state.normalizerEmpty && <Badge status="error" />} Edit data structure
            </Button> */}

            <EuiFlexItem grow={false}>
                <EuiButton onClick={() => this.setState({ showModal: true })}>
                {this.state.normalizerEmpty && <Badge status="error" />} Edit data structure
                </EuiButton>
            </EuiFlexItem>

            <Modal
                title="Edit data structure"
                width={"90%"}
                style={{ maxWidth: "95%" }}
                visible={this.state.showModal}
                onOk={() => {
                    console.log({ onOkInput: this.state.raw, input: this.state.input })
                    this.props.onUpdate(this.state.raw); this.setState({ showModal: false })
                }}
                onCancel={() => { this.setState({ showModal: false }) }}
            >

                <SchemaBuilder inputSchema={this.props.input || []} onUpdate={input => {
                    // console.log({updatingInputOfSchemaModal: input})
                    this.setState({ input, raw: input })
                    this.props.onUpdate(input);
                }} />

                {/* <ReactJson src={this.state.input} /> */}
            </Modal>
        </div>
    }
}