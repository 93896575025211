import { EuiButton, EuiButtonIcon, EuiContextMenuPanel, EuiFlexItem, EuiFormRow, EuiIcon, EuiPopover } from "@elastic/eui";
import React from 'react';
import { deleteStep, updateStep } from '../../utils/workflows.js';
import StepCard from "../CardComponents";
import DataStructureFlyout from '../DataStructureFlyout';

export default class ConnectorStep extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			empty: this.props.empty,
			inputMappingModal: false,
			outputVariablesModal: false,
			reloadingStep: false
		}
	}

	render() {
		const {
			step,
			block,
			localWorkflow,
			availableVariables,
			workflow,
			availableMethods,
			products,
			updateWorkflow,
			updateLocalWorkflow,
			updateAvailableMethods,
			workflowVariables
		} = this.props

		return <StepCard
			arrow={<EuiIcon type="arrowRight" />}
			title="Return HTTP response"

			iconsLeft={[]}
			iconsRight={[
				<EuiButtonIcon size="l" iconType="refresh" />,

				<EuiPopover
					id="contentPanel"
					anchorPosition="rightUp"
					button={<EuiButtonIcon size="l" iconType="trash" onClick={() => this.setState({ popoverOpen: !this.state.popoverOpen })} />}
					isOpen={this.state.popoverOpen}
					closePopover={() => this.setState({ popoverOpen: false })}
					panelPaddingSize="s"
				>
					<EuiContextMenuPanel>
						<EuiFormRow label="Are you sure?">
							<EuiButton
								onClick={() => {
									let newWorkflow = deleteStep(workflow, step)
									updateWorkflow(newWorkflow)
								}}
								iconType="trash"
								color="danger"
							>
								Delete that step
							</EuiButton>
						</EuiFormRow>
					</EuiContextMenuPanel>
				</EuiPopover>
			]}
			footer={<>
				<EuiFormRow>
					<EuiFlexItem grow={false}>
						<DataStructureFlyout
							text="Update data structure"
							input={step.properties}
							onUpdate={inputSchema => {
								let newStep = { ...step }
								newStep.properties = inputSchema
								const newWorkflow = updateStep(localWorkflow, newStep)
								updateLocalWorkflow(newWorkflow)
							}}
						/>
					</EuiFlexItem>
				</EuiFormRow>

				


				<EuiFormRow>
					<EuiFlexItem grow={false}>
						<EuiButton onClick={() => window.alert('Button clicked')}>
							Update data mapping
						</EuiButton>
					</EuiFlexItem>
				</EuiFormRow>

			</>}
			>
		</StepCard>
	}
}