import uuidv4 from 'uuid/v4'

const defaultSteps = {
    ifelse: {
        "uniqueId": "@UUID",
        "ifelse": true,
        "if": { "1": "", "2": "", "type": "eq" },
        "then": {
            "sequenceId": "@UUID",
            "do": []
        },
        "else": {
            "sequenceId": "@UUID",
            "do": []
        }
    },

    for: {
        "uniqueId": "@UUID",
        "for2": true,
        "forEach": "",
        "childName": "currentObject",
        "sequenceId": "@UUID",
        "do": []
    },

    filter: {
        "uniqueId": "@UUID",
        "filter": true,
        "forEach": "",
        "childName": "currentObject",
        "if": { "1": "", "2": "", "type": "eq" },
        "each": {
            "sequenceId": "@UUID",
            "do": []
        },
        "empty": {
            "sequenceId": "@UUID",
            "do": []
        }
    },

    storage: {
        "uniqueId": "@UUID",
        "storage": true,
        "key": "",
        "value": ""
    },

    database: {
        "uniqueId": "@UUID",
        "wrapperId": "@UUID",
        "database": true,
        "databaseId": "",
        "tableName": "",
        "action": "",
        "objectId": "",
        "listFilter": "",
        "parameters": []
    },

    error: {
        "uniqueId": "@UUID",
        "error": true,
        "errorCode": "500",
        "errorMessage": "Something wrong happened"
    },

    webhook: {
        "uniqueId": "@UUID",
        "webhook": true,
        "url": "",
        "properties": []
    },

    response: {
        "uniqueId": "@UUID",
        "return": true,
        "code": 200,
        "properties": []
    },

    dictionary: {
        "uniqueId": "@UUID",
        "dictionary": true,
        "type": "get",
        "key": "",
        "value": ""
    },

    encapsulation: {
        "uniqueId": "@UUID",
        "encapsulation": true,
        "sequencePath": "",
        "properties": [],
        "then": {
            "sequenceId": "@UUID",
            "do": []
        },
        "fail": {
            "sequenceId": "@UUID",
            "do": []
        }
    },

    emit: {
        "uniqueId": "@UUID",
        "emit": true,
        "event": null,
        "properties": []
    }
}

export default {
    get: type => {
        console.log({type})
        return JSON.parse(JSON.stringify(defaultSteps[type]).replace(/@UUID/g, () => uuidv4()))
    }
}