import { Button, Card, Icon, Mentions, Popconfirm, Select as AntSelect } from 'antd';
import React from 'react';
import { encapsulate, getProperties } from '@simplifier/normalization';
import { deleteStep, display, updateStep } from '../../utils/workflows.js';
import Steps from '../Steps';

const { Option } = Mentions

export default class IfStep extends React.Component {
	constructor(props) { super(props) }

	render() {
		const {
			step,
			localWorkflow,
			availableVariables,
			workflow,
			availableMethods,
			products,
			updateWorkflow,
			updateLocalWorkflow,
			updateAvailableMethods,
			databases,
			workflowVariables
		} = this.props

		return <React.Fragment>

			<table className='workflowTable' style={{ height: '100%' }}><tbody>
				<tr style={{ height: '100%' }}>

					<td rowspan="2" valign="top" style={{ height: '100%', position: 'relative' }}>
						<Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-25px', top: '150px' }} />
						<Card
							className='workflowCard'
							style={{ scrollSnapAlign: "start", height: 'calc(100% - 20px)' }}
							title="Check if..."
							extra={<Popconfirm
								title="Do you really want to delete this step?"
								onConfirm={() => {
									let newWorkflow = deleteStep(workflow, step)
									updateWorkflow(newWorkflow)
								}}
								okText="Yes"
								cancelText="No"
							>
								<Button size="small" shape="circle" icon="close" />
							</Popconfirm>}
						>
							{/* {step.uniqueId} */}

							<Mentions
								style={{ width: '100%', marginBottom: 15 }}
								placeholder={"{ to select a first term variable"}
								size="large"
								prefix={['{']}
								onChange={value => {
									let newStep = { ...step }
									newStep.if["1"] = value
									const newWorkflow = updateStep(localWorkflow, newStep)
									updateLocalWorkflow(newWorkflow)
								}}
								value={step.if["1"] || ""}
							>
								{getProperties([...availableVariables, ...encapsulate(workflowVariables, "storage")])
									// .filter(property => property.type !== 'object' && property.type !== "array")
									.map(model => <Option key={model.path} value={model.path + "}"}>
										<strong style={{ color: "#293742" }}>{display(model.path)}</strong><br />
										<i style={{ color: "#495762" }}>{display(model.type)}{model.example ? <span>, Ex.: {model.example}</span> : <span></span>}</i><br />
									</Option>)}
							</Mentions>

							<AntSelect
								placeholder="Condition type"
								style={{ width: '100%', marginBottom: 15 }}
								size="large"
								onChange={value => {
									let newStep = { ...step }
									newStep.if["type"] = value
									const newWorkflow = updateStep(localWorkflow, newStep)
									updateLocalWorkflow(newWorkflow)
								}}
								value={step.if.type || "eq"}
							>
								<AntSelect.Option value="eq">Is equal to</AntSelect.Option>
								<AntSelect.Option value="notempty">Is not empty</AntSelect.Option>
								<AntSelect.Option value="contains">Contains</AntSelect.Option>
								<AntSelect.Option value="containsns">Contains (not case sensitive)</AntSelect.Option>
								<AntSelect.Option value="gt">Is greater than</AntSelect.Option>
								<AntSelect.Option value="st">Is smaller than</AntSelect.Option>
								<AntSelect.Option value="get">Is greater or equal to</AntSelect.Option>
								<AntSelect.Option value="set">Is smaller or equal to</AntSelect.Option>
								<AntSelect.Option value="diff">Is different from</AntSelect.Option>
								<AntSelect.Option value="defined">Is properly defined</AntSelect.Option>

							</AntSelect>

							{
								!["notempty"].includes(step.if.type) && <Mentions
									style={{ width: '100%', marginBottom: 15 }} size="large" prefix={['{']}
									placeholder={"{ to select a second term variable"}
									value={step.if["2"] || ""}
									onChange={value => {
										let newStep = { ...step }
										newStep.if["2"] = value
										const newWorkflow = updateStep(localWorkflow, newStep)
										updateLocalWorkflow(newWorkflow)
									}}
								>
									{getProperties([...availableVariables, ...encapsulate(workflowVariables, "storage")])
										.filter(property => property.type !== 'object' && property.type !== "array")
										.map(model => <Option key={model.path} value={model.path + "}"}>
											<strong style={{ color: "#293742" }}>{display(model.path)}</strong><br />
											<i style={{ color: "#495762" }}>{model.type} ({model.example})</i>
										</Option>)}
								</Mentions>
							}


						</Card>
					</td>

					<td style={{ height: '100%', position: 'relative' }}>
						<Icon type="check" style={{ fontSize: '16px', width: 50, position: 'absolute', left: '-25px', top: '130px', color: 'green' }} />
						<table className='workflowTable'><tr>
							<Steps
								steps={step.then.do}
								sequenceId={step.then.sequenceId}
								workflow={workflow}
								availableMethods={availableMethods}
								products={products}
								updateWorkflow={updateWorkflow.bind(this)}
								updateAvailableMethods={updateAvailableMethods}
								inheritedVariables={encapsulate(availableVariables, "parent")}
								workflowVariables={workflowVariables}
								databases={databases}
								availableEvents={this.props.availableEvents}
								inputSequenceRank={this.props.inputSequenceRank}
							/>
						</tr></table>
					</td>
				</tr>

				<tr>
					<td style={{ height: '100%', position: 'relative' }}>
						<Icon type="close" style={{ fontSize: '16px', width: 50, position: 'absolute', left: '-25px', top: '130px', color: 'red' }} />
						<table className='workflowTable'><tbody><tr>
							<Steps
								steps={step.else.do}
								sequenceId={step.else.sequenceId}
								workflow={workflow}
								availableMethods={availableMethods}
								products={products}
								updateWorkflow={updateWorkflow.bind(this)}
								updateAvailableMethods={updateAvailableMethods}
								inheritedVariables={encapsulate(availableVariables, "parent")}
								workflowVariables={workflowVariables}
								databases={databases}
								availableEvents={this.props.availableEvents}
								inputSequenceRank={this.props.inputSequenceRank}
							/>
						</tr></tbody></table>
					</td>
				</tr>

			</tbody></table>


			<br /></React.Fragment>
	}
}