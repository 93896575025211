import { EuiBadge, EuiToolTip, EuiEmptyPrompt, EuiSelectable, EuiLoadingContent, EuiButton, EuiCard, EuiAvatar, EuiFieldSearch, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiPageContentBody, EuiPageContentHeader, EuiPageHeaderSection, EuiPagination, EuiPanel, EuiSpacer, EuiSuperSelect, EuiText, EuiTitle } from '@elastic/eui';
import { Typography } from 'antd';
import React from 'react';
import api from '../api';



const { Paragraph } = Typography;

const options = [
    {
        value: 'all', inputDisplay: 'Private & public',
        dropdownDisplay: (<>
            <strong>Private & public</strong>
            <EuiText size="s" color="subdued">
                <p className="euiTextColor--subdued">Everything we have in store.</p>
            </EuiText>
        </>),
    },
    {
        value: 'private', inputDisplay: 'Private',
        dropdownDisplay: (<>
            <strong>Private</strong>
            <EuiText size="s" color="subdued">
                <p className="euiTextColor--subdued">Connectors created by you and not available to other users.</p>
            </EuiText>
        </>),
    },
    {
        value: 'public', inputDisplay: 'Public',
        dropdownDisplay: (<>
            <strong>Public</strong>
            <EuiText size="s" color="subdued">
                <p className="euiTextColor--subdued">Connectors created and tested by our team.</p>
            </EuiText>
        </>),
    },
];


export default class Scenarios extends React.Component {
    constructor(props) {
        super(props)

        document.title = "Products — Simplifier"

        this.state = {
            products: [],
            categories: [],
            activePage: 0,
            loading: true,
            ownerFilter: "all",
            categoryFilter: "all",
            createProductLoader: false,
        }
    }

    async componentDidMount() {
        const { data: { products } } = await api('products')

        const categories = [{
            key: "all",
            label: "All",
            checked: "on"
        }, ...[...new Set(products.map(product => product.category || "none"))].map(cat => ({
            key: cat || "None",
            label: cat || "None",
            checked: undefined
        }))]

        this.setState({ products, loading: false, categories })
    }

    async updateSelectedCategories(selection) {
        if (!selection.filter(item => item.checked === "on").length) {
            selection[0].checked = "on"
        }

        this.setState({ categories: selection, categoryFilter: selection.filter(item => item.checked === "on")[0].key, activePage: 0 })
    }

    async updateSelectedOwner(value) {
        this.setState({ ownerFilter: value })
    }

    render() {
        const { products, search, activePage, loading, ownerFilter, categoryFilter } = this.state

        const productsToDisplay = [
            ...products.filter(p => p.owner === "private"),
            ...products.filter(p => p.owner === "public")
        ]
            .filter(product => product.name)
            .filter(product => {
                if (categoryFilter === "all") return true
                return categoryFilter === product.category
            })
            .filter(product => {
                if (ownerFilter === "all") return true
                return ownerFilter === product.owner
            })
            .filter(product => (product.name || "").toLowerCase().indexOf((search || "").toLowerCase()) > -1)

        const ITEMS_PER_PAGE = 20

        const PAGE_COUNT = parseInt(productsToDisplay.length / ITEMS_PER_PAGE) + 1

        const goToPage = pageNumber => {
            this.setState({ activePage: pageNumber })
        };

        return <EuiPageContentBody>
            <EuiPageContentHeader>
                <EuiPageHeaderSection>
                    <EuiTitle size="l">
                        <h1>Connectors</h1>
                    </EuiTitle>
                </EuiPageHeaderSection>
                <EuiPageHeaderSection>
                    <EuiButton iconType="plusInCircle" fill isLoading={this.state.createProductLoader} onClick={async () => {
                        this.setState({ createProductLoader: true })
                        const defaultProduct = { name: "", baseURL: "", auth: {}, logo: "" }
                        const { data: { createProduct: { id } } } = await api.post('products', defaultProduct)
                        this.setState({ createProductLoader: false })
                        this.props.history.push(`/products/${id}`)
                        return true
                    }}>
                        Create connector
                    </EuiButton>
                </EuiPageHeaderSection>
            </EuiPageContentHeader>


            <EuiSpacer size="l" />

            <EuiFieldSearch
                placeholder="Search..."
                value={search}
                onChange={e => this.setState({ search: e.target.value })}
                isClearable fullWidth
            />

            <EuiSpacer size="xl" />




            <EuiFlexGroup>
                <EuiFlexItem grow={false} style={{ minWidth: 300, height: 490 }} >

                    <EuiPanel paddingSize="l">
                        <EuiTitle size="m"><h3>Filter</h3></EuiTitle>

                        {/* <EuiToolTip
                        position="top"
                        className="pink"
                        content={
                            <p>Works on any kind of element &mdash; buttons, inputs, you name it!</p>
                        }>
                        <EuiButton
                            onClick={() => alert('Buttons are still clickable within tooltips.')}
                        >
                            Hover me
                        </EuiButton>
                    </EuiToolTip> */}

                        <EuiTitle size="s"><h4>Owner</h4></EuiTitle>

                        <EuiSpacer size="s" />

                        <EuiSuperSelect
                            fullWidth
                            options={options}
                            valueOfSelected={"all"}
                            onChange={this.updateSelectedOwner.bind(this)}
                            itemLayoutAlign="top"
                            hasDividers
                            valueOfSelected={ownerFilter}
                        />

                        <EuiSpacer size="l" />

                        <EuiTitle size="s"><h4>Category</h4></EuiTitle>

                        <EuiSpacer size="s" />

                        <EuiSelectable
                            options={this.state.categories}
                            listProps={{ bordered: false }}
                            singleSelection
                            height={500}
                            searchable
                            searchProps={{ 'data-test-subj': 'selectableSearchHere', }}
                            onChange={this.updateSelectedCategories.bind(this)}
                        >
                            {(list, search) => (
                                <>
                                    {search}
                                    {list}
                                </>
                            )}
                        </EuiSelectable>

                        <br />

                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>



                    {!loading && <EuiFlexGrid columns={4} gutterSize="l">
                        {productsToDisplay
                            .filter((item, i) => activePage * ITEMS_PER_PAGE <= i && (1 + activePage) * ITEMS_PER_PAGE > i)
                            .map((product, i) => <EuiFlexItem key={i}>
                                <EuiCard
                                    // layout="horizontal"
                                    title={product.name}
                                    description={<><EuiBadge color="hollow">{product.category}</EuiBadge></>}
                                    icon={product.logo ? <img src={product.logo} style={{ maxWidth: 70, marginBottom: -5 }} /> : <EuiAvatar size="xl" type="space" name="Not provided" initials="?" color="#D3DAE6" />}
                                    betaBadgeLabel={product.owner === "private" ? <span>private</span> : null}
                                    // betaBadgeTooltipContent={product.product.owner}
                                    onClick={() => this.props.history.push(`/products/${product.id}`)}
                                />
                            </EuiFlexItem>)
                        }
                    </EuiFlexGrid>}

                    {!loading && !!!productsToDisplay.length && <EuiEmptyPrompt
                        title={<h2>Connector not found</h2>}
                        actions={[<EuiButton iconType="plusInCircle" fill isLoading={this.state.createProductLoader} onClick={async () => {
                            this.setState({ createProductLoader: true })
                            const defaultProduct = { name: "", baseURL: "", auth: {}, logo: "" }
                            const { data: { createProduct: { id } } } = await api.post('products', defaultProduct)
                            this.setState({ createProductLoader: false })
                            this.props.history.push(`/products/${id}`)
                            return true
                        }}>
                            Create connector
                        </EuiButton>]}
                    />}

                    {loading && <EuiFlexGrid columns={4} gutterSize="l">
                        {[1, 2, 3, 4, 5, 6, 7]
                            .map((product, i) => <EuiFlexItem key={i}>
                                <EuiCard
                                    // layout="horizontal"
                                    title={<EuiLoadingContent lines={1} />}
                                    description={<EuiLoadingContent lines={3} />}
                                />
                            </EuiFlexItem>)
                        }
                    </EuiFlexGrid>}

                    <EuiSpacer size="l" />

                    {!!productsToDisplay.length && <EuiFlexGroup justifyContent="spaceAround">
                        <EuiFlexItem grow={false}>
                            <EuiPagination
                                pageCount={PAGE_COUNT}
                                activePage={activePage}
                                onPageClick={activePage => goToPage(activePage)}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>}


                </EuiFlexItem>
            </EuiFlexGroup>

        </EuiPageContentBody>

    }
}
