import React from 'react'
import { Button, Badge, Modal } from 'antd'
import SchemaBuilder from './SchemaBuilder'
import ReactJson from 'react-json-view'


export default class SchemaBuilderModal extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			input: this.props.input || [],
			onUpdate: this.props.onUpdate || console.log
		}
	}

	render() {
		return <div>
			<Button block size="large" type="default"  style={{ marginBottom: this.props.noMargin ? 0 : 15 }} onClick={() => this.setState({ showModal: true })}>
				{this.state.normalizerEmpty && <Badge status="error" />} Edit data structure
            </Button>
			
			<Modal
				title="Edit data structure"
				width={"90%"}
				style={{maxWidth: "95%"}}
				visible={this.state.showModal}
				onOk={() => {
					console.log({onOkInput: this.state.raw, input: this.state.input})
					if(this.props.onUpdate) this.props.onUpdate(this.state.raw); this.setState({ showModal: false })
					if(this.props.onOk) { this.props.onOk(this.state.raw) } 
				}}
				onCancel={() => { this.setState({showModal: false}) }}
			>
				
				<SchemaBuilder inputSchema={this.state.input || []} onUpdate={input => {
					// console.log({updatingInputOfSchemaModal: input})
					this.setState({ input, raw: input })
					if(this.props.onUpdate) this.props.onUpdate(input);
				} } />

				{/* <ReactJson src={this.state.input} /> */}
			</Modal>
		</div>
	}
}