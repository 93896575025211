import { Card, Col, List, PageHeader, Row, Statistic, Steps, Typography } from 'antd';
import React from 'react';
import api from '../api';
import ReactJson from 'react-json-view'
import TimeAgo from 'react-timeago';

const { Paragraph } = Typography
const { Step } = Steps;

const topics = [
	"prod-async-depiling", 
	"prod-sync-depiling", 
	"dev-async-depiling", 
	"dev-sync-depiling",
	"dev-sync-end", 
	"prod-sync-end", 
	"prod-acasi"
]


export default class Log extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            topics: {}
        }
    }

    async getStats() {

        setTimeout(async () => {
            const { data } = await api("stats/pulsar")
            this.setState({ topics: data })

            // console.log(data)

            this.getStats()
        }, 10000);


    }

    async componentDidMount() {
        this.getStats()
    }

    render() {

        const { topics } = this.state

        const gridStyle = {
            width: '25%',
            textAlign: 'center',
        }


        return <React.Fragment>

            <PageHeader
                title="Pulsar stats"
                style={{ backgroundColor: 'white' }}
            >
                <div className="wrap">
                    <div className="content">
                        <Paragraph>

                        </Paragraph>

                    </div>
                </div>
            </PageHeader>


            {Object.keys(topics).map(topicName => {

                const topic = topics[topicName]
                const subscriptions = topic.subscriptions || {}

                let consumers = []
                let subscriptionsObj = []

                for(let subscriptionName of Object.keys(subscriptions)) {
                    const conss = subscriptions[subscriptionName].consumers
                    subscriptionsObj.push(subscriptions[subscriptionName])
                    for(let cons of conss) {
                        consumers.push(cons)
                    }
                }

                // console.log({subscriptionsObj})

                // console.log(subscriptionsObj.reduce((acc, sub) => { return acc + sub.msgBacklog}, 0))

                return <Card title={topicName} style={{ margin: 15 }}>

                    <Row gutter={16}>
                        <Col span={8}>
                            <Statistic title="Message rate in" value={topic.msgRateIn} precision={3} />
                        </Col>
                        <Col span={8}>
                            <Statistic title="Message rate out" value={topic.msgRateOut} precision={3} />
                        </Col>
                        <Col span={8}>
                            {/* <Statistic title="Messages in" value={topic.msgInCounter} precision={0} /> */}
                            {/* <Statistic title="Messages out" value={topic.msgOutCounter} precision={0} /> */}
                            <Statistic title="Backlog size" value={subscriptionsObj.reduce((acc, sub) => { return acc + sub.msgBacklog}, 0)} precision={0} />
                        </Col>
                    </Row>

                    <List
                        itemLayout="horizontal"
                        dataSource={consumers}
                        renderItem={consumer => (
                            <List.Item

                            >
                                <List.Item.Meta
                                    title={<a href="https://ant.design">{consumer.consumerName}</a>}
                                    description={<>
                                        Connected since : <TimeAgo date={consumer.connectedSince} />&nbsp;
                                        — Unacked messages : {consumer.unackedMessages}&nbsp;
                                        {/* — Messages rate out : {consumer.msgRateOut} */}
                                        — Messages out : {consumer.msgOutCounter}
                                    
                                    </>}
                                />
                                <div>{(consumer.availablePermits) ? "✅" : "❌"}</div>
                            </List.Item>
                        )}
                    />


                    {/* <ReactJson src={topic} /> */}
                </Card>
            })}


        </React.Fragment >
    }
}
