import { Spinner } from "@blueprintjs/core";
import { Alert, Button, Card, Checkbox, Descriptions, Divider, Dropdown, Form, Icon, Input, Menu, message, PageHeader, Radio, Switch, Table, Tabs, Typography } from 'antd';
import 'brace/mode/json';
import 'brace/theme/solarized_dark';
import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import uuidV4 from 'uuid/v4';
import api from '../api';
import InputBlock from '../components/InputBlock';
import Steps from '../components/Steps';
import WorkflowLogs from '../components/WorkflowLogs';
import WorkflowUsers from '../components/WorkflowUsers';
import { encapsulate } from '@simplifier/normalization';
import { getAuths } from '../utils/workflows.js';

import ReactMarkdown from 'react-markdown'

const { Title, Paragraph, Text } = Typography;

export default class Workflow extends React.Component {
    constructor(props) {
        super(props)

        const defaultTab = (new URLSearchParams(window.location.search)).get('tab') || "builder"

        this.state = {
            persistingWorkflow: false,
            normalizerEmpty: true,
            availableMethods: [],
            authModes: [],
            inputScaffold: "{}",
            scaffold: {},
            showNewBlockCard: false,
            id: "",
            outputScaffold: "{}",
            defaultTab: defaultTab,
            currentTab: defaultTab,
            localWorkflow: null,

            workflowVariables: [],

            workflow: null,
            auth: {},
            products: [],

            mode: "dev",
            dev_url: "",
            prod_url: ""
        }

    }

    async componentDidMount() {
        const { workflowId } = this.props.match.params
        const { data } = await api(`workflows/${workflowId}`)
        const { data: authModes } = await api(`workflow/authModes/developerData`)

        console.log(data)


        const workflow = data
        const availableMethods = data.methods
        const databases = data.databases

        const { data: { eventStructures } } = await api(`events/project/${workflow.project._id}`)

        console.log({ eventStructures })

        console.log({ eventStructures })

        this.setState({
            availableMethods: availableMethods,
            workflow: workflow,
            auth: workflow.auth,
            availableEvents: eventStructures,

            authModes,

            blockIds: workflow.blockIds,

            localWorkflow: workflow,
            project: workflow.project,
            id: workflow._id,

            workflowVariables: data.variables,
            databases,

            mode: workflow.mode,
            dev_url: workflow.dev_url,
            prod_url: workflow.prod_url
        })
    }

    updateOutputSchema(input) {
        let workflow = this.state.workflow

        // console.log({ newOutSchema: input })

        workflow.outputSchema = input

        this.setState({ workflow })
    }


    updateWorkflow(newWorkflow) {

        this.setState({
            workflow: {
                ...newWorkflow,
                auth: getAuths(newWorkflow, this.state.availableMethods)
            },
            auth: getAuths(newWorkflow, this.state.availableMethods)
        })
    }

    async persistWorkflow() {
        const { id, workflow, availableMethods } = this.state

        this.setState({ persistingWorkflow: true })

        let newWorkflow = {
            ...workflow,
            auth: this.state.auth
        }

        await api(`workflows/${id}/update`, 'post', {
            workflow: newWorkflow,
            triggerDelay: workflow.delay,
            neededMethods: this.state.availableMethods.map(b => b._id),
            workflowVariables: this.state.workflowVariables,
            mode: this.state.mode,
            dev_url: this.state.dev_url,
            prod_url: this.state.prod_url
        })

        this.setState({ persistingWorkflow: false, workflow: newWorkflow })
    }

    updateData(productName, key, value) {
        let { workflow } = this.state
        let auth = workflow.auth || {}

        // console.log({ update: { productName, key, value } })

        auth[productName]['data'][key] = value
        workflow.auth = auth

        // console.log({ newAuth: workflow.auth })

        this.setState({ auth })
    }

    async updateAvailableMethods(block) {
        const { availableMethods } = this.state

        if (availableMethods) {
            console.log('updated value of method')
            this.setState({ availableMethods: [...availableMethods, block] })
            return [...availableMethods, block]
        } else {
            console.log("created value for method")
            this.setState({ availableMethods: [block] })
            return [block]
        }

    }

    render() {
        let { state: { workflow, project, persistingWorkflow, id, availableMethods, products, databases, authModes } } = this
        let { state: { persistWorkflow } } = this
        const { props } = this

        if (!workflow) {
            return <React.Fragment>
                <PageHeader
                    title={"Workflow"}
                    style={{ backgroundColor: 'white' }}
                >
                    <br /><br /><h3 className="text-center"><Spinner /></h3><br /><br />
                </PageHeader>
            </React.Fragment>
        }

        let auth = this.state.auth

        async function handleMenuClick(workflowId, value) {

            const { key } = value
            if (key === "duplicate") {
                const { data } = await api(`workflows/${workflowId}/duplicate`, 'post')

                console.log(data)
                // this.props.history.push(`/workflows/${id}`)
                message.success(<span>Workflow has been duplicated, <a href={"/workflows/" + data._id}>open</a></span>, 10);
            }

            if (key === "delete") {

                if (window.confirm("Are you sure you want to delete this workflow?")) {
                    const { data } = await api(`workflows/${workflowId}/delete`, 'post')
                    message.success(<span>Workflow has been deleted</span>);
                    props.history.push(`/${project._id}/scenarios`)
                }
            }
        }

        const menu = <Menu onClick={value => handleMenuClick(id, value)}>
            <Menu.Item key="duplicate">
                <Icon type="copy" />
                Duplicate
            </Menu.Item>
            <Menu.Item key="delete">
                <Icon type="delete" />
                Delete workflow
            </Menu.Item>
        </Menu>

        // const eventSequences = workflow.sequences.filter(s => s.type === "event")

        console.log({ auths: Object.keys(auth || {}) })

        return <React.Fragment>
            <PageHeader
                onBack={() => { if (project) { this.props.history.push(`/${project._id}/scenarios`) } }}
                title={"Integration builder"}
                style={{ backgroundColor: 'white' }}
                extra={[
                    <a key={0} href={`/workflows/${id}/doc`} target="_blank"><Button type="default" size="large" icon="link">Workflow documentation</Button></a>,



                    id === "28216bkx65av9u" ? <a key={1} href={`https://ytest.gitbook.io/libeo/zoho-invoice/introduction`} target="_blank"><Button type="default" size="large" icon="link">Zoho invoice integration documentation</Button></a> : <></>,
                    id === "28216bkx66q1xf" ? <a key={2} href={`https://ytest.gitbook.io/libeo/quickbooks/introduction`} target="_blank"><Button type="default" size="large" icon="link">Quickbooks integration documentation</Button></a> : <></>,
                    id === "7n1u5kx79rirp" ? <a key={3} href={`https://ytest.gitbook.io/libeo/xero/introduction`} target="_blank"><Button type="default" size="large" icon="link">Xero integration documentation</Button></a> : <></>,
                    id === "7n1u5kx7o4shk" ? <a key={4} href={`https://ytest.gitbook.io/libeo/factomos/introduction`} target="_blank"><Button type="default" size="large" icon="link">Factomos invoice integration documentation</Button></a> : <></>,





                    <Dropdown overlay={menu}>
                        <Button size="large">
                            Menu <Icon type="down" />
                        </Button>
                    </Dropdown>,


                    <Button key={1} type="primary" size="large" icon="save" onClick={this.persistWorkflow.bind(this)} loading={persistingWorkflow}>Save</Button>
                ]}
                footer={
                    <Tabs defaultActiveKey={this.state.defaultTab} onChange={value => {
                        this.setState({ currentTab: value })

                        const params = new URLSearchParams(window.location.search);

                        // console.log(params)
                        params.set('tab', value);
                        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
                    }}>
                        <Tabs.TabPane tab="Builder" key="builder"></Tabs.TabPane>
                        <Tabs.TabPane tab="Third party authentication" key="auth"></Tabs.TabPane>
                        <Tabs.TabPane tab="Users" key="users"></Tabs.TabPane>
                        <Tabs.TabPane tab="Logs" key="logs"></Tabs.TabPane>
                        <Tabs.TabPane tab="Settings" key="settings"></Tabs.TabPane>
                        {/* <Tabs.TabPane tab="Post user authentication workflow" key="4"></Tabs.TabPane> */}
                    </Tabs>
                }
            >

                <Descriptions bordered>
                    <Descriptions.Item label="Workflow description">
                        <Paragraph style={{ display: 'inline' }} editable={{
                            onChange: label => {
                                let newWorkflow = this.state.workflow
                                newWorkflow.name = label
                                this.updateWorkflow(newWorkflow)
                            }
                        }}>{workflow.name}</Paragraph>
                    </Descriptions.Item>
                </Descriptions>
            </PageHeader>

            <DragDropContext onDragEnd={console.log}>
                <div style={{ padding: 24, minHeight: 360, overflowX: "scroll", display: this.state.currentTab === "builder" ? 'block' : 'none' }} >

                    {/* {eventSequences.map(sequence => <p>Event sequence : {sequence.sequenceId} - {sequence.eventName}</p>)} */}

                    {
                        workflow.sequences.map((sequence, index) =>

                            <table style={{ scrollSnapType: "both mandatory" }} ><tbody><tr>
                                <InputBlock
                                    sequenceId={sequence.sequenceId}
                                    workflow={workflow}
                                    updateWorkflow={this.updateWorkflow.bind(this)}
                                    workflowId={id}
                                    workflowVariables={this.state.workflowVariables}
                                    availableEvents={this.state.availableEvents}
                                />

                                {!sequence.hide && <Steps
                                    available
                                    workflow={workflow}
                                    steps={sequence.steps}
                                    availableMethods={this.state.availableMethods}
                                    updateAvailableMethods={this.updateAvailableMethods.bind(this)}
                                    products={products}
                                    updateWorkflow={this.updateWorkflow.bind(this)}
                                    sequenceId={sequence.sequenceId}
                                    workflowVariables={this.state.workflowVariables}
                                    databases={databases}
                                    inheritedVariables={
                                        sequence.type === "event" && sequence.event ?
                                            encapsulate(sequence.event.input, "input") :
                                            encapsulate(sequence.inputSchema, "input")
                                    }
                                    availableEvents={this.state.availableEvents}
                                    inputSequenceRank={index}
                                />}

                            </tr></tbody>
                            </table>
                        )}


                    <Button type="dashed" className='workflowCard' block style={{ display: 'block', backgroundColor: 'transparent' }} onClick={() => {
                        let newSequence = {
                            "sequenceId": uuidV4(),
                            "type": "api",
                            "name": "default",
                            "apiPath": "",
                            "inputSchema": [],
                            "steps": []
                        }

                        let newWorkflow = workflow
                        newWorkflow.sequences.push(newSequence)

                        this.updateWorkflow(newWorkflow)
                    }}>
                        <Icon type="play-circle" style={{ fontSize: 24 }} />
                        <h2>Add a trigger</h2>
                    </Button>

                </div>
            </DragDropContext>

            <div style={{ padding: 24, minHeight: 360, overflowX: "scroll", display: this.state.currentTab === "auth" ? 'block' : 'none' }} >
                <Card>
                    <Title>Authentication configuration</Title>

                    {Object.keys(auth || {}).map(product => {
                        let productAuth = auth[product]

                        console.log({ product })

                        const modes = authModes.filter(authMode => authMode.slug === productAuth.product.auth.type)

                        const authMode = modes.length ? modes[0] : null

                        return <div key={product} style={{ marginTop: 25 }}>
                            <Title level={2}>{productAuth.product.name}{productAuth.product.auth.developerAuthInstructions && <> (<a href={productAuth.product.auth.developerAuthInstructions} target="_blank">Auth documentation</a>)</>}</Title>

                            {productAuth.product.auth.authenticationHelp &&

                                <Alert
                                    message="Authentication help"
                                    description={<ReactMarkdown source={productAuth.product.auth.authenticationHelp} />}
                                    type="info"
                                    showIcon
                                    style={{ marginBottom: 15 }}
                                />
                            }


                            <Paragraph><Switch checkedChildren={<Icon type="check" />} defaultChecked={productAuth.data.step1} onChange={value => this.updateData(product, "step1", value)} />&nbsp;&nbsp;&nbsp;Create an app on the {productAuth.product.auth.developerDashboard ? <><a href={productAuth.product.auth.developerDashboard} target="_blank">{productAuth.product.name} developer dashboard</a></> : <>{productAuth.product.name} developer dashboard</>}<br /></Paragraph>

                            {
                                productAuth.scopes.length ? <Paragraph>
                                    <Switch checkedChildren={<Icon type="check" />} defaultChecked={productAuth.data.step2} onChange={value => this.updateData(product, "step2", value)} />
                                    &nbsp;&nbsp;&nbsp;Add the following scopes : <span>{productAuth.scopes.map(scope => <Text key={scope} code>{scope}</Text>)}<br /></span>
                                </Paragraph> : <span></span>
                            }

                            <Paragraph><Switch checkedChildren={<Icon type="check" />} defaultChecked={productAuth.data.step3} onChange={value => this.updateData(product, "step3", value)} />&nbsp;&nbsp;&nbsp;Add the following redirect_uri : <Paragraph style={{ display: 'inline' }} code copyable>{api.endpoint + "workflow/callback/" + id}</Paragraph></Paragraph>



                            {
                                authMode && <>

                                    <Table bordered pagination={false} dataSource={
                                        authMode.developerDataSchema.map(param => {
                                            return {
                                                key: param.path,
                                                parameter: param.label,
                                                value: { id: param.path, label: param.label }
                                            }

                                        })} columns={[
                                            { title: "Parameter", dataIndex: "parameter", key: "parameter" },
                                            {
                                                title: 'Value', dataIndex: 'value', key: 'value',
                                                render: ({ id, label }) => <Input
                                                    key={id}
                                                    defaultValue={productAuth.data[id]}
                                                    onChange={e => this.updateData(product, id, e.target.value)}
                                                />
                                            }
                                        ]}
                                    />

                                </>
                            }

                        </div>

                    })

                    }

                    {
                        !Object.keys(auth || {}).length && <div></div>
                    }

                </Card>
            </div>

            <div style={{ padding: 24, minHeight: 360, overflowX: "scroll", display: this.state.currentTab === "users" ? 'block' : 'none' }} >
                <WorkflowUsers workflowId={id} history={this.props.history} workflowVariables={this.state.workflowVariables} setWorkflowVariables={inputSchema => this.setState({ workflowVariables: inputSchema })} />
            </div>

            <div style={{ padding: 24, minHeight: 360, overflowX: "scroll", display: this.state.currentTab === "logs" ? 'block' : 'none' }} >
                <WorkflowLogs workflowId={id} history={this.props.history} workflowVariables={this.state.workflowVariables} />
            </div>

            <div style={{ padding: 24, minHeight: 360, overflowX: "scroll", display: this.state.currentTab === "settings" ? 'block' : 'none' }} >
                <Card>
                    <Title>Workflow settings</Title>

                    <Title level={3}>Webhook host</Title>

                    <p>You can use "{"{host}"}" in the webhook block url. {"{host}"} will be replaced with the provided value, depening on the execution mode (development/production).</p>

                    <Paragraph>

                        Current mode : <Switch
                            checkedChildren={"Production"}
                            unCheckedChildren={"Development"}
                            defaultChecked={this.state.mode === "prod"} onChange={checked => {
                                if (checked) {
                                    this.setState({ mode: "prod" })
                                } else {
                                    this.setState({ mode: "dev" })
                                }
                            }} />
                    </Paragraph>

                    <Table bordered pagination={false} dataSource={
                        [{
                            key: "dev",
                            parameter: this.state.mode === "prod" ? "Development URL" : "✅ Development URL",
                            value: { id: "dev", label: "Development URL", ph: "http://localhost:8080" }
                        }, {
                            key: "prod",
                            parameter: this.state.mode === "dev" ? "Production URL" : "✅ Production URL",
                            value: { id: "prod", label: "Production URL", ph: "https://www.mysite.com" }
                        }]} columns={[
                            { title: "Parameter", dataIndex: "parameter", key: "parameter" },
                            {
                                title: 'Value', dataIndex: 'value', key: 'value',
                                render: ({ id, label, ph }) => <Input
                                    key={id}
                                    defaultValue={this.state[id + "_url"]}
                                    onChange={(e) => this.setState({ [id + "_url"]: e.target.value })}
                                    placeholder={ph}
                                />
                            }
                        ]}
                    />


                </Card>
            </div>

        </React.Fragment>

    }
}