import { Button, Col, Form, Input, PageHeader, Row, Typography, Spin } from 'antd';
import React from 'react';
import api from '../api';
import SchemaBuilder from '../components/SchemaBuilder';

const { Paragraph, Title } = Typography

export default class Databases extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            database: {},
            loadingSave: false,
            loading: false,
            loadingFetch: false
        }
    }

    async componentDidMount() {
        const { databaseId, table } = this.props.match.params
        this.setState({ loading: true })


        const { data: database } = await api(`databases/${databaseId}/${table}`)
        this.setState({ database, loading: false })
    }

    render() {
        const { databaseId } = this.props.match.params
        const { database } = this.state

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        }

        let table = database.tables ? database.tables[0] : {}

        return <React.Fragment>

            <PageHeader
                title={table.name || "Table"}
                subTitle={database.parameters ? database.parameters.type : "Loading"}
                style={{ backgroundColor: 'white' }}
                onBack={() => this.props.history.push(`/databases`)}
                extra={<>
                    {<Button loading={this.state.loadingFetch} type="default" disabled={!database.parameters} size="large" onClick={async () => {
                        this.setState({ loadingFetch: true })
                        const { data } = await api(`databases/${databaseId}/${this.props.match.params.table}/fetch-example`, 'get', { table })
                        let db = this.state.database
                        database.tables[0].fields = data
                        console.log(db)
                        this.setState({ database: db, loadingFetch: false })
                    }}>
                        Fetch from online examples
                </Button>}

                    {!this.state.loading && <Button loading={this.state.loadingSave} type="primary" disabled={!database.parameters} size="large" onClick={async () => {
                        this.setState({ loadingSave: true })
                        await api(`databases/${databaseId}/${this.props.match.params.table}`, 'put', { table })
                        this.setState({ loadingSave: false })
                    }}>
                        Save
                </Button>}
                    {this.state.loading && <Spin size="large" style={{ marginLeft: "auto", marginRight: "auto" }} />}
                </>}
            >
                <div className="wrap">
                    <div className="content">
                    </div>
                </div>
            </PageHeader>



            {table.fields && <div style={{ margin: 24, padding: 24, minHeight: 360, borderRadius: 5, backgroundColor: "white" }}>

                <Title level={2}>Settings</Title>

                <Row>
                    <Col xs={2} sm={4} md={6} lg={6} xl={6}>&nbsp;</Col>
                    <Col xs={20} sm={16} md={12} lg={12} xl={12}>

                        <Form {...formItemLayout} onSubmit={e => e.preventDefault()} className="login-form">

                            <Form.Item label="Table name">
                                <Input placeholder="Table name" value={table.name} onChange={e => {
                                    this.setState({
                                        database: {
                                            ...this.state.database,
                                            tables: [{
                                                ...table,
                                                name: e.target.value
                                            }]
                                        }
                                    })
                                }} />
                            </Form.Item>




                            {(table.parameters || []).map(parameter => <Form.Item label={parameter.label}>
                                <Input placeholder={parameter.example} value={parameter.value} onChange={e => {
                                    let newParameter = table.parameters.filter(param => param.id === parameter.id)[0]
                                    newParameter.value = e.target.value
                                    this.setState({
                                        database: {
                                            ...this.state.database,
                                            tables: [{
                                                ...table,
                                                parameters: table.parameters
                                            }]
                                        }
                                    })
                                }} />
                            </Form.Item>)}
                        </Form>

                    </Col>
                    <Col xs={2} sm={4} md={6} lg={6} xl={6}>&nbsp;</Col>
                </Row>

                <Title level={2}>Parameters</Title>


                {this.state.loadingFetch && <p>Deducting from online examples... It may take a few seconds.</p>}


                {!this.state.loadingFetch && <SchemaBuilder inputSchema={table.fields} onUpdate={value => this.setState({
                    database: {
                        ...this.state.database,
                        tables: [{
                            ...table,
                            fields: value
                        }]
                    }
                })} />}

                {/* <ReactJson src={database} /> */}
            </div>}
        </React.Fragment>
    }
}
