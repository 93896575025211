import { Button, Col, Collapse, Descriptions, Divider, message, Row, Steps, Table, Tooltip } from 'antd';
import React from 'react';
import ReactJson from 'react-json-view';
import TimeAgo from 'react-timeago';
import api from '../api';

const { Panel } = Collapse;
const { Step } = Steps

const cap = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export default class Log extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            log: props.log,
            selectedStep: 0,

        }
    }

    render() {

        const { log, selectedStep } = this.state

        console.log(log)

        if (log.sequence && log.sequence.type === "newUser") {
            return <div>

                <Descriptions
                    title="New user connected to integration"
                    bordered
                    column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                >
                    <Descriptions.Item label="Environment">{log.env}</Descriptions.Item>
                    <Descriptions.Item label="Started"><TimeAgo date={log.startTime} /></Descriptions.Item>
                    <Descriptions.Item label="Trigger">{log.sequence.type}</Descriptions.Item>
                    <Descriptions.Item label="Workflow">{log.workflowId}</Descriptions.Item>
                    <Descriptions.Item label="User">{log.user}</Descriptions.Item>
                    <Descriptions.Item label="New user">{log.userCreated ? "Yes" : "No"}</Descriptions.Item>

                </Descriptions>

                <br />

                <h2>Accesses granted</h2>

                <br />

                {log.accesses.map(access => {
                    return <>
                        <Descriptions
                            bordered
                        // column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        >
                            <Descriptions.Item label="Product">{access.productName}</Descriptions.Item>
                            <Descriptions.Item label="Type">{access.authType}</Descriptions.Item>
                        </Descriptions>

                        <br />

                        <Descriptions bordered>
                            <Descriptions.Item label="Result"><ReactJson src={access.parameters} /></Descriptions.Item>
                        </Descriptions>

                        <Divider />

                    </>
                })}


                {/* <ReactJson src={log} /> */}
            </div>
        }



        const currentStep = log ? log.steps[selectedStep - 1] : {}
        let currentTimestamp = log.startTime

        const times = []

        let lastStep = {
            end: (new Date(log.startTime)).getTime()
        }

        for (let step of log.steps) {

            times.push({
                start: (new Date(lastStep.end)).getTime(),
                end: (new Date(step.start)).getTime(),
                name: "routing",
                color: "#EFF1F2"
            })

            times.push({
                start: (new Date(step.start)).getTime(),
                end: (new Date(step.end)).getTime(),
                name: step.type,
                color: "#40A9FF"
            })

            lastStep = step
        }

        times.push({
            start: (new Date(lastStep.end)).getTime(),
            end: (new Date(log.endTime)).getTime(),
            name: "ending execution",
            color: "#52C41A"
        })

        const totalTimes = log.endTime - log.startTime

        // console.log(selectedStep, currentStep)

        return <>

            {/* <Button
                type="primary"
                style={{ float: "right" }}
                loading={this.state.loadingReplay}
                onClick={async () => {
                    this.setState({ loadingReplay: true })

                    const { data: { requestId } } = await api.post('logs/replay/' + log._id)

                    this.setState({ loadingReplay: false })


                    message.info(<>Execution started. <a key={0} href={`http://localhost:3000/logs?requestId=${requestId}`} target="_blank">Open new log</a></>)

                }}
            >
                Replay
        </Button> */}


            <Descriptions
                title="Request logs"
                bordered
                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
                <Descriptions.Item label="Environment">{log.env}</Descriptions.Item>
                <Descriptions.Item label="Started"><TimeAgo date={log.startTime} /></Descriptions.Item>
                <Descriptions.Item label="Duration">{log.endTime - log.startTime}ms</Descriptions.Item>
                <Descriptions.Item label="Trigger">{log.sequence.type}</Descriptions.Item>
                <Descriptions.Item label="Workflow">{log.workflowId}</Descriptions.Item>
                <Descriptions.Item label="User">{log.user}</Descriptions.Item>

            </Descriptions>

            {/* {log._id} */}

            <br />

            <div style={{ width: "100%", height: 40, border: "1px solid #E2E2E2", borderRadius: 5, overflow: "hidden" }}>
                {times.map(time => {
                    let length = 100 * (time.end - time.start) / totalTimes
                    // console.log(length)
                    return <Tooltip placement="top" title={<span>{cap(time.name)}<br />{time.end - time.start}ms<br /></span>}>
                        <div style={{ display: "inline-block", height: "100%", width: length + "%", background: time.color }}></div>
                    </Tooltip>
                })}
            </div>


            <br /> <br />

            <Row>
                <Col span={19} push={5}>

                    {selectedStep === 0 && <>
                        <h3>Input data</h3>
                        <ReactJson src={log.input} />
                    </>}

                    {currentStep && ["connector"].includes(currentStep.type) && <> <Connector currentStep={currentStep} /> </>}
                    {currentStep && ["return"].includes(currentStep.type) && <> <ReactJson src={currentStep} /> </>}
                    {currentStep && ["filter", "filter2"].includes(currentStep.type) && <> <Filter currentStep={currentStep} /> </>}
                    {currentStep && ["for", "for2"].includes(currentStep.type) && <> <For currentStep={currentStep} /> </>}
                    {currentStep && ["ifelse", "ifelse2"].includes(currentStep.type) && <> <ReactJson src={currentStep} /> </>}
                    {currentStep && ["webhook"].includes(currentStep.type) && <> <Webhook currentStep={currentStep} /> </>}
                    {currentStep && ["storage"].includes(currentStep.type) && <> <ReactJson src={currentStep} /> </>}
                    {currentStep && ["database"].includes(currentStep.type) && <Database currentStep={currentStep} />}
                    {currentStep && ["emptyReturn"].includes(currentStep.type) && <> <ReactJson src={currentStep} /> </>}

                </Col>
                <Col span={5} pull={19}>
                    <Steps progressDot current={selectedStep} onChange={(s) => this.setState({ selectedStep: s })} direction="vertical">

                        <Step title={"Trigger"} description={log.sequence.type} />

                        {log.steps.map(step => {
                            let type = step.type
                            if (type === "database" && step.base && step.base.type) type = `${type} (${step.base.type})`
                            return <Step title={cap(type)} description={new Date(step.end) - new Date(step.start) + "ms"} />
                        })}
                    </Steps>
                </Col>
            </Row>
        </>
    }
}


function Database(props) {
    const { currentStep: step } = props
    const keyValueColumns = [{ title: "key", dataIndex: "key", key: "key", }, { title: "value", dataIndex: "value", key: "value", }]

    if (step.action === "get") {
        const input = JSON.parse(step.input || {})
        const output = JSON.parse(step.output || {})[step.table][step.action]

        const dataSource = Object.keys(output).map(key => ({ key, value: output[key] }))

        return <>
            <Descriptions
                title="Database"
                bordered
                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
                <Descriptions.Item label="Database name">{step.base.name}</Descriptions.Item>
                <Descriptions.Item label="Database type">{step.base.type}</Descriptions.Item>
                <Descriptions.Item label="Table">{step.table}</Descriptions.Item>
                <Descriptions.Item label="Action">{step.action}</Descriptions.Item>
                <Descriptions.Item label="Object ID">{step.objectId}</Descriptions.Item>

            </Descriptions>

            <br />

            <Table size="small" pagination={false} dataSource={dataSource} columns={keyValueColumns} />

        </>
    }

    return <>

        <Descriptions
            title="Database"
            bordered
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        >
            {step.base && <>
                <Descriptions.Item label="Database name">{step.base.name}</Descriptions.Item>
                <Descriptions.Item label="Database type">{step.base.type}</Descriptions.Item>
            </>}
            <Descriptions.Item label="Table">{step.table}</Descriptions.Item>
            <Descriptions.Item label="Action">{step.action}</Descriptions.Item>

        </Descriptions>

        <br />


        <ReactJson src={step} />
    </>

}

function Webhook(props) {
    let data = {}


    try {
        data = JSON.parse(props.currentStep.data)
    } catch (error) {
        console.log(error)
        data = {}
    }

    return <>
        <h3>Sending webhook</h3>

        URL : {props.currentStep.url}<br /><br />

        <ReactJson src={data} />
    </>
}

function For(props) {
    let data = []
    try {
        data = JSON.parse(props.currentStep.children)
    } catch (error) {
        console.log(error)
        data = []
    }

    return <>
        <Collapse>
            {(data || []).map((child, i) => {
                let name = "Item " + i
                if (Object.values(child).filter(v => typeof v === "string").length) { name = Object.values(child).filter(v => typeof v === "string")[0] }

                return <Panel header={name} key={"" + i}>
                    <ReactJson src={child} />
                </Panel>
            })}
        </Collapse>
    </>
}

function Filter(props) {
    let data = []
    try {
        data = JSON.parse(props.currentStep.childrenToExecute)
    } catch (error) {
        console.log(error)
        data = []
    }

    return <>
        <Collapse>
            {data.map((child, i) => {
                let name = "Item " + i
                if (Object.values(child).filter(v => typeof v === "string").length) { name = Object.values(child).filter(v => typeof v === "string")[0] }

                return <Panel header={name} key={"" + i}>
                    <ReactJson src={child} />
                </Panel>
            })}
        </Collapse>
    </>
}

function Connector(props) {
    let input = {}
    let output = {}
    let error = {}


    try {
        input = JSON.parse(props.currentStep.input)
        output = JSON.parse(props.currentStep.response)
        error = JSON.parse(props.currentStep.error)
    } catch (error) {
        console.log(error)
    }

    return <>
        <h3>Connector : {props.currentStep.label}</h3>

        <h4>Input</h4>
        <ReactJson src={input} />


        <h4>Output</h4>
        <ReactJson src={output} />


        <h4>Errors</h4>
        <ReactJson src={error} />
    </>
}

