import { EuiCard, EuiFlexGroup, EuiFlexItem, EuiButton, EuiText, EuiButtonIcon, EuiSuperSelect, EuiFieldText, EuiFormRow, EuiPopover, EuiContextMenuPanel, EuiKeyPadMenu, EuiKeyPadMenuItem, EuiTitle, EuiIcon } from '@elastic/eui';
import { Button, Card, Form, Icon, Input, Mentions, Select as AntSelect, Select, Typography } from 'antd';
import 'brace/mode/json';
import 'brace/theme/solarized_dark';
import React from 'react';
import uuidV4 from 'uuid/v4';
import SchemaModal from '../components2/SchemaModal';
import { getProperties } from '@simplifier/normalization';
import { display, countNextSteps } from '../utils/workflows.js';

import StepCard from './CardComponents'

const { Title, Paragraph, Text } = Typography;

const { Option } = Mentions
let timer;
function debounce(callback) {
    return function () {
        var args = arguments;
        var context = this;
        clearTimeout(timer);
        timer = setTimeout(function () {
            callback.apply(context, args);
        }, 100)
    }
}

export default class InputBlock extends React.Component {
    constructor(props) {
        super(props)
        this.sequence = this.getSequence(this.props.workflow, this.props.sequenceId)
        this.state = {
            apiPath: this.sequence.apiPath,
            inputSchema: this.sequence.inputSchema,
            hide: this.sequence.hide,
            popoverOpen: false,
            open: true
        }
    }

    changeTriggerMode(triggerMode) {
        let { workflow, sequenceId, updateWorkflow } = this.props

        if (triggerMode === "clock") {
            let sequence = this.getSequence(workflow, sequenceId)
            sequence.type = triggerMode
            workflow.delay = "1h"
            sequence.inputSchema = [
                { "id": uuidV4(), "label": "lastCheck", "path": "lastCheck", "type": "string", "value": null, "base": null },
                { "id": uuidV4(), "label": "now", "path": "now", "type": "string", "value": null, "base": null }
            ]
            updateWorkflow(workflow)
        } else if (triggerMode === "email") {
            let sequence = this.getSequence(workflow, sequenceId)
            sequence.type = triggerMode

            const toId = uuidV4()
            const ccId = uuidV4()
            const allRecipientsId = uuidV4()
            const otherRecipientsId = uuidV4()

            sequence.inputSchema = [
                {
                    id: toId,
                    label: 'to',
                    path: 'to',
                    type: 'array',
                    base: null
                },
                {
                    id: uuidV4(),
                    label: 'address',
                    path: 'address',
                    type: 'string',
                    example: 'johndoe@gmail.com',
                    base: toId
                },
                {
                    id: uuidV4(),
                    label: 'name',
                    path: 'name',
                    type: 'string',
                    example: 'John Doe',
                    base: toId
                },
                {
                    id: uuidV4(),
                    label: 'domain',
                    path: 'domain',
                    type: 'string',
                    example: 'gmail.com',
                    base: toId
                },
                {
                    id: ccId,
                    label: 'cc',
                    path: 'cc',
                    type: 'array',
                    base: null
                },
                {
                    id: uuidV4(),
                    label: 'address',
                    path: 'address',
                    type: 'string',
                    example: 'johndoe@gmail.com',
                    base: ccId
                },
                {
                    id: uuidV4(),
                    label: 'name',
                    path: 'name',
                    type: 'string',
                    example: 'John Doe',
                    base: ccId
                },
                {
                    id: uuidV4(),
                    label: 'domain',
                    path: 'domain',
                    type: 'string',
                    example: 'gmail.com',
                    base: ccId
                },
                {
                    id: uuidV4(),
                    label: 'address',
                    path: 'sender.address',
                    type: 'string',
                    example: 'johndoe@gmail.com',
                    base: null
                },
                {
                    id: uuidV4(),
                    label: 'name',
                    path: 'sender.name',
                    type: 'string',
                    example: 'John Doe',
                    base: null
                },
                {
                    id: uuidV4(),
                    label: 'domain',
                    path: 'sender.domain',
                    type: 'string',
                    example: 'gmail.com',
                    base: null
                },
                {
                    id: allRecipientsId,
                    label: 'allRecipients',
                    path: 'allRecipients',
                    type: 'array',
                    base: null
                },
                {
                    id: uuidV4(),
                    label: 'address',
                    path: 'address',
                    type: 'string',
                    example: 'johndoe@gmail.com',
                    base: allRecipientsId
                },
                {
                    id: uuidV4(),
                    label: 'name',
                    path: 'name',
                    type: 'string',
                    example: 'John Doe',
                    base: allRecipientsId
                },
                {
                    id: uuidV4(),
                    label: 'domain',
                    path: 'domain',
                    type: 'string',
                    example: 'gmail.com',
                    base: allRecipientsId
                },
                {
                    id: otherRecipientsId,
                    label: 'otherRecipients',
                    path: 'otherRecipients',
                    type: 'array',
                    base: null
                },
                {
                    id: uuidV4(),
                    label: 'address',
                    path: 'address',
                    type: 'string',
                    example: 'johndoe@gmail.com',
                    base: otherRecipientsId
                },
                {
                    id: uuidV4(),
                    label: 'name',
                    path: 'name',
                    type: 'string',
                    example: 'John Doe',
                    base: otherRecipientsId
                },
                {
                    id: uuidV4(),
                    label: 'domain',
                    path: 'domain',
                    type: 'string',
                    example: 'gmail.com',
                    base: otherRecipientsId
                },
                {
                    id: uuidV4(),
                    label: 'subject',
                    path: 'subject',
                    type: 'string',
                    example: 'Hello!',
                    base: null
                },
                {
                    id: uuidV4(),
                    label: 'content',
                    path: 'content',
                    type: 'string',
                    example: 'Hello world',
                    base: null
                },
                {
                    id: uuidV4(),
                    label: 'htmlContent',
                    path: 'htmlContent',
                    type: 'string',
                    example: '<p>Hello world</p>',
                    base: null
                },
                {
                    id: uuidV4(),
                    label: 'date',
                    path: 'date',
                    type: 'string',
                    example: '2020-01-24T19:26:11Z',
                    base: null
                },
                {
                    id: uuidV4(),
                    label: 'isAReply',
                    path: 'isAReply',
                    type: 'boolean',
                    example: true,
                    base: null
                }
            ]
            console.log({ workflow })
            updateWorkflow(workflow)
        } else {
            let sequence = this.getSequence(workflow, sequenceId)
            sequence.type = triggerMode
            sequence.inputSchema = []
            updateWorkflow(workflow)
        }


    }

    changeDelay(delay) {
        let { workflow, sequenceId, updateWorkflow } = this.props

        workflow.delay = delay
        updateWorkflow(workflow)
    }

    getSequence(workflow, sequenceId) {
        let sequence = workflow.sequences.filter(sequence => sequence.sequenceId === sequenceId)[0]
        return sequence
    }

    getSequenceTypeLabel(type) {
        const types = [{
            value: 'api',
            inputDisplay: "API call",
        }, {
            value: 'clock',
            inputDisplay: "Clock",
        }, {
            value: 'auth',
            inputDisplay: "After user auth",
        }, {
            value: 'email',
            inputDisplay: "Incoming email",
        }, {
            value: 'webhook',
            inputDisplay: "External webhook",
        }]

        for (let t of types) {
            if (t.value === type) return t.inputDisplay
        }
    }

    render() {

        const { sequenceId, workflow, updateWorkflow, workflowId, workflowVariables } = this.props

        let sequence = this.sequence
        const hasSeveralTriggersWithSamePath = workflow.sequences.filter(s => s.type === "api").filter(s => s.apiPath === this.state.apiPath).length > 1

        return <StepCard
            title={this.getSequenceTypeLabel(sequence.type)}
            cardStyle={{ marginLeft: 32 }}
            iconsRight={[
                <EuiButtonIcon size="l" iconType="minimize" />,
                <EuiPopover
                    id="contentPanel"
                    anchorPosition="rightUp"
                    button={<EuiButtonIcon size="l" iconType="merge" onClick={() => this.setState({ popoverOpen: !this.state.popoverOpen })} />}
                    isOpen={this.state.popoverOpen}
                    closePopover={() => this.setState({ popoverOpen: false })}
                    panelPaddingSize="s"
                >
                    <EuiContextMenuPanel>
                        <EuiFormRow label="Trigger type">
                            <EuiSuperSelect
                                style={{ minWidth: 300 }}
                                options={[{ value: 'api', inputDisplay: "API call", },
                                { value: 'clock', inputDisplay: "Clock", },
                                { value: 'auth', inputDisplay: "After user auth", },
                                { value: 'email', inputDisplay: "Incoming email", },
                                { value: 'webhook', inputDisplay: "External webhook", }]}
                                valueOfSelected={sequence.type}
                                onChange={value => this.changeTriggerMode(value)}
                            />
                        </EuiFormRow>
                    </EuiContextMenuPanel>
                </EuiPopover>
            ]}
            footer={<>
                {sequence.type === "api" && <div>

                    <EuiFormRow
                        label="When an POST request is made on..."
                        isInvalid={hasSeveralTriggersWithSamePath}
                        error={hasSeveralTriggersWithSamePath ? "You cannot have several sequences triggered by the same API path" : undefined}>

                        <EuiFieldText
                            placeholder="empty path"
                            prepend="/"
                            aria-label="Use aria labels when no actual label is in use"
                            value={this.state.apiPath || ""}
                            onChange={e => {
                                let { workflow, sequenceId, updateWorkflow } = this.props
                                sequence.apiPath = e.target.value
                                this.setState({ apiPath: e.target.value })
                                debounce(() => updateWorkflow(workflow))()
                            }}
                        />
                    </EuiFormRow>

                    <EuiFormRow>
                        <SchemaModal
                            input={this.state.inputSchema}
                            onUpdate={inputSchema => {
                                let { workflow, sequenceId, updateWorkflow } = this.props
                                sequence.inputSchema = inputSchema
                                this.setState({ inputSchema })
                                // updateWorkflow(workflow)
                                debounce(() => updateWorkflow(workflow))()
                            }}
                        />
                    </EuiFormRow>
                </div>}

                {sequence.type === "clock" && <p>

                    <EuiFormRow label="Trigger automatically every...">


                        <EuiSuperSelect
                            options={[{
                                value: '2min',
                                inputDisplay: "2 minutes",
                            }, {
                                value: '10min',
                                inputDisplay: "10 minutes",
                            }, {
                                value: '30min',
                                inputDisplay: "30 minutes",
                            }, {
                                value: '1h',
                                inputDisplay: "1 hour",
                            }, {
                                value: '6h',
                                inputDisplay: "6 hours",
                            }, {
                                value: '1d',
                                inputDisplay: "1 day",
                            }]}
                            onChange={value => this.changeDelay(value)}
                            valueOfSelected={workflow.delay}
                        />

                    </EuiFormRow>
                </p>}

                {sequence.type === "webhook" && <p>
                    <h4>When a webhook is sent on...</h4>

                    <Input
                        placeholder="triggerPath"
                        style={{ marginBottom: 5 }}
                        size="large"
                        value={"https://beta.getsimplifier.com/webhook/" + workflowId}

                        onChange={e => { }} />

                    <h4>With the following data structure...</h4>

                    <SchemaModal
                        input={this.state.inputSchema}
                        onUpdate={inputSchema => {
                            let { workflow, sequenceId, updateWorkflow } = this.props
                            sequence.inputSchema = inputSchema
                            this.setState({ inputSchema })
                            // updateWorkflow(workflow)
                            debounce(() => updateWorkflow(workflow))()
                        }}
                    />

                    <h4>And the following input parameter...</h4>

                    <AntSelect
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        style={{ width: '100%', height: 50 }}
                        size="large"
                        placeholder={"Click to select a variable to set / update"}
                        value={JSON.stringify({ id: sequence.inputMatch || null, path: sequence.inputMatchPath || null })}
                        onChange={(value) => {
                            const { id, path } = JSON.parse(value)
                            sequence.inputMatch = id
                            sequence.inputMatchPath = path
                            debounce(() => updateWorkflow(workflow))()
                        }}
                    >
                        {getProperties(this.state.inputSchema).map(model => <AntSelect.Option key={model.path} value={JSON.stringify({ id: model.id, path: model.path })}>{display(model.path)}</AntSelect.Option>)}
                    </AntSelect>

                    <h4>Matches the following storage parameter...</h4>

                    <AntSelect
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        style={{ width: '100%', height: 50, marginBottom: 15 }}
                        size="large"
                        placeholder={"Click to select a variable to set / update"}
                        value={JSON.stringify({ id: sequence.storageMatch || null, path: sequence.storageMatchPath || null })}
                        onChange={(value) => {
                            const { id, path } = JSON.parse(value)
                            sequence.storageMatch = id
                            sequence.storageMatchPath = path
                            debounce(() => updateWorkflow(workflow))()
                        }}
                    >
                        {getProperties(workflowVariables).map(model => <AntSelect.Option key={model.path} value={JSON.stringify({ id: model.id, path: model.path })}>{display(model.path)}</AntSelect.Option>)}
                    </AntSelect>

                </p>}

                {sequence.type === "auth" && <p>
                    <p>This sequence will be trigerred immediately after user authentication.</p>


                </p>}

                {sequence.type === "email" && <p>
                    <h4>When an email is sent on...</h4>

                    <Input
                        placeholder="triggerPath"
                        style={{ marginBottom: 5 }}
                        size="large"
                        value={workflowId + "@email.simplifier.run"}

                        onChange={e => { }} />

                    <br />

                    <h4>And the following input parameter...</h4>

                    <AntSelect
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        style={{ width: '100%', height: 50 }}
                        size="large"
                        placeholder={"Click to select a variable to set / update"}
                        value={sequence.inputMatch ? JSON.stringify({ id: sequence.inputMatch || null, path: sequence.inputMatchPath || null }) : undefined}
                        onChange={(value) => {
                            const { id, path } = JSON.parse(value)
                            sequence.inputMatch = id
                            sequence.inputMatchPath = path
                            debounce(() => updateWorkflow(workflow))()
                        }}
                    >
                        {getProperties(sequence.inputSchema).map(model => <AntSelect.Option key={model.path} value={JSON.stringify({ id: model.id, path: model.path })}>{display(model.path)}</AntSelect.Option>)}
                    </AntSelect>

                    <h4>Matches the following storage parameter...</h4>

                    <AntSelect
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        style={{ width: '100%', height: 50, marginBottom: 15 }}
                        size="large"
                        placeholder={"Click to select a variable to set / update"}
                        value={sequence.storageMatch ? JSON.stringify({ id: sequence.storageMatch || null, path: sequence.storageMatchPath || null }) : undefined}
                        onChange={(value) => {
                            const { id, path } = JSON.parse(value)
                            sequence.storageMatch = id
                            sequence.storageMatchPath = path
                            debounce(() => updateWorkflow(workflow))()
                        }}
                    >
                        {getProperties(workflowVariables).map(model => <AntSelect.Option key={model.path} value={JSON.stringify({ id: model.id, path: model.path })}>{display(model.path)}</AntSelect.Option>)}
                    </AntSelect>
                    <br />

                    <a href="#"><Icon type="question-circle" />&nbsp;How to set up a custom email address?</a>
                </p>}
            </>}
        >

        </StepCard>









        return <td valign="top" aaa="b">
            <Card style={{ scrollSnapAlign: "start", overflow: 'none' }} className='workflowCard' title={
                <Select size="large" style={{ width: 250 }} onChange={value => this.changeTriggerMode(value)} value={sequence.type}>
                    <Select.Option value="api">API call{this.state.hide && <span> on /{this.state.apiPath}</span>}</Select.Option>
                    <Select.Option value="clock">Clock</Select.Option>
                    <Select.Option value="auth">After user auth</Select.Option>
                    <Select.Option value="webhook">External webhook</Select.Option>
                    <Select.Option value="email">Incoming email</Select.Option>
                    {/* <Select.Option value="2way">2 ways sync</Select.Option> */}
                </Select>
            } extra={<>

                {!this.state.hide && <>
                    <Button size="small" shape="circle" icon="minus" onClick={e => {
                        let { workflow, sequenceId, updateWorkflow } = this.props
                        sequence.hide = true
                        this.setState({ hide: true })
                        debounce(() => updateWorkflow(workflow))()
                    }} />

                </>}

                {this.state.hide && <>
                    <Button size="small" shape="circle" icon="plus" onClick={e => {
                        let { workflow, sequenceId, updateWorkflow } = this.props
                        sequence.hide = false
                        this.setState({ hide: false })
                        debounce(() => updateWorkflow(workflow))()
                    }} />

                </>}


            </>} style={{
                height: this.state.hide ? "70px" : "360px",
                width: this.state.hide ? "800" : "200",
                overflow: this.state.hide ? "hidden" : "scroll"
            }} >

                {!this.state.hide && <>

                    {sequence.type === "api" && <div>

                        <h4>When an POST request is made on...</h4>
                        <Form.Item
                            validateStatus={hasSeveralTriggersWithSamePath ? "error" : undefined}
                            help={hasSeveralTriggersWithSamePath ? "You cannot have several flows triggered by the same API path" : undefined}
                        ><Input
                                placeholder="triggerPath"
                                addonBefore="/"
                                style={{ marginBottom: 5 }}
                                size="large"
                                value={this.state.apiPath || ""}

                                onChange={e => {
                                    let { workflow, sequenceId, updateWorkflow } = this.props
                                    sequence.apiPath = e.target.value
                                    this.setState({ apiPath: e.target.value })
                                    debounce(() => updateWorkflow(workflow))()
                                }} /></Form.Item>

                        <h4>With the following data structure...</h4>

                        <SchemaModal
                            input={this.state.inputSchema}
                            onUpdate={inputSchema => {
                                let { workflow, sequenceId, updateWorkflow } = this.props
                                sequence.inputSchema = inputSchema
                                this.setState({ inputSchema })
                                // updateWorkflow(workflow)
                                debounce(() => updateWorkflow(workflow))()
                            }}
                        />

                    </div>

                    }

                    {sequence.type === "clock" && <p>
                        <h4>Trigger automatically every...</h4>

                        <Select size="large" style={{ width: "100%" }} onChange={value => this.changeDelay(value)} value={workflow.delay}>
                            <Select.Option value="2min">2 minutes</Select.Option>
                            <Select.Option value="10min">10 minutes</Select.Option>
                            <Select.Option value="30min">30 minutes</Select.Option>
                            <Select.Option value="1h">1 hour</Select.Option>
                            <Select.Option value="6h">6 hours</Select.Option>
                            <Select.Option value="1day">1 day</Select.Option>
                        </Select>
                    </p>}

                    {sequence.type === "webhook" && <p>
                        <h4>When a webhook is sent on...</h4>

                        <Input
                            placeholder="triggerPath"
                            style={{ marginBottom: 5 }}
                            size="large"
                            value={"https://beta.getsimplifier.com/webhook/" + workflowId}

                            onChange={e => { }} />

                        <h4>With the following data structure...</h4>

                        <SchemaModal
                            input={this.state.inputSchema}
                            onUpdate={inputSchema => {
                                let { workflow, sequenceId, updateWorkflow } = this.props
                                sequence.inputSchema = inputSchema
                                this.setState({ inputSchema })
                                // updateWorkflow(workflow)
                                debounce(() => updateWorkflow(workflow))()
                            }}
                        />

                        <h4>And the following input parameter...</h4>

                        <AntSelect
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            style={{ width: '100%', height: 50 }}
                            size="large"
                            placeholder={"Click to select a variable to set / update"}
                            value={JSON.stringify({ id: sequence.inputMatch || null, path: sequence.inputMatchPath || null })}
                            onChange={(value) => {
                                const { id, path } = JSON.parse(value)
                                sequence.inputMatch = id
                                sequence.inputMatchPath = path
                                debounce(() => updateWorkflow(workflow))()
                            }}
                        >
                            {getProperties(this.state.inputSchema).map(model => <AntSelect.Option key={model.path} value={JSON.stringify({ id: model.id, path: model.path })}>{display(model.path)}</AntSelect.Option>)}
                        </AntSelect>

                        <h4>Matches the following storage parameter...</h4>

                        <AntSelect
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            style={{ width: '100%', height: 50, marginBottom: 15 }}
                            size="large"
                            placeholder={"Click to select a variable to set / update"}
                            value={JSON.stringify({ id: sequence.storageMatch || null, path: sequence.storageMatchPath || null })}
                            onChange={(value) => {
                                const { id, path } = JSON.parse(value)
                                sequence.storageMatch = id
                                sequence.storageMatchPath = path
                                debounce(() => updateWorkflow(workflow))()
                            }}
                        >
                            {getProperties(workflowVariables).map(model => <AntSelect.Option key={model.path} value={JSON.stringify({ id: model.id, path: model.path })}>{display(model.path)}</AntSelect.Option>)}
                        </AntSelect>

                    </p>}

                    {sequence.type === "auth" && <p>
                        <h4>This path will be trigerred immediately after user authentication.</h4>


                    </p>}

                    {sequence.type === "email" && <p>
                        <h4>When an email is sent on...</h4>

                        <Input
                            placeholder="triggerPath"
                            style={{ marginBottom: 5 }}
                            size="large"
                            value={workflowId + "@email.simplifier.run"}

                            onChange={e => { }} />

                        <br />

                        <h4>And the following input parameter...</h4>

                        <AntSelect
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            style={{ width: '100%', height: 50 }}
                            size="large"
                            placeholder={"Click to select a variable to set / update"}
                            value={sequence.inputMatch ? JSON.stringify({ id: sequence.inputMatch || null, path: sequence.inputMatchPath || null }) : undefined}
                            onChange={(value) => {
                                const { id, path } = JSON.parse(value)
                                sequence.inputMatch = id
                                sequence.inputMatchPath = path
                                debounce(() => updateWorkflow(workflow))()
                            }}
                        >
                            {getProperties(sequence.inputSchema).map(model => <AntSelect.Option key={model.path} value={JSON.stringify({ id: model.id, path: model.path })}>{display(model.path)}</AntSelect.Option>)}
                        </AntSelect>

                        <h4>Matches the following storage parameter...</h4>

                        <AntSelect
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            style={{ width: '100%', height: 50, marginBottom: 15 }}
                            size="large"
                            placeholder={"Click to select a variable to set / update"}
                            value={sequence.storageMatch ? JSON.stringify({ id: sequence.storageMatch || null, path: sequence.storageMatchPath || null }) : undefined}
                            onChange={(value) => {
                                const { id, path } = JSON.parse(value)
                                sequence.storageMatch = id
                                sequence.storageMatchPath = path
                                debounce(() => updateWorkflow(workflow))()
                            }}
                        >
                            {getProperties(workflowVariables).map(model => <AntSelect.Option key={model.path} value={JSON.stringify({ id: model.id, path: model.path })}>{display(model.path)}</AntSelect.Option>)}
                        </AntSelect>
                        <br />

                        <a href="#"><Icon type="question-circle" />&nbsp;How to set up a custom email address?</a>
                    </p>}

                </>}

            </Card>
        </td>
    }
}