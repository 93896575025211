import { Spinner } from "@blueprintjs/core";
import { Alert, Button, Card, Descriptions, Dropdown, Icon, Input, Menu, message, PageHeader, Switch, Table, Tabs, Typography } from 'antd';
import 'brace/mode/json';
import 'brace/theme/solarized_dark';
import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import ReactMarkdown from 'react-markdown';
import uuidV4 from 'uuid/v4';
import api from '../api';
import InputBlock from '../components2/InputBlock';
import Steps from '../components2/Steps';
import WorkflowLogs from '../components/WorkflowLogs';
import WorkflowUsers from '../components/WorkflowUsers';
import { encapsulate } from '@simplifier/normalization';
import { getAuths } from '../utils/workflows.js';
import CTE from "react-click-to-edit"
import FPSStats from "react-fps-stats"
import { Prompt } from 'react-router'


import {
    EuiPage,
    EuiPageBody,
    EuiSpacer,
    EuiPageContentHeader,
    EuiPageContentHeaderSection,
    EuiButton,
    EuiTab,
    EuiTabs,
    EuiText,
    EuiLoadingContent,
    EuiCard,
    EuiFlexGroup,
    EuiFlexItem,
    EuiStat,
    EuiHeader,
    EuiHeaderSectionItem,
    EuiHeaderLogo,
    EuiHeaderLinks,
    EuiHeaderLink,
    EuiIcon,
    EuiEmptyPrompt
} from "@elastic/eui";

import inflect from 'i'
const infl = inflect()
console.log({ data: infl.singularize("data") })


const { Title, Paragraph, Text } = Typography;

const tabs = [
    { id: "builder", name: "Builder", disabled: false },
    { id: "dextrose", name: "Apps", disabled: false },
    { id: "monosodium_glutammate", name: "Users", disabled: false },
    { id: "elastic_link", name: "Monitoring", disabled: false },
    { id: "tests", name: "Tests", disabled: true },
    { id: "settings", name: "Settings", disabled: false }
]

export default class Workflow extends React.Component {
    constructor(props) {
        super(props)

        const defaultTab = (new URLSearchParams(window.location.search)).get('tab') || "builder"

        this.state = {
            persistingWorkflow: false,
            normalizerEmpty: true,
            availableMethods: [],
            authModes: [],
            inputScaffold: "{}",
            scaffold: {},
            showNewBlockCard: false,
            id: "",
            outputScaffold: "{}",
            defaultTab: defaultTab,
            currentTab: defaultTab,
            localWorkflow: null,

            workflowVariables: [],

            workflow: null,
            auth: {},
            products: [],

            dirty: false
        }

    }

    async componentDidMount() {
        const { workflowId } = this.props.match.params
        const { data } = await api(`workflows/${workflowId}`)
        const { data: authModes } = await api(`workflow/authModes/developerData`)

        const workflow = data.workflow
        const availableMethods = data.availableMethods
        const databases = data.databases

        document.title = workflow.workflow.name + " - Simplifier"

        window.addEventListener('beforeunload', function (e) {
            delete e['returnValue'];
        });

        this.setState({
            availableMethods: availableMethods,
            workflow: workflow.workflow,
            auth: workflow.workflow.auth,

            authModes,

            blockIds: workflow.blockIds,

            localWorkflow: workflow.workflow,
            projectId: workflow.project.id,
            id: workflow.id,

            workflowVariables: data.variables,
            databases,

            inputScaffold: JSON.stringify(workflow.workflow.inputExample),
            scaffold: workflow.workflow.inputExample,
            outputScaffold: JSON.stringify(workflow.workflow.outputExample),
        })
    }

    componentDidUpdate = () => {
        if (this.state.dirty) {
            window.onbeforeunload = (e) => {
                e.preventDefault();
                e.returnValue = ''
            }
        } else {
            window.onbeforeunload = undefined
        }
    }

    updateOutputSchema(input) {
        let workflow = this.state.workflow

        // console.log({ newOutSchema: input })

        workflow.outputSchema = input

        this.setState({ workflow })
    }


    updateWorkflow(newWorkflow) {
        this.setState({
            workflow: {
                ...newWorkflow,
                auth: getAuths(newWorkflow, this.state.availableMethods)
            },
            auth: getAuths(newWorkflow, this.state.availableMethods),
            dirty: true
        })
    }

    async persistWorkflow() {
        const { id, workflow, availableMethods } = this.state

        this.setState({ persistingWorkflow: true })

        let newWorkflow = {
            ...workflow,
            auth: this.state.auth
        }

        // console.log("w", this.state.workflowVariables)

        // console.log(this.state.availableMethods.map(b => b.id).join(","))

        await api(`workflows/${id}/update`, 'post', {
            workflow: newWorkflow,
            triggerDelay: workflow.delay,
            blockIds: this.state.availableMethods.map(b => b.id).join(","),
            workflowVariables: this.state.workflowVariables
        })

        this.setState({ persistingWorkflow: false, workflow: newWorkflow, dirty: false })
    }

    updateData(productName, key, value) {
        let { workflow } = this.state
        let auth = workflow.auth

        // console.log({ update: { productName, key, value } })

        auth[productName]['data'][key] = value
        workflow.auth = auth

        // console.log({ newAuth: workflow.auth })

        this.setState({ auth })
    }

    async updateAvailableMethods(block) {
        const { availableMethods } = this.state
        // console.log("updating av blocks", availableMethods)
        // console.trace()
        this.setState({
            availableMethods: [...availableMethods, block]
        })
        return [...availableMethods, block]
    }

    render() {
        let { state: { workflow, projectId, persistingWorkflow, id, availableMethods, products, databases, authModes } } = this
        let { state: { persistWorkflow } } = this


        if (!workflow) {
            return <React.Fragment>
                <PageHeader
                    title={"Workflow"}
                    style={{ backgroundColor: 'white' }}
                >
                    <br /><br /><h3 className="text-center"><Spinner /></h3><br /><br />
                </PageHeader>
            </React.Fragment>
        }

        let auth = this.state.auth
        console.log(Object.values(workflow.auth).map(p => p.product._id))

        console.log(Object.values(workflow.auth))

        async function handleMenuClick(workflowId, value) {

            const { key } = value
            if (key === "duplicate") {
                const { data: { createWorkflow: { id } } } = await api(`workflows/${workflowId}/duplicate`, 'post')
                // this.props.history.push(`/workflows/${id}`)
                message.success(<span>Workflow has been duplicated, <a href={"/workflows/" + id}>open</a></span>, 10);
            }

            if (key === "delete") {
                // alert('delete')
            }
        }

        const menu = <Menu onClick={value => handleMenuClick(id, value)}>
            <Menu.Item key="duplicate">
                <Icon type="copy" />
                Duplicate
            </Menu.Item>
            <Menu.Item key="delete">
                <Icon type="delete" />
                Delete workflowX
            </Menu.Item>
        </Menu>





        return <>

            <EuiPageContentHeader>
                <EuiPageContentHeaderSection>
                    <EuiText grow={false}>
                        <h1>
                            {workflow.name}
                        </h1>

                        <Prompt
                            when={this.state.dirty}
                            message='You have unsaved changes, are you sure you want to leave?'
                        />
                    </EuiText>

                    <FPSStats />
                </EuiPageContentHeaderSection>
                <EuiPageContentHeaderSection>
                    <EuiFlexGroup gutterSize="s" alignItems="center">
                        <EuiFlexItem grow={false}>
                            <a key={0} href={`/workflows/${id}/doc`} target="_blank">
                                <EuiButton
                                    iconSide="left"
                                    iconType="link"
                                >
                                    Documentation
                            </EuiButton>
                            </a>
                        </EuiFlexItem>

                        <EuiFlexItem grow={false}>
                            <EuiButton
                                onClick={this.persistWorkflow.bind(this)}
                                isLoading={persistingWorkflow}
                                iconSide="left" fill
                                iconType={this.state.dirty ? "save" : "check"}
                                isDisabled={!this.state.dirty}
                            >
                                {this.state.dirty ? "Save" : "Saved"}
                            </EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiPageContentHeaderSection>
            </EuiPageContentHeader>

            <EuiSpacer />

            <EuiTabs>
                {tabs.map((tab, index) => (
                    <EuiTab
                        {...tab.href && { href: tab.href, target: "_blank" }}
                        disabled={tab.disabled}
                        isSelected={tab.id === "builder"}
                        key={index}
                    >
                        {tab.name}
                    </EuiTab>
                ))}
            </EuiTabs>

            <br /><br />

            <div style={{ minHeight: 360, overflowX: "scroll", overflowY: "visible", marginLeft: -33, marginRight: -33, marginBottom: 32, paddingTop: 5 }} >

                {
                    workflow.sequences.map((sequence, index) =>

                        <table style={{ scrollSnapType: "both mandatory", marginBottom: 32 * 2, paddingRight: 32 }} ><tbody><tr>
                            <InputBlock
                                sequenceId={sequence.sequenceId}
                                workflow={workflow}
                                updateWorkflow={this.updateWorkflow.bind(this)}
                                workflowId={id}
                                workflowVariables={this.state.workflowVariables}
                            />

                            {!sequence.hide && <Steps
                                available
                                workflow={workflow}
                                steps={sequence.steps}
                                availableMethods={this.state.availableMethods}
                                updateAvailableMethods={this.updateAvailableMethods.bind(this)}
                                products={products}
                                updateWorkflow={this.updateWorkflow.bind(this)}
                                sequenceId={sequence.sequenceId}
                                workflowVariables={this.state.workflowVariables}
                                databases={databases}
                                inheritedVariables={
                                    encapsulate(sequence.inputSchema, "input")
                                }
                            />}

                            <td style={{ minWidth: 32 }}>&nbsp;</td>

                        </tr></tbody>
                        </table>
                    )
                }

                <td rowspan="1" valign="top" style={{ position: "relative", height: 300, verticalAlign: "top" }}>

                    <EuiCard
                        textAlign="center"
                        onClick={() => {
                            let newSequence = {
                                "sequenceId": uuidV4(),
                                "type": "api",
                                "name": "default",
                                "apiPath": "",
                                "inputSchema": [],
                                "steps": []
                            }

                            let newWorkflow = workflow
                            newWorkflow.sequences.push(newSequence)

                            this.updateWorkflow(newWorkflow)
                        }}
                        className="add-step"
                        style={{
                            scrollSnapAlign: "start",
                            position: "relative",
                            width: 325,
                            height: 325,
                            marginLeft: 32, paddingTop: 32,
                            overflow: "hidden",
                            backgroundColor: "#fafbfd",
                        }}
                    >
                        <div style={{
                            backgroundColor: "#fafbfd",
                            position: "absolute",
                            top: 0, left: 0, right: 0,
                            padding: 11,
                            paddingLeft: 16,
                            height: 36,
                        }}>

                            <EuiFlexGroup justifyContent="spaceBetween">
                                <EuiFlexItem grow={false}>
                                    <EuiFlexGroup gutterSize="s">
                                        &nbsp;
                                    </EuiFlexGroup>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiFlexGroup gutterSize="s">
                                        &nbsp;
                                    </EuiFlexGroup>
                                </EuiFlexItem>
                            </EuiFlexGroup>

                        </div>

                        <div style={{ marginTop: 5 }}><EuiEmptyPrompt
                            iconType="plusInCircle"
                            iconColor={null}
                            title={<h3>Add sequence</h3>}
                            titleSize="s"

                        /></div>

                    </EuiCard>
                </td>

                <br /><br />

            </div>

        </>

































        return <React.Fragment>
            <PageHeader
                onBack={() => { if (projectId) { this.props.history.push(`/${projectId}/scenarios`) } }}
                title={"Workflow builder"}
                style={{ backgroundColor: 'white' }}
                extra={[
                    <a key={0} href={`/workflows/${id}/doc`} target="_blank"><Button type="default" size="large" icon="link">Workflow documentation</Button></a>,

                    <Dropdown overlay={menu}>
                        <Button size="large">
                            Menu <Icon type="down" />
                        </Button>
                    </Dropdown>,


                    <Button key={1} type="primary" size="large" icon="save" onClick={this.persistWorkflow.bind(this)} loading={persistingWorkflow}>Save</Button>
                ]}
                footer={
                    <Tabs defaultActiveKey={this.state.defaultTab} onChange={value => {
                        this.setState({ currentTab: value })

                        const params = new URLSearchParams(window.location.search);

                        // console.log(params)
                        params.set('tab', value);
                        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
                    }}>
                        <Tabs.TabPane tab="Builder" key="builder"></Tabs.TabPane>
                        <Tabs.TabPane tab="Third party authentication" key="auth"></Tabs.TabPane>
                        <Tabs.TabPane tab="Users" key="users"></Tabs.TabPane>
                        <Tabs.TabPane tab="Logs" key="logs"></Tabs.TabPane>
                        {/* <Tabs.TabPane tab="Post user authentication workflow" key="4"></Tabs.TabPane> */}
                    </Tabs>
                }
            >

                <Descriptions bordered>
                    <Descriptions.Item label="Workflow description">
                        <Paragraph style={{ display: 'inline' }} editable={{
                            onChange: label => {
                                let newWorkflow = this.state.workflow
                                newWorkflow.name = label
                                this.updateWorkflow(newWorkflow)
                            }
                        }}>{workflow.name}</Paragraph>
                    </Descriptions.Item>
                </Descriptions>
            </PageHeader>

            <DragDropContext onDragEnd={console.log}>
                <div style={{ padding: 24, minHeight: 360, overflowX: "scroll", display: this.state.currentTab === "builder" ? 'block' : 'none' }} >
                    {
                        workflow.sequences.map((sequence, index) =>

                            <table style={{ scrollSnapType: "both mandatory" }} ><tbody><tr>
                                <InputBlock
                                    sequenceId={sequence.sequenceId}
                                    workflow={workflow}
                                    updateWorkflow={this.updateWorkflow.bind(this)}
                                    workflowId={id}
                                    workflowVariables={this.state.workflowVariables}

                                />

                                {!sequence.hide && <Steps
                                    available
                                    workflow={workflow}
                                    steps={sequence.steps}
                                    availableMethods={this.state.availableMethods}
                                    updateAvailableMethods={this.updateAvailableMethods.bind(this)}
                                    products={products}
                                    updateWorkflow={this.updateWorkflow.bind(this)}
                                    sequenceId={sequence.sequenceId}
                                    workflowVariables={this.state.workflowVariables}
                                    databases={databases}
                                    inheritedVariables={
                                        encapsulate(sequence.inputSchema, "input")
                                    }
                                />}

                            </tr></tbody>
                            </table>
                        )}


                    <Button type="dashed" className='workflowCard' block style={{ display: 'block', backgroundColor: 'transparent' }} onClick={() => {
                        let newSequence = {
                            "sequenceId": uuidV4(),
                            "type": "api",
                            "name": "default",
                            "apiPath": "",
                            "inputSchema": [],
                            "steps": []
                        }

                        let newWorkflow = workflow
                        newWorkflow.sequences.push(newSequence)

                        this.updateWorkflow(newWorkflow)
                    }}>
                        <Icon type="play-circle" style={{ fontSize: 24 }} />
                        <h2>Add a trigger</h2>
                    </Button>

                </div>
            </DragDropContext>

            <div style={{ padding: 24, minHeight: 360, overflowX: "scroll", display: this.state.currentTab === "auth" ? 'block' : 'none' }} >
                <Card>
                    <Title>Authentication configuration</Title>

                    {Object.keys(auth).map(product => {
                        let productAuth = auth[product]

                        const modes = authModes.filter(authMode => authMode.slug === productAuth.product.auth.type)

                        const authMode = modes.length ? modes[0] : null

                        return <div key={product} style={{ marginTop: 25 }}>
                            <Title level={2}>{productAuth.product.name}{productAuth.product.auth.developerAuthInstructions && <> (<a href={productAuth.product.auth.developerAuthInstructions} target="_blank">Auth documentation</a>)</>}</Title>

                            {productAuth.product.auth.authenticationHelp &&

                                <Alert
                                    message="Authentication help"
                                    description={<ReactMarkdown source={productAuth.product.auth.authenticationHelp} />}
                                    type="info"
                                    showIcon
                                    style={{ marginBottom: 15 }}
                                />
                            }


                            <Paragraph><Switch checkedChildren={<Icon type="check" />} defaultChecked={productAuth.data.step1} onChange={value => this.updateData(product, "step1", value)} />&nbsp;&nbsp;&nbsp;Create an app on the {productAuth.product.auth.developerDashboard ? <><a href={productAuth.product.auth.developerDashboard} target="_blank">{product} developer dashboard</a></> : <>{product} developer dashboard</>}<br /></Paragraph>

                            {
                                productAuth.scopes.length ? <Paragraph>
                                    <Switch checkedChildren={<Icon type="check" />} defaultChecked={productAuth.data.step2} onChange={value => this.updateData(product, "step2", value)} />
                                    &nbsp;&nbsp;&nbsp;Add the following scopes : <span>{productAuth.scopes.map(scope => <Text key={scope} code>{scope}</Text>)}<br /></span>
                                </Paragraph> : <span></span>
                            }

                            <Paragraph><Switch checkedChildren={<Icon type="check" />} defaultChecked={productAuth.data.step3} onChange={value => this.updateData(product, "step3", value)} />&nbsp;&nbsp;&nbsp;Add the following redirect_uri : <Paragraph style={{ display: 'inline' }} code copyable>{api.endpoint + "workflow/callback/" + id}</Paragraph></Paragraph>



                            {
                                authMode && <>

                                    <Table bordered pagination={false} dataSource={
                                        authMode.developerDataSchema.map(param => {
                                            return {
                                                key: param.path,
                                                parameter: param.label,
                                                value: { id: param.path, label: param.label }
                                            }

                                        })} columns={[
                                            { title: "Parameter", dataIndex: "parameter", key: "parameter" },
                                            {
                                                title: 'Value', dataIndex: 'value', key: 'value',
                                                render: ({ id, label }) => <Input
                                                    key={id}
                                                    defaultValue={productAuth.data[id]}
                                                    onChange={e => this.updateData(product, id, e.target.value)}
                                                />
                                            }
                                        ]}
                                    />

                                </>
                            }

                        </div>

                    })

                    }

                    {
                        !Object.keys(auth).length && <div></div>
                    }

                </Card>
            </div>

            <div style={{ padding: 24, minHeight: 360, overflowX: "scroll", display: this.state.currentTab === "users" ? 'block' : 'none' }} >
                <WorkflowUsers workflowId={id} history={this.props.history} workflowVariables={this.state.workflowVariables} setWorkflowVariables={inputSchema => this.setState({ workflowVariables: inputSchema })} />
            </div>

            <div style={{ padding: 24, minHeight: 360, overflowX: "scroll", display: this.state.currentTab === "logs" ? 'block' : 'none' }} >
                <WorkflowLogs workflowId={id} history={this.props.history} workflowVariables={this.state.workflowVariables} />
            </div>

        </React.Fragment>

    }
}