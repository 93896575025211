import { EuiAccordion, EuiButton, EuiButtonIcon, EuiCheckbox, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiLink, EuiPanel, EuiSpacer, EuiSuperSelect, EuiTitle, EuiToolTip } from '@elastic/eui';
import React from 'react';

export default function (props) {
    const { product, updateProduct, authModes, complete, advancement } = props

    let selectedAuth = authModes.filter(auth => auth.slug === product.auth.type).length ?
        authModes.filter(auth => auth.slug === product.auth.type)[0]
        : null

    return <>

        <EuiFlexGroup>
            <EuiFlexItem>
                <EuiFormRow label="Authentication documentation page">
                    <EuiFieldText
                        fullWidth name="first" placeholder="https://api.slack.com/auth"
                        onChange={(e) => updateProduct(e.target.value, 'auth', 'developerAuthInstructions')}
                        value={product.auth.developerAuthInstructions}
                        append={[<EuiToolTip position="right" className="pink" content="What is the format of the data Simplifier should send to the API? Don't worry, we will do the conversion for you.">
                            <EuiLink href="#"><EuiButtonIcon iconType="questionInCircle" aria-label="Gear this" /></EuiLink>
                        </EuiToolTip>]}
                    />
                </EuiFormRow>

            </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size="l" />

        <EuiFlexGroup>
            <EuiFlexItem>
                <EuiFormRow label="Developer dashboard URL">
                    <EuiFieldText
                        fullWidth name="first" placeholder="https://api.slack.com/dashboard"
                        onChange={(e) => updateProduct(e.target.value, 'auth', 'developerDashboard')}
                        value={product.auth.developerDashboard}
                        append={[<EuiToolTip position="right" className="pink" content="What is the format of the data Simplifier should send to the API? Don't worry, we will do the conversion for you.">
                            <EuiLink href="#"><EuiButtonIcon iconType="questionInCircle" aria-label="Gear this" /></EuiLink>
                        </EuiToolTip>]}
                    />
                </EuiFormRow>

            </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size="l" />

        <EuiFlexGroup>
            <EuiFlexItem>
                <EuiFormRow label="Authentication method">
                    <EuiSuperSelect
                        options={authModes.map(mode => ({
                            value: mode.slug,
                            inputDisplay: <>{mode.name}</>,
                        }))}
                        valueOfSelected={product.auth.type}
                        onChange={value => updateProduct(value, 'auth', 'type')}
                    />
                </EuiFormRow>

            </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size="l" />

        {
            selectedAuth && <>
                {
                    [...new Set(selectedAuth.productDataSchema.map(product => product.category))]
                        .map(category => ({
                            name: category,
                            params: selectedAuth.productDataSchema.filter(product => product.category === category)
                        }))
                        .map(category => {
                            return <>
                                <EuiFlexGroup>
                                    <EuiFlexItem grow={false}>
                                        <EuiPanel paddingSize="s" style={{ width: 400 }}>

                                            <EuiAccordion
                                                id={"id" + category}
                                                buttonContent={<EuiTitle size="xs"><h4>{category.name || selectedAuth.name + " settings"}</h4></EuiTitle>}
                                                paddingSize="l">
                                                {
                                                    category.params.map(param => {

                                                        if (param.type === "boolean") {
                                                            return <>
                                                                <EuiFormRow><>
                                                                    <EuiCheckbox
                                                                        id={param.label}
                                                                        label={param.label}
                                                                        checked={product.auth[param.path]}
                                                                        onChange={(e) => {
                                                                            console.log(e.target.checked)
                                                                            updateProduct(e.target.checked, 'auth', param.path)
                                                                        }}
                                                                    />
                                                                </></EuiFormRow>
                                                            </>
                                                        }

                                                        else if (param.isEnum) {

                                                            return <>
                                                                <EuiFormRow label={param.label}>
                                                                    <EuiSuperSelect
                                                                        options={param.enumValues.map(enumValue => ({
                                                                            value: enumValue.value,
                                                                            inputDisplay: <>{enumValue.label}</>,
                                                                        }))}
                                                                        valueOfSelected={product.auth[param.path] || param.default}
                                                                        onChange={(v) => updateProduct(v, 'auth', param.path)}
                                                                        append={param.help ? [<EuiToolTip position="right" className="pink" content={param.help}>
                                                                            <EuiLink href="#"><EuiButtonIcon iconType="questionInCircle" aria-label="Gear this" /></EuiLink>
                                                                        </EuiToolTip>] : []}
                                                                    />
                                                                </EuiFormRow>

                                                                <EuiSpacer size="l" />
                                                            </>
                                                        }

                                                        else {

                                                            return <>
                                                                <EuiFormRow label={param.label}>
                                                                    <EuiFieldText
                                                                        fullWidth name="first"
                                                                        onChange={(e) => updateProduct(e.target.value, 'auth', param.path)}
                                                                        value={product.auth[param.path]}
                                                                        placeholder={param.example}
                                                                        append={param.help ? [<EuiToolTip position="right" className="pink" content={param.help}>
                                                                            <EuiLink href="#"><EuiButtonIcon iconType="questionInCircle" aria-label="Gear this" /></EuiLink>
                                                                        </EuiToolTip>] : []}
                                                                    />
                                                                </EuiFormRow>

                                                                <EuiSpacer size="l" />
                                                            </>
                                                        }
                                                    })
                                                }
                                            </EuiAccordion>


                                            {/* <EuiTitle size="xs"><h4>{category.name}</h4></EuiTitle> */}

                                            {/* <EuiSpacer size="s" /> */}


                                        </EuiPanel><EuiSpacer size="m" />
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </>
                        })

                }
            </>
        }

        <EuiSpacer size="l" />

        <EuiFlexGroup gutterSize="s" alignItems="center">
            {/* <EuiFlexItem grow={false}>
                <EuiButton fill iconType="play" color="warning" onClick={() => complete()}>Test product auth</EuiButton>
            </EuiFlexItem> */}
            <EuiFlexItem grow={false}>
                {
                    advancement === "incomplete" && <EuiButton iconType="arrowRight" fill={true} color="primary" onClick={() => complete()}>Next</EuiButton>
                }
            </EuiFlexItem>
        </EuiFlexGroup>




    </>
}