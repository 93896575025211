import { EuiButton, EuiStat, EuiFlexGroup, EuiPanel, EuiEmptyPrompt, EuiButtonEmpty, EuiCard, EuiFlexGrid, EuiFlexItem, EuiLoadingContent, EuiPageContentBody, EuiPageContentHeader, EuiPageHeaderSection, EuiSpacer, EuiTitle } from '@elastic/eui';
import { Button as AntButton, Card, List, PageHeader, Statistic, Typography } from 'antd';
import React from 'react';
import api from '../api';

import { Link } from "react-router-dom"

const { Paragraph } = Typography

class Squircle extends React.Component {
	render() {
		const { imageUrl, width, height, className, onClick, fit, style } = this.props

		return (
			<div style={{ display: 'inline-block', marginRight: 0, padding: 5 }}>
				<svg width="0" height="0" style={{ border: "1px solid #eee" }}>
					<defs>
						<clipPath id="squircle" clipPathUnits="objectBoundingBox">
							<path d="M .5,0 C .1,0 0,.1 0,.5 0,.9 .1,1 .5,1 .9,1 1,.9 1,.5 1,.1 .9,0 .5,0 Z" />
						</clipPath>
					</defs>
				</svg>

				<img onClick={onClick} style={{ "clip-path": "url(#squircle)", "height": height, "width": width, "objectFit": fit, ...style }} className={className} src={imageUrl} />
			</div>
		);
	}
}

function chunk(array, size) {
	if (!array) return [];
	const firstChunk = array.slice(0, size);
	if (!firstChunk.length) { return array; }
	return [firstChunk].concat(chunk(array.slice(size, array.length), size));
}

export default class Scenarios extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			name: "Integations",
			scenarios: [],
			workflows: [],
			createWorkflowLoader: false,
			availableMethods: [],
			loading: true
		}
	}

	async componentDidMount() {
		let availableMethods = []
		const { data: { workflows, name } } = await api('projects/' + this.props.match.params.projectId + '/workflows')
		this.setState({ name, workflows, availableMethods, loading: false })

		document.title = name + " — Simplifier"
	}

	async createWorkflow() {
		this.setState({ createWorkflowLoader: true })

		const { projectId } = this.props.match.params
		const defaultWorkflow = {
			"version": 2,
			"auth": {},
			"name": "Workflow",
			"sequences": []
		}

		const { data: { createWorkflow: { id } } } = await api('workflows', 'post', { projectId, workflow: defaultWorkflow })

		this.setState({ createWorkflowLoader: false })

		this.props.history.push(`/workflows/${id}`)
	}

	count(scenario) { return scenario.setups.reduce((acc, curr) => acc + curr.auths.length, 0) }

	render() {

		const { createWorkflowLoader, workflows, loading } = this.state

		const { projectId } = this.props.match.params

		return <EuiPageContentBody>
			<EuiPageContentHeader>
				<EuiPageHeaderSection>
					<EuiTitle size="l">
						<h1>{this.state.name}</h1>
					</EuiTitle>
				</EuiPageHeaderSection>
				<EuiPageHeaderSection>
					<EuiButton isLoading={createWorkflowLoader} onClick={this.createWorkflow.bind(this)}>
						Create integration
                    </EuiButton>
				</EuiPageHeaderSection>
			</EuiPageContentHeader>

			<EuiSpacer size="xl" />

			{loading && <EuiFlexGrid columns={1} gutterSize="l">
				{[1, 2].map(x => <EuiFlexItem>
					<EuiCard
						layout="horizontal"
						display="panel"
						title={<EuiLoadingContent lines={1} />}
						description={<EuiLoadingContent lines={3} />}
					/>
				</EuiFlexItem>)}

			</EuiFlexGrid>}

			{!loading && <EuiFlexGrid columns={1} gutterSize="l">
				{workflows.map(workflow => <EuiFlexItem>
					<Link to={"/workflows/" + workflow._id}>
						<EuiCard
							layout="horizontal"
							display="panel"
							title={workflow.name}
							description={<div style={{ position: "relative" }}>
								<div style={{ position: "absolute", top: -37, right: -12 }}>{
									Object.values(workflow.auth)
										.map(p => <Squircle
											width="41px"
											height="41px"
											fit="cover"
											imageUrl={p.product.logo}
											style={{ backgroundColor: "#f3f3f3", display: 'inline-block' }}
										/>)
								}</div>
								<EuiFlexGroup>
									<EuiFlexItem>
										<EuiStat title={workflow.users} description="Users" titleSize="s" textAlign="left" reverse className="project-stat" />
									</EuiFlexItem>
									<EuiFlexItem>
										<EuiStat title={workflow.sevenDays} description="7day triggers" titleSize="s" textAlign="left" reverse className="project-stat" />
									</EuiFlexItem>
									<EuiFlexItem>
										<EuiStat title={workflow.oneDay} description="24h triggers" titleSize="s" textAlign="left" reverse className="project-stat" />
									</EuiFlexItem>
								</EuiFlexGroup>
							</div>}
							// onClick={() => this.props.history.push("/workflows/" + workflow._id)}
							className="clickable-card"
						/>
					</Link>
				</EuiFlexItem>)}

			</EuiFlexGrid>}

			{!loading && !workflows.length && <EuiEmptyPrompt
				title={<h2>This app has no integration</h2>}
				actions={[
					<EuiButton isLoading={createWorkflowLoader} onClick={this.createWorkflow.bind(this)}>
						Create integration
                    </EuiButton>
				]}
			/>}

		</EuiPageContentBody>
	}
}
