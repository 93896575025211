import { Button, Col, Form, Input, PageHeader, Row, Typography } from 'antd';
import React from 'react';
import ReactJson from 'react-json-view';
import api from '../api';

const { Title } = Typography

export default class Databases extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            database: {},
            loadingSve: false
        }
    }

    async componentDidMount() {
        const { databaseId } = this.props.match.params

        const { data: database } = await api(`databases/${databaseId}`)
        this.setState({ database })
    }

    render() {
        const { database } = this.state

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        }

        return <React.Fragment>

            <PageHeader
                title={database.name || "Database"}
                style={{ backgroundColor: 'white' }}
                subTitle={database.parameters ? database.parameters.type : "Loading"}
                onBack={() => this.props.history.push(`/databases`)}

                extra={<Button loading={this.state.loadingSave} type="primary" disabled={!database.parameters} size="large" onClick={async () => {
                    this.setState({ loadingSave: true })
                    await api(`databases/${database._id}`, 'put', { database })
                    this.setState({ loadingSave: false })
                }}>
                    Save
                </Button>}
            >
            </PageHeader>

            <div style={{ margin: 24, padding: 24, minHeight: 360, borderRadius: 4, backgroundColor: "white" }}>
                <Title level={2}>Settings</Title>

                <Row>
                    <Col xs={2} sm={4} md={6} lg={6} xl={6}>&nbsp;</Col>
                    <Col xs={20} sm={16} md={12} lg={12} xl={12}>

                        <Form {...formItemLayout} onSubmit={e => e.preventDefault()} className="login-form">

                            <Form.Item label="Database name">
                                <Input placeholder="Database name" value={database.name} onChange={e => {
                                    this.setState({
                                        database: {
                                            ...database,
                                            name: e.target.value
                                        }
                                    })
                                }} />
                            </Form.Item>


                            {(database.parameters || []).map(parameter => <Form.Item label={parameter.label}>
                                <Input placeholder={parameter.example} value={parameter.value} onChange={e => {
                                    let newParameter = database.parameters.filter(param => param.id === parameter.id)[0]
                                    newParameter.value = e.target.value
                                    this.setState({
                                        database: {
                                            ...database,
                                            parameters: database.parameters
                                        }
                                    })
                                }} />
                            </Form.Item>)}
                        </Form>

                    </Col>
                    <Col xs={2} sm={4} md={6} lg={6} xl={6}>&nbsp;</Col>
                </Row>

                {/* <Title level={2}>Parameters</Title> */}

                {/* <ReactJson src={database} /> */}
            </div>
        </React.Fragment >
    }
}
