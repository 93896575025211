import axios from 'axios'
import auth0Client from './Auth.js'

const baseURL = window.location.origin + '/'
const path = (baseURL === "http://localhost:3000/") ? "https://simplifier-test.ngrok.io/" : "https://beta.getsimplifier.com/"

async function api(endpoint, method, data) {
    if (!endpoint) return path

    data = data || {}
    method = method || 'get'

    return await axios({
        method,
        url: path + endpoint,
        data,
        headers: { 'Authorization': 'Bearer ' + auth0Client.idToken }
    })
}

api.endpoint = path
api.baseURL = baseURL

api.get = async (endpoint) => await api(endpoint, "get")
api.post = async (endpoint, data) => await api(endpoint, "post", data)
api.patch = async (endpoint, data) => await api(endpoint, "patch", data)
api.delete = async (endpoint, data) => await api(endpoint, "delete", data)

export default api 