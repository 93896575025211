import { Button, Card, Icon, Mentions, Popconfirm, Radio, Select as AntSelect } from 'antd';
import React from 'react';
import { encapsulate, getProperties } from '@simplifier/normalization';
import { deleteStep, display, updateStep } from '../../utils/workflows.js';

const { Option } = Mentions

export default class DictionaryStep extends React.Component {
    constructor(props) {
        super(props)

        this.state = { outputMappingModal: false }
    }

    render() {
        const { step, localWorkflow, availableVariables, workflow, availableMethods, products, updateWorkflow, updateLocalWorkflow, updateAvailableMethods, workflowVariables } = this.props
        const { outputMappingModal } = this.state

        

        return <React.Fragment>
            <td valign="top" style={{ position: 'relative' }}>
                <Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-25px', top: '150px' }} />
                <Card
                    className='workflowCard'
                    title="Dictionary"
                    extra={<Popconfirm
                        title="Do you really want to delete this step?"
                        onConfirm={() => {
                            let newWorkflow = deleteStep(workflow, step)
                            updateWorkflow(newWorkflow)
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button size="small" shape="circle" icon="close" />
                    </Popconfirm>}
                >


                    <Radio.Group value={step.type} buttonStyle="solid" style={{ width: "100%" }} onChange={e => {
                        let newStep = { ...step }
                        newStep.type = e.target.value
                        const newWorkflow = updateStep(localWorkflow, newStep)
                        updateLocalWorkflow(newWorkflow)
                    }}>
                        <Radio.Button style={{ width: 150, textAlign: "center" }} value="get">Get</Radio.Button>
                        <Radio.Button style={{ width: 150, textAlign: "center" }} value="set">Set</Radio.Button>
                    </Radio.Group>

                    <br />
                    <br />

                    <h4>From following key...</h4>

                    <Mentions
                        style={{ width: '100%', marginBottom: 15 }}
                        placeholder={"Key"}
                        size="large"
                        prefix={['{']}
                        onChange={value => {
                            let newStep = { ...step }
                            newStep.key = value
                            const newWorkflow = updateStep(localWorkflow, newStep)
                            updateLocalWorkflow(newWorkflow)
                        }}
                        value={step.key || ""}
                    >
                        {getProperties([...availableVariables, ...encapsulate(workflowVariables, "storage")])
                            // .filter(property => property.type !== 'object' && property.type !== "array")
                            .map(model => <Option key={model.path} value={model.path + "}"}>
                                <strong style={{ color: "#293742" }}>{display(model.path)}</strong><br />
                                <i style={{ color: "#495762" }}>{display(model.type)}{model.example ? <span>, Ex.: {model.example}</span> : <span></span>}</i><br />
                            </Option>)}
                    </Mentions>


                    {step.type === "set" && <>
                        <h4>With following value...</h4>

                        <Mentions
                            style={{ width: '100%', marginBottom: 15 }}
                            placeholder={"Value"}
                            size="large"
                            prefix={['{']}
                            onChange={value => {
                                let newStep = { ...step }
                                newStep.value = value
                                const newWorkflow = updateStep(localWorkflow, newStep)
                                updateLocalWorkflow(newWorkflow)
                            }}
                            value={step.value || ""}
                        >
                            {getProperties([...availableVariables, ...encapsulate(workflowVariables, "storage")])
                                // .filter(property => property.type !== 'object' && property.type !== "array")
                                .map(model => <Option key={model.path} value={model.path + "}"}>
                                    <strong style={{ color: "#293742" }}>{display(model.path)}</strong><br />
                                    <i style={{ color: "#495762" }}>{display(model.type)}{model.example ? <span>, Ex.: {model.example}</span> : <span></span>}</i><br />
                                </Option>)}
                        </Mentions>
                    </>}

                </Card>
            </td>
        </React.Fragment>
    }
}