import React from 'react';
import api from '../api'

import { Avatar, Button, Input, PageHeader, Table, Tag, Typography } from 'antd';

import ReactJson from 'react-json-view'

const { Paragraph } = Typography;


export default class Products extends React.Component {
	constructor(props) {
		super(props)

		document.title = "Products — Simplifier"

		this.state = {
			connectors: []
		}
	}

	async componentDidMount() {
		const { data: { connectors } } = await api('products')

		this.setState({ connectors })
	}

	async open(id) {

	}

	render() {
		const { connectors, search } = this.state

		console.log(connectors)


		const dataSource = connectors.map(product => {
			return {
				_id: product._id,
				key: product._id,
				name: product.name,
				product: product,
				authType: (product.auth || {}).type,
				blocks: product.methods.length,
				logo: product.logo,
				state: product.state
			}
		})

		const columns = [{
			title: '',
			dataIndex: 'logo',
			key: 'logo',
			render: logo => logo ? <Avatar shape="square" size="large" src={logo} /> : <Avatar shape="square" size="large" icon="user" />
		}, {
			title: 'Name',
			dataIndex: 'name',
			key: 'name'
		}, {
			title: 'Auth type',
			dataIndex: 'authType',
			key: 'authType',
			render: type => <span>{type || '—'}</span>
		}, {
			title: 'Blocks',
			dataIndex: 'blocks',
			key: 'blocks',
			sorter: (a, b) => a.blocks - b.blocks,
			sortDirections: ['ascend', 'descend']
		}, {
			title: 'State',
			dataIndex: 'state',
			// sorter: (a, b) => parseInt(a.state) - parseInt(b.state),
			filters: [
				{ text: "Broken", value: "4" },
				{ text: "Needs check", value: "3" },
				{ text: "Valid", value: "2" },
				{ text: "Tested", value: "1" },
			],
			onFilter: (value, record) => record.state === value,
			key: 'state',
			render: type => {
				if (type === "4") return <Tag color="volcano">Broken</Tag>
				if (type === "3") return <Tag color="gold">Needs check</Tag>
				if (type === "2") return <Tag color="blue">Valid</Tag>
				if (type === "1") return <Tag color="green">Tested</Tag>
				return <span><i>Not set</i></span>
			}
		}, {
			title: '',
			dataIndex: '_id',
			key: 'open',
			render: (_id, record) => {
				if (!record.product.canEdit) return <p>—</p>
				return <Button type="primary" onClick={() => this.props.history.push(`/products/${_id}`)}>Open</Button>
			}
		}]

		return <React.Fragment>

			<PageHeader
				title="Products"
				style={{ backgroundColor: 'white' }}
				extra={[<Button key="3" size="large" type="primary" icon="plus" loading={this.state.createProductLoader} onClick={async () => {
					this.setState({ createProductLoader: true })

					const defaultProduct = { name: "", baseURL: "", auth: {}, logo: "" }

					const { data: { createProduct: { _id } } } = await api.post('products', defaultProduct)

					this.setState({ createProductLoader: false })

					this.props.history.push(`/products/${_id}`)
					return true
				}}>Add product</Button>]}
			>
				<div className="wrap">
					<div className="content">

						<Input.Search size="large" placeholder="Search..." value={search} onChange={e => this.setState({ search: e.target.value })} />

						<br />
						<br />

						<h2>My products</h2>

						<Table
							bordered
							dataSource={dataSource
								.filter(product => product.product.owner === "private")
								.filter(product => (product.name || "").toLowerCase().indexOf((search || "").toLowerCase()) > -1)}
							columns={columns}
						/>

						<br />

						<h2>Public products</h2>

						<Table
							bordered
							dataSource={dataSource
								.filter(product => product.product.owner === "public")
								.filter(product => (product.name || "").toLowerCase().indexOf((search || "").toLowerCase()) > -1)}
							columns={columns}
						/>
					</div>
				</div>
			</PageHeader>


		</React.Fragment>
	}
}
