import { Button, Checkbox, Icon, Input, InputNumber, List, Select, Table, Tabs, Typography } from 'antd'
import { format } from 'json-string-formatter'
import React, { useState } from 'react'
import AceEditor from 'react-ace'
import TextareaAutosize from "react-textarea-autosize"
import uuidv4 from 'uuid/v4'
import api from '../api'
import { buildSchema, buildTree, resetValues, set } from '@simplifier/normalization'
import { representPropertiesRecursively } from '../utils/workflows'

const { Paragraph } = Typography
const { Option } = Select
const { TabPane } = Tabs

let timer;
function debounce(callback, t = 300) {
	return function () {
		var args = arguments;
		var context = this;
		clearTimeout(timer);
		timer = setTimeout(function () {
			console.log("debounce")
			callback.apply(context, args);
		}, t)
	}
}

function deductProperties(state) {
	return Object.entries(state).filter(([p, v]) => p[0] === "$")
}

export default class extends React.Component {
	constructor(props) {
		super(props)

		// console.log(props.inputSchema)

		let serializedProperties = {}

		for (let property of props.inputSchema) {
			serializedProperties["$" + property.id] = property
		}


		console.log({ serializedProperties })
		console.log(Object.entries(serializedProperties))

		this.state = {
			onUpdate: props.onUpdate || console.log,
			dirty: false,
			inputSchema: props.inputSchema || [],
			inputJson: props.inputJson ? format(JSON.stringify(buildTree(props.inputSchema))) : "{}",
			userSchemas: [],
			loadingSchemas: true,


			...serializedProperties
		}
	}

	async componentDidMount() {
		console.log('mount schema builder')
		const { data: { schemas } } = await api(`schemas`)
		this.setState({ loadingSchemas: false, userSchemas: schemas })

		let inputJson = {}

		try {
			console.log(JSON.stringify(buildTree(this.props.inputSchema)))
			inputJson = format(JSON.stringify(buildTree(this.props.inputSchema)))
		} catch (error) {
			console.log(error)
		}

		this.setState({ inputJson })

	}

	cleanJson() {
		this.setState({
			inputJson: format(this.state.inputJson)
		})
	}

	getGenericExampleFromType(type) {
		if (type === "number") return 42
		if (type === "integer") return 42
		if (type === "string") return "Hello world"
		if (type === "boolean") return true
		else return "Data"
	}

	onExampleChange(string) {
		this.setState({ inputJson: string, loading: true })

		debounce(() => {
			try {
				// console.log(JSON.stringify(string))

				// const json = JSON.parse(string)
				let json = {}


				// try {
					// console.log('pa±rsing json')
					json = JSON.parse(string)
					// console.log('json parsed')
				// } catch (error) {
					// console.log('could not parse string! ')
				// }


				console.log(JSON.stringify(buildSchema(json)))

				const inputSchema = [
					// ...this.state.inputSchema,
					...buildSchema(json),
					// ...updateSchemaFromTree(this.state.inputSchema, json),
				]

				console.log({ inputSchema })

				this.setState({
					inputSchema,
					loading: false
				})
				// console.log('Example changed')
				this.state.onUpdate(inputSchema)
			} catch (error) {
				console.log({ error })
			}
		})()
	}

	render() {
		const { inputSchema, inputJson, dirty, loading } = this.state

		// console.log('render schema builder')

		// return <p>{JSON.stringify(inputJson)}</p>


		if(this.props.structureOnly) {
			return <SchemaTable currentBase={null} properties={inputSchema} onChange={properties => {
				let inputJson = ""

				try { inputJson = format(JSON.stringify(buildTree(properties))) } 
				catch (error) { console.log({ error }) }

				this.setState({ inputSchema: properties, dirty: true, inputJson })

				this.state.onUpdate(properties)
			}} />
		}


		return <React.Fragment>

			<Tabs
				defaultActiveKey="2"
				tabBarExtraContent={[
					<Icon type="code" style={{ marginRight: 5, cursor: 'pointer' }} onClick={this.cleanJson.bind(this)}/>,
					loading ? <Icon type="loading" /> : <Icon type="check" style={{ color: "#52c41a" }} />
				]}
			>
				<TabPane tab="Duplicate existing schema" key="0">

					{this.state.loadingSchemas ? <div>
						<p>Loading history...</p>
					</div>
						:
						<div>
							<Button
								style={{ marginBottom: 15 }}
								onClick={async () => {
									const { data: { schemas } } = await api(`schemas`)
									this.setState({ loadingSchemas: false, userSchemas: schemas })
								}}
							>Reload</Button>
							<List
								size="large"
								bordered
								dataSource={this.state.userSchemas}
								renderItem={item => <List.Item>
									<List.Item.Meta title={<h4>{item.name}</h4>} />
									<Button onClick={() => {
										this.setState({
											inputSchema: item.properties,
											dirty: true,
											inputJson: format(JSON.stringify(buildTree(item.properties)))
										})
										this.state.onUpdate(item.properties)
									}}>Import</Button>
								</List.Item>}
							/>
						</div>
					}


				</TabPane>
				<TabPane tab="Deduct from example" key="1">
					<AceEditor
						mode="json"
						onChange={this.onExampleChange.bind(this)}
						value={inputJson}
						name="UNIQUE_ID_OF_DIV999"
						editorProps={{ $blockScrolling: true }}
						style={{ height: 300, borderRadius: 3, padding: 10, boxShadow: "0px 1px 1px 1px rgba(0,0,0,0.10)", width: "100%" }}
						placeholder="{}"
						fontSize={18}
					/>
				</TabPane>
				<TabPane tab="Data schema" key="2">

					<Input.Group size="large" style={{ marginBottom: 15 }}>
						<Input size="large" style={{ width: '58%' }} placeholder="Schema name" value={this.state.schemaName} onChange={e => this.setState({ schemaName: e.target.value })} />
						<Button
							type="default"
							size="large"
							style={{ width: "40%", float: "right" }}
							onClick={async () => {
								console.log(inputSchema)

								await api('schemas', 'post', {
									properties: resetValues(inputSchema),
									name: this.state.schemaName || "Untitled"
								})

								console.log(inputSchema)
								this.state.onUpdate(inputSchema)
							}}
						>
							Wanna reuse this schema? Save it.
					</Button>
					</Input.Group>

					<br />



					{/* {
						deductProperties(this.state).map(([id, property]) => {
							return <div>
								{property.path} - {id}
								<Input value={property.path} onChange={e => {
									let newProperty = {}
									newProperty["$" + property.id] = {
										...property,
										path: e.target.value
									}
									// console.log(newProperty)

									this.setState(newProperty)
								}} />	
							</div>
						})
					} */}





					<SchemaTable currentBase={null} properties={inputSchema} onChange={properties => {

						let inputJson = ""

						try {
							inputJson = format(JSON.stringify(buildTree(properties)))
						} catch (error) {
							console.log({ error })
						}


						this.setState({
							inputSchema: properties,
							dirty: true,
							inputJson
						})
						// console.log('Params table changed')
						this.state.onUpdate(properties)
					}} />
				</TabPane>
			</Tabs>
		</React.Fragment>
	}
}

function CustomInput({ kind, initialValue, onChange, properties, recordId, field, placeholder, type }) {
	const [value, setValue] = useState(initialValue);
	const [loading, setLoading] = useState(false);

	// console.log('Rendering custom input', initialValue)

	let timer;
	function deb(callback, t = 300) {
		return function () {
			var args = arguments;
			var context = this;
			clearTimeout(timer);
			timer = setTimeout(function () {
				callback.apply(context, args);
			}, t)
		}
	}

	const change = function (v) {
		setValue(v)
		setLoading(true)
		let obj = {}
		obj[field] = v
		deb(() => {
			onChange(set(properties, recordId, obj))
			setLoading(false)
		}, 300)()
	}

	if (type === "boolean") {
		return <Select
			style={{ width: "100%", marginBottom: 10 }}
			value={value}
			onChange={v => change(v)}
			loading={loading}
			placeholder={placeholder}
		>
			<Option value={true}>True</Option>
			<Option value={false}>False</Option>
		</Select>
	}

	if (kind === "textarea") {
		return <TextareaAutosize
			className="ant-input"
			onChange={e => change(e.target.value)}
			value={value}
			maxRows={5}
			placeholder={placeholder}
			style={{ minHeight: 75, fontSize: 16, marginLeft: 0, width: "100%" }}
		/>
	}

	else if (type === "number" || type === "integer") {
		return <InputNumber
			style={{ width: "100%", marginBottom: 10 }}
			value={value}
			onChange={v => change(v)}
			loading={loading}
			placeholder={placeholder}
		/>
	}

	if (kind) { return <span>—</span> }

	return <div><Input
		value={value}
		suffix={loading ? <Icon type="loading" /> : <></>}
		onChange={e => change(e.target.value)}
		placeholder={placeholder}
		style={{ width: "100%", marginBottom: 10 }}
	/></div>
}

class SchemaTable extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			properties: props.properties,
			currentBase: props.currentBase || null
		}

		this.i = 0
	}

	deleteProperty(id) {
		let newProperties = this.state.properties.filter(prop => prop.id !== id)
		this.onChange(newProperties)
	}

	onChange(properties) {
		this.setState({ properties })
		return this.props.onChange(properties)
	}

	render() {

		const getDataSource = representPropertiesRecursively

		const { properties, currentBase } = this.props

		const columns = [

			// {
			// 	title: " ", width: 50,
			// 	dataIndex: "prop", key: "label", render: record => <></>
			// },
			{
				title: "Property", className: "nowrapcol", width: 150,
				dataIndex: "prop", key: "label", render: record => <div style={{ display: 'inline-block' }}>
					<CustomInput initialValue={record.label} placeholder="Label" onChange={this.onChange.bind(this)} properties={properties} recordId={record.id} field="label" /><br />
					<CustomInput initialValue={record.path} placeholder="Key" onChange={this.onChange.bind(this)} properties={properties} recordId={record.id} field="path" />
				</div>
			},
			{
				title: "Description", width: 250, dataIndex: "prop", key: "description", render: record => {
					return <CustomInput kind="textarea" initialValue={record.description} placeholder="Description" onChange={this.onChange.bind(this)} properties={properties} recordId={record.id} field="description" />
				}
			},
			{
				title: "Format", width: 120, dataIndex: "prop", key: "type", render: record => {
					return <>
						<Select style={{ width: "100%", marginBottom: 10 }} value={record.type} onChange={type => this.onChange(set(properties, record.id, { type, example: undefined, default: undefined }))}>
							<Option value="string">String</Option>
							<Option value="number">Number</Option>
							<Option value="integer">Integer</Option>
							<Option value="boolean">Boolean</Option>
							<Option value="array">Array of objects</Option>
						</Select>
						{/* 
						<Select style={{ width: "100%", marginBottom: 10 }} value={record.required || false} onChange={required => this.onChange(set(properties, record.id, { required }))}>
							<Option value={true}>Required</Option>
							<Option value={false}>Optional</Option>
						</Select> */}

						<Checkbox onChange={e => this.onChange(set(properties, record.id, { required: e.target.checked }))} checked={record.required || false}>Required</Checkbox>

						<Checkbox onChange={e => this.onChange(set(properties, record.id, { isList: e.target.checked }))} checked={record.isList || false}>Is a list</Checkbox>

					</>
				}
			},
			{
				title: "Values", width: 150, dataIndex: "prop", key: "example", render: record => {
					// if (record.type === "string") {

					return record.type !== "array" ? <>
						<CustomInput
							initialValue={record.default}
							placeholder="Default value"
							onChange={this.onChange.bind(this)}
							properties={properties}
							recordId={record.id}
							field="default"
							type={record.type}
						/>

						<CustomInput
							initialValue={record.example}
							placeholder="Example value"
							onChange={this.onChange.bind(this)}
							properties={properties}
							recordId={record.id}
							field="example"
							type={record.type}
						/>

					</> : <span>—</span>

				}
			},
			{
				title: " ", width: 80, dataIndex: "prop", key: "action", render: record => {

					if (record.type === "array") {
						return <div><Button block style={{ width: "100%", marginBottom: 10 }} icon="plus" onClick={() => {
							let newProperties = properties
							this.i = this.i + 1
							newProperties.push({
								"id": uuidv4(),
								"label": "",
								"description": "",
								"required": false,
								"path": "untitled-" + this.i,
								"type": "string",
								"example": "",
								"base": record.id
							})

							this.onChange(newProperties)
						}}>Child</Button><br /><Button style={{ width: "100%" }} type="danger" icon="delete" onClick={() => this.deleteProperty(record.id)}></Button></div>
					}
					return <span><Button block style={{ width: "100%" }} type="danger" icon="delete" onClick={() => this.deleteProperty(record.id)}></Button></span>
				}
			},
		]

		const dataSource = getDataSource(properties, currentBase)

		// console.log({dataSource})

		return <div style={{ backgroundColor: "white", borderRadius: 5, marginBottom: 20 }}>

			<Table
				bordered pagination={false}
				// expandRowByClick={true}
				size="middle"
				// scroll={{ x: 1370 }}
				dataSource={dataSource} columns={columns}
				footer={data => {
					return <Button block icon="plus" onClick={() => {
						let newProperties = properties
						this.i = this.i + 1
						newProperties.push({
							"id": uuidv4(),
							"label": "",
							"description": "",
							"required": false,
							"path": "untitled-" + this.i,
							"type": "string",
							"example": "",
							"base": null
						})

						this.onChange(newProperties)
					}}>Add property</Button>
				}
				}
			/>


		</div >
	}
}