import { Avatar, Badge, Button, Card, Icon, List, Modal, Popconfirm, Popover } from 'antd';
import React from 'react';
import api from '../../api';
import Normalizer from '../../components/Normalizer';
import { encapsulate, flatten } from '@simplifier/normalization'
import { deleteStep, updateStep, updateStepInput } from '../../utils/workflows.js';
import StepCard from "../CardComponents"
import { EuiPopover, EuiContextMenuPanel, EuiIcon, EuiFormRow, EuiFlexGroup, EuiFlexItem, EuiButton, EuiButtonEmpty, EuiCard, EuiTitle, EuiButtonIcon } from "@elastic/eui"

import Truncate from '../utils/Truncate'

export default class ConnectorStep extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			empty: this.props.empty,
			inputMappingModal: false,
			outputVariablesModal: false,
			reloadingStep: false
		}
	}

	render() {
		const {
			step,
			block,
			localWorkflow,
			availableVariables,
			workflow,
			availableMethods,
			products,
			updateWorkflow,
			updateLocalWorkflow,
			updateAvailableMethods,
			workflowVariables
		} = this.props

		const { empty, inputMappingModal, outputVariablesModal } = this.state

		const outputParameters = block ? flatten(block.output)
			.filter(item => !item.base)
			.filter(item => item.path) : []

		if (!block) {
			return <td valign="top" style={{ position: 'relative' }}>
				<Popover content="Then, do..."><Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-25px', top: '150px' }} /></Popover>
				<Card style={{ scrollSnapAlign: "start" }}
					className='workflowCard'
					extra={<>
						<Popconfirm
							title="Do you really want to delete this step?"
							onConfirm={() => {
								let newWorkflow = deleteStep(workflow, step)
								updateWorkflow(newWorkflow)
							}}
							okText="Yes"
							cancelText="No"
						>
							<Button size="small" shape="circle" icon="close" />
						</Popconfirm>
					</>}
					title={<span title={"Untitled connector"}></span>}>

					This block does not exist
			</Card></td>
		}



		return <StepCard
			arrow={<EuiIcon type="arrowRight" />}
			title={block.name || "Untitled connector"}

			iconsLeft={[
				<EuiButtonEmpty size="s" small flush="left" style={{ marginTop: -5 }}>{block.product.name}</EuiButtonEmpty>
			]}
			iconsRight={[
				<EuiButtonIcon size="l" iconType="refresh" />,

				<EuiPopover
					id="contentPanel"
					anchorPosition="rightUp"
					button={<EuiButtonIcon size="l" iconType="trash" onClick={() => this.setState({ popoverOpen: !this.state.popoverOpen })} />}
					isOpen={this.state.popoverOpen}
					closePopover={() => this.setState({ popoverOpen: false })}
					panelPaddingSize="s"
				>
					<EuiContextMenuPanel>
						<EuiFormRow label="Are you sure?">
							<EuiButton
								onClick={() => {
									let newWorkflow = deleteStep(workflow, step)
									updateWorkflow(newWorkflow)
								}}
								iconType="trash"
								color="danger"
							>
								Delete that step
							</EuiButton>
						</EuiFormRow>
					</EuiContextMenuPanel>
				</EuiPopover>
			]}
			footer={<>
				{/* <Button icon="login" size="large" block size="large" type="default" onClick={() => this.setState({ inputMappingModal: true })} style={{ marginBottom: 15 }}>
				{empty && <Badge status="error" />} Edit input data mapping
			</Button> */}



				<EuiFormRow>
					<EuiFlexItem grow={false}>
						<EuiButton
							onClick={() => window.alert('Button clicked')}
						// iconType="arrowUp"
						>
							Update input mapping
       				</EuiButton>
					</EuiFlexItem>
				</EuiFormRow>


				<Modal
					title="Update data mapping"
					visible={inputMappingModal}
					style={{ maxWidth: "95%" }}
					onOk={() => { updateWorkflow(localWorkflow); this.setState({ inputMappingModal: false }) }}
					onCancel={() => this.setState({ inputMappingModal: false })}
					width={800}
				>
					<Normalizer
						input={step.input}
						model={step.action.split('-')[0]}
						availableVariables={[...availableVariables, ...encapsulate(workflowVariables, "storage")]}
						updateStepInput={input => {
							const newWorkflow = updateStepInput(localWorkflow, step.stepId, input)
							updateLocalWorkflow(newWorkflow)
						}}
						apiDocumentation={block.apiDocumentation}
						productHelp={block.product.auth.productHelp}
						blockHelp={block.help}
					/>
				</Modal>

				{/* <p>{block.description}</p> */}

				{/* <Button icon="logout" size="large" block size="large" type="default" onClick={() => this.setState({ outputVariablesModal: true })}>
				See output data
				</Button> */}

				<EuiFormRow>
					<EuiFlexItem grow={false}>
						<EuiButton
							onClick={() => window.alert('Button clicked')}
						// iconType="arrowUp"
						>
							See data output
       				</EuiButton>
					</EuiFlexItem>
				</EuiFormRow>

				<Modal
					title="See output variables"
					visible={outputVariablesModal || false}
					style={{ maxWidth: "95%" }}
					onOk={() => this.setState({ outputVariablesModal: false })}
					onCancel={() => this.setState({ outputVariablesModal: false })}
				>

					<List
						size="small"
						dataSource={outputParameters}
						renderItem={item => <List.Item>
							<List.Item.Meta
								title={<strong>{step.action}.{item.path}</strong>}
								description={<i>{item.type}</i>}
							/>
						</List.Item>}
					/>

				</Modal>
			</>}
		>

			<p style={{marginTop: 5}}><Truncate>{block.description}</Truncate></p>

		</StepCard>





		return <td valign="top" style={{ position: 'relative' }}>
			{/* <Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'relative', left: 27 }} /> */}

			<EuiCard
				// title={<span title={block.name}>{block ? (block.description ? block.description.split('.')[0] : "Untitled connector") : "Untitled connector"}</span>}
				style={{ scrollSnapAlign: "start", width: 300, height: 300, marginLeft: 32 }}
				textAlign="left"
			// icon={block.product.logo ? <img src={block.product.logo} style={{ marginRight: 5, maxWidth: 70 }} /> : <></>}
			// loading={this.state.reloadingStep}
			>
				{/* {block.product.logo ? <img src={block.product.logo} style={{ marginRight: 5, maxWidth: 36 }} /> : <></>} */}

				<EuiTitle size="s"><h3>{block.name || "Untitles connector"}</h3></EuiTitle>


				<Button size="small" shape="circle" icon="reload" style={{ marginRight: 5 }} onClick={async e => {
					let newStep = { ...step }

					this.setState({ reloadingStep: true })


					console.log(newStep)

					const { data: { block } } = await api('blocks/' + step.id)

					const newInput = flatten(block.input)

					// let currentParameters = step.parameters
					// const { data: database } = await api(`databases/${step.databaseId}/${step.tableName}`)

					// let newParameters = database.tables[0].fields

					for (let parameter of step.input) {
						if (parameter.value) {
							console.log('found', parameter)
							let property = newInput.filter(param => param.id === parameter.id)[0]

							if (property) {
								property.value = parameter.value
								console.log(newInput.filter(param => param.id === parameter.id)[0])
							}
						}
					}

					newStep.input = newInput

					const newWorkflow = updateStep(localWorkflow, newStep)
					updateLocalWorkflow(newWorkflow)

					this.setState({ reloadingStep: false })

				}} />
				<Popconfirm
					title="Do you really want to delete this step?"
					onConfirm={() => {
						let newWorkflow = deleteStep(workflow, step)
						updateWorkflow(newWorkflow)
					}}
					okText="Yes"
					cancelText="No"
				>
					<Button size="small" shape="circle" icon="close" />
				</Popconfirm>


				{/* {this.state.reloadingStep && "reloading"} */}

				{/* {step.uniqueId} */}

				<Button icon="login" size="large" block size="large" type="default" onClick={() => this.setState({ inputMappingModal: true })} style={{ marginBottom: 15 }}>
					{empty && <Badge status="error" />} Edit input data mapping
				</Button>
				<Modal
					title="Update data mapping"
					visible={inputMappingModal}
					style={{ maxWidth: "95%" }}
					onOk={() => { updateWorkflow(localWorkflow); this.setState({ inputMappingModal: false }) }}
					onCancel={() => this.setState({ inputMappingModal: false })}
					width={800}
				>
					<Normalizer
						input={step.input}
						model={step.action.split('-')[0]}
						availableVariables={[...availableVariables, ...encapsulate(workflowVariables, "storage")]}
						updateStepInput={input => {
							const newWorkflow = updateStepInput(localWorkflow, step.stepId, input)
							updateLocalWorkflow(newWorkflow)
						}}
						apiDocumentation={block.apiDocumentation}
						productHelp={block.product.auth.productHelp}
						blockHelp={block.help}
					/>
				</Modal>

				{/* <p>{block.description}</p> */}

				<Button icon="logout" size="large" block size="large" type="default" onClick={() => this.setState({ outputVariablesModal: true })}>
					See output data
				</Button>

				<Modal
					title="See output variables"
					visible={outputVariablesModal || false}
					style={{ maxWidth: "95%" }}
					onOk={() => this.setState({ outputVariablesModal: false })}
					onCancel={() => this.setState({ outputVariablesModal: false })}
				>

					<List
						size="small"
						dataSource={outputParameters}
						renderItem={item => <List.Item>
							<List.Item.Meta
								title={<strong>{step.action}.{item.path}</strong>}
								description={<i>{item.type}</i>}
							/>
						</List.Item>}
					/>

					{/* <Tag key={step.action} style={{ "marginBottom": "5px" }}>{item.path}</Tag> */}


				</Modal>
			</EuiCard>
		</td >
	}
}