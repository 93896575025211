import { Spinner } from "@blueprintjs/core";
import {
    EuiButton,
    EuiCard, EuiFlexGrid,
    EuiFlexGroup,
    EuiFlexItem, EuiPageContentBody,
    EuiPageContentHeader,
    EuiPageHeaderSection, EuiSpacer,
    EuiStat, EuiTitle
} from '@elastic/eui';
import { Typography } from 'antd';
import React from 'react';
import { Link } from "react-router-dom";
import api from '../api';

const { Paragraph } = Typography

export default class Projects extends React.Component {
    constructor(props) {
        super(props)

        document.title = "My apps — Simplifier"


        this.state = {
            projects: [],
            loading: false,
            createAppLoader: false
        }
    }

    async componentDidMount() {
        this.setState({ loading: true })
        const { data: projects } = await api('projects')
        this.setState({ projects, loading: false })
    }

    async timeout() {
        setTimeout(async () => {
            this.setState({ loading: true })
            const { data: projects } = await api('projects')
            if (!projects) return this.timeout()
            this.setState({ projects, loading: false })
        }, 100);
    }

    render() {

        const { projects } = this.state

        if (this.state.loading) return <span><br /><br /><h3 className="text-center"><Spinner /></h3></span>

        if (!this.state.projects.length) {
            this.timeout()
            return <span><br /><br /><h3 className="text-center"><Spinner /></h3></span>
        }

        return <EuiPageContentBody>
            <EuiPageContentHeader>
                <EuiPageHeaderSection>
                    <EuiTitle size="l">
                        <h1>My apps</h1>
                    </EuiTitle>
                </EuiPageHeaderSection>
                <EuiPageHeaderSection>
                    <EuiButton isLoading={this.state.createAppLoader} onClick={async () => {
                        this.setState({ createAppLoader: true })                        
                        const name = window.prompt("App name", "New app")
                        const { data } = await api('projects', 'post', { name })
                        console.log(data.id)
                        this.setState({ createAppLoader: false })
                        this.props.history.push('apps/' + data.id + "/scenarios")
                    }}>
                        Create app
                    </EuiButton>
                </EuiPageHeaderSection>
            </EuiPageContentHeader>

            <EuiSpacer size="l" />

            <EuiFlexGrid columns={3} gutterSize="l">
                {projects
                    .map((project, i) => <EuiFlexItem key={i}>
                        <Link to={'apps/' + project.id + "/scenarios"}><EuiCard
                            style={{
                                borderRadius: 10
                                // backgroundImage: i % 3 === 0 ? "linear-gradient(90deg, #ff715b, #ff934f)" : (i % 3 === 1 ? "linear-gradient(90deg, #ff934f, #3ccf91)" : "linear-gradient(90deg, #3ccf91, #3c5ccf)")
                            }}
                            title={project.name}
                            textAlign="left"
                            description={
                                <EuiFlexGroup>
                                    <EuiFlexItem>
                                        <EuiStat title={project.workflows.length} description="Integrations" titleSize="s" textAlign="left" reverse className="project-stat"/>
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                        <EuiStat title={project.workflows.reduce((acc, v) => acc + v.auths.length, 0)} description="Users" titleSize="s" textAlign="left" reverse className="project-stat"/>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            }
                            // onClick={console.log}
                            className="clickable-card"
                        /></Link>
                    </EuiFlexItem>)
                }
            </EuiFlexGrid>

        </EuiPageContentBody>
    }
}
