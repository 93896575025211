import { Avatar, Badge, Button, Card, Icon, List, Modal, Popconfirm, Popover } from 'antd';
import React from 'react';
import api from '../../api';
import Normalizer from '../../components/Normalizer';
import { encapsulate, flatten } from '@simplifier/normalization';
import { deleteStep, updateStep, updateStepInput } from '../../utils/workflows.js';


export default class ConnectorStep extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			empty: this.props.empty,
			inputMappingModal: false,
			outputVariablesModal: false,
			reloadingStep: false
		}
	}

	render() {
		const {
			step,
			block,
			localWorkflow,
			availableVariables,
			workflow,
			availableMethods,
			products,
			updateWorkflow,
			updateLocalWorkflow,
			updateAvailableMethods,
			workflowVariables
		} = this.props

		const { empty, inputMappingModal, outputVariablesModal } = this.state

		const outputParameters = block ? flatten(block.output)
			.filter(item => !item.base)
			.filter(item => item.path) : []

		console.log({ block })

		if (!block) {
			return <td valign="top" style={{ position: 'relative' }}>
				<Popover content="Then, do..."><Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-25px', top: '150px' }} /></Popover>
				<Card style={{ scrollSnapAlign: "start" }}
					className='workflowCard'
					extra={<>
						<Popconfirm
							title="Do you really want to delete this step?"
							onConfirm={() => {
								let newWorkflow = deleteStep(workflow, step)
								updateWorkflow(newWorkflow)
							}}
							okText="Yes"
							cancelText="No"
						>
							<Button size="small" shape="circle" icon="close" />
						</Popconfirm>
					</>}
					title={<span title={"Untitled connector"}></span>}>

					This block does not exist

					{JSON.stringify(step)}
				</Card></td>
		}

		return <td valign="top" style={{ position: 'relative' }}>
			<Popover content="Then, do..."><Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-25px', top: '150px' }} /></Popover>
			<Card
				style={{ scrollSnapAlign: "start" }}
				className='workflowCard'
				loading={this.state.reloadingStep}
				extra={<>
					{block.product.logo ? <Avatar shape="square" size="small" src={block.product.logo} style={{ marginRight: 5 }} /> : <Avatar shape="square" size="small" style={{ marginRight: 5 }}>{block.product.name}</Avatar>}


					<Button size="small" shape="circle" icon="reload" style={{ marginRight: 5 }} onClick={async e => {
						let newStep = { ...step }

						this.setState({ reloadingStep: true })


						console.log(newStep)

						const { data } = await api('blocks/' + step.id)
						const block = data.method

						console.log({data})

						const newInput = flatten(block.input)

						for (let parameter of step.input) {
							if (parameter.value) {
								console.log('found', parameter)
								let property = newInput.filter(param => param.id === parameter.id)[0]

								if (property) {
									property.value = parameter.value
									console.log(newInput.filter(param => param.id === parameter.id)[0])
								}
							}
						}

						newStep.input = newInput

						const newWorkflow = updateStep(localWorkflow, newStep)
						updateLocalWorkflow(newWorkflow)

						this.setState({ reloadingStep: false })

					}} />



					<Popconfirm
						title="Do you really want to delete this step?"
						onConfirm={() => {
							let newWorkflow = deleteStep(workflow, step)
							updateWorkflow(newWorkflow)
						}}
						okText="Yes"
						cancelText="No"
					>
						<Button size="small" shape="circle" icon="close" />
					</Popconfirm>
				</>}
				title={<span title={block.name}>{block ? (block.description ? block.description.split('.')[0] : "Untitled connector") : "Untitled connector"}</span>}
			>

				{/* {this.state.reloadingStep && "reloading"} */}

				{/* {step.uniqueId} */}

				<Button icon="login" size="large" block size="large" type="default" onClick={() => this.setState({ inputMappingModal: true })} style={{ marginBottom: 15 }}>
					{empty && <Badge status="error" />} Edit input data mapping
				</Button>
				<Modal
					title="Update data mapping"
					visible={inputMappingModal}
					style={{ maxWidth: "95%" }}
					onOk={() => { updateWorkflow(localWorkflow); this.setState({ inputMappingModal: false }) }}
					onCancel={() => this.setState({ inputMappingModal: false })}
					width={800}
				>
					<Normalizer
						input={step.input}
						model={step.action.split('-')[0]}
						availableVariables={[...availableVariables, ...encapsulate(workflowVariables, "storage")]}
						updateStepInput={input => {
							const newWorkflow = updateStepInput(localWorkflow, step.stepId, input)
							updateLocalWorkflow(newWorkflow)
						}}
						apiDocumentation={block.apiDocumentation}
						productHelp={block.product.auth.productHelp}
						blockHelp={block.help}
					/>
				</Modal>

				{/* <p>{block.description}</p> */}

				<Button icon="logout" size="large" block size="large" type="default" onClick={() => this.setState({ outputVariablesModal: true })}>
					See output data
				</Button>

				<Modal
					title="See output variables"
					visible={outputVariablesModal || false}
					style={{ maxWidth: "95%" }}
					onOk={() => this.setState({ outputVariablesModal: false })}
					onCancel={() => this.setState({ outputVariablesModal: false })}
				>

					<List
						size="small"
						dataSource={outputParameters}
						renderItem={item => <List.Item>
							<List.Item.Meta
								title={<strong>{step.action}.{item.path}</strong>}
								description={<i>{item.type}</i>}
							/>
						</List.Item>}
					/>

					{/* <Tag key={step.action} style={{ "marginBottom": "5px" }}>{item.path}</Tag> */}


				</Modal>
			</Card></td>
	}
}