import { Spinner } from "@blueprintjs/core";
import { Row, Badge, Col, Alert, Button, Card, Checkbox, Descriptions, Divider, Dropdown, Form, Icon, Input, Menu, message, PageHeader, Radio, Switch, Table, Tabs, Typography } from 'antd';
import 'brace/mode/json';
import 'brace/theme/solarized_dark';
import React, { useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import uuidV4 from 'uuid/v4';
import api from '../api';
import InputBlock from '../components/InputBlock';
import Steps from '../components/Steps';
import WorkflowLogs from '../components/WorkflowLogs';
import WorkflowUsers from '../components/WorkflowUsers';
import { encapsulate, buildTreeWithModelValues } from '@simplifier/normalization';
import { getAuths } from '../utils/workflows.js';

import { getBlockById, pushToSequence, updateStepInput } from '../utils/workflows.js'
import Step from '../components/Step'
import uuidv4 from 'uuid/v4'

import Normalizer from '../components/Normalizer'

import '@elastic/eui/dist/eui_theme_amsterdam_light.css';

import {
    EuiIcon,
    EuiTabs,
    EuiTab,
    EuiSpacer,
} from '@elastic/eui';

import ReactMarkdown from 'react-markdown'
import { JsonHighlightRules } from "brace/mode/json";

const { Title, Paragraph, Text } = Typography;

export default class Workflow extends React.Component {
    constructor(props) {
        super(props)

        const defaultTab = (new URLSearchParams(window.location.search)).get('tab') || "builder"

        this.state = {
            persistingWorkflow: false,
            normalizerEmpty: true,
            availableMethods: [],
            authModes: [],
            inputScaffold: "{}",
            scaffold: {},
            showNewBlockCard: false,
            id: "",
            outputScaffold: "{}",
            defaultTab: defaultTab,
            currentTab: defaultTab,
            localWorkflow: null,

            workflowVariables: [],

            workflow: null,
            auth: {},
            products: [],

            displayedSequence: 0,
            openedStep: { step: null }
        }

    }

    async componentDidMount() {
        const { workflowId } = this.props.match.params
        const { data } = await api(`workflows/${workflowId}`)
        const { data: authModes } = await api(`workflow/authModes/developerData`)

        const workflow = data.workflow
        const availableMethods = data.availableMethods
        const databases = data.databases

        this.setState({
            availableMethods: availableMethods,
            workflow: workflow.workflow,
            auth: workflow.workflow.auth,

            authModes,

            blockIds: workflow.blockIds,

            localWorkflow: workflow.workflow,
            projectId: workflow.project.id,
            id: workflow.id,

            workflowVariables: data.variables,
            databases,

            inputScaffold: JSON.stringify(workflow.workflow.inputExample),
            scaffold: workflow.workflow.inputExample,
            outputScaffold: JSON.stringify(workflow.workflow.outputExample),
        })
    }

    updateOutputSchema(input) {
        let workflow = this.state.workflow

        // console.log({ newOutSchema: input })

        workflow.outputSchema = input

        this.setState({ workflow })
    }


    updateWorkflow(newWorkflow) {
        // console.log("Update workflow", newWorkflow)
        // console.log({
        //     workflow: {
        //         ...newWorkflow,
        //         auth: getAuths(newWorkflow, this.state.availableMethods)
        //     }
        // })
        this.setState({
            workflow: {
                ...newWorkflow,
                auth: getAuths(newWorkflow, this.state.availableMethods)
            },
            auth: getAuths(newWorkflow, this.state.availableMethods)
        })
    }

    async persistWorkflow() {
        const { id, workflow, availableMethods } = this.state

        this.setState({ persistingWorkflow: true })

        let newWorkflow = {
            ...workflow,
            auth: this.state.auth
        }

        // console.log("w", this.state.workflowVariables)

        // console.log(this.state.availableMethods.map(b => b.id).join(","))

        await api(`workflows/${id}/update`, 'post', {
            workflow: newWorkflow,
            triggerDelay: workflow.delay,
            blockIds: this.state.availableMethods.map(b => b.id).join(","),
            workflowVariables: this.state.workflowVariables
        })

        this.setState({ persistingWorkflow: false, workflow: newWorkflow })
    }

    updateData(productName, key, value) {
        let { workflow } = this.state
        let auth = workflow.auth

        // console.log({ update: { productName, key, value } })

        auth[productName]['data'][key] = value
        workflow.auth = auth

        // console.log({ newAuth: workflow.auth })

        this.setState({ auth })
    }

    async updateAvailableMethods(block) {
        const { availableMethods } = this.state
        // console.log("updating av blocks", availableMethods)
        // console.trace()
        this.setState({
            availableMethods: [...availableMethods, block]
        })
        return [...availableMethods, block]
    }

    render() {
        let { state: { workflow, projectId, persistingWorkflow, id, availableMethods, products, databases, authModes } } = this
        let { state: { persistWorkflow, displayedSequence, openedStep } } = this

        if (!workflow) {
            return <React.Fragment>
                <PageHeader
                    title={"Workflow"}
                    style={{ backgroundColor: 'white' }}
                >
                    <br /><br /><h3 className="text-center"><Spinner /></h3><br /><br />
                </PageHeader>
            </React.Fragment>
        }

        let auth = this.state.auth

        async function handleMenuClick(workflowId, value) {

            const { key } = value
            if (key === "duplicate") {
                const { data: { createWorkflow: { id } } } = await api(`workflows/${workflowId}/duplicate`, 'post')
                // this.props.history.push(`/workflows/${id}`)
                message.success(<span>Workflow has been duplicated, <a href={"/workflows/" + id}>open</a></span>, 10);
            }

            if (key === "delete") {
                // alert('delete')
            }
        }

        const menu = <Menu onClick={value => handleMenuClick(id, value)}>
            <Menu.Item key="duplicate">
                <Icon type="copy" />
                Duplicate
            </Menu.Item>
            <Menu.Item key="delete">
                <Icon type="delete" />
                Delete workflow
            </Menu.Item>
        </Menu>

        const eventSequences = workflow.sequences.filter(s => s.type === "event")

        const Purple = props => <span style={{ color: "#A626A4", fontWeight: 600 }}>{props.children}</span>
        const Blue = props => <span style={{ color: "#4078F2", fontWeight: 600 }}>{props.children}</span>
        const Green = props => <span style={{ color: "#53A352", fontWeight: 600 }}>{props.children}</span>
        const Yellow = props => <span style={{ color: "#C18401", fontWeight: 600 }}>{props.children}</span>
        const Black = props => <span style={{ color: "#000", fontWeight: 600 }}>{props.children}</span>

        const ButtonColor = "#53A352"

        const Sequence = props => {
            const { steps, updateDisplayedSequence, openStep } = props

            const [isExpanded1, expand1] = useState(true)
            const [isExpanded2, expand2] = useState(true)

            console.log(steps)

            return <>{steps.map(step => {

                const toDisplay = {
                    step,
                    sequenceId: 123
                }

                if (step.id) {
                    return <div class="euiPanel euiPanel--paddingMedium" style={{ marginBottom: 8 }} onClick={() => openStep(toDisplay)}>
                        <img src="https://logo.clearbit.com/copper.com?size=100" style={{ display: 'inline-block', width: 20, marginRight: 10 }} />

                        {/* <h3 style={{ display: "inline", position: "relative", top: -3 }}> */}
                        <Black>{step.name}</Black>
                        {/* {JSON.stringify(step)} */}
                        {/* </h3> */}
                    </div>
                }

                if (step.encapsulation) {
                    return <><div class="euiPanel euiPanel--paddingMedium" style={{ marginBottom: 8 }} onClick={() => openStep(toDisplay)}>
                        <Blue>Run sequence</Blue> <Button type="dashed" size="small" onClick={() => {
                            let i = 0
                            let n = null
                            for (let sequence of workflow.sequences) {
                                if (sequence.apiPath === step.sequencePath) {
                                    // n = i
                                    updateDisplayedSequence(i)
                                } else {
                                    i++
                                }
                            }
                        }} style={{ color: "#C18401", fontWeight: 600 }}>{step.sequencePath}</Button> <Purple>then do</Purple>

                        {/* <div><pre>{JSON.stringify(buildTreeWithModelValues(step.properties), null, 2)}</pre></div> */}

                        {/* {JSON.stringify(step.properties)} */}
                    </div>

                        {isExpanded1 && <Row>
                            <Col span={1}></Col>
                            <Col span={23}>

                                <div style={{ width: "1px", height: "100%", borderLeft: "1px solid #ccc", position: "absolute", left: -20 }}>&nbsp;</div>

                                <Sequence
                                    {...props}
                                    steps={step.then.do}
                                    availableMethods={this.state.availableMethods}
                                /></Col>
                        </Row>}


                    </>
                }

                if (step.return) {
                    return <div class="euiPanel euiPanel--paddingMedium" style={{ marginBottom: 8 }} onClick={() => openStep(toDisplay)}>
                        <Blue>Return HTTP response</Blue>
                        <div style={{ float: "right" }}>
                            {/* <Badge count={"200"} style={{ backgroundColor: '#52c41a' }} /> */}

                        </div>
                    </div >
                }

                if (step.ifelse) {
                    return <div>
                        <div class="euiPanel euiPanel--paddingMedium" style={{ marginBottom: 8 }} onClick={() => openStep(toDisplay)}>
                            <Purple>If</Purple> <Button type="dashed" size="small" style={{ color: ButtonColor, fontWeight: 600 }}>{step.if["1"]}</Button> <Button type="dashed" size="small" style={{ color: "#4FA2AD", fontWeight: 600 }} >{step.if["type"]}</Button> <Button type="dashed" size="small" style={{ color: ButtonColor, fontWeight: 600 }}>{step.if["2"]}</Button> <Purple>do</Purple>
                            <div style={{ float: "right" }}>
                                {isExpanded1 ? <Button shape="circle" icon="shrink" size="small" onClick={() => expand1(false)} /> : <Button shape="circle" icon="arrows-alt" size="small" onClick={() => expand1(true)} />}
                            </div>
                        </div>

                        {isExpanded1 && <Row style={{ margin: 0 }}>
                            <Col span={1}></Col>
                            <Col span={23}>

                                <div style={{ width: "1px", height: "calc(100% - 8px)", borderLeft: "1px solid #ccc", position: "absolute", left: -20 }}></div>

                                <Sequence
                                    {...props}
                                    steps={step.then.do}
                                    availableMethods={this.state.availableMethods}
                                /></Col>
                        </Row>}

                        <div class="euiPanel euiPanel--paddingMedium" style={{ marginBottom: 8 }}>
                            <Purple>Else</Purple> (<Button type="dashed" size="small" style={{ color: ButtonColor, fontWeight: 600 }}>{step.if["1"]}</Button> <Purple>not</Purple> <Button type="dashed" size="small" style={{ color: "#4FA2AD", fontWeight: 600 }} >{step.if["type"]}</Button> <Button type="dashed" size="small" style={{ color: ButtonColor, fontWeight: 600 }}>{step.if["2"]}</Button>) <Purple>do</Purple>
                            <div style={{ float: "right" }}>
                                {isExpanded2 ? <Button shape="circle" icon="shrink" size="small" onClick={() => expand2(false)} /> : <Button shape="circle" icon="arrows-alt" size="small" onClick={() => expand2(true)} />}
                            </div>
                        </div>

                        {isExpanded2 && <Row style={{ margin: 0 }}>
                            <Col span={1}></Col>
                            <Col span={23}>

                                <div style={{ width: "1px", height: "calc(100% - 8px)", borderLeft: "1px solid #ccc", position: "absolute", left: -20 }}></div>


                                <Sequence
                                    {...props}
                                    steps={step.else.do}
                                    availableMethods={this.state.availableMethods}
                                /></Col>
                        </Row>}
                    </div>
                }

                if (step.for2) {
                    return <div>
                        <div class="euiPanel euiPanel--paddingMedium" style={{ marginBottom: 8 }} onClick={() => openStep(toDisplay)}>
                            <Purple>For each</Purple> <Green>item</Green> <Purple>of</Purple> <Button type="dashed" size="small" style={{ color: ButtonColor, fontWeight: 600 }}>{step.forEach}</Button> <Purple>do</Purple>
                            <div style={{ float: "right" }}>
                                {isExpanded1 ? <Button shape="circle" icon="shrink" size="small" onClick={() => expand1(false)} /> : <Button shape="circle" icon="arrows-alt" size="small" onClick={() => expand1(true)} />}
                            </div>
                        </div>

                        {isExpanded1 && <Row>
                            <Col span={1}></Col>
                            <Col span={23}>

                                <div style={{ width: "1px", height: "100%", borderLeft: "1px solid #ccc", position: "absolute", left: -20 }}>&nbsp;</div>

                                <Sequence
                                    {...props}
                                    steps={step.do}
                                /></Col>
                        </Row>}
                    </div>
                }

                if (step.filter) {
                    return <div>
                        <div class="euiPanel euiPanel--paddingMedium" style={{ marginBottom: 8 }} onClick={() => openStep(toDisplay)}>
                            <Purple>For each</Purple> <Green>item</Green> <Purple>of</Purple> <Button type="dashed" size="small" style={{ color: ButtonColor, fontWeight: 600 }}>{step.forEach}</Button> <Purple>for which</Purple> <Button type="dashed" size="small" style={{ color: ButtonColor, fontWeight: 600 }}>{step.if["1"]}</Button> <Button type="dashed" size="small" style={{ color: "#4FA2AD", fontWeight: 600 }}>{step.if["type"]}</Button> {step.if["2"] && <Button type="dashed" size="small" style={{ color: ButtonColor, fontWeight: 600 }}>{step.if["2"]}</Button>} <Purple>do</Purple>
                            <div style={{ float: "right" }}>
                                {isExpanded1 ? <Button shape="circle" icon="shrink" size="small" onClick={() => expand1(false)} /> : <Button shape="circle" icon="arrows-alt" size="small" onClick={() => expand1(true)} />}
                            </div>
                        </div>

                        {isExpanded1 && <Row>
                            <Col span={1}></Col>
                            <Col span={23}>

                                <div style={{ width: "1px", height: "100%", borderLeft: "1px solid #ccc", position: "absolute", left: -20 }}>&nbsp;</div>

                                <Sequence
                                    {...props}
                                    steps={step.each.do}
                                /></Col>
                        </Row>}

                        <div class="euiPanel euiPanel--paddingMedium" style={{ marginBottom: 8 }}>
                            <Purple>If no</Purple> <Green>item</Green> <Purple>satisfies</Purple> <Button type="dashed" size="small" style={{ color: ButtonColor, fontWeight: 600 }}>{step.if["1"]}</Button> <Button type="dashed" size="small" style={{ color: "#4FA2AD", fontWeight: 600 }}>{step.if["type"]}</Button> {step.if["2"] && <Button type="dashed" size="small" style={{ color: ButtonColor, fontWeight: 600 }}>{step.if["2"]}</Button>}
                            <div style={{ float: "right" }}>
                                {isExpanded2 ? <Button shape="circle" icon="shrink" size="small" onClick={() => expand2(false)} /> : <Button shape="circle" icon="arrows-alt" size="small" onClick={() => expand2(true)} />}
                            </div>
                        </div>

                        {isExpanded2 && <Row style={{}}>
                            <Col span={1}></Col>
                            <Col span={23}>

                                <div style={{ width: "1px", height: "100%", borderLeft: "1px solid #ccc", position: "absolute", left: -20 }}>&nbsp;</div>


                                <Sequence
                                    {...props}
                                    steps={step.empty.do}
                                    availableMethods={this.state.availableMethods}
                                /></Col>
                        </Row>}
                    </div>
                }

                else {
                    return <div class="euiPanel euiPanel--paddingMedium" style={{ marginBottom: 8 }}>
                        {JSON.stringify(step)}
                    </div>
                }

            })
            }


                <div class="euiPanel euiPanel--paddingMedium euiPanel--isClickable" style={{ marginBottom: 8, backgroundColor: "rgba(255,255,255,0.1)", display: steps.filter(step => step.id || step.webhook || step.storage || step.database || step.dictionary || step.encapsulation).length === steps.length ? 'block' : 'none' }}>
                    Add a step
                </div>

            </>
        }

        return <>
            <PageHeader
                onBack={() => { if (projectId) { this.props.history.push(`/${projectId}/scenarios`) } }}
                title={"Integration builder"}
                style={{ backgroundColor: 'white' }}
                extra={[
                    <a key={0} href={`/workflows/${id}/doc`} target="_blank"><Button type="default" size="large" icon="link">Workflow documentation</Button></a>,

                    <Dropdown overlay={menu}>
                        <Button size="large">
                            Menu <Icon type="down" />
                        </Button>
                    </Dropdown>,


                    <Button key={1} type="primary" size="large" icon="save" onClick={this.persistWorkflow.bind(this)} loading={persistingWorkflow}>Save</Button>
                ]}
                footer={
                    <Tabs defaultActiveKey={this.state.defaultTab} onChange={value => {
                        this.setState({ currentTab: value })

                        const params = new URLSearchParams(window.location.search);

                        // console.log(params)
                        params.set('tab', value);
                        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
                    }}>
                        <Tabs.TabPane tab="Builder" key="builder"></Tabs.TabPane>
                        <Tabs.TabPane tab="Third party authentication" key="auth"></Tabs.TabPane>
                        <Tabs.TabPane tab="Users" key="users"></Tabs.TabPane>
                        <Tabs.TabPane tab="Logs" key="logs"></Tabs.TabPane>
                        {/* <Tabs.TabPane tab="Post user authentication workflow" key="4"></Tabs.TabPane> */}
                    </Tabs>
                }
            >
            </PageHeader>

            <div style={{ padding: 12, minHeight: 360, display: this.state.currentTab === "builder" ? 'block' : 'none' }} >

                <EuiTabs>
                    {workflow.sequences.map((sequence, i) =>
                        <EuiTab
                            onClick={() => this.setState({ displayedSequence: i })}
                            isSelected={i === displayedSequence}
                        >
                            {sequence.apiPath}
                        </EuiTab>
                    )}
                </EuiTabs>

                {workflow.sequences.filter((s, i) => i === displayedSequence).map(sequence => <div style={{ marginTop: 12 }}>

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ width: "60%", height: "100%", paddingTop: 8 }}>
                            <Sequence
                                steps={sequence.steps}
                                availableMethods={this.state.availableMethods}
                                updateDisplayedSequence={n => this.setState({
                                    displayedSequence: n,
                                    openedStep: { step: null }
                                })}
                                openStep={(step) => this.setState({ openedStep: step })}
                            />
                        </div>
                        <div class="euiPanel euiPanel--paddingMedium" style={{
                            height: "calc(100vh - 24px)", width: "40%", position: "sticky", top: 12, marginLeft: 12, marginTop: 8, marginBottom: 8, overflowY: "scroll"
                        }}>
                            {openedStep.step && <>


                                {
                                    ((props) => {
                                        if (openedStep.step.encapsulation) {
                                            return <div>
                                                <pre>{JSON.stringify(buildTreeWithModelValues(openedStep.step.properties), null, 2)}</pre>
                                            </div>
                                        }

                                        else if (openedStep.step.ifelse) {
                                            return <div>
                                                {openedStep.step.if["1"]}<br />
                                                {openedStep.step.if["type"]}<br />
                                                {openedStep.step.if["2"]}
                                            </div>
                                        }

                                        else if (openedStep.step.id) {


                                            return <Normalizer
                                                input={openedStep.step.input}
                                                model={openedStep.step.action.split('-')[0]}
                                                availableVariables={[]}
                                                // availableVariables={[...availableVariables, ...encapsulate(workflowVariables, "storage")]}
                                                updateStepInput={console.log}
                                            // apiDocumentation={getBlockById(openedStep.step.id, availableMethods).apiDocumentation}
                                            // productHelp={getBlockById(openedStep.step.id, availableMethods).product.auth.productHelp}
                                            // blockHelp={getBlockById(openedStep.step.id, availableMethods).help}
                                            />


                                            return <div>
                                                <pre>{JSON.stringify(buildTreeWithModelValues(openedStep.step.input), null, 2)}</pre>
                                            </div>
                                        }

                                        else return <Step
                                            step={openedStep.step}
                                            sequenceId={openedStep.sequenceId}
                                            key={uuidv4()}
                                            block={getBlockById(openedStep.step.id, availableMethods)}
                                            products={products}
                                            workflow={workflow}
                                            // updateStepInput={this.updateStepInput.bind(this)}
                                            updateWorkflow={console.log}
                                            availableMethods={availableMethods}
                                            // updateAvailableMethods={this.props.updateAvailableMethods}
                                            availableVariables={[]}
                                            // availableVariables={[
                                            //     ...inheritedVariables,
                                            //     ...this.getCurrentSequenceVariablesAvailable(step)
                                            // ]}
                                            // workflowVariables={workflowVariables}
                                            databases={databases}
                                        />
                                    })()
                                }


                            </>}

                        </div>
                    </div>
                </div>)}

            </div>

            <div style={{ padding: 24, minHeight: 360, overflowX: "scroll", display: this.state.currentTab === "auth" ? 'block' : 'none' }} >
                <Card>
                    <Title>Authentication configuration</Title>

                    {Object.keys(auth).map(product => {
                        let productAuth = auth[product]

                        console.log({ product })

                        const modes = authModes.filter(authMode => authMode.slug === productAuth.product.auth.type)

                        const authMode = modes.length ? modes[0] : null

                        return <div key={product} style={{ marginTop: 25 }}>
                            <Title level={2}>{productAuth.product.name}{productAuth.product.auth.developerAuthInstructions && <> (<a href={productAuth.product.auth.developerAuthInstructions} target="_blank">Auth documentation</a>)</>}</Title>

                            {productAuth.product.auth.authenticationHelp &&

                                <Alert
                                    message="Authentication help"
                                    description={<ReactMarkdown source={productAuth.product.auth.authenticationHelp} />}
                                    type="info"
                                    showIcon
                                    style={{ marginBottom: 15 }}
                                />
                            }


                            <Paragraph><Switch checkedChildren={<Icon type="check" />} defaultChecked={productAuth.data.step1} onChange={value => this.updateData(product, "step1", value)} />&nbsp;&nbsp;&nbsp;Create an app on the {productAuth.product.auth.developerDashboard ? <><a href={productAuth.product.auth.developerDashboard} target="_blank">{product} developer dashboard</a></> : <>{product} developer dashboard</>}<br /></Paragraph>

                            {
                                productAuth.scopes.length ? <Paragraph>
                                    <Switch checkedChildren={<Icon type="check" />} defaultChecked={productAuth.data.step2} onChange={value => this.updateData(product, "step2", value)} />
                                    &nbsp;&nbsp;&nbsp;Add the following scopes : <span>{productAuth.scopes.map(scope => <Text key={scope} code>{scope}</Text>)}<br /></span>
                                </Paragraph> : <span></span>
                            }

                            <Paragraph><Switch checkedChildren={<Icon type="check" />} defaultChecked={productAuth.data.step3} onChange={value => this.updateData(product, "step3", value)} />&nbsp;&nbsp;&nbsp;Add the following redirect_uri : <Paragraph style={{ display: 'inline' }} code copyable>{api.endpoint + "workflow/callback/" + id}</Paragraph></Paragraph>



                            {
                                authMode && <>

                                    <Table bordered pagination={false} dataSource={
                                        authMode.developerDataSchema.map(param => {
                                            return {
                                                key: param.path,
                                                parameter: param.label,
                                                value: { id: param.path, label: param.label }
                                            }

                                        })} columns={[
                                            { title: "Parameter", dataIndex: "parameter", key: "parameter" },
                                            {
                                                title: 'Value', dataIndex: 'value', key: 'value',
                                                render: ({ id, label }) => <Input
                                                    key={id}
                                                    defaultValue={productAuth.data[id]}
                                                    onChange={e => this.updateData(product, id, e.target.value)}
                                                />
                                            }
                                        ]}
                                    />

                                </>
                            }

                        </div>

                    })

                    }

                    {
                        !Object.keys(auth).length && <div></div>
                    }

                </Card>
            </div>

            <div style={{ padding: 24, minHeight: 360, overflowX: "scroll", display: this.state.currentTab === "users" ? 'block' : 'none' }} >
                <WorkflowUsers workflowId={id} history={this.props.history} workflowVariables={this.state.workflowVariables} setWorkflowVariables={inputSchema => this.setState({ workflowVariables: inputSchema })} />
            </div>

            <div style={{ padding: 24, minHeight: 360, overflowX: "scroll", display: this.state.currentTab === "logs" ? 'block' : 'none' }} >
                <WorkflowLogs workflowId={id} history={this.props.history} workflowVariables={this.state.workflowVariables} />
            </div>

        </>

    }
}