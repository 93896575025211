import React from 'react';
import api from '../api'

import { Link } from 'react-router-dom'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import { Spinner } from '@blueprintjs/core'

import { Icon, Layout } from 'antd'

import DynForm from '../components/DynForm'

export default class extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            setupId: "",
            authUrl: "",
            postConsentPromptParameters: [],
            parametersSecuritySchema: {},
            authId: localStorage.getItem('authId'),
            loading: true,
            done: false
        }
    }

    async componentDidMount() {
        const query = this.props.location.search
        const authId = localStorage.getItem('authId')
        const { setupId } = this.props.match.params
        console.log('connectAction/getPostConsentPrompts/' + authId + query)
        const { data } = await api('connectAction/getPostConsentPrompts/' + authId + query)
        const { postConsentPromptParameters: rawPromptParameters, parametersSecuritySchema } = data

        const postConsentPromptParameters = rawPromptParameters.filter(parameter =>
            !parametersSecuritySchema[parameter].description
            || parametersSecuritySchema[parameter].description !== 'afterConsent'
        )

            console.log('POST CONSENT')


        this.setState({ setupId, postConsentPromptParameters, parametersSecuritySchema })

        if (!postConsentPromptParameters.length) {
            console.log({ authId })
            const { data: { userToken, action } } = await api('connectAction/setPostConsentPrompts/' + authId, 'post', { prompts: {} })

            localStorage.setItem('authId', null)

            const redirect_uri = localStorage.getItem('redirect_uri')

            window.localStorage.setItem('savedUserToken', userToken)

            console.log({ redirect_uri })

            if (redirect_uri) {
                let redirectTo = decodeURIComponent(redirect_uri)
                localStorage.removeItem('redirect_uri')
                console.log({ redirect_uri, redirectTo })
                console.log("1")
                window.location = redirectTo + "?userToken=" + userToken + "&action=" + action
            } else {
                this.setState({ done: true })
            }

            // this.setState({ authUrl })

            console.log(data)
        }
    }

    async submitForm({ config: parameters }) {
        const { props: { match, history }, state: { setupId, authId } } = this
        let prompts = parameters

        const { data: { userToken, action } } = await api('connectAction/setPostConsentPrompts/' + authId, 'post', { prompts })

        const redirect_uri = localStorage.getItem('redirect_uri')

        window.localStorage.setItem('savedUserToken', userToken)

        if (redirect_uri) {
            console.log(redirect_uri + "?userToken=" + userToken + "&action=" + action)
            console.log("2")
            window.location = redirect_uri + "?userToken=" + userToken + "&action=" + action
        } else {
            this.setState({ done: true })
        }
    }

    render() {
        const { state: { postConsentPromptParameters, parametersSecuritySchema, authUrl, setupId }, props } = this

        return <React.Fragment><div style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0, zIndex: 100000, backgroundColor: "white" }}>

            <Layout style={{ position: "relative", borderRadius: 5 }}><Layout.Content style={{ maxWidth: 800, backgroundColor: 'white', padding: 30, marginTop: 100, position: "absolute", left: "50%", textAlign: 'center', transform: "translate(-50%, 0)", borderRadius: 10 }}>

                {this.state.done ? <React.Fragment>

                    <Icon type="check-circle" style={{ margin: 5, zoom: 7 }} theme="twoTone" twoToneColor="#52c41a" />

                    <br />

                    <h1 style={{fontSize: 24}}>Authentication done</h1>

                </React.Fragment> :

                    <React.Fragment>{this.state.loading ? <Spinner /> : <React.Fragment />}

                        {/* {this.state.loading ? <p style={{ textAlign: "center" }}><span><br /><br /><h3 className="text-center"><Spinner /></h3></span><br /></p> : <React.Fragment />} */}

                        <DynForm
                            promptVariables={postConsentPromptParameters}
                            securitySchema={parametersSecuritySchema}
                            callback={this.submitForm.bind(this)}
                            buttonText="Connect"
                        />

                        {authUrl && !postConsentPromptParameters.length && <p style={{ textAlign: "center" }}><a href={authUrl}><span><br /><br /><h3 className="text-center"><Spinner /></h3></span><br />Click here if you are not redirected</a></p>}
                    </React.Fragment>
                }
            </Layout.Content></Layout>
        </div></React.Fragment>
    }
}
