import { Alert, Button, Badge, Cascader, Input, Form, Mentions, Collapse, Typography, Select as AntSelect } from 'antd';
import React from 'react';
import stringSimilarity from 'string-similarity';
import { setValue, encapsulate, setArrayValue, getSubtree, getProperties, flatten, getParentProperties, getChildProperties, updateSchema } from '@simplifier/normalization';
import { display, representPropertiesRecursively } from '../utils/workflows';
import ReactJson from 'react-json-view'
import TextareaAutosize from "react-textarea-autosize"
import ReactMarkdown from 'react-markdown'

const { Title } = Typography
const { Option } = Mentions
const { Panel } = Collapse

const capitalize = s => {
	if (typeof s !== "string") return "";
	return s.charAt(0).toUpperCase() + s.slice(1);
};

function buildSelectorTree(properties) {
	function cleanPath(path) {
		return path.replace(/\.\./g, ".");
	}

	function displayRec(props) {
		let origins = props.map(p => cleanPath(p.path).split(".")[0]);
		let uniqueOrigins = [...new Set(origins)];

		return uniqueOrigins.map(origin => {
			let associatedProperty = props.filter(
				p => cleanPath(p.path).split(".")[0] === origin
			).length
				? props.filter(p => cleanPath(p.path) === origin)[0]
				: {};

			const getLabelproperty = function (pr, o) {
				if (!pr) return capitalize(o);
				if (pr.example) {
					return <span title={pr.description}>
						{capitalize(pr.label)}<br />
						<i style={{ fontSize: 12 }}>Ex: {pr.example}</i>
					</span>
				}
				return <span title={pr.description}>{capitalize(pr.label)}</span>;
			};

			return {
				...associatedProperty,
				label: getLabelproperty(associatedProperty, origin),
				path: origin,
				children: displayRec(
					props
						.filter(p => cleanPath(p.path).indexOf(`${origin}.`) === 0)
						.map(p => {
							let newProperty = { ...p };
							newProperty.path = cleanPath(newProperty.path).replace(`${origin}.`, "");
							return { ...newProperty };
						})
				)
			};
		});
	}

	return displayRec(properties);
}


class NormalizationTable extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const { input, availableVariables, change, title, section } = this.props

		const onChange = (p, v, options) => change(input, p, v, options)

		return <div style={{ display: (input && input.length) ? "block" : "none" }}>

			{/* <Button onClick={e => {
    console.log(this.props)
   }}>Reload</Button> */}

			{
				(input || []).filter(property => !property.base).map(property => {
					return <Selector
						base={null}
						onChange={(p, v, options = {}) => onChange(p, v, options)}
						allProperties={input}
						property={property}
						availableVariables={availableVariables}
					/>
				})
			}
		</div>
	}
}

export default class Normalizer extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			schema: props.input,
			input: props.input,
			output: props.output,
			availableVariables: props.availableVariables
		}
	}

	change(properties, property, value, options = {}) {
		let schema
		console.log({ options })
		if (options.isArrayValue) {
			schema = setArrayValue(properties, property.id, value)
		} else {
			schema = setValue(properties, property.id, value)
		}

		this.props.updateStepInput(schema)
	}

	autofill(properties, availableVariables) {

		// console.log({properties, availableVariables})

		function findMostLikely(property) {
			let mostLikelyValue = 0.1
			let mostLikely = null

			for (let variable of availableVariables) {
				let similarity = stringSimilarity.compareTwoStrings(property.path, variable.path)
				if (similarity > mostLikelyValue) {
					mostLikely = '{' + variable.path + '}'
					mostLikelyValue = similarity
				}
			}

			// console.log(mostLikelyValue)

			return mostLikely
		}

		for (let property of properties) {
			if (!property.base && property.type !== "array")
				property.value = findMostLikely(property)
			// console.log(property.path, findMostLikely(property))
			// this.onChange({ value: findMostLikely(property) }, null, property)
		}

		this.props.updateStepInput(properties)
	}

	render() {
		const { apiDocumentation, productHelp, blockHelp, input, availableVariables: av } = this.props

		console.log(this.props)

		const availableVariables = av.filter(item => item.type !== 'object')



		let mostLikelyOption = function (variables, property) {
			let mostLikely = {}
			let mostLikelyValue = 0.1

			for (let variable of variables) {
				let similarity = stringSimilarity.compareTwoStrings(property.path, variable.path)
				if (similarity > mostLikelyValue) {
					mostLikely = Object.assign({}, variable)
					mostLikelyValue = similarity
				}
			}

			if (mostLikely.path !== "leaveempty") mostLikely.label = "⚡️ " + mostLikely.label
			return mostLikely
		}


		return <div>

			{
				apiDocumentation && <>
					<Alert
						message="Method documentation"
						description={<><a href={apiDocumentation} target="_blank">Open in new tab</a></>}
						type="info"
						showIcon
						style={{ marginBottom: 15 }}
					/>
				</>
			}

			{
				productHelp && <>
					<Alert
						message="Product help"
						description={<ReactMarkdown source={productHelp} />}
						type="info"
						showIcon
						style={{ marginBottom: 15 }}
					/>
				</>
			}

			{
				blockHelp && <>
					<Alert
						message="Method help"
						description={<ReactMarkdown source={blockHelp} />}
						type="info"
						showIcon
						style={{ marginBottom: 15 }}
					/>
				</>
			}

			<NormalizationTable
				title="Parameters"
				base={this.props}
				section=""
				input={input} availableVariables={availableVariables} change={this.change.bind(this)}
			/>
		</div>
	}
}

class Selector extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedArray: this.props.property.value
		}
	}

	render() {
		const { availableVariables, onChange, allProperties, property, base } = this.props
		const { selectedArray } = this.state

		if (property.type === "array") {
			return <div>
				<Form.Item
					label={display(property.path)}
					className={property.required ? "ant-form-item-required" : ""}
					style={{ marginBottom: 10 }}
				>
					<AntSelect
						showSearch
						optionFilterProp="children"
						filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						style={{ width: '100%', height: 50, marginBottom: 10 }}
						size="large"
						placeholder={"Click to select a list"}
						value={property.value}
						onChange={(value) => {
							if (!value) {
								this.setState({ selectedArray: value })
								onChange(property, null)
							} else {
								this.setState({ selectedArray: value })
								let path = availableVariables.filter(variable => variable.id === value).map(variable => variable.path)[0]
								if (value === "#single") onChange(property, "#single")
								else onChange(property, path)
							}
						}}
					>
						<AntSelect.Option key={null} value={null}>Leave empty</AntSelect.Option>
						<AntSelect.Option key="#single" value="#single">Single element</AntSelect.Option>
						{
							getProperties(availableVariables)
								.filter(property => property.type === "array" || property.isList)
								.map(model => <AntSelect.Option key={model.path} value={model.id}>{display(model.path)}</AntSelect.Option>)
						}
					</AntSelect>

				</Form.Item>

				{
					selectedArray && selectedArray !== "#single" &&
					<Collapse defaultActiveKey={['0']} style={{ marginTop: "-10px", marginLeft: 2, marginRight: 2 }}>
						<Panel header={"Child properties for " + display(property.path)} key="1">
							{allProperties
								.filter(prop => prop.base === property.id)
								.map(prop => <div><Selector
									base={property.id}
									onChange={(prop, v) => { onChange(prop, v) }}
									allProperties={allProperties}
									property={prop}
									availableVariables={[
										...flatten({ parent: getParentProperties(availableVariables, selectedArray || ""), }),
										...flatten({ current: getChildProperties(availableVariables, selectedArray || "") })
									]}
								/></div>)}
						</Panel>
					</Collapse>
				}

				{
					selectedArray && selectedArray === "#single" && <>

						<Collapse defaultActiveKey={['0']} style={{ marginTop: "-10px", marginLeft: 2, marginRight: 2 }}>
							<Panel header={"Child properties for " + display(property.path)} key="1">
								{allProperties
									.filter(prop => prop.base === property.id)
									.map(prop => <div><Selector
										base={property.id}
										onChange={(prop, v) => { onChange(prop, v) }}
										allProperties={allProperties}
										property={prop}
										availableVariables={[
											...flatten({ parent: getParentProperties(availableVariables, selectedArray || ""), })
										]}
									/></div>)}
							</Panel>
						</Collapse>
					</>
				}
			</div>
		}

		if (property.isList) {
			return <div>
				<Form.Item
					label={display(property.path)}
					className={property.required ? "ant-form-item-required" : ""}
					style={{ marginBottom: 10 }}
				>
					{/* <span>{property.arrayValue} -> {property.value}</span> */}

					<AntSelect
						showSearch
						optionFilterProp="children"
						filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						style={{ width: '100%', height: 50, marginBottom: 10 }}
						size="large"
						placeholder={"Click to select a list"}
						value={property.arrayValue}
						onChange={(value) => {
							if (!value) {
								this.setState({ selectedArray: value })
								onChange(property, null, { isArrayValue: true })
							} else {
								this.setState({ selectedArray: value })
								let path = availableVariables.filter(variable => variable.id === value).map(variable => variable.path)[0]
								if (value === "#single") onChange(property, "#single", { isArrayValue: true })
								else onChange(property, path, { isArrayValue: true })
							}
						}}
					>
						<AntSelect.Option key={null} value={null}>Leave empty</AntSelect.Option>
						<AntSelect.Option key="#single" value="#single">Single element</AntSelect.Option>
						{
							getProperties(availableVariables)
								.filter(property => property.type === "array" || property.isList)
								.map(model => <AntSelect.Option key={model.path} value={model.id}>{display(model.path)}</AntSelect.Option>)
						}
					</AntSelect>

					<table>
						<tr>
							<td style={{ paddingRight: 10, verticalAlign: "top" }}>
								<Cascader
									autoFocus
									popupClassName="popoup"
									expandTrigger="hover"
									fieldNames={{ label: "label", value: "path", children: "children" }}
									options={buildSelectorTree(getProperties([
										...flatten({ parent: getParentProperties(availableVariables, property.arrayValue || ""), }),
										...flatten({ current: getChildProperties(availableVariables, property.arrayValue || "") })
									])
										.filter(property => !property.base && property.type !== "array" && !property.isList))
									}
									onChange={v => onChange(property, (property.value || "") + " {" + v.join(".") + "}")}
									placeholder="Please select"
									value=""
								>
									<Button size="large" type="default" icon="plus" />
								</Cascader>
							</td>
							<td style={{ width: "100%" }}>
								<TextareaAutosize
									className="ant-input"
									onChange={e => onChange(property, e.target.value)}
									value={property.value || ""}
									style={{ minHeight: 40, fontSize: 16, marginLeft: 0, width: "100%" }}
								/>
							</td>
						</tr>
					</table>







				</Form.Item>

				{/* {availableVariables.map(variable => <p>{variable.label} : {variable.path} ({property.base})</p>)} */}

				{
					selectedArray && selectedArray !== "#single" && !property.isList &&
					<Collapse defaultActiveKey={['0']} style={{ marginTop: "-10px", marginLeft: 2, marginRight: 2 }}>
						<Panel header={"Child properties for " + display(property.path)} key="1">
							{allProperties
								.filter(prop => prop.base === property.id)
								.map(prop => <div><Selector
									base={property.id}
									onChange={(prop, v) => { onChange(prop, v) }}
									allProperties={allProperties}
									property={prop}
									availableVariables={availableVariables}
								/></div>)}
						</Panel>
					</Collapse>
				}

				{
					selectedArray && selectedArray === "#single" && !property.isList && <>

						<Collapse defaultActiveKey={['0']} style={{ marginTop: "-10px", marginLeft: 2, marginRight: 2 }}>
							<Panel header={"Child properties for " + display(property.path)} key="1">
								{allProperties
									.filter(prop => prop.base === property.id)
									.map(prop => <div><Selector
										base={property.id}
										onChange={(prop, v) => { onChange(prop, v) }}
										allProperties={allProperties}
										property={prop}
										availableVariables={[
											...flatten({ parent: getParentProperties(availableVariables, selectedArray || ""), })
										]}
									/></div>)}
							</Panel>
						</Collapse>
					</>
				}
			</div>
		}

		return <div>

			<div>
				<Form.Item
					label={display(property.path)}
					className={property.required ? "ant-form-item-required" : ""}
					style={{ marginBottom: 15 }}
					help={<span>{display(property.type)}{property.example ? <span>, Ex.: {property.example}</span> : <span></span>}{property.description ? <span>, {property.description}</span> : <span></span>}</span>}
				>

					<table>
						<tr>
							<td style={{ paddingRight: 10, verticalAlign: "top" }}>
								<Cascader
									autoFocus
									popupClassName="popoup"
									expandTrigger="hover"
									fieldNames={{ label: "label", value: "path", children: "children" }}
									options={buildSelectorTree(getProperties(availableVariables).filter(property => !property.base && property.type !== "array"))}
									onChange={v => onChange(property, (property.value || "") + " {" + v.join(".") + "}")}
									placeholder="Please select"
									value=""
								>
									<Button size="large" type="default" icon="plus" />
								</Cascader>
							</td>
							<td style={{ width: "100%" }}>
								<TextareaAutosize
									className="ant-input"
									// value={this.state.value}
									onChange={e => onChange(property, e.target.value)}
									value={property.value || ""}
									style={{ minHeight: 40, fontSize: 16, marginLeft: 0, width: "100%" }}
								/>
							</td>
						</tr>
					</table>

				</Form.Item>
			</div>
		</div>
	}
}