import { Button, Card, Modal, Table, Typography, Descriptions, Badge, Select, DatePicker, Pagination } from 'antd';
import 'brace/mode/json';
import 'brace/theme/solarized_dark';
import React from 'react';
// import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import api from '../api';
import Log from '../components/Log';

const { Title, Paragraph } = Typography;

export default class WorkflowLogs extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            workflowId: props.workflowId,
            logs: [],
            showModal: false,
            log: {},

            logs_loading: true,

            number_of_logs: 0,
            possible_events: [],
            possible_users: [],
            possible_types: [],
            possible_status: [],

            selected_users: [],
            selected_types: [],
            selected_from_date: null,
            selected_to_date: null,
            selected_log_status: [],
            selected_events: [],
            current_page: 1,
            order_by: "created_at",
            order_type: "desc"
        }
    }

    async componentDidMount() {
        await this.fetch_logs({})

    }

    async fetch_logs(filter) {
        this.setState(filter)

        this.setState({ logs_loading: true })

        const {
            selected_users,
            selected_types,
            selected_from_date,
            selected_to_date,
            selected_log_status,
            selected_events,
            current_page,
            order_by,
            order_type,
        } = { ...this.state, ...filter }

        const { data: { number_of_logs, possible_events, possible_users, possible_types, possible_status } } = await api.post(`logs/workflow_metadata/${this.state.workflowId}`, {
            selected_users,
            selected_types,
            selected_from_date,
            selected_to_date,
            selected_log_status,
            selected_events,
            current_page,
        })

        this.setState({
            number_of_logs,
            possible_events,
            possible_users,
            possible_types,
            possible_status
        })

        const { data: logs } = await api.post(`logs/workflow/${this.state.workflowId}`, {
            selected_users,
            selected_types,
            selected_from_date,
            selected_to_date,
            selected_log_status,
            selected_events,
            current_page,
            order_by,
            order_type,
        })
        this.setState({ logs })

        this.setState({ logs_loading: false })

    }


    render() {
        const { logs, log, workflowId, showModal } = this.state
        const { workflowVariables } = this.props

        const {

            number_of_logs,
            possible_events,
            possible_users,
            possible_types,
            possible_status,

            selected_types,
            selected_from_date,
            selected_to_date,
            selected_log_status,
            selected_events,
            current_page,
            selected_users,
            order_by,
            order_type,
        } = this.state

        const find_event = (_id) => {
            if(!_id) return undefined
            return possible_events.find(event => event._id === _id)
        }

        return <Card>
            <div style={{ float: 'right' }}>
                <Button size="large" icon="plus" onClick={() => { this.fetch_logs({}) }} icon="reload" /> &nbsp; &nbsp;
                {/* <Button size="large" icon="plus" type="primary" onClick={() => this.props.history.push("/workflow/auth/" + workflowId)}>New user</Button> */}
            </div>

            <Modal
                title={"Log"}
                visible={this.state.showModal}
                width={1200}
                style={{ maxWidth: "95%" }}
                onOk={async () => { this.setState({ log: {}, showModal: false }) }}
                onCancel={async () => { this.setState({ log: {}, showModal: false }) }}
            >
                {/* {log._id} */}
                {log._id && <Log log={log} />}
            </Modal>


            <Title>Logs</Title>

            <Descriptions title="Filters" bordered>
                <Descriptions.Item label="User" span={3}>

                    {/* {JSON.stringify(possible_users)} */}

                    <Select mode="tags" style={{ width: '100%' }} placeholder="Filter" onChange={(v) => { this.fetch_logs({ selected_users: v }); }}>
                        {possible_users.map(user => <Select.Option key={user._id}>{user._id} (<i>{user.count}</i>)</Select.Option>)}
                    </Select>

                </Descriptions.Item>
                <Descriptions.Item label="Min. date" span={3}>
                    <DatePicker showTime placeholder="Select date and time" onChange={(d) => { if (!d) { this.fetch_logs({ selected_from_date: null }) } else { this.fetch_logs({ selected_from_date: d.toISOString() }) } }} onOk={(d) => { if (d) this.fetch_logs({ selected_from_date: d.toISOString() }) }} />
                </Descriptions.Item>
                <Descriptions.Item label="Max. date" span={3}>
                    <DatePicker showTime placeholder="Select date and time" onChange={(d) => { if (!d) { this.fetch_logs({ selected_to_date: null }) } else { this.fetch_logs({ selected_to_date: d.toISOString() }) } }} onOk={(d) => { if (d) this.fetch_logs({ selected_to_date: d.toISOString() }) }} />
                </Descriptions.Item>
                <Descriptions.Item label="Type" span={3}>
                    <Select mode="tags" style={{ width: '100%' }} placeholder="Filter" value={this.state.selected_types} onChange={(v) => { this.fetch_logs({ selected_types: v }); }}>
                        {possible_types.map(type => <Select.Option key={type._id}>{type._id} ({type.count})</Select.Option>)}
                    </Select>
                </Descriptions.Item>
                <Descriptions.Item label="Status" span={3}>
                    <Select mode="tags" style={{ width: '100%' }} placeholder="Filter" value={this.state.selected_log_status} onChange={(v) => { this.fetch_logs({ selected_log_status: v }); }}>
                        {possible_status.map(type => <Select.Option key={type._id}>{type._id} ({type.count})</Select.Option>)}
                    </Select>
                </Descriptions.Item>
                <Descriptions.Item label="Event name" span={3}>
                    <Select mode="tags" style={{ width: '100%' }} placeholder="Filter" value={this.state.selected_events} onChange={(v) => { this.fetch_logs({ selected_events: v }); }}>
                        {possible_events.map(type => <Select.Option key={type._id}>{type.name || 'No event'} ({type.count})</Select.Option>)}
                    </Select>
                </Descriptions.Item>
            </Descriptions>

            <br /><br />

            <Pagination onChange={page => { this.fetch_logs({ current_page: page }) }} total={number_of_logs} pageSize={20} current={this.state.current_page} />


            <br />
            <><Table
                loading={this.state.logs_loading}
                bordered
                columns={[
                    {
                        title: "User", dataIndex: "user", key: "user",
                        render: user => { return <Paragraph><a key={0} href={`/users/${user}`} target="_blank">{user}</a></Paragraph>; },
                        // filters: [...new Set(logs.map(log => log.user))].map(user => ({ text: user, value: user })),
                        // onFilter: (value, record) => record.user === value,
                    },
                    { title: "Date", dataIndex: "startTime", key: "date", render: data => <>{(new Date(data)).toISOString().replace('Z', '').split('T').join(' at ')} (GMT+0)</> },
                    { title: "Start time", dataIndex: "startTime", key: "startTime", render: data => <><TimeAgo date={data} /></> },
                    { title: "Duration", dataIndex: "startTime", key: "startTimeX", render: (data, record) => record.endTime ? <>{record.endTime - record.startTime} ms</> : <>—</> },
                    { title: "Type", dataIndex: "sequence.type", key: "sequence.type", },
                    { title: "Path", dataIndex: "sequence.apiPath", key: "sequence.apiPath", render: (data, record) => record.sequence && record.sequence.apiPath ? <>/{record.sequence.apiPath}</> : <>—</>, },
                    { title: "Event", dataIndex: "sequenceFilter.event", key: "sequenceFilter.event", render: (data, record) => record.sequenceFilter && record.sequenceFilter.event ? <>{find_event(record.sequenceFilter.event) ? <a href={`/events/${record.sequenceFilter.event}`} target="_blank">{find_event(record.sequenceFilter.event).name}</a> : '—'}</> : <>—</>, },
                    {
                        title: "—", dataIndex: "user", key: "open", render: (data, record) => <Button block type="default" onClick={async () => {
                            const { data: log } = await api("logs/request/" + record._id);
                            this.setState({
                                log,
                                showModal: true
                            });
                        }}>Open</Button>
                    },
                ]}
                dataSource={logs}
                pagination={false} />

                <br />

                <Pagination onChange={page => { this.fetch_logs({ current_page: page }) }} total={number_of_logs} pageSize={20} current={this.state.current_page} /></>


        </Card>
    }
}