import { Button, Col, Modal, PageHeader, Row, Statistic, Table, Tabs, Typography } from 'antd';
import React from 'react';
import ReactJson from 'react-json-view';
import TimeAgo from 'react-timeago';
import api from '../api';

const { TabPane } = Tabs
const { Paragraph } = Typography;

export default class Logs extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            rawData: [],
            type: 'all',
            hasNext: true,
            skip: 0,
            limit: 25
        }
    }

    async componentDidMount() {
        const { skip, limit, type } = this.state
        const { projectId } = this.props.match.params

        this.setState({ loader: true })

        const { data: { results: rawData, hasNext, count, countError } } = await api(`logs/${projectId}/${limit}/${skip}/${type}`)

        console.log({ rawData, hasNext, count, countError })

        this.setState({ rawData, skip: limit, hasNext, loader: false, count, countError })
    }

    async reload() {
        const { skip, limit, type } = this.state
        const { projectId } = this.props.match.params

        this.setState({ rawData: [], skip: 0, hasNext: true, reloadLoader: true })

        const { data: { results: rawData, hasNext, count, countError } } = await api(`logs/${projectId}/${limit}/0/${type}`)

        console.log(hasNext)

        this.setState({ rawData, skip: limit, hasNext, reloadLoader: false, count, countError })
    }

    async getMore() {
        const { skip, limit, type, rawData: oldData } = this.state
        const { projectId } = this.props.match.params

        this.setState({ loader: true })

        const { data: { results: rawData, hasNext, count, countError } } = await api(`logs/${projectId}/${limit}/${skip}/${type}`)

        this.setState({ rawData: [...oldData, ...rawData], skip: skip + limit, hasNext, loader: false, count, countError })
    }

    async changeTab(type) {
        const { skip, limit } = this.state
        const { projectId } = this.props.match.params

        this.setState({ loader: true })

        const { data: { results: rawData, hasNext, count, countError } } = await api(`logs/${projectId}/${limit}/0/${type}`)

        this.setState({ rawData, skip: limit, type, hasNext, loader: false, count, countError })
    }

    showModal(content) {
        this.setState({
            showModal: true,
            modalContent: content
        })
    }

    hideModal() {
        this.setState({
            showModal: false,
            modalContent: {}
        })
    }

    render() {
        const { rawData, count, countError } = this.state
        const { projectId } = this.props.match.params

        const data = rawData

        const columns = [
            { title: 'ID', dataIndex: '_id', key: '_id', },
            { title: 'Date', dataIndex: 'createdAt', key: 'createdAt', render: text => <span title={text}><TimeAgo date={text} /></span> },
            { title: 'Setup ID', dataIndex: 'setupId', key: 'setupId', render: text => <span><a href={`/setups/${text}`}>{text}</a></span>, },
            { title: 'User Token', dataIndex: 'userId', key: 'userId', render: text => <span>{text}</span>, },
            { title: 'Response', dataIndex: 'response', key: 'response', render: text => <i>Expand to see</i>, },
            { title: 'Error', dataIndex: 'error', key: 'error', render: text => text ? <i>Expand to see</i> : <span>—</span>, },
            { title: 'Webhook Response', dataIndex: 'webhookResponse', key: 'webhookResponse', render: text => <i>Expand to see</i>, }
        ];

        return <React.Fragment>

            <PageHeader
                title="Logs"
                style={{ backgroundColor: 'white' }}
                onBack={() => this.props.history.push(`/${projectId}/scenarios`)}
                extra={[
                    <Button key={0} loader={this.state.reloadLoader} type="default" size="large" icon="reload" onClick={this.reload.bind(this)}></Button>
                ]}
            >
                <div className="wrap">
                    <div className="content">

                        <Row gutter={16}>
                            <Col span={12}>
                                <Statistic title="Invocations" value={count} />
                            </Col>
                            <Col span={12}>
                                <Statistic title="Errors" value={countError} />
                            </Col>
                        </Row>

                        <br />

                        <Modal
                            title="Basic Modal"
                            visible={this.state.showModal || false}
                            onOk={this.hideModal.bind(this)}
                            onCancel={this.hideModal.bind(this)}
                            width={850}
                            style={{ maxWidth: "95%" }}
                        >
                            <ReactJson src={this.state.modalContent || {}} />
                        </Modal>

                        <Tabs onChange={this.changeTab.bind(this)} size="large">
                            <TabPane tab="All the logs" key="all"></TabPane>
                            <TabPane tab="Errors" key="errors"></TabPane>
                        </Tabs>


                        <Table
                            columns={columns.map(item => ({ ...item }))}
                            dataSource={data}
                            pagination={false}
                            // expandRowByClick
                            expandedRowRender={record => {

                                let cleanResponse = {}
                                let cleanError = {}
                                let cleanWebhookResponse = {}

                                try {
                                    cleanResponse = JSON.parse(record.response)
                                } catch (error) {
                                    cleanResponse = { response: record.response }
                                }

                                try {
                                    cleanError = JSON.parse(record.error)
                                } catch (error) {
                                    cleanError = { error: record.error }
                                }

                                try {
                                    cleanWebhookResponse = JSON.parse(record.webhookResponse)
                                } catch (error) {
                                    cleanWebhookResponse = { webhookResponse: record.webhookResponse }
                                }

                                delete cleanResponse._rawData

                                return <Row style={{ margin: 0 }}>
                                    <Col span={8}>
                                        <h2>Response &nbsp;<Button type="primary" onClick={() => {
                                            this.showModal(JSON.parse(record.response)._rawData)
                                        }}>Raw data</Button></h2>

                                        <ReactJson src={cleanResponse} />
                                    </Col>
                                    <Col span={8}>
                                        <h2>Error</h2>
                                        {
                                            record.error ?
                                                <ReactJson src={cleanError} />
                                                : <i>No error</i>
                                        }
                                    </Col>
                                    <Col span={8}>
                                        <h2>Webhook response</h2>
                                        <ReactJson src={cleanWebhookResponse} />
                                    </Col>

                                </Row>
                            }}
                        />

                        <br />
                        {this.state.hasNext}
                        {this.state.hasNext && <Button block size='large' loading={this.state.loader} onClick={this.getMore.bind(this)}>Get more</Button>}
                    </div>
                </div>
            </PageHeader>
        </React.Fragment>
    }
}
