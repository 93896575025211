import { Button, Col, Layout, Row } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import auth0Client from '../Auth';


const { Header } = Layout

function NavBar(props) {
	const signOut = () => {
		auth0Client.signOut();
		props.history.replace('/');
	};

	console.log(auth0Client)

	return <Header style={{ background: '#fff', padding: 0, boxShadow: "0 1px 4px rgba(0,21,41,.08)", zIndex: "100000" }}>
		
		<Row>
			{/* {auth0Client.profile ? auth0Client.profile.name : "no profile"} */}
			<Col span={3} style={{paddingLeft: 10}}><h2 style={{margin: 0, marginLeft: 15}}>Simplifier</h2></Col>
			<Col span={19} style={{paddingLeft: 10, marginTop: 17}}>{props.children}</Col>
			<Col span={2} style={{paddingLeft: 10}}>{auth0Client.isAuthenticated() ? <><Button key="2" style={{ borderRadius: 50 }} onClick={() => { signOut() }}>Log out</Button></> : <Button key="2" style={{ borderRadius: 50 }} onClick={auth0Client.signIn}>Log in</Button>}</Col>
		</Row>
	
	</Header>
}

export default withRouter(NavBar);