import { Spinner } from "@blueprintjs/core";
import { Avatar, Button, Card, Icon, PageHeader, List, Tabs, Steps, Typography } from 'antd';
import React from 'react';
import api from '../api';
import ReactJson from 'react-json-view'
import TimeAgo from 'react-timeago';

const { Paragraph, Text } = Typography

export default class Scenarios extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			user: {},
			tab: "workflows",
			variables: []
		}
	}

	async componentDidMount() {
		const { userToken } = this.props.match.params
		console.log(`users/${userToken}`)
		const { data: { user } } = await api(`users/${userToken}`)

		const { data: variables } = await api(`users/${userToken}/variables`)

		console.log(variables)

		console.log({ user })


		this.setState({ user, variables })
	}


	render() {
		const { user, variables } = this.state

		const shouldRefreshAccess = function (access) {
			let shouldRefresh = false

			if (!access.parameters) {
				shouldRefresh = false
			}
			else if (access.parameters.refresh_token && access.parameters.expires_in && new Date((new Date()).getTime() + 60000 * 5).getTime() > new Date((new Date(access.updatedAt)).getTime() + 1000 * access.parameters.expires_in).getTime()) {
				shouldRefresh = true
			}

			return shouldRefresh
		}

		if (!user) { return <p>Loading</p> }

		if (!user.token) {
			return <React.Fragment>
				<PageHeader
					// title={this.props.match.params.userToken}
					title="User"
					style={{ backgroundColor: 'white' }}
				>
					<br /><br /><h3 className="text-center"><Spinner /></h3><br /><br />
				</PageHeader>
			</React.Fragment>
		}

		let workflowAuths = user.auths

		// for (let access of user.accesses) {
		// 	for (let workflowAuth of access.auths) {
		// 		if (!workflowAuths.map(w => w.id).includes(workflowAuth.id)) {
		// 			workflowAuths.push(workflowAuth)
		// 		}
		// 	}
		// }

		return <React.Fragment>

			<PageHeader
				title={user.token}
				// subTitle={block.description}
				// onBack={() => this.props.history.push(`/products/${block.product.id}`)}
				style={{ backgroundColor: 'white' }}
				footer={
					<Tabs defaultActiveKey="workflows" onChange={value => this.setState({ tab: value })}>
						<Tabs.TabPane tab="Workflows" key="workflows" />
						<Tabs.TabPane tab="Authenticated products" key="products" />
					</Tabs>
				}
			>

			</PageHeader>



			<div style={{ display: this.state.tab === "workflows" ? "block" : "none" }}>
				{
					workflowAuths.map(workflowAuth => {

						let workflowVariables = {}

						for (let variable of variables) {
							if (variable.workflowId === workflowAuth.workflow.id) {
								workflowVariables = variable.data
							}
						}

						return <div style={{ margin: 20 }} hoverable ><PageHeader
							ghost={false}
							title={<a href="#" onClick={() => this.props.history.push("/workflows/" + workflowAuth.workflow._id)}>{workflowAuth.workflow.name || workflowAuth.workflow._id}</a>}
						>
							<h3>Variables</h3>
							<ReactJson src={workflowVariables} />
						</PageHeader></div>
					})
				}
			</div>


			<div style={{ display: this.state.tab === "products" ? "block" : "none" }}>

				{
					user.accesses.map(access => {

						return <div style={{ margin: 20 }}>
							<PageHeader
								ghost={false}
								// avatar={{ src: access.product.logo }}
								title={access.product.name}
								extra={[
									<div>
										<div>{access.done ? <Text strong>Authentication process done</Text> : <Text strong type="danger">Authentication failed</Text>}</div>
										{access.done && <div>{(shouldRefreshAccess(access)) ? <Text strong type="warning">Authentication is expired</Text> : <Text strong>Authentication activated and up to date</Text>}</div>}
									</div>

									// <div>{access.scopes && <div>Allowed scopes : {access.scopes}</div>}</div>

								]}
							>

								<Paragraph>

									<div>App connected <span title={access.createdAt}><TimeAgo date={access.createdAt} /></span></div>
									{access.scopes && <div>Allowed scopes : {access.scopes}</div>}
								</Paragraph>

								{/* <Steps progressDot current={1} status="error">
									<Steps.Step title="App connected" description={<span title={access.createdAt}><TimeAgo date={access.createdAt} /></span>} />
									<Steps.Step title="Fresh auth" description="Authentication still active" />
									<Steps.Step title="Workflow executed" description="W" />
								</Steps> */}

								<ReactJson collapsed src={access.parameters} />
							</PageHeader>

						</div>
					})
				}

			</div>

		</React.Fragment>
	}
}
