import {
    EuiButton,
    EuiButtonIcon,
    EuiContextMenuPanel,
    EuiFieldText,
    EuiFormRow, EuiIcon,
    EuiPopover
} from "@elastic/eui";
import { Button, Card, Cascader, Icon, Mentions, Popconfirm, Select as AntSelect } from 'antd';
import React from 'react';
import { encapsulate, flatten, getChildProperties, getParentProperties, getProperties } from '@simplifier/normalization'
import { deleteStep, display, updateStep, buildSelectorTree, singularize } from '../../utils/workflows';
import StepCard from "../CardComponents";
import Steps from '../Steps';

const { Option } = Mentions

export default class For2Step extends React.Component {
    constructor(props) {
        super(props)

        this.state = { popoverOpen: false }
    }
    render() {

        const {
            step,
            localWorkflow,
            availableVariables,
            workflow,
            availableMethods,
            products,
            updateWorkflow,
            updateLocalWorkflow,
            updateAvailableMethods,
            databases,
            workflowVariables,
        } = this.props


        const entityName = step.forEachPath ?
            step.forEachPath.split(".")[step.forEachPath.split(".").length - 1] :
            "items"

        const [entityLabel, entityPath] = singularize(entityName)

        console.log({ singularize: singularize(entityName) })

        let variablesToInherit = getParentProperties(availableVariables, step.forEach || "")
        // ...flatten({ parent: getParentProperties(availableVariables, step.forEach || ""), }),

        let arr = {}
        arr[entityPath] = getChildProperties(availableVariables, step.forEach || "")
        variablesToInherit = [...flatten(arr), ...variablesToInherit]
        
        
        
        console.log([entityLabel, entityPath], variablesToInherit)


        return <React.Fragment>

            <table className='workflowTable' style={{ height: '100%' }}><tbody>
                <tr style={{ height: '100%' }} className="sticky-block-content">

                    {/* <td rowspan="2" valign="top" style={{ height: '100%', position: 'relative' }}> */}
                    {/* <Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-25px', top: '150px' }} /> */}


                    <StepCard
                        arrow={<EuiIcon type="arrowRight" />}
                        title="Loop"
                        iconsLeft={[]}
                        iconsRight={[
                            <EuiPopover
                                id="contentPanel"
                                anchorPosition="rightUp"
                                button={<EuiButtonIcon size="l" iconType="trash" onClick={() => this.setState({ popoverOpen: !this.state.popoverOpen })} />}
                                isOpen={this.state.popoverOpen}
                                closePopover={() => this.setState({ popoverOpen: false })}
                                panelPaddingSize="s"
                            >
                                <EuiContextMenuPanel>
                                    <EuiFormRow label="Are you sure?">
                                        <EuiButton
                                            onClick={() => {
                                                let newWorkflow = deleteStep(workflow, step)
                                                updateWorkflow(newWorkflow)
                                            }}
                                            iconType="trash"
                                            color="danger"
                                        >
                                            Delete that step
										</EuiButton>
                                    </EuiFormRow>
                                </EuiContextMenuPanel>
                            </EuiPopover>
                        ]}>

                    <p style={{marginTop: 5}}>For each {entityName && <strong>{singularize(entityName)[1]} </strong>}of...</p>

                        <Cascader
                            autoFocus
                            popupClassName="popoup"
                            expandTrigger="hover"
                            fieldNames={{ label: "label", value: "path", children: "children" }}
                            options={buildSelectorTree(
                                getProperties([...availableVariables, ...encapsulate(workflowVariables, "storage")]).filter(property => property.type === "array")
                            )}
                            // onChange={v => onChange(property, (property.value || "") + " {" + v.join(".") + "}")}
                            onChange={(selected, s) => {
                                console.log({
                                    id: s[s.length - 1].id,
                                    path: selected.join('.')
                                })

                                const newWorkflow = updateStep(localWorkflow, {
                                    ...step,
                                    forEach: s[s.length - 1].id,
                                    forEachPath: selected.join('.')
                                })

                                console.log({ newWorkflow })
                                updateLocalWorkflow(newWorkflow)
                            }}
                            placeholder="Please select"
                            value=""
                        >
                            <EuiFormRow label=" ">
                                <EuiFieldText
                                    placeholder="data.users"
                                    value={step.forEach ? step.forEachPath : undefined}
                                    append={
                                        <EuiPopover
                                            button={<EuiButtonIcon iconType="arrowDown" aria-label="Popover" />}
                                            closePopover={() => { }}
                                        />
                                    }
                                    onChange={e => { }}
                                />
                            </EuiFormRow>
                        </Cascader>



                    </StepCard>

                    <td style={{ height: '100%', position: 'relative' }}>
                        {/* <Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-24px', top: '100px' }} /> */}
                        {/* <Icon type="caret-right" style={{ fontSize: '24px', width: 50, position: 'absolute', left: '-24px', top: '126px' }} /> */}
                        <table className='workflowTable'><tbody><tr>

                            <Steps
                                steps={step.do}
                                sequenceId={step.sequenceId}
                                workflow={workflow}
                                availableMethods={availableMethods}
                                products={products}
                                updateWorkflow={updateWorkflow.bind(this)}
                                updateAvailableMethods={updateAvailableMethods}
                                inheritedVariables={variablesToInherit}
                                workflowVariables={workflowVariables}
                                databases={databases}
                            />
                        </tr></tbody></table>
                    </td>
                </tr>

            </tbody></table>
        </React.Fragment>
    }
}